/**
 * Redux Store
 */
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from "redux-saga";
import reducers from '../reducers';
import RootSaga from "../sagas";
import history from '../history'
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();



// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

export default createStore(reducers,
    applyMiddleware(middleware, sagaMiddleware)
)

sagaMiddleware.run(RootSaga);
