/**
 * Audience Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addCustomDomainFailure,
  addCustomDomainSuccess,
  deleteCustomDomainFailure,
  deleteCustomDomainSuccess,
  getCustomDomainsFailure,
  getCustomDomainsSuccess,
  refreshCustomDomains,
  renewCustomDomainFailure,
  renewCustomDomainSuccess,
  validateCustomDomainFailure,
  validateCustomDomainSuccess,
  validateCustomDomainDnsSuccess,
  validateCustomDomainDnsFailure
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const customDomains = (state) => state.customDomainReducer;
export const authUser = (state) => state.authUser;

const getCustomDomainsFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain.id },
  };

  return await getAxiosRequest().get(
    `/manage/customer/${currentDomain.customerId}/customdomain`,
    config
  );
};

const addCustomDomainToServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain.id },
  };
  return await getAxiosRequest().post(
    `/manage/customer/${model.currentDomain.customerId}/customdomain`,
    model.customDomain,
    config
  );
};

const validateCustomDomainOnServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain.id },
  };

  model.customDomain.status = "Created"; 
  return await getAxiosRequest().put(
    `/manage/customer/${model.currentDomain.customerId}/customdomain/${model.customDomain.id}/validate`,
    model.customDomain,
    config
  );
};

const validateCustomDomainDnsOnServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain.id },
  };
  return await getAxiosRequest().get(
    `/manage/customer/${model.currentDomain.customerId}/customdomain/validate/dns/${model.customDomain.domain}`,
    config
  );
};


const renewCustomDomainOnServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain.id },
  };
  return await getAxiosRequest().put(
    `/manage/customer/${model.currentDomain.customerId}/customdomain/${model.customDomain.id}/renew`,
    model.customDomain,
    config
  );
};

const removeCustomDomain = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain.id },
  };
  return await getAxiosRequest().delete(
    `/manage/customer/${model.currentDomain.customerId}/customdomain/${model.customDomain.id}`,
    config
  );
};

/**
 * Get Custom Domains
 */
function* getCustomDomains() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    const response = yield call(getCustomDomainsFromServer, user.currentDomain);
    yield put(getCustomDomainsSuccess(response));
  } catch (error) {
    yield put(getCustomDomainsFailure(error.response.data.error));
  }
}
/**
 * Add Custom Domain
 */
function* addCustomDomain() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(customDomains); // <-- get the model
    let customDomain = {
      domain: model.customDomain.domain,
    };
    var customDomainModel = {
      customDomain: customDomain,
      currentDomain: user.currentDomain,
    };
    const response = yield call(addCustomDomainToServer, customDomainModel);
    yield put(addCustomDomainSuccess(response));
  } catch (error) {
    yield put(addCustomDomainFailure(error.response.data.error));
  }
}

/**
 * Validate Custom Domain
 */
function* validate() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(customDomains); // <-- get the model

    var customDomainModel = {
      customDomain: model.customDomain,
      currentDomain: user.currentDomain,
    };

    const response = yield call(
      validateCustomDomainOnServer,
      customDomainModel
    );
    yield put(validateCustomDomainSuccess(response));
  } catch (error) {
    yield put(validateCustomDomainFailure(error.response.data.error));
  }
}


/**
 * Validate Custom Domain Dns
 */
 function* validateDns() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(customDomains); // <-- get the model
    var customDomainModel = {
      customDomain: model.customDomainDns,
      currentDomain: user.currentDomain,
    };
  

    const response = yield call(
      validateCustomDomainDnsOnServer,
      customDomainModel
    );
    yield put(validateCustomDomainDnsSuccess(response));
  } catch (error) {
    yield put(validateCustomDomainDnsFailure(error.response.data.error));
  }
}


/**
 * Renew Custom Domain
 */
function* renew() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(customDomains); // <-- get the model

    var customDomainModel = {
      customDomain: model.customDomain,
      currentDomain: user.currentDomain,
    };

    const response = yield call(renewCustomDomainOnServer, customDomainModel);
    yield put(renewCustomDomainSuccess(response));
  } catch (error) {
    yield put(renewCustomDomainFailure(error.response.data.error));
  }
}

/**
 * Delete Custom Domain
 */
function* deleteCustomDomain() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(customDomains); // <-- get the model
    var customDomainModel = {
      customDomain: model.customDomain,
      currentDomain: user.currentDomain,
    };

    const response = yield call(removeCustomDomain, customDomainModel);
    yield put(deleteCustomDomainSuccess(response));
    const refreshResponse = yield call(
      getCustomDomainsFromServer,
      user.currentDomain
    );
    yield put(refreshCustomDomains(refreshResponse));
  } catch (error) {
    if (error.response) {
      yield put(deleteCustomDomainFailure(error.response.data.error));
    } else {
      yield put(deleteCustomDomainFailure(error));
    }
  }
}

export const customDomainSagas = [
  takeEvery("GET_CUSTOMDOMAINS", getCustomDomains),
  takeEvery("ADD_CUSTOMDOMAIN", addCustomDomain),
  takeEvery("VALIDATE_CUSTOMDOMAIN", validate),
  takeEvery("RENEW_CUSTOMDOMAIN", renew),
  takeEvery("DELETE_CUSTOMDOMAIN", deleteCustomDomain),
  takeEvery("VALIDATE_CUSTOMDOMAIN_DNS", validateDns),
];


/**
 * Custom Domain Root Saga
 */
export default function* rootSaga() {
  yield all([...customDomainSagas]);
}
