import React from "react";
import AppConfig from "../../../constants/AppConfig";

const RedirectPage = () => {
  return (
    <>
      <div className="container-fluid">
        <div
          className="jumbotron text-center"
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <p className="lead">
            We have upgraded and have a brand new UI experience for you.
          </p>
          <p className="lead">
            Please login to <a href={AppConfig.appV2Url}>adminv2.gravito.net</a>
          </p>
        </div>
      </div>
      <div style={{ position: "fixed", bottom: 0, zIndex: -1 }}>
        <img
          src={require("../../../assets/illustrations/progress-hd.png").default}
          className="img-fluid"
          alt=""
        />
      </div>
    </>
  );
};
export default RedirectPage;
