/* eslint-disable no-unused-vars */
/**
 * Feedback Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { sendFeedbackFailure, sendFeedbackSuccess } from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

// Get default consents from the state...
export const settings = (state) => state.settings;

// Get user consents for the default domain
const feedback = async (model) => {
  return await getAxiosRequest().post("user/contactus?message=" + model, null);
};

/**
 * Send Feedback
 */
function* sendFeedback() {
  try {
    let model = yield select(settings); // <-- get the model
    const response = yield call(feedback, model.feedback);
    yield put(sendFeedbackSuccess(response.data));
  } catch (error) {
    yield put(sendFeedbackFailure(error));
  }
}

export const feedbackSagas = [takeEvery("SEND_FEEDBACK", sendFeedback)];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...feedbackSagas]);
}
