/* eslint-disable import/no-anonymous-default-export */
/*
    Domain Properties Reducer
    Curd Operations on Domain Properties.
*/
import { NotificationManager } from "react-notifications";
import {
  ACTIVATE_DOMAIN_PROPERTY,
  ACTIVATE_DOMAIN_PROPERTY_FAILURE,
  ACTIVATE_DOMAIN_PROPERTY_SUCCESS,
  ADD_DOMAIN_PROPERTY,
  ADD_DOMAIN_PROPERTY_FAILURE,
  ADD_DOMAIN_PROPERTY_SUCCESS,
  DELETE_DOMAIN_PROPERTY,
  DELETE_DOMAIN_PROPERTY_FAILURE,
  DELETE_DOMAIN_PROPERTY_SUCCESS,
  GET_DOMAINS_FOR_PROPERTY,
  GET_DOMAINS_FOR_PROPERTY_FAILURE,
  GET_DOMAINS_FOR_PROPERTY_SUCCESS,
  GET_DOMAIN_PROPERTIES,
  GET_DOMAIN_PROPERTIES_FAILURE,
  GET_DOMAIN_PROPERTIES_FOR_SELECTED_DOMAIN,
  GET_DOMAIN_PROPERTIES_SUCCESS,
  SELECT_DOMAIN_PROPERTY,
  UPDATE_DOMAIN_PROPERTY,
  UPDATE_DOMAIN_PROPERTY_FAILURE,
  UPDATE_DOMAIN_PROPERTY_SUCCESS,
} from "../actions/types";
// initial state
const INIT_STATE = {
  domains: [],
  selectedDomain: null,
  domainProperties: [],
  property: null,
  selected: null,
  redirect: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SELECT_DOMAIN_PROPERTY:
      return {
        ...state,
        loading: false,
        selected: action.payload,
        redirect: false,
      };
    // Get domain properties from server.
    case GET_DOMAIN_PROPERTIES:
      return { ...state, loading: true };
    // On Success...
    case GET_DOMAIN_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        domainProperties: action.payload.data,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_DOMAIN_PROPERTIES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    //ADD new domain property
    case ADD_DOMAIN_PROPERTY:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please enter valid domain property details",
        };
      }
      //get the domain and use it for passing it in header... when the request is made by gravito admin
      return {
        ...state,
        loading: true,
        property: action.payload.property,
        selectedDomain: action.payload.domainName,
        action: "ADD",
        redirect: false,
      };

    case ADD_DOMAIN_PROPERTY_SUCCESS:
      NotificationManager.success("Domain Property added successfully.");
      return { ...state, loading: false, property: null, redirect: true };

    case ADD_DOMAIN_PROPERTY_FAILURE:
      NotificationManager.error("Error while adding new domain property.");
      return { ...state, loading: false, property: null, redirect: false };
    //Edit domain property
    case UPDATE_DOMAIN_PROPERTY:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please enter valid domain property details",
        };
      }

      return {
        ...state,
        loading: true,
        selected: action.payload,
        action: "Edit",
      };

    case UPDATE_DOMAIN_PROPERTY_SUCCESS:
      NotificationManager.success("Domain Property updated successfully.");
      return {
        ...state,
        loading: false,
        selected: null,
        selectedDomain: null,
        domainProperties: [],
        redirect: true,
      };

    case UPDATE_DOMAIN_PROPERTY_FAILURE:
      NotificationManager.error("Error while editing  domain property.");
      return { ...state, loading: false, selected: null, redirect: false };

    //Edit domain property
    case DELETE_DOMAIN_PROPERTY:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please enter valid domain property details",
        };
      }

      return {
        ...state,
        loading: true,
        selected: action.payload,
        action: "Edit",
      };

    case DELETE_DOMAIN_PROPERTY_SUCCESS:
      NotificationManager.success("Domain Property deleted successfully.");
      return { ...state, loading: false, selected: null };

    case DELETE_DOMAIN_PROPERTY_FAILURE:
      NotificationManager.error("Error while deleting domain property.");
      return { ...state, loading: false, selected: null };

    case ACTIVATE_DOMAIN_PROPERTY:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please enter valid domain property details",
        };
      }

      return {
        ...state,
        loading: true,
        property: action.payload,
        redirect: false,
      };

    case ACTIVATE_DOMAIN_PROPERTY_SUCCESS:
      NotificationManager.success("Domain Property activated successfully.");
      return {
        ...state,
        loading: false,
        property: null,
        selectedDomain: null,
        domainProperties: [],
        redirect: true,
      };

    case ACTIVATE_DOMAIN_PROPERTY_FAILURE:
      NotificationManager.error("Error while activating domain property.");
      return { ...state, loading: false, property: null, redirect: false };

    case GET_DOMAINS_FOR_PROPERTY:
      return { ...state, loading: true };
    case GET_DOMAINS_FOR_PROPERTY_SUCCESS:
      if (action.payload.data) {
        var domainList = [];
        action.payload.data.forEach((element) => {
          domainList.push({ label: element.domain, value: element.id });
        });
      }
      return { ...state, loading: false, domains: domainList };

    case GET_DOMAINS_FOR_PROPERTY_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case GET_DOMAIN_PROPERTIES_FOR_SELECTED_DOMAIN:
      return { ...state, loading: true, selectedDomain: action.payload };

    default:
      return { ...state };
  }
};
