/**
 * Redux Actions for managing crud operations on Applications.
 */

import {
    GET_APPLICATIONS,
    GET_APPLICATIONS_SUCCESS,
    GET_APPLICATIONS_FAILURE,
    ADD_APPLICATION,
    ADD_APPLICATION_SUCCESS ,
    ADD_APPLICATION_FAILURE ,
    UPDATE_APPLICATION,
    UPDATE_APPLICATION_SUCCESS ,
    UPDATE_APPLICATION_FAILURE ,
    DELETE_APPLICATION ,
    DELETE_APPLICATION_SUCCESS,
    DELETE_APPLICATION_FAILURE,
    FILLTER_APPLICATIONS,
    REFRESH_APPLICATIONS,
    FILLTER_APPLICATIONS_BY_TYPE,
    SELECT_APPLICATION,
} from './types';

/**
 * Redux action to get APPLICATIONS
 */
export const getApplications =()=>({
    type: GET_APPLICATIONS
})

/**
 * Redux action for successful fetching of APPLICATIONS
 */

export const getApplicationsSuccess =(response)=>({
    type: GET_APPLICATIONS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching APPLICATIONS
 */
export const getApplicationsFailure =(error)=>({
    type: GET_APPLICATIONS_FAILURE,
    payload: error
})


/**
 * Redux action for fetching APPLICATIONS after add/edit/delete of Application
 */
export const refreshApplications = (response) => ({
    type: REFRESH_APPLICATIONS,
    payload: response
});

/**
 * Redux action for adding new Application
 */
export const addApplication = (model) => ({
    type: ADD_APPLICATION,
    payload:model
})
/**
 * Redux action for successful addition of Application
 */
export const addApplicationSuccess =(response)=>({
    type: ADD_APPLICATION_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to add new Application
 */
export const addApplicationFailure =(error)=>({
    type: ADD_APPLICATION_FAILURE,
    payload: error
})

/**
 * Redux action for editing a Application
 */
export const updateApplication =(model)=>({
    type: UPDATE_APPLICATION,
    payload:model
})
/**
 * Redux action for successful editing of a Application
 */
export const updateApplicationSuccess =(response)=>({
    type: UPDATE_APPLICATION_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to edit a Application
 */
export const updateApplicationFailure =(error)=>({
    type: UPDATE_APPLICATION_FAILURE,
    payload: error
})

/**
 * Redux action for deleting a Application
 */
export const deleteApplication =(id)=>({
    type: DELETE_APPLICATION,
    payload:id
})
/**
 * Redux action for successful deletion of Application
 */
export const deleteApplicationSuccess =(response)=>({
    type: DELETE_APPLICATION_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in deletion of Application
 */
export const deleteApplicationFailure =(error)=>({
    type: DELETE_APPLICATION_FAILURE,
    payload: error
})

/**
 * Redux action for filtering Applications from search box
 */
export const fillterApplications=(keyword)=>({
    type: FILLTER_APPLICATIONS,
    payload: keyword
})
/**
 * Redux action for filtering Applications by type.
 */
export const fillterApplicationsByType=(type)=>({
    type: FILLTER_APPLICATIONS_BY_TYPE,
    payload: type
})
/**
 * Redux action for selecting a Application
 */
export const selectApplication=(id)=>({
    type: SELECT_APPLICATION,
    payload: id
})
