import {
  GET_ALL_FP_APPS,
  GET_ALL_FP_APPS_FAILURE,
  GET_ALL_FP_APPS_SUCCESS,
  SEARCH_FP_APPS,
  ADD_FP_APP,
  ADD_FP_APP_FAILURE,
  ADD_FP_APP_SUCCESS,
  UPDATE_FP_APP,
  UPDATE_FP_APP_FAILURE,
  UPDATE_FP_APP_SUCCESS,
} from "../actions/types";
import { NotificationManager } from "react-notifications";

const initState = {
  loading: false,
  appsList: [],
  filteredApps: [],
  addModel: null,
  redirect: false,
};
export default (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_FP_APPS:
      return { ...state, loading: true };

    case GET_ALL_FP_APPS_SUCCESS:
      return {
        ...state,
        loading: false,
        appsList: action.payload,
        filteredApps: action.payload,
        redirect: false,
      };

    case GET_ALL_FP_APPS_FAILURE:
      NotificationManager.error("Error while fetching Apps");
      return { ...state, loading: false, appsList: [], filteredApps: [] };
    case SEARCH_FP_APPS:
      let filteredApps = [...state.appsList].filter((a) =>
        a.name.includes(action.payload)
      );
      return { ...state, filteredApps: filteredApps };
    case ADD_FP_APP:
      return { ...state, loading: true, addModel: action.payload };
    case ADD_FP_APP_SUCCESS:
      return { ...state, loading: false, addModel: null, redirect: true };
    case ADD_FP_APP_FAILURE:
      NotificationManager.error("Error while adding Apps");
      return { ...state, loading: false, addModel: null };
    case UPDATE_FP_APP:
      return { ...state, loading: true, addModel: action.payload };
    case UPDATE_FP_APP_SUCCESS:
      return { ...state, loading: false, addModel: null, redirect: true };
    case UPDATE_FP_APP_FAILURE:
      NotificationManager.error("Error while updating App");
      return { ...state, loading: false, addModel: null };
    default:
      return { ...state };
  }
};
