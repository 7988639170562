/* eslint-disable no-redeclare */
/* eslint-disable no-fallthrough */
/* eslint-disable import/no-anonymous-default-export */
/*
    applications Reducer
    Curd Operations on applications.
*/
import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_APPLICATION,
  ADD_APPLICATION_FAILURE,
  ADD_APPLICATION_SUCCESS,
  DELETE_APPLICATION,
  DELETE_APPLICATION_FAILURE,
  DELETE_APPLICATION_SUCCESS,
  FILLTER_APPLICATIONS,
  FILLTER_APPLICATIONS_BY_TYPE,
  GET_APPLICATIONS,
  GET_APPLICATIONS_FAILURE,
  GET_APPLICATIONS_SUCCESS,
  REFRESH_APPLICATIONS,
  SELECT_APPLICATION,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_FAILURE,
  UPDATE_APPLICATION_SUCCESS,
} from "../actions/types";

// initial state
const INIT_STATE = {
  applications: [],
  filteredApplications: [],
  application: null,
  search: "",
  action: "",
  loading: false,
  applicationList: null,
  redirect: false,
  selected: null,
  isOr: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get applications from server.
    case GET_APPLICATIONS:
      return { ...state, loading: true };
    // On Success...
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        applications: action.payload.data,
        filteredApplications: action.payload.data,
        redirect: false,
      };
    // On Failure, show error message.
    case GET_APPLICATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Refres
    case REFRESH_APPLICATIONS:
      if (state.action === "UPDATE") {
        NotificationManager.success("Application updated successfully.");
      } else if (state.action === "ADD") {
        NotificationManager.success("Application added successfully.");
      } else {
        NotificationManager.success("Application deleted successfully.");
      }
      if (state.search === "") {
        return {
          ...state,
          loading: false,
          applications: action.payload.data,
          filteredApplications: action.payload.data,
          segmentCount: 0,
        };
      } else {
        const filtered = action.payload.data.filter(
          (item) =>
            item.contentType.toLowerCase().indexOf(state.search.toLowerCase()) >
            -1
        );
        return {
          ...state,
          loading: false,
          filteredApplications: filtered,
          applications: action.payload.data,
        };
      }

    //ADD new content
    case ADD_APPLICATION:
      if (action.payload === "") {
        return { ...state, error: "Please enter valid application details" };
      } else {
        let clientSecrete = false;
        let localRedirectUris = [];
        localRedirectUris.push(action.payload.redirectUrl);
        let postLogoutRedirectUris = [];
        postLogoutRedirectUris.push(action.payload.postLogoutRedirectUris);
        let localAllowedGrantTypes = [];
        let localRequirePKCE = false;
        // if client type is single page application
        if (action.payload.clientType === 1) {
          localAllowedGrantTypes.push("authorization_code");
          localAllowedGrantTypes.push("refresh_token");
          localRequirePKCE = true;
        } else {
          localAllowedGrantTypes.push("client_credentials");
          if (action.payload.clientType === 0) {
            clientSecrete = true;
          }
        }

        var applicationModel = {
          clientName: action.payload.clientName,
          clientType: action.payload.clientType,
          allowedGrantTypes: localAllowedGrantTypes,
          redirectUris: localRedirectUris,
          allowedCorsOrigins: action.payload.allowedOrigins,
          allowedScopes: ["API"],
          requireConsent: false,
          requirePkce: localRequirePKCE,
          requireClientSecret: clientSecrete,
          allowAccessTokensViaBrowser: true,
          accessTokenLifetime: 3600,
          logoUri: action.payload.logoUri,
          primaryColor: action.payload.primaryColor,
          secondaryColor: action.payload.secondaryColor,
          postLogoutRedirectUris: postLogoutRedirectUris,
          allowOfflineAccess: action.payload.allowOfflineAccess,
        };

        return {
          ...state,
          loading: true,
          application: applicationModel,
          action: "ADD",
          redirect: false,
        };
      }

    // On Success, show message and redirect to listing page...
    case ADD_APPLICATION_SUCCESS:
      NotificationManager.success("Application added successfully.");
      return { ...state, loading: false, application: null, redirect: true };
    // On failure, show error message.
    case ADD_APPLICATION_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, application: null };
    // Select content from list
    case SELECT_APPLICATION:
      if (action.payload === "") {
        NotificationManager.error("Invalid Application");
      } else {
        var selectedContent = _.filter(state.applications, {
          id: action.payload,
        });
        return {
          ...state,
          loading: false,
          selected: selectedContent[0],
          redirect: false,
        };
      }
    // Update content
    case UPDATE_APPLICATION:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please select valid application for updation",
        };
      } else {
        let editClientSecrete = false;
        let editLocalRedirectUris = [];
        editLocalRedirectUris.push(action.payload.redirectUrl);
        let postLogoutRedirectUris = [];
        postLogoutRedirectUris.push(action.payload.postLogoutRedirectUris);
        let editLocalAllowedGrantTypes = [];
        let localRequirePKCE = false;
        // if client type is single page application
        if (action.payload.clientType.value === 1) {
          editLocalAllowedGrantTypes.push("authorization_code");
          editLocalAllowedGrantTypes.push("refresh_token");
          localRequirePKCE = true;
        } else {
          editLocalAllowedGrantTypes.push("client_credentials");
          if (action.payload.clientType.value === 0) {
            editClientSecrete = true;
          }
        }

        var applicationModel = {
          clientId: action.payload.clientId,
          id: action.payload.id,
          clientName: action.payload.clientName,
          clientType: action.payload.clientType.value,
          allowedGrantTypes: editLocalAllowedGrantTypes,
          redirectUris: editLocalRedirectUris,
          allowedCorsOrigins: action.payload.allowedOrigins,
          allowedScopes: ["API"],
          requireConsent: false,
          requirePkce: localRequirePKCE,
          requireClientSecret: editClientSecrete,
          allowAccessTokensViaBrowser: true,
          accessTokenLifetime: 3600,
          logoUri: action.payload.logoUri,
          primaryColor: action.payload.primaryColor,
          secondaryColor: action.payload.secondaryColor,
          postLogoutRedirectUris: postLogoutRedirectUris,
          allowOfflineAccess: action.payload.allowOfflineAccess,
        };
      }

      return {
        ...state,
        loading: true,
        application: applicationModel,
        action: "UPDATE",
        redirect: false,
      };
    // On Success, show message and redirect to listing page....
    case UPDATE_APPLICATION_SUCCESS:
      NotificationManager.success("Application updated successfully.");
      return {
        ...state,
        loading: false,
        application: null,
        selected: null,
        redirect: false,
      };

    // On Failure show error message.
    case UPDATE_APPLICATION_FAILURE:
      NotificationManager.error("Could not update application");
      return { ...state, loading: false, application: null, redirect: true };

    // Delete content
    case DELETE_APPLICATION:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid application details" };
      } else {
        return {
          ...state,
          loading: true,
          application: action.payload,
          action: "DELETE",
        };
      }

    // On Success, redirect to listing page..
    case DELETE_APPLICATION_SUCCESS:
      return { ...state, loading: true, application: null };

    // on
    case DELETE_APPLICATION_FAILURE:
      NotificationManager.error("Could not delete application");
      return { ...state, loading: false, application: null };

    case FILLTER_APPLICATIONS:
      if (action.payload === "") {
        return { ...state, filteredApplications: state.applications };
      } else {
        const filtered = state.applications.filter(
          (item) =>
            item.clientName
              .toLowerCase()
              .indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredApplications: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case FILLTER_APPLICATIONS_BY_TYPE:
      if (action.payload === "") {
        return { ...state, filteredApplications: state.applications };
      } else {
        const filtered = state.applications.filter(
          (item) =>
            item.channel.toLowerCase().indexOf(action.payload.toLowerCase()) >
            -1
        );
        return {
          ...state,
          filteredApplications: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    default:
      return { ...state };
  }
};
