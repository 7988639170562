/**
 * Roles Sagas
 */
import { all, call, put, takeEvery } from "redux-saga/effects";
import { getDomainSegmentsFailure, getDomainSegmentsSuccess } from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

const getDomainSegmentsFromServer = async () => {
  return await getAxiosRequest().get("/user/segmentcount");
};
/**
 * Get Domain Segments for the logged in users
 */
function* getDomainSegments() {
  try {
    // call api to generate magic link
    const response = yield call(getDomainSegmentsFromServer);
    yield put(getDomainSegmentsSuccess(response.data));
  } catch (error) {
    yield put(getDomainSegmentsFailure(error.response.data.error));
  }
}

export const domainSegmentsSagas = [
  takeEvery("GET_DOMAIN_SEGMENTS", getDomainSegments),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...domainSegmentsSagas]);
}
