import {
  GET_GRAVITO_DOMAIN_COUNT,
  GET_GRAVITO_DOMAIN_COUNT_SUCCESS,
  GET_GRAVITO_DOMAIN_COUNT_FAILURE,
  GET_GRAVITO_FP_DOMAIN_COUNT,
  GET_GRAVITO_FP_DOMAIN_COUNT_SUCCESS,
  GET_GRAVITO_FP_DOMAIN_COUNT_FAILURE,
  GET_GRAVITO_FP_DOMAINS,
  GET_GRAVITO_FP_DOMAINS_SUCCESS,
  GET_GRAVITO_FP_DOMAINS_FAILURE,
  CERTIFICATE_CHECK,
  CERTIFICATE_CHECK_SUCCESS,
  CERTIFICATE_CHECK_FAILURE
} from "./types";

export const getGravitoDomainCount = () => ({
  type: GET_GRAVITO_DOMAIN_COUNT,
});
export const getGravitoDomainCountSucess = (data) => ({
  type: GET_GRAVITO_DOMAIN_COUNT_SUCCESS,
  payload: data,
});
export const getGravitoDomainCountFailure = (error) => ({
  type: GET_GRAVITO_DOMAIN_COUNT_FAILURE,
  payload: error,
});

export const getGravitoFPDomainCount = () => ({
  type: GET_GRAVITO_FP_DOMAIN_COUNT,
});
export const getGravitoFPDomainCountSucess = (data) => ({
  type: GET_GRAVITO_FP_DOMAIN_COUNT_SUCCESS,
  payload: data,
});
export const getGravitoFPDomainCountFailure = (error) => ({
  type: GET_GRAVITO_FP_DOMAIN_COUNT_FAILURE,
  payload: error,
});

export const getGravitoFPDomains = () => ({
  type: GET_GRAVITO_FP_DOMAINS,
});
export const getGravitoFPDomainsSuccess = (data) => ({
  type: GET_GRAVITO_FP_DOMAINS_SUCCESS,
  payload: data,
});
export const getGravitoFPDomainsFailure = (error) => ({
  type: GET_GRAVITO_FP_DOMAINS_FAILURE,
  payload: error,
});

export const certificateCheck = (customerId) => ({
  type: CERTIFICATE_CHECK,
  payload:customerId
});
export const certificateCheckSuccess = (data) => ({
  type: CERTIFICATE_CHECK_SUCCESS,
  payload: data,
});
export const certificateCheckFailure = (error) => ({
  type: CERTIFICATE_CHECK_FAILURE,
  payload: error,
});
