/**
 * App Routes
 */
 import $ from "jquery";
 import React, { Component, useEffect, useState } from "react";
 import { connect } from "react-redux";
 import Drawer from "@material-ui/core/Drawer";
 import IconButton from "@material-ui/core/IconButton";
 import Tooltip from "@material-ui/core/Tooltip";
 import { CircularProgress } from "@material-ui/core";
 import HeaderMenu from "../Header/Header";
 // Components

 const RegistrationLayout = (props) => {
 
   
   const [state, setState] = useState({
     windowWidth : $(window).width(),
     windowHeight : $(window).height(),
     isHelpDrawerOpen:false
 
   })
 
   useEffect(()=>{
    
     updateDimensions();
     window.addEventListener("resize", updateDimensions);
     return function cleanup() {
       window.removeEventListener("resize", updateDimensions);
     };
   }, [])
   
 
   const updateDimensions = () => {
     setState({...state,
       ['windowWidth']: $(window).width(),
       ['windowHeight']: $(window).height(),
     });
   };
 
 

   const { children } = props;
     return (

       <div className='container-fluid'>
         <div className='wrapper'>
         <HeaderMenu />
         <div className='content'>{children}</div>
         
         </div>
       </div>
     );
   }
   export default RegistrationLayout;
   