/**
 * Roles Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  compareGVLVersionsFailure,
  compareGVLVersionsSuccess,
  getGVLVersionsFailure,
  getGVLVersionsSuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

const gvlupdates = (state) => state.gvlUpdatesReducer;

const getGVLVersions = async () => {
  return await getAxiosRequest().get("/cmpconfigurator/gvlfile");
};

function* gvlVersions() {
  try {
    const response = yield call(getGVLVersions);
    yield put(getGVLVersionsSuccess(response));
  } catch (error) {
    yield put(getGVLVersionsFailure(error.response.data.error));
  }
}

const compareGVLVersions = async (model) => {
  return await getAxiosRequest().get(
    `cmpconfigurator/gvlfile/compare?currentFileid=${model.current}&previousFileid=${model.previous}`
  );
};

function* compareVersions() {
  try {
    let model = yield select(gvlupdates); // <-- get the model
    const response = yield call(compareGVLVersions, model.compareModel);
    yield put(compareGVLVersionsSuccess(response));
  } catch (error) {
    yield put(compareGVLVersionsFailure(error.response.data.error));
  }
}

export const inviteConfirmationSagas = [
  takeEvery("GET_GVL_VERSIONS", gvlVersions),
  takeEvery("COMPARE_GVL_VERSIONS", compareVersions),
];

/**
 * Confirmation Root Saga
 */
export default function* rootSaga() {
  yield all([...inviteConfirmationSagas]);
}
