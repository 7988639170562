/**
 * Audience Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addCustomerFailure,
  addCustomerSuccess,
  cancelCustomerOnboardingFailure,
  cancelCustomerOnboardingSuccess,
  customerOnHoldFailure,
  customerOnHoldSuccess,
  getCustomerFailure,
  getCustomerSuccess,
  onboardCustomerFailure,
  onboardCustomerSuccess,
  refreshCustomerDomainListFailure,
  refreshCustomerDomainListSuccess,
  refreshCustomers,
  updateCustomerFailure,
  updateCustomerSuccess,
  updateCustomerTypeSuccess,
  updateCustomerTypeFailure,
  getCustomerScanFailure,
  getCustomerScanSuccess,
  getCustomerByIdSuccess,
  getCustomerByIdFailure,
  deleteCustomerScanSuccess,
  deleteCustomerScanFailure,
  addCustomerScanSuccess,
  addCustomerScanFailure,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const customerReducer = (state) => state.customerReducer;

const getCustomersFromServer = async () => {
  //   var isPartner = getContextObject() ? getContextObject().isPartner : false;
  //   var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  //   if (isPartner) {
  //     return await getAxiosRequest().get(`/partner/{id}/customer`);
  //   } else {
  //     return await getAxiosRequest().get("/customer");
  //     }
  return await getAxiosRequest().get("/customer");
};

/**
 * Get customer
 */
function* getCustomers() {
  try {
    // let customer = yield select(customerReducer);
    const response = yield call(getCustomersFromServer);
    yield put(getCustomerSuccess(response.data));
  } catch (error) {
    yield put(getCustomerFailure("error"));
  }
}

const getCustomerByIdFromServer = async (id) => {
  return await getAxiosRequest().get(`/customer/current/${id}`);
};

function* getCustomerById() {
  try {
    let customer = yield select(customerReducer);
    const response = yield call(getCustomerByIdFromServer, customer.selected);
    yield put(getCustomerByIdSuccess(response.data));
  } catch (error) {
    yield put(getCustomerByIdFailure("error"));
  }
}

const getCustomerScanFromServer = async (customerId) => {
  return await getAxiosRequest().get(`/customer/${customerId}/getcustomerscan`);
};

function* getCustomerScan() {
  try {
    let customer = yield select(customerReducer);
    const response = yield call(
      getCustomerScanFromServer,
      customer.selected.id
    );
    yield put(
      getCustomerScanSuccess(response.data ? JSON.parse(response.data) : null)
    );
  } catch (error) {
    yield put(getCustomerScanFailure("error"));
  }
}

const deleteCustomDomainFromServer = async ({ customerId, domainId }) => {
  return await getAxiosRequest().delete(
    `/customer/${customerId}/deletecustomerscandomain?customerScanDomainId=${domainId}`
  );
};

function* deleteCustomDomainSaga() {
  try {
    let customer = yield select(customerReducer);
    const response = yield call(deleteCustomDomainFromServer, {
      customerId: customer.selected.id,
      domainId: customer.selectedDomainID,
    });
    yield put(deleteCustomerScanSuccess(response));
    const responseScan = yield call(
      getCustomerScanFromServer,
      customer.selected.id
    );
    yield put(
      getCustomerScanSuccess(
        responseScan.data ? JSON.parse(responseScan.data) : null
      )
    );
  } catch (error) {
    yield put(deleteCustomerScanFailure(error.response.data.error));
  }
}

const addCustomerScanDomainToServer = async (model) => {
  return await getAxiosRequest().post("/customer/createcustomerscan", model);
};

function* addCustomerScanDomain() {
  try {
    let customer = yield select(customerReducer);
    const response = yield call(addCustomerScanDomainToServer, {
      json: customer.selectedDomainIDs,
      customerId: customer.selected.id,
    });
    yield put(addCustomerScanSuccess(response));
    const responseScan = yield call(
      getCustomerScanFromServer,
      customer.selected.id
    );
    yield put(
      getCustomerScanSuccess(
        responseScan.data ? JSON.parse(responseScan.data) : null
      )
    );
  } catch (error) {
    yield put(addCustomerScanFailure(error.response.data.error));
  }
}

const updateCustomerStatusToServer = async (customerDetail) => {
  return await getAxiosRequest().put(
    `/customer/Status?id=${customerDetail.id}&status=${customerDetail.status}`,
    {}
  );
};

function* cancelOnboarding() {
  try {
    let customer = yield select(customerReducer);
    const response = yield call(
      updateCustomerStatusToServer,
      customer.customerStatus
    );
    yield put(cancelCustomerOnboardingSuccess(response));
    const refCustomers = yield call(getCustomersFromServer);
    yield put(refreshCustomers(refCustomers.data));
  } catch (error) {
    yield put(cancelCustomerOnboardingFailure(error.response.data.error));
  }
}

function* customerOnHold() {
  try {
    let customer = yield select(customerReducer);
    const response = yield call(
      updateCustomerStatusToServer,
      customer.customerStatus
    );
    yield put(customerOnHoldSuccess(response));
    const refCustomers = yield call(getCustomersFromServer);
    yield put(refreshCustomers(refCustomers.data));
  } catch (error) {
    yield put(customerOnHoldFailure(error.response.data.error));
  }
}

const updateCustomerToServer = async (customerDetail) => {
  return await getAxiosRequest().put("/customer", customerDetail);
};

function* updateCustomer() {
  try {
    let customer = yield select(customerReducer);
    const response = yield call(
      updateCustomerToServer,
      customer.customerDetail
    );
    yield put(updateCustomerSuccess(response));
    const refCustomers = yield call(getCustomersFromServer);
    yield put(refreshCustomers(refCustomers.data));
  } catch (error) {
    yield put(updateCustomerFailure(error.response.data.error));
  }
}

const onboardCustomerToServer = async (customerDetail) => {
  return await getAxiosRequest().put("/customer/onboard", customerDetail);
};

function* onboardCustomer() {
  try {
    let customer = yield select(customerReducer);
    const response = yield call(
      onboardCustomerToServer,
      customer.customerDetail
    );
    yield put(onboardCustomerSuccess(response));
    const refCustomers = yield call(getCustomersFromServer);
    yield put(refreshCustomers(refCustomers.data));
  } catch (error) {
    yield put(onboardCustomerFailure(error.response.data.error));
  }
}

const getCustomersDomainListFromServer = async () => {
  return await getAxiosRequest().get("/customer/customerdomainlist");
};

/**
 * Get customer
 */
function* getCustomersDomainList() {
  try {
    const response = yield call(getCustomersDomainListFromServer);
    yield put(refreshCustomerDomainListSuccess(response.data));
  } catch (error) {
    yield put(refreshCustomerDomainListFailure(error.response.data.error));
  }
}

const addCustomerToServer = async (customerDetail) => {
  return await getAxiosRequest().post("/customer/register", customerDetail);
};

function* addCustomer() {
  try {
    let customer = yield select(customerReducer);

    const response = yield call(addCustomerToServer, customer.customerDetail);
    yield put(addCustomerSuccess(response));
    const refCustomers = yield call(getCustomersFromServer);
    yield put(refreshCustomers(refCustomers.data));
  } catch (error) {
    yield put(addCustomerFailure(error.response.data.error));
  }
}

const updateCustomerTypeToServer = async (model) => {
  return await getAxiosRequest().put(
    `/customer/${model.id}/enterprise/${!model.type}`
  );
};

function* updateCustomerType() {
  try {
    let customer = yield select(customerReducer);
    const response = yield call(
      updateCustomerTypeToServer,
      customer.customerTypeModel
    );
    yield put(updateCustomerTypeSuccess(response));
    const refCustomers = yield call(getCustomersFromServer);
    yield put(refreshCustomers(refCustomers.data));
  } catch (error) {
    yield put(updateCustomerTypeFailure(error.response.data.error));
  }
}

export const customerSagas = [
  takeEvery("GET_CUSTOMERS", getCustomers),
  takeEvery("GET_CUSTOMER_BY_ID", getCustomerById),
  takeEvery("GET_CUSTOMER_SCAN", getCustomerScan),
  takeEvery("DELETE_CUSTOMER_SCAN_DOMAIN", deleteCustomDomainSaga),
  takeEvery("ADD_CUSTOMER_SCAN_DOMAIN", addCustomerScanDomain),
  takeEvery("UPDATE_CUSTOMER", updateCustomer),
  takeEvery("ONBOARD_CUSTOMER", onboardCustomer),
  takeEvery("HOLD_CUSTOMER", customerOnHold),
  takeEvery("CANCEL_ONBOARDING", cancelOnboarding),
  takeEvery("REFRESH_CUSTOMER_DOMAIN_LIST", getCustomersDomainList),
  takeEvery("ADD_CUSTOMER", addCustomer),
  takeEvery("UPDATE_CUSTOMER_TYPE", updateCustomerType),
];

/**
 * Default Customer Root Saga
 */
export default function* rootSaga() {
  yield all([...customerSagas]);
}
