/**
 * Reactify - A Material Design Admin Template
 * Copyright 2018 All Rights Reserved
 * Made Wih Love
 * Created By The Iron Network, LLC
 */
import React from "react";
import { Provider } from "react-redux";
import MomentUtils from "@date-io/moment";
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";

// css
import "./lib/reactifyCss";

// app component
import AppContainer from "./container/AppContainer";

import store from "./store";
window.jQuery = window.$ = require("jquery/dist/jquery.min");
require("bootstrap/dist/js/bootstrap.bundle");
require("./assets/js/plugins/perfect-scrollbar.jquery.min");
require("./assets/js/now-ui-dashboard");

const MainApp = () => {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router history={history}>
          <Switch>
            <Route path="/" component={AppContainer} />
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default MainApp;
