/**
 * Redux Actions for managing admin profile options.
 */

 import {
    GET_DOMAIN_REQUESTS,
    GET_DOMAIN_REQUESTS_SUCCESS,
    GET_DOMAIN_REQUESTS_FAILURE,
    PROCESS_DOMAIN_REQUESTS,
    PROCESS_DOMAIN_REQUESTS_SUCCESS,
    PROCESS_DOMAIN_REQUESTS_FAILURE
  } from "./types";
  
  /**
   * Redux action to get domain requests
   */
  export const getDomainRequests = () => ({
    type: GET_DOMAIN_REQUESTS,
  });
  
  /**
   * Redux action for successful fetching of domain requests
   */
  
  export const getDomainRequestsSuccess = (response) => ({
    type: GET_DOMAIN_REQUESTS_SUCCESS,
    payload: response,
  });
  /**
   * Redux action for failure in fetching domain requests
   */
  export const getDomainRequestsFailure = (error) => ({
    type: GET_DOMAIN_REQUESTS_FAILURE,
    payload: error,
  });
  
  
  /**
   * Redux action for processing domain request
   */
   export const processDomainRequest = (model) => ({
    type: PROCESS_DOMAIN_REQUESTS,
    payload: model,
  });
  /**
   * Redux action for successful processing of domain request
   */
  export const processDomainRequestSuccess = (response) => ({
    type: PROCESS_DOMAIN_REQUESTS_SUCCESS,
    payload: response,
  });
  
  /**
   * Redux action for failing to process a domain request
   */
  export const processDomainRequestFailure = (error) => ({
    type: PROCESS_DOMAIN_REQUESTS_FAILURE,
    payload: error,
  });
  