/* eslint-disable import/no-anonymous-default-export */
import { NotificationManager } from "react-notifications";
import {
  ADD_CONNECTOR,
  ADD_CONNECTOR_FAILURE,
  ADD_CONNECTOR_SUCCESS,
  DELETE_CONNECTOR,
  DELETE_CONNECTOR_FAILURE,
  DELETE_CONNECTOR_SUCCESS,
  GET_CONNECTORS,
  GET_CONNECTORS_FAILURE,
  GET_CONNECTORS_SUCCESS,
  GET_CONNECTOR_RUNDATA,
  GET_CONNECTOR_RUNDATA_FAILURE,
  GET_CONNECTOR_RUNDATA_SUCCESS,
  GET_CONNECTOR_TYPES,
  GET_CONNECTOR_TYPES_FAILURE,
  GET_CONNECTOR_TYPES_SUCCESS,
  TEST_CONNECTOR,
  TEST_CONNECTOR_FAILURE,
  TEST_CONNECTOR_SUCCESS,
  UPDATE_CONNECTOR,
  UPDATE_CONNECTOR_FAILURE,
  UPDATE_CONNECTOR_SUCCESS,
} from "../actions/types";

const initState = {
  loading: false,
  connectorID: null,
  connector: null,
  connectorTypes: {},
  redirect: false,
  deleteType: null,
  querryData: {},
  runData: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_CONNECTORS:
      return { ...state, loading: true, redirect: false };

    case GET_CONNECTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        connector: action.payload.connector,
        connectorTypes: action.payload.connectorTypes,
      };
    case GET_CONNECTORS_FAILURE:
      return {
        ...state,
        loading: false,
        connector: null,
      };
    case GET_CONNECTOR_TYPES:
      return { ...state, loading: true };
    case GET_CONNECTOR_TYPES_SUCCESS:
      return { ...state, loading: false, connectorTypes: action.payload };
    case GET_CONNECTOR_TYPES_FAILURE:
      NotificationManager.error("Something went wrong");
      return { ...state, loading: false };
    case ADD_CONNECTOR:
      return { ...state, loading: true, connector: action.payload };
    case ADD_CONNECTOR_SUCCESS:
      return { ...state, loading: false, redirect: true };
    case ADD_CONNECTOR_FAILURE:
      NotificationManager.error("Something went wrong");
      return { ...state, loading: false };
    case UPDATE_CONNECTOR:
      return { ...state, loading: true, connector: action.payload };
    case UPDATE_CONNECTOR_SUCCESS:
      return { ...state, loading: false, redirect: true };
    case UPDATE_CONNECTOR_FAILURE:
      NotificationManager.error("Something went wrong");
      return { ...state, loading: false };
    case DELETE_CONNECTOR:
      return {
        ...state,
        loading: true,
        connectorToDelete: action.payload,
      };
    case DELETE_CONNECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteType: null,
        connectorID: null,
        connector: null,
        connectorToDelete: null,
      };
    case DELETE_CONNECTOR_FAILURE:
      NotificationManager.error("Something went wrong");
      return { ...state, loading: false, deleteType: null, connectorID: null };

    case TEST_CONNECTOR:
      return {
        ...state,
        loading: true,
        connectorID: action.payload,
      };
    case TEST_CONNECTOR_SUCCESS:
      let result = action.payload;
      if (!result.isSuccessful) {
        NotificationManager.error(result.errorMessage);
        return {
          ...state,
          loading: false,
        };
      }
      NotificationManager.success("connector is working fine");
      return {
        ...state,
        loading: false,
      };

    case TEST_CONNECTOR_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false };
    case GET_CONNECTOR_RUNDATA:
      return { ...state, loading: true, querryData: action.payload };

    case GET_CONNECTOR_RUNDATA_SUCCESS:
      return { ...state, loading: false, runData: action.payload };
    case GET_CONNECTOR_RUNDATA_FAILURE:
      NotificationManager.error("Something went wrong");
      return { ...state, loading: false, runData: null };
    default:
      return { ...state };
  }
};
