/**
 * Root Sagas
 */
import { all } from "redux-saga/effects";
import applicationSagas from "./application";
import audienceSagas from "./audience";
import authUserSagas from "./authUser";
import campaignSagas from "./campaign";
import campaignDashboardSagas from "./campaigndashboard";
import configSagas from "./config";
import connectorsSagas from "./connectors";
import constellationSagas from "./constellation";
import contentSagas from "./content";
import cookieScanSagas from "./cookieScan";
import customDomainSagas from "./customDomains";
import customerSagas from "./customer";
import customerDashboardSagas from "./customerdashboard";
import domainSagas from "./domain";
import domainPropertySagas from "./domainproperties";
import domainSegmentsSagas from "./DomainSegments";
import domainUsersSagas from "./domainUser";
import feedbackSagas from "./Feedback";
import helpSagas from "./help";
import lightConfigurationSagas from "./lightconfiguration";
import customerRegistrationSagas from "./registration";
import reportsSagas from "./reports";

// sagas
import rolesSagas from "./roles";
import segmentsSagas from "./segments";
import tcfConfigurationSagas from "./tcfconfiguration";
import usersSagas from "./Users";
import manageAccountSagas from "./manageaccount";
import inviteConfirmationSagas from "./inviteconfirmation";
import manageRequestsSagas from "./managerequests";
import partnerSagas from "./partners";
import gvlUpdatesSagas from "./gvlupdates";
import adminRouteSagas from "./adminroute";
import firstPartyAppSagas from "./firstpartyApps";
import customerDomainAppSagas from "./customerdomainApps";
import compareTrends from "./comparetrends";

export default function* rootSaga(getState) {
  yield all([
    rolesSagas(),
    usersSagas(),
    contentSagas(),
    configSagas(),
    constellationSagas(),
    audienceSagas(),
    domainSegmentsSagas(),
    campaignSagas(),
    campaignDashboardSagas(),
    authUserSagas(),
    domainSagas(),
    feedbackSagas(),
    customerSagas(),
    domainPropertySagas(),
    domainUsersSagas(),
    segmentsSagas(),
    applicationSagas(),
    customDomainSagas(),
    lightConfigurationSagas(),
    tcfConfigurationSagas(),
    helpSagas(),
    reportsSagas(),
    customerDashboardSagas(),
    connectorsSagas(),
    customerRegistrationSagas(),
    cookieScanSagas(),
    manageAccountSagas(),
    inviteConfirmationSagas(),
    manageRequestsSagas(),
    partnerSagas(),
    gvlUpdatesSagas(),
    adminRouteSagas(),
    firstPartyAppSagas(),
    customerDomainAppSagas(),
    compareTrends(),
  ]);
}
