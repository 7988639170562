/**
 * Sidebar Content
 */
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Button } from "semantic-ui-react";
// redux actions
import {
  getDomainUsers,
  logOff,
  setUserCustomer,
  setUserDomain,
} from "../../../actions";
import AppConfig from "../../../constants/AppConfig";
import IntlMessages from "../../../util/IntlMessages";
import Feedback from "../Feedback";
import Navlinks from "./NavLinks";
import NavMenuItem from "./NavMenuItem";

const MenuContent = (props) => {
  const [state, setState] = useState({
    customizer: false,
    isMobileSearchFormVisible: false,
    settingsDropdownOpen: false,
    navigateToTerms: false,
    openConfirmationAlert: false, // confirmation to change domain
    openCustomerConfirmationAlert: false, // confirmation to change customer
    selectedDomain: null,
    selectedCustomer: null,
    redirectOnDomainChange: false,
    fixed: true,
  });

  const DomainSelector = () => (
    <UncontrolledDropdown
      hidden={props.authUser.isAdmin}
      nav
      className="list-inline-item rct-dropdown mr-50 leftBorder"
    >
      <Tooltip
        id="tooltip-icon"
        color="primary"
        placement="top-end"
        title={<div>Change Domain</div>}
      >
        <DropdownToggle caret nav className="dropdown-group-link headerDomain">
          <i className="ti-world"></i> {props.authUser.currentDomain.domain}
        </DropdownToggle>
      </Tooltip>
      <DropdownMenu right>
        <Scrollbars
          className="rct-scroll"
          autoHeight
          autoHeightMin={100}
          autoHeightMax={280}
        >
          <ul className="list-unstyled mb-0">
            {props.authUser.mappedDomains !== null ? (
              props.authUser.mappedDomains.map((domain, index) => (
                <li
                  key={domain.id + index}
                  className="dropdown-item headerDomainLink"
                  onClick={(event) =>
                    onSelectDomain(
                      domain.id,
                      domain.domain,
                      domain.customerId,
                      event
                    )
                  }
                >
                  {domain.domain}
                </li>
              ))
            ) : (
              <li>Domains Not Avaliable</li>
            )}
          </ul>
        </Scrollbars>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const CustomerSelector = () => (
    <UncontrolledDropdown
      hidden={props.authUser.isAdmin}
      nav
      className="list-inline-item rct-dropdown mr-10"
    >
      <Tooltip
        id="tooltip-icon"
        color="primary"
        placement="top-end"
        title={<div>Change Customer</div>}
      >
        <DropdownToggle
          caret
          nav
          className="dropdown-group-link headerCustomer"
        >
          <i className="fa fa-building"></i>{" "}
          {props.authUser.currentCustomer.name}
        </DropdownToggle>
      </Tooltip>
      <DropdownMenu right>
        <Scrollbars
          className="rct-scroll"
          autoHeight
          autoHeightMin={100}
          autoHeightMax={280}
        >
          <ul className="list-unstyled mb-0">
            {props.authUser.mappedCustomers !== null &&
            props.authUser.mappedCustomers.length > 1 ? (
              props.authUser.mappedCustomers.map((customer, index) => (
                <li
                  key={customer.id + index}
                  className="dropdown-item headerDomainLink"
                  onClick={(customerChangeEvent) =>
                    onSelectCustomer(
                      customer.id,
                      customer.name,
                      customer.useCM,
                      customerChangeEvent
                    )
                  }
                >
                  {customer.name}
                </li>
              ))
            ) : (
              <li>Domains Not Avaliable</li>
            )}
          </ul>
        </Scrollbars>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const OtherMenuOptions = () => (
    <UncontrolledDropdown nav className="list-inline-item rct-dropdown">
      <DropdownToggle
        nav
        className="dropdown-group-link  text-white mt-10 p-0 ml-20"
      >
        <i className="fa fa-ellipsis-v"></i> Options
      </DropdownToggle>
      <DropdownMenu right>
        <Scrollbars
          className="rct-scroll"
          autoHeight
          autoHeightMin={100}
          autoHeightMax={280}
        >
          <ul className="list-unstyled mb-0">
            <li
              className="dropdown-item headerDomainLink"
              onClick={() => logout()}
            >
              <IntlMessages id="header.logout" />
            </li>

            <li
              className="dropdown-item headerDomainLink"
              onClick={() => redirectToGravitoProfile(AppConfig.accountUrl)}
            >
              Gravito Profile
            </li>
          </ul>
        </Scrollbars>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  // on domain change, show confirmation dialog
  const onSelectDomain = (id, domain, customer, event) => {
    sessionStorage.setItem("domainChangePath", props.location.pathname);
    event.preventDefault();
    setState({
      ...state,
      openConfirmationAlert: true,
      selectedDomain: { id: id, domain: domain, customerId: customer },
    });
  };

  // on customer change, show confirmation dialog
  const onSelectCustomer = (id, name, useCM, event) => {
    event.preventDefault();
    setState({
      ...state,
      openCustomerConfirmationAlert: true,
      selectedCustomer: { id: id, name: name, useCM: useCM },
    });
  };

  /**
   * On confirmation, redirect user to the current page with new domain context
   */
  const changeDomain = () => {
    props.setUserDomain(state.selectedDomain);
    props.getDomainUsers();
    setTimeout(() => {
      props.callback();
    }, 2000);
  };

  /**
   * On confirmation, redirect user to the current page with new domain context
   */
  const changeCustomer = () => {
    props.setUserCustomer(state.selectedCustomer);
    setTimeout(() => {
      props.callback();
    }, 2000);
  };

  const logout = (e) => {
    e.preventDefault();
    props.logOff();
  };

  const redirectToGravitoProfile = (profileLink) => {
    window.location.href = profileLink;
  };

  const updateAppVersion = (e) => {
    e.preventDefault();
    localStorage.setItem("appversion", AppConfig.appVersion);
    window.location.reload();
  };
  const openPreference = () => {
    if (window) {
      if (window.gravitoLightCMP) {
        window.gravitoLightCMP.openPreferences();
      }
    }
  };

  const sidebarMenus = Navlinks;
  const { customerAdmin, isAdmin, isNormalUser, isPartner, currentCustomer } = props.authUser;
  
  return (
    <div
      className="pos-f-t"
      style={{ marginLeft: "-12px", marginRight: "-12px" }}
    >
      <div className="bg-dark p-0">
        <div className="row">
          <div className="col-sm-6 d-flex justify-content-start">
            <ul className="list-inline">
              {localStorage.getItem("appversion") &&
              Number(localStorage.getItem("appversion")) <
                Number(AppConfig.appVersion) ? (
                <li className=" list-inline-item rct-dropdown dropdown nav-item mt-10 ml-20">
                  <a
                    href="#"
                    className="text-primary fw-bold"
                    onClick={(e) => updateAppVersion(e)}
                  >
                    It's time to update, click here to start using our new
                    release !
                  </a>
                </li>
              ) : sessionStorage.getItem("currentemail") ? (
                <li className=" list-inline-item rct-dropdown dropdown nav-item mt-10 ml-20">
                  <span className="text-white fw-bold">
                    Welcome, {sessionStorage.getItem("currentemail")} !{" "}
                    {sessionStorage.getItem("gravitoassist")
                      ? sessionStorage.getItem("gravitoassist") === "true"
                        ? "( assisted by gravito admin)"
                        : null
                      : null}
                  </span>
                </li>
              ) : null}
            </ul>
          </div>
          <div className="col-sm-6 d-flex justify-content-end">
            <ul className="pr-40 list-inline">
              <Feedback style={{ float: "right" }} />
              <li className="list-inline-item rct-dropdown dropdown nav-item">
                <span className="ml-10 mr-10 text-white">|</span>
              </li>
              {customerAdmin ? (
                <li className="list-inline-item rct-dropdown dropdown nav-item">
                  <Link to="/manageaccount" className="text-white">
                    Account
                  </Link>
                </li>
              ) : null}
              {customerAdmin ? (
                <li className="list-inline-item rct-dropdown dropdown nav-item">
                  <span className="ml-10 mr-10 text-white">|</span>
                </li>
              ) : null}
              <li className="list-inline-item rct-dropdown dropdown nav-item">
                <a href="#" className="text-white" onClick={(e) => logout(e)}>
                  {" "}
                  <IntlMessages id="header.logout" />
                </a>
              </li>
              {/* <OtherMenuOptions></OtherMenuOptions> */}
            </ul>
          </div>
        </div>
      </div>
      {!isNormalUser ? (
        <nav
          id="nav"
          className="navbar navbar-expand-lg bg-light mb-10"
          style={{
            backgroundColor: "#FFFFFF",
            borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
            boxShadow: "rgba(0, 0, 0, 0.20) 0px 0px 20px 0px",
          }}
        >
          <Dialog
            open={state.openConfirmationAlert}
            onClose={() => setState({ ...state, openConfirmationAlert: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure, you want to switch the domain?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please make sure that all changes are saved.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                id="Disagree"
                secondary
                onClick={() =>
                  setState({ ...state, openConfirmationAlert: false })
                }
                className="text-white btn-danger"
              >
                Cancel
              </Button>
              <Button
                id="Agree"
                primary
                onClick={() => changeDomain()}
                className="text-white"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={state.openCustomerConfirmationAlert}
            onClose={() =>
              setState({ ...state, openCustomerConfirmationAlert: false })
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure, you want to switch the customer?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please make sure that all changes are saved.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                id="Disagree"
                secondary
                onClick={() =>
                  setState({ ...state, openCustomerConfirmationAlert: false })
                }
                className="text-white btn-danger"
              >
                Cancel
              </Button>
              <Button
                id="Agree"
                primary
                onClick={() => changeCustomer()}
                className="text-white"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="ti-menu navbar-toggler-icon"></i>
          </button>

          <div className="logo">
            <Link to="/" className="logo-normal text-center mr-20">
              <img
                src={require("../../../assets/img/gravito_logo.png").default}
                className="img-fluid logoimage"
                alt="site-logo"
                width="120"
                height="80"
              />
              <img
                src={require("../../../assets/img/bck.png").default}
                className="img-fluid logobackground"
                alt="site-logo"
                width="120"
                height="80"
              />
            </Link>
          </div>

          <div
            className="collapse navbar-collapse mr-20"
            id="navbarSupportedContent"
          >
            {!isNormalUser ? (
              <ul className="navbar-nav mr-auto">
                {sidebarMenus.topLevelItems.map((menu, key) => (
                  <NavMenuItem
                    menu={menu}
                    key={key}
                    location={props.location}
                  />
                ))}

                {isPartner &&
                  sidebarMenus.customerForPartners.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}

                {isAdmin &&
                  sidebarMenus.gravitoAdmin1.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}

                {isAdmin &&
                  sidebarMenus.gravitoAdmin2.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}
                {isAdmin &&
                  sidebarMenus.gravitoAdmin3.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}

                {isAdmin &&
                  sidebarMenus.gravitoAdmin4.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}
                {isAdmin &&
                  sidebarMenus.gravitoAdmin5.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}
                {isAdmin &&
                  sidebarMenus.gravitoAdmin6.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}

                {!isAdmin &&
                  sidebarMenus.consentManagement.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}

                {!isAdmin &&
                  sidebarMenus.domainManagement.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}

                {!isAdmin && currentCustomer.useCM && 
                  sidebarMenus.segments.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}
                {/* {!isAdmin &&
                sidebarMenus.IAM.map((menu, key) => (
                  <NavMenuItem menu={menu} key={key} />
                ))} */}

                {!isAdmin && currentCustomer.useCM &&
                  sidebarMenus.contentManagement.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}

                {/* {!isAdmin &&
                sidebarMenus.audiences.map((menu, key) => (
                  <NavMenuItem menu={menu} key={key} />
                ))} */}

                {!isAdmin && currentCustomer.useCM &&
                  sidebarMenus.campaigns.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}

                {!isAdmin &&
                  sidebarMenus.features.map((menu, key) => (
                    <NavMenuItem
                      menu={menu}
                      key={key}
                      location={props.location}
                    />
                  ))}
                <li id="manageSettings" className="nav-item">
                  <span
                    onClick={() => {
                      openPreference();
                    }}
                    className="nav-link hoverCls"
                    style={{
                      color: "#706f6f",
                      cursor: "pointer",
                    }}
                    role="button"
                  >
                    <span className="menu">{"Cookie Settings"}</span>
                  </span>
                </li>

                {/* {!isAdmin &&
                sidebarMenus.profiles.map((menu, key) => (
                  <NavMenuItem menu={menu} key={key} />
                ))} */}

                {/* {sidebarMenus.settings.map((menu, key) => (
                <NavMenuItem
                  menu={menu}
                  key={key}
                />
            ))} */}
              </ul>
            ) : (
              <ul className="navbar-nav mr-auto">
                {/* {sidebarMenus.welcome.map((menu, key) => (
                <NavMenuItem menu={menu} key={key} />
              ))}
              {sidebarMenus.register.map((menu, key) => (
                <NavMenuItem menu={menu} key={key} />
              ))} */}
              </ul>
            )}
            {/*  if the normal user has logged in then do not show the domain selected  */}
            {!isNormalUser &&
            props.authUser.mappedCustomers !== null &&
            props.authUser.mappedCustomers.length > 1 ? (
              <CustomerSelector></CustomerSelector>
            ) : null}
            {/*  if the normal user has logged in then do not show the domain selected  */}
            {!isNormalUser && props.includeDomain ? (
              <DomainSelector></DomainSelector>
            ) : null}

            {/* {localStorage.getItem("appversion") &&
          Number(localStorage.getItem("appversion")) <
            Number(AppConfig.appVersion) ? (
            <li
              style={{
                position: "absolute",
                width: "100%",
                bottom: "10px",
                listStyle: "none",
                color: "white",
                textAlign: "center",
              }}
            >
              <p style={{ marginBottom: 10, fontWeight: "bold" }}>
                A new version is available
              </p>
              <Button
                style={{ color: "#D5573B" }}
                icon
                labelPosition="left"
                onClick={() => this.updateAppVersion()}
              >
                <Icon name="upload" />
                Update
              </Button>
            </li>
          ) : null} */}
          </div>
        </nav>
      ) : null}
    </div>
  );
};

// map state to props
const mapStateToProps = ({ sidebar, authUser }) => {
  return { sidebar, authUser };
};

export default withRouter(
  connect(mapStateToProps, {
    setUserDomain,
    getDomainUsers,
    logOff,
    setUserCustomer,
  })(MenuContent)
);
