/**
 * AsyncComponent
 * Code Splitting Component / Server Side Rendering
 */
import React from "react";
// rct page loader
import RctPageLoader from "../RctPageLoader/RctPageLoader";

// Customer Dashboard
const CustomerDashboardRoute = React.lazy(() =>
  import("../../routes/pages/customerdashboard1/Index")
);

const AsyncCustomerDashboardComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <CustomerDashboardRoute {...props} />
  </React.Suspense>
);

// Compare Trends
const CompareTrendsRoute = React.lazy(() =>
  import("../../routes/pages/comparetrends/Index")
);

const AsyncCompareTrendsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <CompareTrendsRoute {...props} />
  </React.Suspense>
);
// Reports
const ReportsRoute = React.lazy(() =>
  import("../../routes/pages/reports/Index")
);

const AsyncReportsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ReportsRoute {...props} />
  </React.Suspense>
);

const ReportingRoute = React.lazy(() =>
  import("../../routes/pages/reporting/index")
);

const AsyncReportingComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ReportingRoute {...props} />
  </React.Suspense>
);

// Profiles
const ProfilesRoute = React.lazy(() => import("../../routes/pages/profiles"));

const AsyncProfilesComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ProfilesRoute {...props} />
  </React.Suspense>
);

// Devices
const DevicesRoute = React.lazy(() => import("../../routes/pages/devices"));

const AsyncDevicesComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <DevicesRoute {...props} />
  </React.Suspense>
);

// Upload
const UploadRoute = React.lazy(() => import("../../routes/pages/upload"));

const AsyncUploadComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <UploadRoute {...props} />
  </React.Suspense>
);

// manage contents
const ContentsRoute = React.lazy(() => import("../../routes/pages/contents"));

const AsyncManageContentsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ContentsRoute {...props} />
  </React.Suspense>
);

// manage constellation
const ConstellationRoute = React.lazy(() =>
  import("../../routes/pages/constellation")
);

const AsyncManageConstellationsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ConstellationRoute {...props} />
  </React.Suspense>
);

// manage campaigns
const CampaignsRoute = React.lazy(() => import("../../routes/pages/campaigns"));

const AsyncManageCampaignsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <CampaignsRoute {...props} />
  </React.Suspense>
);

// manage Audiences
const AudiencesRoute = React.lazy(() => import("../../routes/pages/audiences"));

const AsyncManageAudiencesComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <AudiencesRoute {...props} />
  </React.Suspense>
);

// Manage Roles
const RolesRoute = React.lazy(() => import("../../routes/pages/roles"));

const AsyncManageRolesComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <RolesRoute {...props} />
  </React.Suspense>
);

// Manage Users
const UsersRoute = React.lazy(() => import("../../routes/pages/users"));

const AsyncManageUsersComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <UsersRoute {...props} />
  </React.Suspense>
);

// Manage Vouchers
const VoucherRoute = React.lazy(() =>
  import("../../routes/pages/managevouchers")
);

const AsyncManageVouchersComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <VoucherRoute {...props} />
  </React.Suspense>
);

// Domain Segments
const DomainSegmentsRoute = React.lazy(() =>
  import("../../routes/pages/domainsegments")
);

const AsyncDomainSegmentsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <DomainSegmentsRoute {...props} />
  </React.Suspense>
);

// Campaign Dashboard
const CampaignDashboardRoute = React.lazy(() =>
  import("../../routes/pages/campaigndashboard")
);

const AsyncCampaignDashboardComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <CampaignDashboardRoute {...props} />
  </React.Suspense>
);

// manage domains
const ManageDomainsRoute = React.lazy(() =>
  import("../../routes/pages/domains")
);

const AsyncManageDomainsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ManageDomainsRoute {...props} />
  </React.Suspense>
);

// manage customer domains
const CustomerDomainsRoute = React.lazy(() =>
  import("../../routes/pages/customerdomains")
);

const AsyncManageCustomerDomainsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <CustomerDomainsRoute {...props} />
  </React.Suspense>
);

// manage Customers
const CustomerRoute = React.lazy(() =>
  import("../../routes/pages/managecustomers")
);

const AsyncManageCustomersComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <CustomerRoute {...props} />
  </React.Suspense>
);

// manage partners
const PartnerRoute = React.lazy(() =>
  import("../../routes/pages/managepartners")
);

const AsyncManagePartnersComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <PartnerRoute {...props} />
  </React.Suspense>
);

// manage a domain for extended properties
const DomainPropertiesRoute = React.lazy(() =>
  import("../../routes/pages/managedomain")
);

const AsyncManageDomainPropertiesComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <DomainPropertiesRoute {...props} />
  </React.Suspense>
);

// manage domain users
const DomainUsersRoute = React.lazy(() =>
  import("../../routes/pages/domainusers")
);

const AsyncDomainUsersComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <DomainUsersRoute {...props} />
  </React.Suspense>
);

//segments page
const SegmentsRoute = React.lazy(() =>
  import("../../routes/pages/segments/index")
);

const AsyncDomainSegmentComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <SegmentsRoute {...props} />
  </React.Suspense>
);

// manage Applications
const ApplicationsRoute = React.lazy(() =>
  import("../../routes/pages/applications")
);

const AsyncManageApplicationsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ApplicationsRoute {...props} />
  </React.Suspense>
);
// manage Custom Domains
const ManageCustomDomainsRoute = React.lazy(() =>
  import("../../routes/pages/managecustomdomains")
);

const AsyncManageCustomDomainsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ManageCustomDomainsRoute {...props} />
  </React.Suspense>
);

// manage tcf cmp configurations
const TCFCMPRoute = React.lazy(() =>
  import("../../routes/pages/consentmanagement/tcfconfiguration")
);

const AsyncManageTCFConfigurationsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <TCFCMPRoute {...props} />
  </React.Suspense>
);

// manage light cmp configurations
const LightCMPRoute = React.lazy(() =>
  import("../../routes/pages/consentmanagement/lightconfiguration")
);

const AsyncManageLightConfigurationsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <LightCMPRoute {...props} />
  </React.Suspense>
);
const ManageConnector = React.lazy(() =>
  import("../../routes/pages/connectors")
);

const AsyncManageConnectors = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ManageConnector {...props} />
  </React.Suspense>
);

const CookieScan = React.lazy(() =>
  import("../../routes/pages/cookieScanV2/index")
);

const AsyncCookieScan = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <CookieScan {...props} />
  </React.Suspense>
);

// Login / Signup Route
const LandingRoute = React.lazy(() => import("../../routes/pages/landing"));

const AsyncLandingComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <LandingRoute {...props} />
  </React.Suspense>
);

// Welcome Page
const WelcomeRoute = React.lazy(() => import("../../routes/pages/welcome"));

const AsyncWelcomeComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <WelcomeRoute {...props} />
  </React.Suspense>
);

// Welcome Page
const RegisterRoute = React.lazy(() =>
  import("../../routes/pages/registration")
);

const AsyncRegistrationComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <RegisterRoute {...props} />
  </React.Suspense>
);

const ManageAccount = React.lazy(() =>
  import("../../routes/pages/manageaccount/index")
);

const AsyncManageAccount = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ManageAccount {...props} />
  </React.Suspense>
);

// Invite Route - When user clicks on the invitation link in the email sent to them
const InviteRoute = React.lazy(() =>
  import("../../routes/pages/teaminvitation")
);

const AsyncInviteComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <InviteRoute {...props} />
  </React.Suspense>
);

// Manage requests route
const ManageRequestsRoute = React.lazy(() =>
  import("../../routes/pages/managerequests")
);

const AsyncManageRequestsComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ManageRequestsRoute {...props} />
  </React.Suspense>
);

// Manage cookieRepo route
const ManageCookieRepoRoute = React.lazy(() =>
  import("../../routes/pages/cookieRepo")
);

const AsyncManageCookieRepoComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ManageCookieRepoRoute {...props} />
  </React.Suspense>
);

// Manage cookieRepo route
const ManageCustomerDomainScanRoute = React.lazy(() =>
  import("../../routes/pages/managecustomerdomainscan")
);

const AsyncManageCustomerDomainScanComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <ManageCustomerDomainScanRoute {...props} />
  </React.Suspense>
);

// GVL Update route
const GVLUpdateRoute = React.lazy(() =>
  import("../../routes/pages/gvlupdates")
);

const AsyncGVLUpdateComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <GVLUpdateRoute {...props} />
  </React.Suspense>
);
const FPConfiguratorRoute = React.lazy(() =>
  import("../../routes/pages/firstpartyconfigurator")
);
const AsyncFPConfiguratorComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <FPConfiguratorRoute {...props} />
  </React.Suspense>
);

const DomainCountRoute = React.lazy(() =>
  import("../../routes/pages/domainCount/index")
);

const AsyncDomainCountComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <DomainCountRoute {...props} />
  </React.Suspense>
);

const CertCheck = React.lazy(() =>
  import("../../routes/pages/certcheck/index")
);

const AsyncCertCheckComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <CertCheck {...props} />
  </React.Suspense>
);
const FirstParty = React.lazy(() =>
  import("../../routes/pages/firstpartyapp/index")
);

const AsyncFirstPartyComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <FirstParty {...props} />
  </React.Suspense>
);

const CustomerDomain = React.lazy(() =>
  import("../../routes/pages/customerdomainapp/index")
);

const AsyncCustomerDomainComponent = (props) => (
  <React.Suspense fallback={<RctPageLoader />}>
    <CustomerDomain {...props} />
  </React.Suspense>
);

/*---------------- Editor -------------------*/

export {
  AsyncReportsComponent,
  AsyncProfilesComponent,
  AsyncDevicesComponent,
  AsyncUploadComponent,
  AsyncManageContentsComponent,
  AsyncManageCampaignsComponent,
  AsyncManageConstellationsComponent,
  AsyncManageRolesComponent,
  AsyncManageUsersComponent,
  AsyncDomainSegmentsComponent,
  AsyncManageAudiencesComponent,
  AsyncCampaignDashboardComponent,
  AsyncManageDomainsComponent,
  AsyncManageCustomersComponent,
  AsyncManageDomainPropertiesComponent,
  AsyncDomainUsersComponent,
  AsyncManageCustomerDomainsComponent,
  AsyncDomainSegmentComponent,
  AsyncManageApplicationsComponent,
  AsyncManageCustomDomainsComponent,
  AsyncManageTCFConfigurationsComponent,
  AsyncManageLightConfigurationsComponent,
  AsyncCustomerDashboardComponent,
  AsyncManageConnectors,
  AsyncLandingComponent,
  AsyncWelcomeComponent,
  AsyncRegistrationComponent,
  AsyncCookieScan,
  AsyncManageAccount,
  AsyncManageVouchersComponent,
  AsyncReportingComponent,
  AsyncInviteComponent,
  AsyncManageRequestsComponent,
  AsyncManagePartnersComponent,
  AsyncManageCookieRepoComponent,
  AsyncManageCustomerDomainScanComponent,
  AsyncGVLUpdateComponent,
  AsyncFPConfiguratorComponent,
  AsyncDomainCountComponent,
  AsyncCertCheckComponent,
  AsyncFirstPartyComponent,
  AsyncCustomerDomainComponent,
  AsyncCompareTrendsComponent,
};
