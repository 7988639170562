/**
 * Roles Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  authenticateUserFailure,
  authenticateUserSuccess,
  checkCustomerAdminStatusFailure,
  checkCustomerAdminStatusSuccess,
  getCurrentUserSuccess,
  getDomainUsersFailure,
  getDomainUsersSuccess,
  getFirstpartyDomainsFailure,
  getFirstpartyDomainsSuccess,
  getUserDomainsFailure,
  getUserDomainsSuccess,
  logOffFailure,
  logOffSuccess,
} from "../actions";
import { GET_FIRSTPARTY_DOMAINS } from "../actions/types";
import { getAxiosRequest, getIdentityAxiosRequest } from "../helpers/helpers";

export const user = (state) => state.authUser;

const authenticate = async () => {
  return await getAxiosRequest().get("/account/authenticate");
};

const logoff = async () => {
  return await getIdentityAxiosRequest().post("/account/logoff", {});
};

const getUserDomainsFromServer = async () => {
  return await getAxiosRequest().get("/user/domain");
};

const getUserCustomerAdminStatus = async () => {
  return await getAxiosRequest().get("/user/customeradmin");
};

const getCurrentUser = async () => {
  return await getAxiosRequest().get("/user");
};

const getFirstpartyDomainsCall = async (cId) => {
  return await getAxiosRequest().get(
    `/manage/customer/${cId}/customdomain/status/validated`
  );
};
const getDomainUsersFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };
  return await getAxiosRequest().get("/manage/domain/users", config);
};

/**
 * Get User domains
 */
function* getUserDomains() {
  try {
    // call api to generate magic link
    let model = yield select(user); // <-- get the model
    const response = yield call(getUserDomainsFromServer);
    yield put(getUserDomainsSuccess(response));
    if (response && !model.isAdmin) {
      const userResponse = yield call(
        getDomainUsersFromServer,
        response.data[0].id
      );
      yield put(getDomainUsersSuccess(userResponse));
    }
  } catch (error) {
    yield put(getUserDomainsFailure(error.response.data.error));
  }
}

/**
 * Get Domain admins within a domain
 */
function* getDomainAdmins() {
  try {
    let model = yield select(user); // <-- get the model
    // call api to generate magic link
    const response = yield call(
      getDomainUsersFromServer,
      model.currentDomain.id
    );
    yield put(getDomainUsersSuccess(response));
  } catch (error) {
    yield put(getDomainUsersFailure(error.response.data.error));
  }
}

/**
 * Get CustomerAdmin Status of logged in user
 */
function* checkCustomerAdminStatus() {
  try {
    // call api to get customer admin status
    const response = yield call(getUserCustomerAdminStatus);
    yield put(checkCustomerAdminStatusSuccess(response));
  } catch (error) {
    yield put(checkCustomerAdminStatusFailure(error.response.data.error));
  }
}

function* authenticateUser() {
  try {
    // call api to generate magic link
    const response = yield call(authenticate);

    if (!response.data.roles.includes("ADMIN")) {
      // check if the logged in user is a customer admin
      yield put(authenticateUserSuccess(response));
      try {
        // call api to get customer admin status
        const customerAdminResponse = yield call(getUserCustomerAdminStatus);
        yield put(checkCustomerAdminStatusSuccess(customerAdminResponse));
      } catch (customerAdminerror) {
        yield put(
          checkCustomerAdminStatusFailure(
            customerAdminerror.response.data.error
          )
        );
      }

      // get User Domains

      // try {
      //   // call api to generate magic link
      //   let model = yield select(user); // <-- get the model
      //   const userDomainsresponse = yield call(getUserDomainsFromServer);
      //   yield put(getUserDomainsSuccess(userDomainsresponse));
      //   if (userDomainsresponse && !model.isAdmin) {
      //     const userResponse = yield call(
      //       getDomainUsersFromServer,
      //       response.data[0].id
      //     );
      //     yield put(getDomainUsersSuccess(userResponse));
      //   }
      // } catch (userDomainserror) {
      //   yield put(getUserDomainsFailure(userDomainserror.response.data.error));
      // }
    } else {
      yield put(authenticateUserSuccess(response));
    }
  } catch (error) {
    yield put(authenticateUserFailure(""));
  }
}

function* logOffUser() {
  try {
    // call api to generate magic link
    const response = yield call(logoff);
    yield put(logOffSuccess(response));
  } catch (error) {
    yield put(logOffFailure(""));
  }
}

function* getUser() {
  try {
    // call api to generate magic link
    const response = yield call(getCurrentUser);
    yield put(getCurrentUserSuccess(response.data.username));
  } catch (error) {}
}

function* getFirstPartyDomain() {
  try {
    let model = yield select(user);
    let customerID = model.currentDomain.customerId;
    let response = yield call(getFirstpartyDomainsCall, customerID);
    if (response) {
      yield put(getFirstpartyDomainsSuccess(response.data));
    }
  } catch (error) {
    yield put(getFirstpartyDomainsFailure(error));
  }
}

export const authUserSagas = [
  takeEvery("CHECK_CUSTOMER_ADMIN_STATUS", checkCustomerAdminStatus),
  takeEvery("GET_USER_DOMAINS", getUserDomains),
  takeEvery("GET_DOMAIN_USERS", getDomainAdmins),
  takeEvery("AUTHENTICATE_USER", authenticateUser),
  takeEvery("LOGOUT_USER", logOffUser),
  takeEvery("GET_CURRENT_USER", getUser),
  takeEvery(GET_FIRSTPARTY_DOMAINS, getFirstPartyDomain),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...authUserSagas]);
}
