/* eslint-disable import/no-anonymous-default-export */
/*
    Customer Dashboard Reducer
    
*/
import { NotificationManager } from "react-notifications";
import { createContextHash, getContextObject } from "../helpers/helpers";
import _ from "lodash";
import moment from "moment";
import {
  CHANGE_DOMAIN,
  EXECUTE_PAYMENT,
  EXECUTE_PAYMENT_FAILURE,
  EXECUTE_PAYMENT_SUCCESS,
  FILTER_CUSTOMER_CONSENT_REPORT,
  GET_CHANNEL_COUNT_REPORT,
  GET_CHANNEL_COUNT_REPORT_FAILURE,
  GET_CHANNEL_COUNT_REPORT_SUCCESS,
  GET_CONSENT_CHECK_COUNT_FAILURE,
  GET_CONSENT_CHECK_COUNT_SUCCESS,
  GET_COST_PER_ACTIVITY_FAILURE,
  GET_COST_PER_ACTIVITY_SUCCESS,
  GET_COST_PER_CONTACT_FAILURE,
  GET_COST_PER_CONTACT_SUCCESS,
  GET_CUSTOMER_CONSENT_REPORT,
  GET_CUSTOMER_CONSENT_REPORT_FAILURE,
  GET_CUSTOMER_CONSENT_REPORT_SUCCESS,
  GET_CUSTOMER_CONSUMER_REPORT,
  GET_CUSTOMER_CONSUMER_REPORT_FAILURE,
  GET_CUSTOMER_CONSUMER_REPORT_SUCCESS,
  GET_CUSTOMER_EVENT_REPORT,
  GET_CUSTOMER_EVENT_REPORT_FAILURE,
  GET_CUSTOMER_EVENT_REPORT_SUCCESS,
  GET_CUSTOMER_LEDGER_REPORT,
  GET_CUSTOMER_LEDGER_REPORT_FAILURE,
  GET_CUSTOMER_LEDGER_REPORT_SUCCESS,
  GET_CUSTOMER_LIST,
  GET_CUSTOMER_LIST_FAILURE,
  GET_CUSTOMER_LIST_SUCCESS,
  GET_DOMAIN_CAMPAIGN_REPORT,
  GET_DOMAIN_CAMPAIGN_REPORT_FAILURE,
  GET_DOMAIN_CAMPAIGN_REPORT_SUCCESS,
  GET_DOMAIN_EVENTS,
  GET_DOMAIN_EVENTS_FAILURE,
  GET_DOMAIN_EVENTS_SUCCESS,
  GET_DOMAIN_LEDGER_REPORT,
  GET_DOMAIN_LEDGER_REPORT_FAILURE,
  GET_DOMAIN_LEDGER_REPORT_SUCCESS,
  REFUND_PAYMENT,
  REFUND_PAYMENT_FAILURE,
  REFUND_PAYMENT_SUCCESS,
  SELECT_CUSTOMER,
  SELECT_CUSTOMER_DOMAIN,
  SELECT_CUSTOMER_DOMAIN_FAILURE,
  SELECT_CUSTOMER_DOMAIN_SUCCESS,
} from "../actions/types";
// initial state
const INIT_STATE = {
  consentdata: [],
  filteredConsentdata: [],
  consumerdata: [],
  eventsdata: [],
  ledgerdata: [],
  channelCount: [],
  audienceCount: 0,
  customers: [],
  selectedCustomer: null,
  selectedCustomerDomain: null,
  loading: false,
  payment: null,
  refundTransaction: null,
  activityCosts: null,
  contactCosts: null,
  consentChecks: null,
  domainReport: null,
  selectedCustomerDomainForEvent: null,
  filterModel: {
    type: "hour",
    text: "Last 12 hours",
    value: 12,
  },
  reportType: null,
  profilesStats: {
    new: 0,
    revisits: 0,
    updates: 0,
  },
  domainStats: [],
  browserStats: [],
  noDataStatus:false,
  consentFilterDays:1,
  consentFilterDomain:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get non draft campaigns from server.
    case GET_CUSTOMER_EVENT_REPORT:
      return { ...state, loading: true };
    // On Success...
    case GET_CUSTOMER_EVENT_REPORT_SUCCESS:
      var events = [];
      if (action.payload.data) {
        events = JSON.parse(action.payload.data);
      }
      return { ...state, loading: false, eventsdata: events };
    // On Failure, show error message.
    case GET_CUSTOMER_EVENT_REPORT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get non draft campaign stats from server.
    case GET_CUSTOMER_LEDGER_REPORT:
      return { ...state, loading: true };
    // On Success...
    case GET_CUSTOMER_LEDGER_REPORT_SUCCESS:
      var ledger = [];
      if (action.payload.data) {
        ledger = JSON.parse(action.payload.data);
      }

      return { ...state, loading: false, ledgerdata: ledger };
    // On Failure, show error message.
    case GET_CUSTOMER_LEDGER_REPORT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_CUSTOMER_LIST:
      return { ...state };
    case GET_CUSTOMER_LIST_SUCCESS:
      return { ...state, customers: action.payload.data };
    case GET_CUSTOMER_LIST_FAILURE:
      return { ...state };
    case SELECT_CUSTOMER:
      
      let usercontext = getContextObject();
      // if context exists
      if(usercontext){
        usercontext.customerId = action.payload.id;
        // refresh hash of the context and store it in sessionStorage
        createContextHash(usercontext);
      }else{
        var context = {
          "isPartner": false, 
          "customerId": action.payload.id
        }
        // create hash of the context and store it in sessionStorage
        createContextHash(context);
  
      }
     
      return {
        ...state,
        selectedCustomer: action.payload,
        reportType: null,
        selectedCustomerDomain: action.payload.domains[0],
        selectedCustomerDomainForEvent: action.payload.domains[0],
        eventsdata: [],
        ledgerdata: [],
        domainReport: null,
      };
    case EXECUTE_PAYMENT:
      return { ...state, loading: true, payment: action.payload };
    case EXECUTE_PAYMENT_SUCCESS:
      NotificationManager.success("Your payment was successful");
      return { ...state, loading: false, payment: null };
    case EXECUTE_PAYMENT_FAILURE:
      NotificationManager.error(
        "We count not process your payment, please try again later."
      );
      return { ...state };
    case REFUND_PAYMENT:
      return { ...state, loading: true, refundTransaction: action.payload };
    case REFUND_PAYMENT_SUCCESS:
      NotificationManager.success("Transaction was refunded successfully.");
      return { ...state, loading: false, refundTransaction: null };
    case REFUND_PAYMENT_FAILURE:
      NotificationManager.error(
        "We count not process refund, please try again later."
      );
      return { ...state, loading: false, refundTransaction: null };
    // Get channel count from server.
    case GET_CHANNEL_COUNT_REPORT:
      return { ...state, loading: true };
    // On Success...
    case GET_CHANNEL_COUNT_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        channelCount: action.payload.data.result.channelCounts,
        audienceCount: action.payload.data.result.userCount,
      };
    // On Failure, show error message.
    case GET_CHANNEL_COUNT_REPORT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get channel count from server for selected Domain.
    case SELECT_CUSTOMER_DOMAIN:
      return {
        ...state,
        loading: true,
        selectedCustomerDomain: action.payload,
      };
    // On Success...
    case SELECT_CUSTOMER_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        channelCount: action.payload.data.result.channelCounts,
        audienceCount: action.payload.data.result.userCount,
      };
    // On Failure, show error message.
    case SELECT_CUSTOMER_DOMAIN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    // On Success...
    case GET_COST_PER_ACTIVITY_SUCCESS:
      var activity = null;
      if (action.payload.data) {
        activity = JSON.parse(action.payload.data);
      }
      return { ...state, loading: false, activityCosts: activity };
    // On Failure, show error message.
    case GET_COST_PER_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        activityCosts: null,
      };

    case GET_COST_PER_CONTACT_SUCCESS:
      return { ...state, loading: false, contactCosts: action.payload.data };
    // On Failure, show error message.
    case GET_COST_PER_CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        contactCosts: null,
      };

    case GET_CONSENT_CHECK_COUNT_SUCCESS:
      return { ...state, loading: false, consentChecks: action.payload.data };
    // On Failure, show error message.
    case GET_CONSENT_CHECK_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        consentChecks: null,
      };

    case GET_DOMAIN_EVENTS:
      if (action.payload && action.payload.filterModel) {
        return {
          ...state,
          loading: true,
          filterModel: action.payload.filterModel,
          reportType: 5,
        };
      } else {
        return { ...state, loading: true, reportType: 5 };
      }

    case GET_DOMAIN_EVENTS_SUCCESS:
      if (action.payload === "") {
        return {
          ...state,
          domainReport: [],
          loading: false,
        };
      } else {
        return {
          ...state,
          domainReport: JSON.parse(action.payload),
          loading: false,
        };
      }

    case GET_DOMAIN_EVENTS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_DOMAIN:
      return { ...state, selectedCustomerDomainForEvent: action.payload };
    case GET_DOMAIN_CAMPAIGN_REPORT:
      return { ...state, reportType: 4, loading: true };
    case GET_DOMAIN_CAMPAIGN_REPORT_SUCCESS:
      let eventsdata;
      if (action.payload === "") {
        eventsdata = [];
      } else {
        eventsdata = JSON.parse(action.payload);
      }
      return { ...state, eventsdata: eventsdata, loading: false };
    case GET_DOMAIN_CAMPAIGN_REPORT_FAILURE:
      return { ...state, loading: false };
    case GET_DOMAIN_LEDGER_REPORT:
      return { ...state, loading: true, reportType: 3, ledgerdata:[] };
    case GET_DOMAIN_LEDGER_REPORT_SUCCESS:
      var ledgerdata;
      if (action.payload === "") {
        ledgerdata = [];
      } else {
        ledgerdata = [];
        ledgerdata = JSON.parse(action.payload);
      }

      return { ...state, ledgerdata: ledgerdata, loading: false };
    case GET_DOMAIN_LEDGER_REPORT_FAILURE:
      return { ...state, loading: false };

// Get customer consent stats from server.
case GET_CUSTOMER_CONSENT_REPORT:
  return { ...state, loading: true, reportType:2, consentFilterDays:action.payload };
// On Success...
case GET_CUSTOMER_CONSENT_REPORT_SUCCESS:
  
  let days = [];    
  // CMP Events
  let cmpDomainsData = [];
  
  let totalcmpLoaded = 0;
  let totalcmpLayer1Visible = 0;
  let totalcmpLayer2Visible = 0;
  let totalcmpLayer1OptinAll = 0;
  let totalcmpLayer2OptinAll = 0;
  let totalcmpLayer2OptinSelected = 0;
  let totalcmpPreviousOption = 0;
  let totalcmpLayer1OptoutAll = 0;
  let totalcmpLayer2OptoutAll = 0;

  
  var eventsData = action.payload;

  // if cmp events are returned
  var cmpInfo = [];
        // if the data is returned by the API
        if (eventsData) {
          days = [];
          // loop through data returned for the given date range.
          eventsData.forEach((item) => {
            // loop through domainCounts array
            days.push(moment(item.reportDay).format("DD MMM"));

            // create a structure for the line charts to be shown in cards. 
            var cmpDetails = {
              day: moment(item.reportDay).format("DD MMM"),
              acceptAll:0,
              acceptSelected:0,
              rejectAll:0,
              revisits:0
            }

            if (item.domainCounts.length > 0) {
              item.domainCounts.forEach((element) => {
                
  
                // push the element into domains data array after checking if an entry exists in the array
                // if entry exisits then add the new counts to previous record
                if (
                  _.filter(cmpDomainsData, { domain: element.domain }).length > 0
                ) {
                  var currentDomainData = _.filter(cmpDomainsData, {
                    domain: element.domain,
                  })[0];
                  // count by events items first
                  if (element.countByEvent["CMP cmploaded"]) {
                    currentDomainData.countByEvent["CMP cmploaded"]
                      ? (currentDomainData.countByEvent["CMP cmploaded"] =
                          currentDomainData.countByEvent["CMP cmploaded"] +
                          element.countByEvent["CMP cmploaded"])
                      : (currentDomainData.countByEvent["CMP cmploaded"] =
                          element.countByEvent["CMP cmploaded"]);
                  }

                  if (element.countByEvent["CMP layer1:visible"]) {
                    currentDomainData.countByEvent["CMP layer1:visible"]
                      ? (currentDomainData.countByEvent["CMP layer1:visible"] =
                          currentDomainData.countByEvent["CMP layer1:visible"] +
                          element.countByEvent["CMP layer1:visible"])
                      : (currentDomainData.countByEvent["CMP layer1:visible"] =
                          element.countByEvent["CMP layer1:visible"]);
                  }

                  if (element.countByEvent["CMP layer1:opt-in:all"]) {
                    currentDomainData.countByEvent["CMP layer1:opt-in:all"]
                      ? (currentDomainData.countByEvent["CMP layer1:opt-in:all"] =
                          currentDomainData.countByEvent["CMP layer1:opt-in:all"] +
                          element.countByEvent["CMP layer1:opt-in:all"])
                      : (currentDomainData.countByEvent["CMP layer1:opt-in:all"] =
                          element.countByEvent["CMP layer1:opt-in:all"]);
                  }
  
                  if (element.countByEvent["CMP layer1:opt-out:all"]) {
                    currentDomainData.countByEvent["CMP layer1:opt-out:all"]
                      ? (currentDomainData.countByEvent["CMP layer1:opt-out:all"] =
                          currentDomainData.countByEvent["CMP layer1:opt-out:all"] +
                          element.countByEvent["CMP layer1:opt-out:all"])
                      : (currentDomainData.countByEvent["CMP layer1:opt-out:all"] =
                          element.countByEvent["CMP layer1:opt-out:all"]);
                  }
  
                  if (element.countByEvent["CMP layer2:visible"]) {
                    currentDomainData.countByEvent["CMP layer2:visible"]
                      ? (currentDomainData.countByEvent["CMP layer2:visible"] =
                          currentDomainData.countByEvent["CMP layer2:visible"] +
                          element.countByEvent["CMP layer2:visible"])
                      : (currentDomainData.countByEvent["CMP layer2:visible"] =
                          element.countByEvent["CMP layer2:visible"]);
                  }

                  if (element.countByEvent["CMP layer2:opt-in:selected"]) {
                    currentDomainData.countByEvent["CMP layer2:opt-in:selected"]
                      ? (currentDomainData.countByEvent["CMP layer2:opt-in:selected"] =
                          currentDomainData.countByEvent["CMP layer2:opt-in:selected"] +
                          element.countByEvent["CMP layer2:opt-in:selected"])
                      : (currentDomainData.countByEvent["CMP layer2:opt-in:selected"] =
                          element.countByEvent["CMP layer2:opt-in:selected"]);
                  }

                  if (element.countByEvent["CMP layer2:opt-in:all"]) {
                    currentDomainData.countByEvent["CMP layer2:opt-in:all"]
                      ? (currentDomainData.countByEvent["CMP layer2:opt-in:all"] =
                          currentDomainData.countByEvent["CMP layer2:opt-in:all"] +
                          element.countByEvent["CMP layer2:opt-in:all"])
                      : (currentDomainData.countByEvent["CMP layer2:opt-in:all"] =
                          element.countByEvent["CMP layer2:opt-in:all"]);
                  }                      
                  if (element.countByEvent["CMP layer2:opt-out:all"]) {
                    currentDomainData.countByEvent["CMP layer2:opt-out:all"]
                      ? (currentDomainData.countByEvent["CMP layer2:opt-out:all"] =
                          currentDomainData.countByEvent["CMP layer2:opt-out:all"] +
                          element.countByEvent["CMP layer2:opt-out:all"])
                      : (currentDomainData.countByEvent["CMP layer2:opt-out:all"] =
                          element.countByEvent["CMP layer2:opt-out:all"]);
                  }                      

                  if (element.countByEvent["CMP opt-in:previously"]) {
                    currentDomainData.countByEvent["CMP opt-in:previously"]
                      ? (currentDomainData.countByEvent["CMP opt-in:previously"] =
                          currentDomainData.countByEvent["CMP opt-in:previously"] +
                          element.countByEvent["CMP opt-in:previously"])
                      : (currentDomainData.countByEvent["CMP opt-in:previously"] =
                          element.countByEvent["CMP opt-in:previously"]);
                  }                      

                }
                // else add the new entry
                else {

                  cmpDomainsData.push(element);
                }
  
                // check if the cmp loaded event is returned
                if (element.countByEvent["CMP cmploaded"]) {
                  totalcmpLoaded =
                  totalcmpLoaded + element.countByEvent["CMP cmploaded"];
                }

            
                if (element.countByEvent["CMP layer1:visible"]) {
                  totalcmpLayer1Visible =
                  totalcmpLayer1Visible + element.countByEvent["CMP layer1:visible"];
                }

                if (element.countByEvent["CMP layer1:opt-in:all"]) {
                  totalcmpLayer1OptinAll =
                  totalcmpLayer1OptinAll + element.countByEvent["CMP layer1:opt-in:all"];
                }
                if (element.countByEvent["CMP layer1:opt-out:all"]) {
                  totalcmpLayer1OptoutAll =
                  totalcmpLayer1OptoutAll + element.countByEvent["CMP layer1:opt-out:all"];
                }
                if (element.countByEvent["CMP layer2:visible"]) {
                  totalcmpLayer2Visible =
                  totalcmpLayer2Visible + element.countByEvent["CMP layer2:visible"];
                }
                if (element.countByEvent["CMP layer2:opt-in:selected"]) {
                  totalcmpLayer2OptinSelected =
                  totalcmpLayer2OptinSelected + element.countByEvent["CMP layer2:opt-in:selected"];
                }
                if (element.countByEvent["CMP layer2:opt-in:all"]) {
                  totalcmpLayer2OptinAll =
                  totalcmpLayer2OptinAll + element.countByEvent["CMP layer2:opt-in:all"];
                }
                if (element.countByEvent["CMP layer2:opt-out:all"]) {
                  totalcmpLayer2OptoutAll =
                  totalcmpLayer2OptoutAll + element.countByEvent["CMP layer2:opt-out:all"];
                }
                if (element.countByEvent["CMP opt-in:previously"]) {
                  totalcmpPreviousOption =
                  totalcmpPreviousOption + element.countByEvent["CMP opt-in:previously"];
                }

              });
  

            } else {
            }
            cmpInfo.push(cmpDetails);
          });
        }
  
        var cmpStats = {
          loaded:totalcmpLoaded,
          layer1Visible: totalcmpLayer1Visible,
          layer1OptinAll:totalcmpLayer1OptinAll,
          layer1OptoutAll:totalcmpLayer1OptoutAll,
          layer2Visible: totalcmpLayer2Visible,
          layer2OptinAll:totalcmpLayer2OptinAll,
          layer2OptoutAll: totalcmpLayer2OptoutAll,
          layer2Selected: totalcmpLayer2OptinSelected,
          previousOptin: totalcmpPreviousOption
        };

      
  return {
    ...state,
    loading: false,
    cmpStats:cmpStats,
    consentdata:cmpDomainsData,
    filteredConsentdata:state.consentFilterDomain ===null || state.consentFilterDomain ===''?cmpDomainsData:_.filter(cmpDomainsData, {domain:state.consentFilterDomain})
  };


  
// On Failure, show error message.
case GET_CUSTOMER_CONSENT_REPORT_FAILURE:
  return { ...state, loading: false, error: action.payload, consentdata:[], filteredConsentdata:[] };      

case FILTER_CUSTOMER_CONSENT_REPORT:
  var filterDomain = action.payload;
  var data = null;
  if(filterDomain !== ''){
    data =   _.filter([...state.consentdata], {domain:filterDomain})
  }else{
    data = state.consentdata;
  }
  
  return { ...state, loading: false, error: action.payload, filteredConsentdata: data, consentFilterDomain:filterDomain };      
// Get customer consumer data stats from server.
case GET_CUSTOMER_CONSUMER_REPORT:
  return { ...state, loading: true, reportType:action.payload };
// On Success...
case GET_CUSTOMER_CONSUMER_REPORT_SUCCESS:
      let totalProfilesCreated = 0;
      let totalProfilesFetched = 0;
      let totalProfilesUpdated = 0;
      let totalmpProfilesCreated = 0;
      let totalmpProfilesFetched = 0;
      let totalmpProfilesUpdated = 0;
      let totalmpProfilesMerged = 0;
      let totalProfilesCreatedForTrends = 0;
      let totalProfilesFetchedForTrends = 0;
      let totalProfilesUpdatedForTrends = 0;
      let totalmpProfilesCreatedForTrends = 0;
      let totalmpProfilesFetchedForTrends = 0;
      let totalmpProfilesUpdatedForTrends = 0;
      let totalmpProfilesMergedForTrends = 0;
      let domainsData = [];
      let browserData = [];
      let newProfiles = [];
      let revisits = [];
      let updates = [];

      // Micro profile details
      let mpCreated = [];
      let mpFetched = [];
      let mpUpdated = [];
      let mpMerged = [];
      var dashboardData = action.payload.data;
    
      // if the data is returned by the API
      if (dashboardData) {
        // loop through data returned for the given date range.
        dashboardData.forEach((item) => {
          // loop through domainCounts array
          if (item.domainCounts.length > 0) {
            item.domainCounts.forEach((element) => {
              // code for getting the trends Data

              if (element.countByEvent.opProfileCreated) {
                totalProfilesCreatedForTrends =
                  totalProfilesCreatedForTrends +
                  element.countByEvent.opProfileCreated;
              }
              if (element.countByEvent.opProfileFetched) {
                totalProfilesFetchedForTrends =
                  totalProfilesFetchedForTrends +
                  element.countByEvent.opProfileFetched;
              }
              if (element.countByEvent.opProfileUpdated) {
                totalProfilesUpdatedForTrends =
                  totalProfilesUpdatedForTrends +
                  element.countByEvent.opProfileUpdated;
              }
              
              // new change for microprofile
              if (element.countByEvent.mpProfileCreated) {
                totalmpProfilesCreatedForTrends =
                 totalmpProfilesCreatedForTrends +
                  element.countByEvent.mpProfileCreated;
              }
              if (element.countByEvent.mpProfileFetched) {
                totalmpProfilesFetchedForTrends =
                  totalmpProfilesFetchedForTrends +
                  element.countByEvent.mpProfileFetched;
              }
              if (element.countByEvent.mpProfileUpdated) {
                totalmpProfilesUpdatedForTrends =
                totalmpProfilesUpdatedForTrends +
                  element.countByEvent.mpProfileUpdated;
              }

              if (element.countByEvent.mpProfileMerged) {
                totalmpProfilesMergedForTrends =
                totalmpProfilesMergedForTrends +
                  element.countByEvent.mpProfileMerged;
              }

              // push the element into domains data array after checking if an entry exists in the array
              // if entry exisits then add the new counts to previous record
              if (
                _.filter(domainsData, { domain: element.domain }).length > 0
              ) {
                var currentDomainData = _.find(domainsData, {
                  domain: element.domain,
                });
                // count by events items first
                if (element.countByEvent.opProfileCreated) {
                  currentDomainData.countByEvent.opProfileCreated
                    ? (currentDomainData.countByEvent.opProfileCreated =
                        currentDomainData.countByEvent.opProfileCreated +
                        element.countByEvent.opProfileCreated)
                    : (currentDomainData.countByEvent.opProfileCreated =
                        element.countByEvent.opProfileCreated);
                }
                if (element.countByEvent.opProfileFetched) {
                  currentDomainData.countByEvent.opProfileFetched
                    ? (currentDomainData.countByEvent.opProfileFetched =
                        currentDomainData.countByEvent.opProfileFetched +
                        element.countByEvent.opProfileFetched)
                    : (currentDomainData.countByEvent.opProfileFetched =
                        element.countByEvent.opProfileFetched);
                }
                if (element.countByEvent.opProfileUpdated) {
                  currentDomainData.countByEvent.opProfileUpdated
                    ? (currentDomainData.countByEvent.opProfileUpdated =
                        currentDomainData.countByEvent.opProfileUpdated +
                        element.countByEvent.opProfileUpdated)
                    : (currentDomainData.countByEvent.opProfileUpdated =
                        element.countByEvent.opProfileUpdated);
                }

                // change for microprofiles

                if (element.countByEvent.mpProfileCreated) {
                  currentDomainData.countByEvent.mpProfileCreated
                    ? (currentDomainData.countByEvent.mpProfileCreated =
                        currentDomainData.countByEvent.mpProfileCreated +
                        element.countByEvent.mpProfileCreated)
                    : (currentDomainData.countByEvent.mpProfileCreated =
                        element.countByEvent.mpProfileCreated);
                }
                if (element.countByEvent.mpProfileFetched) {
                  currentDomainData.countByEvent.mpProfileFetched
                    ? (currentDomainData.countByEvent.mpProfileFetched =
                        currentDomainData.countByEvent.mpProfileFetched +
                        element.countByEvent.mpProfileFetched)
                    : (currentDomainData.countByEvent.mpProfileFetched =
                        element.countByEvent.mpProfileFetched);
                }

                if (element.countByEvent.mpProfileUpdated) {
                  currentDomainData.countByEvent.mpProfileUpdated
                    ? (currentDomainData.countByEvent.mpProfileUpdated =
                        currentDomainData.countByEvent.mpProfileUpdated +
                        element.countByEvent.mpProfileUpdated)
                    : (currentDomainData.countByEvent.mpProfileUpdated =
                        element.countByEvent.mpProfileUpdated);
                }

                if (element.countByEvent.mpProfileMerged) {
                  currentDomainData.countByEvent.mpProfileMerged
                    ? (currentDomainData.countByEvent.mpProfileMerged =
                        currentDomainData.countByEvent.mpProfileMerged +
                        element.countByEvent.mpProfileMerged)
                    : (currentDomainData.countByEvent.mpProfileMerged =
                        element.countByEvent.mpProfileMerged);
                }

                
                // countByBrowsers
                if (element.countByBrowser) {
                  // loop through browser entries
                  // if it exists then add to earlier counts else add a new entry
                  for (var key in element.countByBrowser) {
                    if (element.countByBrowser.hasOwnProperty(key)) {
                      // if browser entry already exists in the list then
                      var prevEntry = _.find(browserData, { browser: key });
                      if (prevEntry) {
                        prevEntry.count =
                          prevEntry.count + element.countByBrowser[key];
                      } else {
                        var item = {
                          browser: key,
                          count: element.countByBrowser[key],
                        };
                        browserData.push(item);
                      }
                    }
                  }
                }
              }
              // else add the new entry
              else {
                for (var key in element.countByBrowser) {
                  if (element.countByBrowser.hasOwnProperty(key)) {
                    // if browser entry already exists in the list then
                    var prevEntry = _.find(browserData, { browser: key });
                    if (prevEntry) {
                      prevEntry.count =
                        prevEntry.count + element.countByBrowser[key];
                    } else {
                      var item = {
                        browser: key,
                        count: element.countByBrowser[key],
                      };
                      browserData.push(item);
                    }
                  }
                }
                domainsData.push(element);
              }

              
              

              // check if the profilefetched event is returned
              if (element.countByEvent.opProfileFetched) {
                totalProfilesFetched =
                  totalProfilesFetched + element.countByEvent.opProfileFetched;
              }
              // check if the profileCreated event is returned
              if (element.countByEvent.opProfileCreated) {
                totalProfilesCreated =
                  totalProfilesCreated + element.countByEvent.opProfileCreated;
              }
              // check if the profileUpdated event is returned
              if (element.countByEvent.opProfileUpdated) {
                totalProfilesUpdated =
                  totalProfilesUpdated + element.countByEvent.opProfileUpdated;
              }

              // change for microprofiles
               // check if the mpprofilefetched event is returned
               if (element.countByEvent.mpProfileFetched) {
                totalmpProfilesFetched =
                  totalmpProfilesFetched + element.countByEvent.mpProfileFetched;
              }
              // check if the mpprofileCreated event is returned
              if (element.countByEvent.mpProfileCreated) {
                totalmpProfilesCreated =
                  totalmpProfilesCreated + element.countByEvent.mpProfileCreated;
              }

               // check if the mpprofileUpdated event is returned
               if (element.countByEvent.mpProfileUpdated) {
                totalmpProfilesUpdated =
                totalmpProfilesUpdated + element.countByEvent.mpProfileUpdated;
              }

                // check if the mpProfileMerged event is returned
                if (element.countByEvent.mpProfileMerged) {
                  totalmpProfilesMerged =
                  totalmpProfilesMerged + element.countByEvent.mpProfileMerged;
                }
            });

            newProfiles.push(totalProfilesCreatedForTrends);
            revisits.push(totalProfilesFetchedForTrends);
            updates.push(totalProfilesUpdatedForTrends);
            mpCreated.push(totalmpProfilesCreatedForTrends)
            mpFetched.push(totalmpProfilesFetchedForTrends);
            mpUpdated.push(totalmpProfilesUpdatedForTrends);
            mpMerged.push(totalmpProfilesMergedForTrends);

            totalProfilesCreatedForTrends = 0;
            totalProfilesFetchedForTrends = 0;
            totalProfilesUpdatedForTrends = 0;
            //changes for microprofile
            totalmpProfilesCreatedForTrends = 0;
            totalmpProfilesFetchedForTrends = 0;
            totalmpProfilesUpdatedForTrends = 0;
            totalmpProfilesMergedForTrends = 0;

          } else {
            newProfiles.push(totalProfilesCreatedForTrends);
            revisits.push(totalProfilesFetchedForTrends);
            updates.push(totalProfilesUpdatedForTrends);
            mpCreated.push(totalmpProfilesCreatedForTrends)
            mpFetched.push(totalmpProfilesFetchedForTrends);
            mpUpdated.push(totalmpProfilesUpdatedForTrends);
            mpMerged.push(totalmpProfilesMergedForTrends);

          }
        });
      }

      // Sort domain data in descending order.
      domainsData
        .sort(
          (x, y) =>
            (x.countByEvent.opProfileFetched
              ? x.countByEvent.opProfileFetched
              : 0 + x.countByEvent.opProfileCreated
              ? x.countByEvent.opProfileCreated
              : 0) -
            (y.countByEvent.opProfileFetched
              ? y.countByEvent.opProfileFetched
              : 0 + y.countByEvent.opProfileCreated
              ? y.countByEvent.opProfileCreated
              : 0)
        )
        .reverse();

     
      var profilesStats = {
        new: totalProfilesCreated,
        revisits: totalProfilesFetched,
        updates: totalProfilesUpdated,
        mpCreated: totalmpProfilesCreated,
        mpFetched: totalmpProfilesFetched,
        mpUpdated: totalmpProfilesUpdated,
        mpMerged: totalmpProfilesMerged
      };

      browserData.sort((x, y) => x.count - y.count).reverse();
      return {
        ...state,
        loading: false,
        domainStats: domainsData,
        profilesStats: profilesStats,
        browserStats: browserData,
        noDataStatus: domainsData.length === 0 ? true : false,
        consumerdata:domainsData
      };
    

// On Failure, show error message.
case GET_CUSTOMER_CONSUMER_REPORT_FAILURE:
  return { ...state, loading: false, error: action.payload, consumerdata:[] };     

  default:
      return { ...state, loading: false };
  }
};
