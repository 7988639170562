/**
 * Manage Users Actions
 */
import {
    GET_USERS_FOR_DOMAIN,
    GET_USERS_FOR_DOMAIN_SUCCESS,
    GET_USERS_FOR_DOMAIN_FAILURE,
    GET_EVENTS_FOR_DOMAIN_PROFILE,
    GET_EVENTS_FOR_DOMAIN_PROFILE_SUCCESS,
    GET_EVENTS_FOR_DOMAIN_PROFILE_FAILURE
} from './types';

/**
 * Redux Action To Fetch  Users
 */
export const geUsersForDomain = (domain) => ({
    type: GET_USERS_FOR_DOMAIN,
    payload:domain
})
/**
 * Redux Action for success in fetching users.
 */
export const geUsersForDomainSuccess = (response) => ({
    type: GET_USERS_FOR_DOMAIN_SUCCESS,
    payload: response
});
/**
 * Redux Action for failure in fetching users.
 */

export const geUsersForDomainFailure = (error) => ({
    type: GET_USERS_FOR_DOMAIN_FAILURE,
    payload: error
});

/**
 * Redux Action To Fetch  Events for selected domain profile
 */
export const getEventsForDomainProfile = (email) => ({
    type: GET_EVENTS_FOR_DOMAIN_PROFILE,
    payload:email
})
/**
 * Redux Action for success in fetching Events for selected domain profile.
 */
export const getEventsForDomainProfileSuccess = (response) => ({
    type: GET_EVENTS_FOR_DOMAIN_PROFILE_SUCCESS,
    payload: response
});
/**
 * Redux Action for failure in fetching Events for selected domain profile.
 */

export const getEventsForDomainProfileFailure = (error) => ({
    type: GET_EVENTS_FOR_DOMAIN_PROFILE_FAILURE,
    payload: error
});
