import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  GET_ALL_CD_APPS,
  ADD_CD_APP,
  UPDATE_CD_APP,
  DELETE_CD_APP,
  STATUS_CHANGE_CD_APP,
} from "../actions/types";
import {
  getALLCDAppsSuccess,
  getALLCDAppsFailure,
  addCDAppsSuccess,
  addCDAppsFailure,
  updateCDAppsSuccess,
  updateCDAppsFailure,
  deleteCDAppsSuccess,
  deleteCDAppsFailure,
  changeCDAppStatusSuccess,
  changeCDAppStatusFailure,
} from "../actions/index";
import { getAxiosRequest } from "../helpers/helpers";

const reducer = (state) => state.CustomerDomainAppsReducer;

async function updateAppsCall(model) {
  return await getAxiosRequest().put("/customerdomainapp", model);
}

async function deleteAppsCall(model) {
  return await getAxiosRequest().delete(`/customerdomainapp/${model.id}`);
}

async function addAppsCall(model) {
  return await getAxiosRequest().post("/customerdomainapp", model);
}
async function getAllAppsCall() {
  return await getAxiosRequest().get("/customerdomainapp");
}

async function statusChangeCall(model) {
  return await getAxiosRequest().put(`/customerdomainapp/${model.id}`);
}

function* getAllApps() {
  try {
    let result = yield call(getAllAppsCall);
    if (result) {
      console.log("sucess", result);
      yield put(getALLCDAppsSuccess(result.data));
    }
  } catch (error) {
    yield put(getALLCDAppsFailure(error));
  }
}

function* addApp() {
  try {
    let reducerData = yield select(reducer);
    let model = reducerData.addModel;
    if (model) {
      let res = yield call(addAppsCall, model);
      if (res) {
        yield put(addCDAppsSuccess());
      }
    }
  } catch (error) {
    yield put(addCDAppsFailure());
  }
}

function* updateApp() {
  try {
    let reducerData = yield select(reducer);
    let model = reducerData.addModel;
    if (model) {
      let res = yield call(updateAppsCall, model);
      if (res) {
        yield put(updateCDAppsSuccess());
      }
    }
  } catch (error) {
    yield put(updateCDAppsFailure());
  }
}

function* deleteApp() {
  try {
    let reducerData = yield select(reducer);
    let model = reducerData.deleteModel;
    if (model) {
      let res = yield call(deleteAppsCall, model);
      if (res) {
        yield put(deleteCDAppsSuccess());
      }
    }
  } catch (error) {
    yield put(deleteCDAppsFailure());
  }
}

function* changeCDAppStatus() {
  try {
    let reducerData = yield select(reducer);
    let model = reducerData.statusChangeModel;
    if (model) {
      let res = yield call(statusChangeCall, model);
      if (res) {
        yield put(changeCDAppStatusSuccess());
      }
    }
  } catch (error) {
    yield put(changeCDAppStatusFailure());
  }
}

export const AppSagas = [
  takeEvery(GET_ALL_CD_APPS, getAllApps),
  takeEvery(ADD_CD_APP, addApp),
  takeEvery(UPDATE_CD_APP, updateApp),
  takeEvery(DELETE_CD_APP, deleteApp),
  takeEvery(STATUS_CHANGE_CD_APP, changeCDAppStatus),
];

export default function* rootSaga() {
  yield all([...AppSagas]);
}
