/**
 * Roles Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addRoleFailure,
  addRoleSuccess,
  deleteRoleFailure,
  deleteRoleSuccess,
  getRolesFailure,
  getRolesSuccess,
  refreshRoles,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const roles = (state) => state;
const role = (state) => state.rolesReducer.role;

const getRolesFromServer = async () => {
  return await getAxiosRequest().get("/role/");
};
/**
 * Generate Magic Link
 */
function* getRoles() {
  try {
    // call api to generate magic link
    const response = yield call(getRolesFromServer);

    yield put(getRolesSuccess(response));
  } catch (error) {
    yield put(getRolesFailure(error));

    //yield put(signinUserFailure(error.response.data.error));
  }
}

const postRoleToServer = async (model) => {
  return await getAxiosRequest().post("/role", model);
};

function* addRoles() {
  let model = yield select(role);
  try {
    const response = yield call(postRoleToServer, model);
    yield put(addRoleSuccess(response));
    const refreshResponse = yield call(getRolesFromServer);
    yield put(refreshRoles(refreshResponse));
  } catch (error) {
    yield put(addRoleFailure(error));
  }
}

const deleteRoleFromServer = async (model) => {
  return await getAxiosRequest().delete("/role?roleName=" + model.roleName);
};

function* deleteRoles() {
  let model = yield select(role);
  try {
    const response = yield call(deleteRoleFromServer, model);
    yield put(deleteRoleSuccess(response));
    const refreshResponse = yield call(getRolesFromServer);
    yield put(refreshRoles(refreshResponse));
  } catch (error) {
    yield put(deleteRoleFailure(error));
  }
}

export const rolesSagas = [
  takeEvery("GET_ROLES", getRoles),
  takeEvery("ADD_ROLES", addRoles),
  takeEvery("DELETE_ROLES", deleteRoles),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...rolesSagas]);
}
