/**
 * Audience Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  activateDomainPropertyFailure,
  activateDomainPropertySuccess,
  addDomainPropertyFailure,
  addDomainPropertySuccess,
  deleteDomainPropertyFailure,
  deleteDomainPropertySuccess,
  getDomainPropertiesFailure,
  getDomainPropertiesSuccess,
  getDomainsForPropertyFailure,
  getDomainsForPropertySuccess,
  updateDomainPropertyFailure,
  updateDomainPropertySuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const domainproperties = (state) => state.domainPropertyReducer;
export const authUser = (state) => state.authUser;

const getDomainPropertiesFromServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model },
  };
  return await getAxiosRequest().get("/manage/domain/properties", config);
};

const getDomainsFromServer = async () => {
  return await getAxiosRequest().get("/domain");
};

const addDomainPropertyToServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain },
  };
  return await getAxiosRequest().post(
    "/manage/domain/properties",
    model.property,
    config
  );
};

const activateDomainPropertyToServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain },
  };
  return await getAxiosRequest().post(
    "/manage/domain/properties/submit",
    model.property,
    config
  );
};

const updateDomainProperty = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain },
  };
  return await getAxiosRequest().put(
    "/manage/domain/properties",
    model.property,
    config
  );
};

const removeDomainProperty = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.currentDomain },
  };
  return await getAxiosRequest().delete(
    "/manage/domain/properties?id=" + model.property.id,
    config
  );
};

/**
 * Get Domain Properties
 */
function* getDomainProperties() {
  try {
    let user = yield select(authUser); // <-- get the model
    // call api to generate magic link

    // call api to generate magic link
    const response = yield call(
      getDomainPropertiesFromServer,
      user.currentDomain.id
    );
    yield put(getDomainPropertiesSuccess(response));
  } catch (error) {
    yield put(getDomainPropertiesFailure(error.response.data.error));
  }
}

/**
 * Get Domain Properties
 */
function* getDomainPropertiesForSelectedDomain() {
  try {
    let model = yield select(domainproperties); // <-- get the model
    // call api to generate magic link
    const response = yield call(
      getDomainPropertiesFromServer,
      model.selectedDomain.value
    );
    yield put(getDomainPropertiesSuccess(response));
  } catch (error) {
    yield put(getDomainPropertiesFailure(error.response.data.error));
  }
}

/**
 * Get Domains
 */
function* getDomains() {
  try {
    // call api to generate magic link
    const response = yield call(getDomainsFromServer);
    yield put(getDomainsForPropertySuccess(response));
  } catch (error) {
    yield put(getDomainsForPropertyFailure(error.response.data.error));
  }
}

/**
 * Add Domain Property
 */
function* addDomainProperty() {
  try {
    let user = yield select(authUser); // <-- get the model
    // call api to generate magic link
    let model = yield select(domainproperties); // <-- get the model
    var propertyModel = {};
    if (user.isAdmin) {
      propertyModel = {
        property: model.property,
        currentDomain: model.selectedDomain,
      };
    } else {
      propertyModel = {
        property: model.property,
        currentDomain: user.currentDomain.id,
      };
    }
    const response = yield call(addDomainPropertyToServer, propertyModel);
    yield put(addDomainPropertySuccess(response));
  } catch (error) {
    yield put(addDomainPropertyFailure(error.response.data.error));
  }
}

/**
 * Activate Domain Property
 */
function* activateDomainProperty() {
  try {
    let user = yield select(authUser); // <-- get the model
    // call api to generate magic link
    let model = yield select(domainproperties); // <-- get the model
    var propertyModel = {};
    if (user.isAdmin) {
      propertyModel = {
        property: model.selected,
        currentDomain: model.selectedDomain.value,
      };
    } else {
      propertyModel = {
        property: model.selected,
        currentDomain: user.currentDomain.id,
      };
    }

    const response = yield call(activateDomainPropertyToServer, propertyModel);
    yield put(activateDomainPropertySuccess(response));
  } catch (error) {
    yield put(activateDomainPropertyFailure(error.response.data.error));
  }
}

/**
 * Edit Domain Property
 */
function* editDomainProperty() {
  try {
    let user = yield select(authUser); // <-- get the model
    // call api to generate magic link
    let model = yield select(domainproperties); // <-- get the model

    var propertyModel = {};
    if (user.isAdmin) {
      propertyModel = {
        property: model.selected,
        currentDomain: model.selectedDomain.value,
      };
    } else {
      propertyModel = {
        property: model.selected,
        currentDomain: user.currentDomain.id,
      };
    }

    const response = yield call(updateDomainProperty, propertyModel);
    yield put(updateDomainPropertySuccess(response));
  } catch (error) {
    yield put(updateDomainPropertyFailure(error.response.data.error));
  }
}

/**
 * Delete Domain Property
 */
function* deleteDomainProperty() {
  try {
    let user = yield select(authUser); // <-- get the model
    // call api to generate magic link
    let model = yield select(domainproperties); // <-- get the model

    var propertyModel = {
      property: model.selected,
      currentDomain: user.currentDomain.id,
    };
    const response = yield call(removeDomainProperty, propertyModel);
    yield put(deleteDomainPropertySuccess(response));
    const refreshResponse = yield call(
      getDomainPropertiesFromServer,
      user.currentDomain.id
    );
    yield put(getDomainPropertiesSuccess(refreshResponse));
  } catch (error) {
    yield put(deleteDomainPropertyFailure(error.response.data.error));
  }
}

export const domainPropertySagas = [
  takeEvery("GET_DOMAIN_PROPERTIES", getDomainProperties),
  takeEvery("ADD_DOMAIN_PROPERTY", addDomainProperty),
  takeEvery("UPDATE_DOMAIN_PROPERTY", editDomainProperty),
  takeEvery("DELETE_DOMAIN_PROPERTY", deleteDomainProperty),
  takeEvery("GET_DOMAINS_FOR_PROPERTY", getDomains),
  takeEvery("ACTIVATE_DOMAIN_PROPERTY", activateDomainProperty),
  takeEvery(
    "GET_DOMAIN_PROPERTIES_FOR_SELECTED_DOMAIN",
    getDomainPropertiesForSelectedDomain
  ),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...domainPropertySagas]);
}
