/**
 * Nav Menu Item
 */
import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import $ from 'jquery';

const NavMenuItem = (props) => {
  
  useEffect(()=>{
    $(".level2").removeClass('active');
  }, [])

  const { menu } = props;

    const level2LinkClick = (e) =>{
      $(".level2").removeClass('active');
      $(e.target).parent().parent().addClass("active");
    }

    const level1LinkClick = (e) =>{
      $(".level2").removeClass('active');
    }

    if (menu.child_routes != null) {
      return (
        <li className="nav-item dropdown level2">
        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {menu.menu_title}
        </a>
        <div className="dropdown-menu" style={{right:'auto', left:'0'}} aria-labelledby="navbarDropdown">
        {menu.child_routes.map((subMenu, index) => {
            return(
           <Link  key={index} to={subMenu.path} className={"/"+props.location.pathname.split("/")[1] === subMenu.path?"dropdown-item submenu-color submenuactive":"dropdown-item submenu-color"} onClick={(e) => level2LinkClick(e)}>
            {subMenu.menu_title}
           </Link>
          )
        })}
        </div>
      </li>
      );
    }
    return (
      <li className="nav-item">
          {menu.path.indexOf("http")=== -1?
       <Link to={menu.path} className={props.location.pathname === menu.path? "nav-link level1 active": "nav-link"} onClick={(e) => level1LinkClick(e)}>
       {menu.menu_title}
            </Link>
            :<a id={menu.menu_title} className="nav-link" href={menu.path}>
           
            <span className="menu">
                {menu.menu_title}
            </span>
        </a>}
    </li>
    );
  }


export default NavMenuItem;
