/**
 * Redux Actions for managing crud operations on Campaigns.
 */

import {
    GET_DOMAINS,
    GET_DOMAINS_SUCCESS,
    GET_DOMAINS_FAILURE,
    ADD_DOMAIN,
    ADD_DOMAIN_SUCCESS,
    ADD_DOMAIN_FAILURE,
    UPDATE_DOMAIN,
    UPDATE_DOMAIN_SUCCESS,
    UPDATE_DOMAIN_FAILURE,
    DELETE_DOMAIN,
    DELETE_DOMAIN_SUCCESS,
    DELETE_DOMAIN_FAILURE,
    FILLTER_DOMAINS,
    REFRESH_DOMAINS,
    SELECT_DOMAIN,
    GET_DOMAIN_CUSTOMERS,
    GET_DOMAIN_CUSTOMERS_SUCCESS,
    GET_DOMAIN_CUSTOMERS_FAILURE,
    GENERATE_DOMAIN_KEY,
    GENERATE_DOMAIN_KEY_SUCCESS,
    GENERATE_DOMAIN_KEY_FAILURE,
    REVOKE_ACCESS,
    REVOKE_ACCESS_SUCCESS,
    REVOKE_ACCESS_FAILURE,
    GET_CUSTOMER_DOMAINS,
    GET_CUSTOMER_DOMAINS_SUCCESS,
    GET_CUSTOMER_DOMAINS_FAILURE,
    GET_DOMAIN_BY_ID,
    GET_DOMAIN_BY_ID_FAILURE,
    GET_DOMAIN_BY_ID_SUCCESS,
    TEST_DOMAIN_WEBHOOK,
    TEST_DOMAIN_WEBHOOK_SUCCESS,
    TEST_DOMAIN_WEBHOOK_FAILURE,
    REQUEST_DOMAIN_DELETION,
    REQUEST_DOMAIN_DELETION_SUCCESS,
    REQUEST_DOMAIN_DELETION_FAILURE
} from './types';

/**
 * Redux action to get Domains
 */
export const getDomains =()=>({
    type: GET_DOMAINS
})

/**
 * Redux action for successful fetching of Domains
 */

export const getDomainsSuccess =(response)=>({
    type: GET_DOMAINS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Domains
 */
export const getDomainsFailure =(error)=>({
    type: GET_DOMAINS_FAILURE,
    payload: error
})


/**
 * Redux action for fetching Domains after add/edit/delete of Domain
 */
export const refreshDomains = (response) => ({
    type: REFRESH_DOMAINS,
    payload: response
});

/**
 * Redux action for adding new Domain
 */
export const addDomain = (model) => ({
    type: ADD_DOMAIN,
    payload:model
})
/**
 * Redux action for successful addition of Domain
 */
export const addDomainSuccess =(response)=>({
    type: ADD_DOMAIN_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to add new Domain
 */
export const addDomainFailure =(error)=>({
    type: ADD_DOMAIN_FAILURE,
    payload: error
})

/**
 * Redux action for editing a Domain
 */
export const updateDomain =(model)=>({
    type: UPDATE_DOMAIN,
    payload:model
})
/**
 * Redux action for successful editing of a Domain
 */
export const updateDomainSuccess =(response)=>({
    type: UPDATE_DOMAIN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to edit a Domain
 */
export const updateDomainFailure =(error)=>({
    type: UPDATE_DOMAIN_FAILURE,
    payload: error
})

/**
 * Redux action for deleting a Domain
 */
export const deleteDomain =(id)=>({
    type: DELETE_DOMAIN,
    payload:id
})
/**
 * Redux action for successful deletion of Domain
 */
export const deleteDomainSuccess =(response)=>({
    type: DELETE_DOMAIN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in deletion of Domain
 */
export const deleteDomainFailure =(error)=>({
    type: DELETE_DOMAIN_FAILURE,
    payload: error
})

/**
 * Redux action for filtering Domains from search box
 */
export const fillterDomains=(keyword)=>({
    type: FILLTER_DOMAINS,
    payload: keyword
})
/**
 * Redux action for selecting a Domain 
 */
export const selectDomain=(id)=>({
    type: SELECT_DOMAIN,
    payload: id
})

/**F
 * Redux action to get Customers
 */
export const getDomainCustomers =()=>({
    type: GET_DOMAIN_CUSTOMERS
})

/**
 * Redux action for successful fetching of customers
 */

export const getDomainCustomersSuccess =(response)=>({
    type: GET_DOMAIN_CUSTOMERS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching customers
 */
export const getDomainCustomersFailure =(error)=>({
    type: GET_DOMAIN_CUSTOMERS_FAILURE,
    payload: error
})

/**
 * Redux action for generating domain key
 */
export const generateDomainKey = (model) => ({
    type: GENERATE_DOMAIN_KEY,
    payload:model
})
/**
 * Redux action for successful generation of domain key
 */
export const generateDomainKeySuccess =(response)=>({
    type: GENERATE_DOMAIN_KEY_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to generate domain key
 */
export const generateDomainKeyFailure =(error)=>({
    type: GENERATE_DOMAIN_KEY_FAILURE,
    payload: error
})

/**
 * Redux action for remove access token for domain
 */
export const revokeAccess = (model) => ({
    type: REVOKE_ACCESS,
    payload:model
})
/**
 * Redux action for successful removal of access token for domain
 */
export const revokeAccessSuccess =(response)=>({
    type: REVOKE_ACCESS_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to remove access token for domain
 */
export const revokeAccessFailure =(error)=>({
    type: REVOKE_ACCESS_FAILURE,
    payload: error
})

/**
 * Redux action to get customer Domains
 */
export const getCustomerDomains =()=>({
    type: GET_CUSTOMER_DOMAINS
})

/**
 * Redux action for successful fetching of customer Domains
 */

export const getCustomerDomainsSuccess =(response)=>({
    type: GET_CUSTOMER_DOMAINS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching customer Domains
 */
export const getCustomerDomainsFailure =(error)=>({
    type: GET_CUSTOMER_DOMAINS_FAILURE,
    payload: error
})

export const getDomainById=(id)=>{
    return({
        type:GET_DOMAIN_BY_ID,
        payload:id
    })
}

export const getDomainByIdSuccess=(response)=>{
    return({
        type:GET_DOMAIN_BY_ID_SUCCESS,
        payload:response
    })
}

export const getDomainByIdFailure=(error)=>{
    return({
        type:GET_DOMAIN_BY_ID_FAILURE,
        payload:error
    })
}


/**
 * Redux action for testing a Domain Webhook
 */
export const testDomainWebhook =()=>({
    type: TEST_DOMAIN_WEBHOOK
})
/**
 * Redux action for successful testing of a Domain webhook
 */
export const testDomainWebhookSuccess =(response)=>({
    type: TEST_DOMAIN_WEBHOOK_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to test a Domain webhook
 */
export const testDomainWebhookFailure =(error)=>({
    type: TEST_DOMAIN_WEBHOOK_FAILURE,
    payload: error
})


/**
 * Redux action for requesting to deleting a Domain
 */
 export const requestDomainDeletion =(id)=>({
    type: REQUEST_DOMAIN_DELETION,
    payload:id
})
/**
 * Redux action for successful request for deletion of Domain
 */
export const requestDomainDeletionSuccess =(response)=>({
    type: REQUEST_DOMAIN_DELETION_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to request deletion of Domain
 */
export const requestDomainDeletionFailure =(error)=>({
    type: REQUEST_DOMAIN_DELETION_FAILURE,
    payload: error
})