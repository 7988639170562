/* eslint-disable import/no-anonymous-default-export */
/*
    Campaign Dashboard Reducer
    
*/
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  EXECUTE_CAMPAIGN,
  EXECUTE_CAMPAIGN_FAILURE,
  EXECUTE_CAMPAIGN_SUCCESS,
  GET_CAMPAIGNS_STATS,
  GET_CAMPAIGNS_STATS_FAILURE,
  GET_CAMPAIGNS_STATS_SUCCESS,
  GET_DASHBOARD_CAMPAIGNS,
  GET_DASHBOARD_CAMPAIGNS_FAILURE,
  GET_DASHBOARD_CAMPAIGNS_SUCCESS,
  SEARCH_DASHBOARD_CAMPAIGN,
  SORT_DASHBOARD,
  STOP_CAMPAIGN,
  STOP_CAMPAIGN_FAILURE,
  STOP_CAMPAIGN_SUCCESS,
} from "../actions/types";

// initial state
const INIT_STATE = {
  campaigns: [],
  standardCampaigns: [],
  transactionalCampaigns: [],
  refreshCampaign: "",
  campaignId: "",
  sortOption: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get non draft campaigns from server.
    case GET_DASHBOARD_CAMPAIGNS:
      return { ...state, loading: true };
    // On Success...
    case GET_DASHBOARD_CAMPAIGNS_SUCCESS:
      var campaigns = [];
      action.payload.data.forEach((element) => {
        var campaignModel = {
          id: element.id,
          name: element.title,
          status: element.status,
          targettedCount: "",
          opens: "",
          clicks: "",
          isTransactional: element.isTransactional,
          lastUpdatedAt: element.lastUpdatedAt,
        };

        campaigns.push(campaignModel);
      });

      var standardCampaigns = _.filter(campaigns, { isTransactional: false });
      var transactionalCampaigns = _.filter(campaigns, {
        isTransactional: true,
      });

      return {
        ...state,
        loading: false,
        campaigns: campaigns,
        standardCampaigns: standardCampaigns,
        transactionalCampaigns: transactionalCampaigns,
      };

    // On Failure, show error message.
    case GET_DASHBOARD_CAMPAIGNS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get non draft campaign stats from server.
    case GET_CAMPAIGNS_STATS:
      return { ...state, loading: true, refreshCampaign: action.payload };
    // On Success...
    case GET_CAMPAIGNS_STATS_SUCCESS:
      var campaign = _.filter(state.campaigns, { id: action.payload.data.id });
      campaign[0].status = action.payload.data.status;
      campaign[0].targettedCount = action.payload.data.targettedCount;
      if (
        action.payload.data.responses &&
        action.payload.data.responses.length > 0
      ) {
        if (
          _.filter(action.payload.data.responses, { event: "open" }).length > 0
        ) {
          campaign[0].opens = _.filter(action.payload.data.responses, {
            event: "open",
          })[0].count;
        }
        if (
          _.filter(action.payload.data.responses, { event: "click" }).length > 0
        ) {
          campaign[0].clicks = _.filter(action.payload.data.responses, {
            event: "click",
          })[0].count;
        }
      }

      return { ...state, loading: false };

    // On Failure, show error message.
    case GET_CAMPAIGNS_STATS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Execute Campaign
    case EXECUTE_CAMPAIGN:
      if (action.payload === "") {
        NotificationManager.error(
          "We encountered a problem while executing the campaign."
        );
      }
      return { ...state, loading: true, campaignId: action.payload };
    // On Success...
    case EXECUTE_CAMPAIGN_SUCCESS:
      campaigns = _.filter(state.campaigns, { id: state.campaignId });
      campaigns[0].status = 2;
      NotificationManager.success("Campaign is queued for execution.");
      return { ...state, loading: false };

    // On Failure, show error message.
    case EXECUTE_CAMPAIGN_FAILURE:
      NotificationManager.error(
        "We encountered a problem while executing the campaign."
      );
      return { ...state, loading: false, error: action.payload };

    // Stop Campaign
    case STOP_CAMPAIGN:
      if (action.payload === "") {
        NotificationManager.error(
          "We encountered a problem while stopping the campaign."
        );
      }
      return { ...state, loading: true, campaignId: action.payload };
    // On Success...
    case STOP_CAMPAIGN_SUCCESS:
      campaigns = _.filter(state.campaigns, { id: state.campaignId });
      campaigns[0].status = 3;
      NotificationManager.success("Campaign is queued for stoppage.");
      return { ...state, loading: false };

    // On Failure, show error message.
    case STOP_CAMPAIGN_FAILURE:
      NotificationManager.error(
        "We encountered a problem while stopping the campaign."
      );
      return { ...state, loading: false, error: action.payload };
    case SORT_DASHBOARD:
      // if campaigns are not sorted by lastUpdated date ever.
      if (state.sortOption === null || state.sortOption === 1) {
        let standardCampaigns = _.orderBy(
          _.filter(state.campaigns, { isTransactional: false }),
          ["lastUpdatedAt"],
          ["asc"]
        );
        let transactionalCampaigns = _.orderBy(
          _.filter(state.campaigns, { isTransactional: true }),
          ["lastUpdatedAt"],
          ["asc"]
        );

        return {
          ...state,
          loading: false,
          sortOption: 0,
          standardCampaigns: standardCampaigns,
          transactionalCampaigns: transactionalCampaigns,
        };
      } else {
        let standardCampaigns = _.orderBy(
          _.filter(state.campaigns, { isTransactional: false }),
          ["lastUpdatedAt"],
          ["desc"]
        );
        let transactionalCampaigns = _.orderBy(
          _.filter(state.campaigns, { isTransactional: true }),
          ["lastUpdatedAt"],
          ["desc"]
        );

        return {
          ...state,
          loading: false,
          sortOption: 1,
          standardCampaigns: standardCampaigns,
          transactionalCampaigns: transactionalCampaigns,
        };
      }

    case SEARCH_DASHBOARD_CAMPAIGN:
      if (action.payload === "") {
        let standardCampaigns = _.filter(state.campaigns, {
          isTransactional: false,
        });
        let transactionalCampaigns = _.filter(state.campaigns, {
          isTransactional: true,
        });

        return {
          ...state,
          loading: false,
          standardCampaigns: standardCampaigns,
          transactionalCampaigns: transactionalCampaigns,
        };
      } else {
        let standardCampaigns = _.filter(state.campaigns, {
          isTransactional: false,
        });
        let transactionalCampaigns = _.filter(state.campaigns, {
          isTransactional: true,
        });

        const filteredStandardCampaigns = standardCampaigns.filter(
          (item) =>
            item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );

        const filteredTransactionalCampaigns = transactionalCampaigns.filter(
          (item) =>
            item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );

        return {
          ...state,
          loading: false,
          standardCampaigns: filteredStandardCampaigns,
          transactionalCampaigns: filteredTransactionalCampaigns,
        };
      }

    default:
      return { ...state };
  }
};
