import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  getDashboardContentFailure,
  getDashboardContentSuccess,
  getHelpContentFailure,
  getHelpContentSuccess,
  getRegistrationContentFailure,
  getRegistrationContentSuccess,
  getTCFHelpContentFailure,
  getTCFHelpContentSuccess,
  getWelcomeContentFailure,
  getWelcomeContentSuccess,
} from "../actions/index";
import {
  GET_DASHBOARD_CONTENT,
  GET_HELP_CONTENT,
  GET_REGISTRATION_CONTENT,
  GET_TCFCONFIGHELP_CONTENT,
  GET_WELCOME_CONTENT,
} from "../actions/types";

const contentful = require("contentful");

const client = contentful.createClient({
  space: "vbdao2w6kspn",
  accessToken: "4bUAGxf7ksN3zxWHd-8l9HeYs8rsF14GyDEmq-wbV38",
});

const help = (state) => state.helpReducer;

const helpContentCall = async (id) => {
  const response = await client.getEntries({
    content_type: "featureHelp",
    limit: 2,
    include: 10,
    "fields.title": id,
  });

  return response;
};

const tcfHelpContentCall = async () => {
  const response = await client.getEntries({
    content_type: "tcfConfigHelp",
    limit: 2,
    include: 10,
    // "fields.title": id,
  });

  return response;
};

const welcomeCall = async () => {
  const response = await client.getEntries({
    content_type: "welcomePage",
    limit: 10,
    include: 10,
  });

  return response;
};

const registrationCall = async () => {
  const response = await client.getEntries({
    content_type: "registration",
    limit: 10,
    include: 10,
  });

  return response;
};

const dashboardCall = async () => {
  const response = await client.getEntries({
    content_type: "dashboardCardsV2",
    limit: 10,
    include: 10,
  });

  return response;
};

function* helpContent() {
  try {
    let reducer = yield select(help);
    let id = reducer.contentID;
    let response = yield call(helpContentCall, id);
    if (response) {
      yield put(getHelpContentSuccess(response));
    }
  } catch (error) {
    yield put(getHelpContentFailure(error));
  }
}
function* welcomeContent() {
  try {
    let response = yield call(welcomeCall);
    if (response) {
      yield put(getWelcomeContentSuccess(response));
    }
  } catch (error) {
    yield put(getWelcomeContentFailure(error));
  }
}

function* registrationContent() {
  try {
    let response = yield call(registrationCall);
    if (response) {
      yield put(getRegistrationContentSuccess(response));
    }
  } catch (error) {
    yield put(getRegistrationContentFailure(error));
  }
}

function* dashboardContent() {
  try {
    let response = yield call(dashboardCall);
    if (response) {
      yield put(getDashboardContentSuccess(response));
    }
  } catch (error) {
    yield put(getDashboardContentFailure(error));
  }
}

function* tcfConfigHelpContent() {
  try {
    let reducer = yield select(help);
    let id = reducer.contentID;
    let response = yield call(tcfHelpContentCall, id);
    if (response) {
      yield put(getTCFHelpContentSuccess(response));
    }
  } catch (error) {
    yield put(getTCFHelpContentFailure(error));
  }
}

export const helpSagas = [
  takeEvery(GET_HELP_CONTENT, helpContent),
  takeEvery(GET_WELCOME_CONTENT, welcomeContent),
  takeEvery(GET_REGISTRATION_CONTENT, registrationContent),
  takeEvery(GET_DASHBOARD_CONTENT, dashboardContent),
  takeEvery(GET_TCFCONFIGHELP_CONTENT, tcfConfigHelpContent),
];

export default function* rootSaga() {
  yield all([...helpSagas]);
}
