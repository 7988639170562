/* eslint-disable no-duplicate-case */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-redeclare */
/*
    Campaign Reducer
    Curd Operations on Campaign.
*/
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_DOMAIN,
  ADD_DOMAIN_FAILURE,
  ADD_DOMAIN_SUCCESS,
  DELETE_DOMAIN,
  DELETE_DOMAIN_FAILURE,
  DELETE_DOMAIN_SUCCESS,
  FILLTER_DOMAINS,
  GENERATE_DOMAIN_KEY,
  GENERATE_DOMAIN_KEY_FAILURE,
  GENERATE_DOMAIN_KEY_SUCCESS,
  GET_CUSTOMER_DOMAINS,
  GET_CUSTOMER_DOMAINS_FAILURE,
  GET_CUSTOMER_DOMAINS_SUCCESS,
  GET_DOMAINS,
  GET_DOMAINS_FAILURE,
  GET_DOMAINS_SUCCESS,
  GET_DOMAIN_BY_ID,
  GET_DOMAIN_BY_ID_FAILURE,
  GET_DOMAIN_BY_ID_SUCCESS,
  GET_DOMAIN_CUSTOMERS,
  GET_DOMAIN_CUSTOMERS_FAILURE,
  GET_DOMAIN_CUSTOMERS_SUCCESS,
  REFRESH_DOMAINS,
  REVOKE_ACCESS,
  REVOKE_ACCESS_FAILURE,
  REVOKE_ACCESS_SUCCESS,
  SELECT_DOMAIN,
  TEST_DOMAIN_WEBHOOK,
  TEST_DOMAIN_WEBHOOK_SUCCESS,
  UPDATE_DOMAIN,
  UPDATE_DOMAIN_FAILURE,
  UPDATE_DOMAIN_SUCCESS,
  REQUEST_DOMAIN_DELETION,
  REQUEST_DOMAIN_DELETION_SUCCESS,
  REQUEST_DOMAIN_DELETION_FAILURE,
} from "../actions/types";
// initial state
const INIT_STATE = {
  domains: [],
  filtereddomains: null,
  domain: null,
  search: "",
  action: "",
  loading: false,
  redirect: false,
  selected: null,
  customers: [],
  domainForKeyGeneration: null,
  selectedDomain: null,
  selectedId: "",
  webHookResponse: null,
  webHookTestStatus: null,
  currentDomain: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get audiences from server.
    case GET_DOMAINS:
      return { ...state, loading: true };
    // On Success...
    case GET_DOMAINS_SUCCESS:
      return {
        ...state,
        loading: false,
        domains: action.payload.data,
        filteredDomains: action.payload.data,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_DOMAINS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    //ADD new domain
    case ADD_DOMAIN:
      if (action.payload === "") {
        return { ...state, error: "Please enter valid domain details" };
      }
      var domain = delete action.payload.emailValid;
      domain = delete action.payload.domainValid;
      var customer = _.filter(state.customers, {
        name: action.payload.name,
      })[0];
      if (customer) {
        action.payload.CustomerIdFK = customer.id;
      }

      return {
        ...state,
        loading: true,
        domain: action.payload,
        action: "ADD",
        redirect: false,
      };

    // On Success, show message and redirect to listing page...
    case ADD_DOMAIN_SUCCESS:
      NotificationManager.success(
        "The new domain has been added, it will take a little while to propagate these changes across Gravito"
      );
      return { ...state, loading: false, domain: null, redirect: true };
    // On failure, show error message.
    case ADD_DOMAIN_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, domain: null };
    // Select campaign from list
    case SELECT_DOMAIN:
      if (action.payload === "") {
        NotificationManager.error("Invalid Domain");
      } else {
        var selectedDomain = _.filter(state.domains, { id: action.payload })[0];

        return {
          ...state,
          loading: false,
          currentDomain: selectedDomain,
          selected: selectedDomain,
          redirect: false,
        };
      }
      break;
    // Update Domain
    case UPDATE_DOMAIN:
      var domain = delete action.payload.emailValid;
      domain = delete action.payload.domainValid;
      return {
        ...state,
        loading: true,
        domain: action.payload,
        action: "UPDATE",
        redirect: false,
      };
    // On Success, show message and redirect to listing page....
    case UPDATE_DOMAIN_SUCCESS:
      var currentDomain = state.domain;
      NotificationManager.success(
        "The domain details have been updated, it will take a little while to propagate these changes across Gravito."
      );
      return {
        ...state,
        loading: false,
        currentDomain: currentDomain,
        domain: null,
        redirect: false,
      };

    // On Failure show error message.
    case UPDATE_DOMAIN_FAILURE:
      NotificationManager.error("Could not update domain");
      return { ...state, loading: false, domain: null, redirect: true };

    // Delete domain
    case DELETE_DOMAIN:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid domain details" };
      } else {
        return {
          ...state,
          loading: true,
          domain: action.payload,
          action: "DELETE",
        };
      }

    // On Success, redirect to listing page..
    case DELETE_DOMAIN_SUCCESS:
      NotificationManager.success("Domain deleted successfully.");
      return { ...state, loading: false, domain: null };

    // on
    case DELETE_DOMAIN_FAILURE:
      NotificationManager.error("Could not delete domain");
      return { ...state, loading: false, domain: null };

    case FILLTER_DOMAINS:
      if (action.payload === "") {
        return { ...state, filteredDomains: state.domains, search: "" };
      } else {
        const filtered = state.domains.filter(
          (item) =>
            item.domain.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredDomains: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case REFRESH_DOMAINS:
      if (state.search === "") {
        return {
          ...state,
          loading: false,
          domains: action.payload.data,
          filteredDomains: action.payload.data,
        };
      } else {
        const filtered = action.payload.data.filter(
          (item) =>
            item.domain.toLowerCase().indexOf(state.search.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredDomain: filtered,
          search: action.payload.toLowerCase(),
          domains: action.payload.data,
        };
      }

    case GET_DOMAIN_CUSTOMERS:
      return { ...state, loading: true };
    case GET_DOMAIN_CUSTOMERS_SUCCESS:
      return { ...state, loading: false, customers: action.payload.data };
    case GET_DOMAIN_CUSTOMERS_FAILURE:
      return { ...state, loading: false };
    case GENERATE_DOMAIN_KEY:
      return {
        ...state,
        loading: true,
        domainForKeyGeneration: action.payload,
      };
    case GENERATE_DOMAIN_KEY_SUCCESS:
      NotificationManager.success(
        "Access Token generated successfully for domain " +
          state.domainForKeyGeneration.name
      );
      return { ...state, loading: false, domainForKeyGeneration: null };
    case GENERATE_DOMAIN_KEY_FAILURE:
      NotificationManager.error(
        "We could not generate the access token for domain " +
          state.domainForKeyGeneration.name
      );
      return { ...state, loading: false, domainForKeyGeneration: null };
    case REVOKE_ACCESS:
      return {
        ...state,
        loading: true,
        domainForKeyGeneration: action.payload,
      };
    case REVOKE_ACCESS_SUCCESS:
      NotificationManager.success(
        "Access token removed for domain " + state.domainForKeyGeneration.name
      );
      return { ...state, loading: false, domainForKeyGeneration: null };
    case REVOKE_ACCESS_FAILURE:
      NotificationManager.error(
        "We could not revoke the access token for domain " +
          state.domainForKeyGeneration.name
      );
      return { ...state, loading: false, domainForKeyGeneration: null };

    // Get domains for the current user from server.
    case GET_CUSTOMER_DOMAINS:
      return { ...state, loading: true };
    // On Success.
    case GET_CUSTOMER_DOMAINS_SUCCESS:
      return {
        ...state,
        loading: false,
        domains: _.compact(action.payload.data),
        filteredDomains: _.compact(action.payload.data),
        redirect: false,
      };
    // On Failure, show error message.
    case GET_CUSTOMER_DOMAINS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case GET_DOMAIN_BY_ID:
      return {
        ...state,
        selectedDomain: null,
        selectedId: action.payload,
        loading: true,
      };
    case GET_DOMAIN_BY_ID_SUCCESS:
      if (action.payload === null) {
        NotificationManager.error("Invalid domain");
      }
      return { ...state, selectedDomain: action.payload, loading: false };
    case GET_DOMAIN_BY_ID_FAILURE:
      NotificationManager.error("error occurred");
      return { ...state, loading: false };
    case TEST_DOMAIN_WEBHOOK:
      return { ...state, loading: true };
    case TEST_DOMAIN_WEBHOOK_SUCCESS:
      var success = true;
      if (action.payload === null) {
        success = false;
      }
      return {
        ...state,
        webHookResponse: action.payload,
        webHookTestStatus: success,
        loading: false,
      };
    case GET_DOMAIN_BY_ID_FAILURE:
      return {
        ...state,
        webHookResponse: action.payload,
        webHookTestStatus: false,
        loading: false,
      };

    // request domain deletion
    case REQUEST_DOMAIN_DELETION:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please select a domain to send the request for deletion.",
        };
      } else {
        return {
          ...state,
          loading: true,
          domain: action.payload,
        };
      }

    // On Success, redirect to listing page..
    case REQUEST_DOMAIN_DELETION_SUCCESS:
      NotificationManager.success(
        "Domain request has been successfully sent to Gravito admin."
      );
      return { ...state, loading: false, domain: null };

    // on
    case REQUEST_DOMAIN_DELETION_FAILURE:
      if (action.payload) {
        NotificationManager.error(action.payload);
      } else {
        NotificationManager.error(
          "Sorry, we could not process your request for domain deletion."
        );
      }

      return { ...state, loading: false, domain: null };

    default:
      return { ...state };
  }
};
