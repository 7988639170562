/**
 * Redux Actions for managing crud operations on Campaigns.
 */

import {
  GET_TRENDS_DATA,
  GET_TRENDS_DATA_SUCCESS,
  GET_TRENDS_DATA_FAILURE,
  SELECT_TRENDS_CUSTOMER,
  GET_CUSTOMER_LIST_FOR_TRENDS,
  GET_CUSTOMER_LIST_FOR_TRENDS_SUCCESS,
  GET_CUSTOMER_LIST_FOR_TRENDS_FAILURE,
  SET_CURRENT_CUSTOMER_DOMAINS,
  CLEAR_DOMAIN_LIST
} from "./types";

/**
 * Redux action to get data for customer dashboard
 */
export const getTrendsData = (data) => ({
  type: GET_TRENDS_DATA,
  payload: data,
});

/**
 * Redux action for successful fetching of Domains
 */

export const getTrendsDataSuccess = (response) => ({
  type: GET_TRENDS_DATA_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching Domains
 */
export const getTrendsDataFailure = (error) => ({
  type: GET_TRENDS_DATA_FAILURE,
  payload: error,
});

export const selectTrendsCustomer = (customer) => ({
  type: SELECT_TRENDS_CUSTOMER,
  payload: customer,
});

/**
 * Redux action to get list of customers
 */
export const getCustomersForTrends = () => ({
  type: GET_CUSTOMER_LIST_FOR_TRENDS,
});

/**
 * Redux action for successful fetching of customers
 */

export const getCustomersForTrendsSuccess = (response) => ({
  type: GET_CUSTOMER_LIST_FOR_TRENDS_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching customers
 */
export const getCustomersForTrendsFailure = (error) => ({
  type: GET_CUSTOMER_LIST_FOR_TRENDS_FAILURE,
  payload: error,
});

export const setCurrentCustomerDomains = (domains) => ({
  type: SET_CURRENT_CUSTOMER_DOMAINS,
  payload: domains,
});

export const clearDomainList = () => ({
  type: CLEAR_DOMAIN_LIST

})