/**
 * App.js Layout Start Here
 */
 import React from "react";
 import {  Route, withRouter } from "react-router-dom";
 // app routes
 import InviteRoutes from "../routes/InviteRoutes";

 /**
  * Initial Path To Check Whether User Is Logged In Or Not
  */
 const InitialPath = ({ component: Component, authUser, ...rest }) => (
   <Route
     {...rest}
     render={(props) =>
 
         <Component {...props} />
     
     }
   />
 );
 
 
 
 const ConfirmationContainer = (props) => {
   return (
           <InitialPath
             path={`${props.match.url}`}
             component={InviteRoutes}
           />
   );
 };
 
 
 export default withRouter(
 (ConfirmationContainer)
 );
 