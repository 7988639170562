/* eslint-disable import/no-anonymous-default-export */
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  GET_DOMAIN_SEGMENTS,
  GET_DOMAIN_SEGMENTS_FAILURE,
  GET_DOMAIN_SEGMENTS_SUCCESS,
  SEARCH_DOMAIN_SEGMENTS,
  SORT_DOMAIN_SEGMENTS,
} from "../actions/types";

// initial state
const INIT_STATE = {
  segments: [],
  loading: false,
  sortOption: null,
  filteredSegments: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOMAIN_SEGMENTS:
      return { ...state, loading: true };
    case GET_DOMAIN_SEGMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        segments: action.payload,
        filteredSegments: action.payload,
      };

    case GET_DOMAIN_SEGMENTS_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, segments: null };
    case SORT_DOMAIN_SEGMENTS:
      // if campaigns are not sorted by lastUpdated date ever.
      if (state.sortOption === null || state.sortOption === 1) {
        let filteredSegments = _.orderBy(state.segments, ["domain"], ["asc"]);
        return {
          ...state,
          loading: false,
          sortOption: 0,
          filteredSegments: filteredSegments,
        };
      } else {
        let filteredSegments = _.orderBy(state.segments, ["domain"], ["desc"]);
        return {
          ...state,
          loading: false,
          sortOption: 1,
          filteredSegments: filteredSegments,
        };
      }

    case SEARCH_DOMAIN_SEGMENTS:
      if (action.payload === "") {
        return { ...state, loading: false, filteredSegments: state.segments };
      } else {
        const filteredSegments = state.segments.filter(
          (item) =>
            item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return { ...state, loading: false, filteredSegments: filteredSegments };
      }

    default:
      return { ...state };
  }
};
