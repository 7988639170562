/* eslint-disable no-redeclare */
/* eslint-disable import/no-anonymous-default-export */
/*
    applications Reducer
    Curd Operations on applications.
*/
import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_LIGHTCONFIGURATION,
  ADD_LIGHTCONFIGURATION_FAILURE,
  ADD_LIGHTCONFIGURATION_SUCCESS,
  DELETE_LIGHTCONFIGURATION,
  DELETE_LIGHTCONFIGURATION_FAILURE,
  DELETE_LIGHTCONFIGURATION_SUCCESS,
  FETCH_LIGHTCMP_CONIFG_BY_ID,
  FETCH_LIGHTCMP_CONIFG_BY_ID_FAILURE,
  FETCH_LIGHTCMP_CONIFG_BY_ID_SUCCESS,
  FETCH_LIGHTCMP_TEMPLATE,
  FETCH_LIGHTCMP_TEMPLATE_FAILURE,
  FETCH_LIGHTCMP_TEMPLATE_SUCCESS,
  FILLTER_LIGHTCONFIGURATION,
  GET_LIGHTCONFIGURATIONS,
  GET_LIGHTCONFIGURATIONS_FAILURE,
  GET_LIGHTCONFIGURATIONS_SUCCESS,
  GET_LIGHTTEMPLATES,
  GET_LIGHTTEMPLATES_FAILURE,
  GET_LIGHTTEMPLATES_SUCCESS,
  GET_METADATA_LIGHT,
  GET_METADATA_LIGHT_FAILURE,
  GET_METADATA_LIGHT_SUCCESS,
  REFRESH_LIGHTCONFIGURATION,
  SELECT_LIGHTCMP_CONFIGURATION,
  SELECT_LIGHTTEMPLATE,
  SELECT_LIGHTVERSION,
} from "../actions/types";

// initial state
const INIT_STATE = {
  configurations: [],
  referenceConfigFolderName: null,
  referenceConfig: null,
  filteredConfigurations: [],
  templatePath: null,
  templates: [],
  versions: [],
  selectedTemplate: null,
  selectedVersion: null,
  configuration: null,
  mapper: null,
  search: "",
  action: "",
  loading: false,
  redirect: false,
  selectedMapper: null,
  sdkFiles: [],
  bundles: [],
  routerHistory: null,
  configFolderNameToDelete: null,
  selectedFolderName: null,
  configTitle: null,
  sdkForScript: null,
  bundleForScript: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get light configurations from server.
    case GET_LIGHTCONFIGURATIONS:
      return { ...state, loading: true, redirect: false };
    // On Success...
    case GET_LIGHTCONFIGURATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        configurations: action.payload.data,
        filteredConfigurations: action.payload.data,
        referenceConfig: null,
        redirect: false,
      };
    // On Failure, show error message.
    case GET_LIGHTCONFIGURATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Refresh
    case REFRESH_LIGHTCONFIGURATION:
      if (state.action === "ADD") {
        NotificationManager.success("Configuration added successfully.");
      } else {
        NotificationManager.success("Configuration deleted successfully.");
      }
      if (state.search === "") {
        return {
          ...state,
          loading: false,
          configurations: action.payload.data,
          filteredConfigurations: action.payload.data,
          segmentCount: 0,
        };
      } else {
        const filtered = action.payload.data.filter(
          (item) =>
            item.contentType.toLowerCase().indexOf(state.search.toLowerCase()) >
            -1
        );
        return {
          ...state,
          loading: false,
          filteredConfigurations: filtered,
          configurations: action.payload.data,
        };
      }

    //ADD new configuration
    case ADD_LIGHTCONFIGURATION:
      if (action.payload === "") {
        return { ...state, error: "Please enter valid configuration details" };
      } else {
        return {
          ...state,
          loading: true,
          configuration: action.payload.config,
          mapper: action.payload.mapper,
          routerHistory: action.payload.history,
          match: action.payload.match,
          action: "ADD",
          redirect: false,
          configTitle: action.payload.title,
          bundleVersion: action.payload.bundleVersion,
          sdkVersion: action.payload.sdkVersion,
        };
      }

    // On Success, show message and redirect to listing page...
    case ADD_LIGHTCONFIGURATION_SUCCESS:
      NotificationManager.success("Configuration added successfully.");

      return {
        ...state,
        loading: false,
        configuration: null,
        redirect: true,
        referenceConfigFolderName: null,
        referenceConfig: null,
        selectedFolderName: null,
        mapper: null,
      };

    // On failure, show error message.
    case ADD_LIGHTCONFIGURATION_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, configuration: null };
    // Select content from list

    // Delete configuration
    case DELETE_LIGHTCONFIGURATION:
      if (action.payload === "") {
        return { ...state, error: "Please select a valid configuration" };
      } else {
        return {
          ...state,
          loading: true,
          configFolderNameToDelete: action.payload,
        };
      }

    // On Success, redirect to listing page..
    case DELETE_LIGHTCONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        configuration: null,
        configFolderNameToDelete: null,
      };

    // on
    case DELETE_LIGHTCONFIGURATION_FAILURE:
      NotificationManager.error("Could not delete configuration");
      return { ...state, loading: false, configuration: null };

    case FILLTER_LIGHTCONFIGURATION:
      if (action.payload === "") {
        return { ...state, filteredConfigurations: state.configurations };
      } else {
        const filtered = state.configurations.filter((item) => {
          return _.includes(item.title, action.payload);
        });
        return {
          ...state,
          filteredConfigurations: filtered,
          search: action.payload.toLowerCase(),
        };
      }
    case SELECT_LIGHTTEMPLATE:
      if (action.payload === "") {
        NotificationManager.error("Invalid template");
      } else {
        var selectedContent = _.filter(state.templates, { id: action.payload });
        return {
          ...state,
          loading: false,
          selectedTemplate: selectedContent[0],
          redirect: false,
        };
      }
      break;
    case SELECT_LIGHTVERSION:
      if (action.payload === "") {
        NotificationManager.error("Invalid version");
      } else {
        var selectedContent = _.filter(state.versions, { id: action.payload });
        return {
          ...state,
          loading: false,
          selectedVersion: selectedContent[0],
          redirect: false,
        };
      }
      break;
    case GET_LIGHTTEMPLATES:
      return { ...state, loading: true };
    case GET_LIGHTTEMPLATES_SUCCESS:
      return { ...state, loading: false, templates: action.payload };
    case GET_LIGHTTEMPLATES_FAILURE:
      NotificationManager.error("error occured");
      return { ...state, loading: false };
    case FETCH_LIGHTCMP_TEMPLATE:
      if (action.payload === "") {
        NotificationManager.error("Invalid path");
        return { ...state };
      }
      return {
        ...state,
        loading: true,
        templatePath: action.payload,
        selectedTemplate: null,
        selectedMapper: null,
      };
    case FETCH_LIGHTCMP_TEMPLATE_SUCCESS:
      let obj = JSON.parse(action.payload);
      let selectedMapper = obj.mapper;
      let selectedTemplate = obj.config;
      return {
        ...state,
        loading: false,
        selectedTemplate: selectedTemplate,
        selectedMapper: selectedMapper,
      };
    case FETCH_LIGHTCMP_TEMPLATE_FAILURE:
      //   NotificationManager.error(action.payload);
      return { ...state, loading: false };
    case GET_METADATA_LIGHT:
      return { ...state, loading: true };
    case GET_METADATA_LIGHT_SUCCESS:
      let templates = action.payload.templates;
      let sdks = action.payload.sdkFiles;
      let bundles = action.payload.bundles;
      if (Array.isArray(action.payload.templates)) {
        templates = action.payload.templates.reverse();
      }
      if (Array.isArray(action.payload.sdkFiles)) {
        sdks = action.payload.sdkFiles.reverse();
      }
      if (Array.isArray(action.payload.bundles)) {
        bundles = action.payload.bundles.reverse();
      }
      return {
        ...state,
        templates: templates,
        sdkFiles: sdks,
        bundles: bundles,
        loading: false,
      };

    case GET_METADATA_LIGHT_FAILURE:
      NotificationManager.error("error occured");
      return { ...state, loading: false };
    case FETCH_LIGHTCMP_CONIFG_BY_ID:
      return {
        ...state,
        referenceConfigFolderName: action.payload,
        referenceConfig: null,
        loading: true,
      };
    case FETCH_LIGHTCMP_CONIFG_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        referenceConfig: JSON.parse(action.payload["config"]),
        sdkForScript: action.payload["sdkVersion"],
        bundleForScript: action.payload["bundleVersion"],

        mapper: JSON.parse(action.payload["mapper"]),
        template: action.payload["template"],
      };
    case FETCH_LIGHTCMP_CONIFG_BY_ID_FAILURE:
      NotificationManager.error("error occured");
      return { ...state, loading: false };
    case SELECT_LIGHTCMP_CONFIGURATION:
      return {
        ...state,
        selectedFolderName: action.payload,
        redirect: false,
        referenceConfig: null,
      };
    default:
      return { ...state };
  }
};
