/**
 * App.js Layout Start Here
 */
 import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import AppConfig from "../constants/AppConfig";
// app routes
import RegRoutes from "../routes/RegRoutes";
 
 
 /**
  * Initial Path To Check Whether User Is Logged In Or Not
  */
 const InitialPath = ({ component: Component, authUser, ...rest }) => (
   <Route
     {...rest}
     render={(props) =>
 
         <Component {...props} />
     
     }
   />
 );
 
 
 
 const RegContainer = (props) => {
   
  
 
   if (props.location.pathname === "/") {
     // if the appVersion is not present in localStorage then set the appVersion
     if (!localStorage.getItem("appversion")) {
       localStorage.setItem("appversion", AppConfig.appVersion);
     }
     if (
       sessionStorage.getItem("domainChangePath") &&
       sessionStorage.getItem("domainChangePath") !== ""
     ) {
       return (
         <Redirect to={`${sessionStorage.getItem("domainChangePath")}`} />
       );
     } else {
       return <Redirect to={"/register"} />;
     }
     
   }
 
   // Get the current language from the navigator.
 
   return (
           <InitialPath
             path={`${props.match.url}`}
             component={RegRoutes}
           />
          
   );
 };
 
 
 export default withRouter(
 (RegContainer)
 );
 