/**
 * Redux Actions for managing Light CMP configurations in CDN Container.
 */

import {
  GET_LIGHTCONFIGURATIONS,
  GET_LIGHTCONFIGURATIONS_SUCCESS,
  GET_LIGHTCONFIGURATIONS_FAILURE,
  ADD_LIGHTCONFIGURATION,
  ADD_LIGHTCONFIGURATION_SUCCESS,
  ADD_LIGHTCONFIGURATION_FAILURE,
  DELETE_LIGHTCONFIGURATION,
  DELETE_LIGHTCONFIGURATION_SUCCESS,
  DELETE_LIGHTCONFIGURATION_FAILURE,
  FILLTER_LIGHTCONFIGURATION,
  REFRESH_LIGHTCONFIGURATION,
  GET_LIGHTTEMPLATES,
  GET_LIGHTTEMPLATES_SUCCESS,
  GET_LIGHTTEMPLATES_FAILURE,
  GET_LIGHTVERSIONS,
  GET_LIGHTVERSIONS_SUCCESS,
  GET_LIGHTVERSIONS_FAILURE,
  SELECT_LIGHTTEMPLATE,
  SELECT_LIGHTVERSION,
  FETCH_LIGHTCMP_TEMPLATE,
  FETCH_LIGHTCMP_TEMPLATE_FAILURE,
  FETCH_LIGHTCMP_TEMPLATE_SUCCESS,
  GET_METADATA_LIGHT,
  GET_METADATA_LIGHT_FAILURE,
  GET_METADATA_LIGHT_SUCCESS,
  FETCH_LIGHTCMP_CONIFG_BY_ID,
  FETCH_LIGHTCMP_CONIFG_BY_ID_FAILURE,
  FETCH_LIGHTCMP_CONIFG_BY_ID_SUCCESS,
  SELECT_LIGHTCMP_CONFIGURATION,
} from "./types";

/**
 * Redux action to get LIGHTConfigurations
 */
export const getLightConfigurations = () => ({
  type: GET_LIGHTCONFIGURATIONS,
});

/**
 * Redux action for successful fetching of LIGHTConfigurations
 */

export const getLightConfigurationsSuccess = (response) => ({
  type: GET_LIGHTCONFIGURATIONS_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching LIGHTConfigurations
 */
export const getLightConfigurationsFailure = (error) => ({
  type: GET_LIGHTCONFIGURATIONS_FAILURE,
  payload: error,
});

/**
 * Redux action to get LightTemplates
 */
export const getLightTemplates = () => ({
  type: GET_LIGHTTEMPLATES,
});

/**
 * Redux action for successful fetching of LightTemplates
 */

export const getLightTemplatesSuccess = (response) => ({
  type: GET_LIGHTTEMPLATES_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching LightTemplates
 */
export const getLightTemplatesFailure = (error) => ({
  type: GET_LIGHTTEMPLATES_FAILURE,
  payload: error,
});

/**
 * Redux action to get LightVersions
 */
export const getLightVersions = () => ({
  type: GET_LIGHTVERSIONS,
});

/**
 * Redux action for successful fetching of LightVersions
 */

export const getLightVersionsSuccess = (response) => ({
  type: GET_LIGHTVERSIONS_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching LightVersions
 */
export const getLightVersionsFailure = (error) => ({
  type: GET_LIGHTVERSIONS_FAILURE,
  payload: error,
});

/**
 * Redux action for fetching Lightconfigurations after add/delete of Application
 */
export const refreshLightConfigurations = (response) => ({
  type: REFRESH_LIGHTCONFIGURATION,
  payload: response,
});

/**
 * Redux action for adding new light configuration
 */
export const addLightConfiguration = (model) => ({
  type: ADD_LIGHTCONFIGURATION,
  payload: model,
});
/**
 * Redux action for successful addition of LightConfiguration
 */
export const addLightConfigurationSuccess = (response) => ({
  type: ADD_LIGHTCONFIGURATION_SUCCESS,
  payload: response,
});

/**
 * Redux action for failing to add new Light configuration
 */
export const addLightConfigurationFailure = (error) => ({
  type: ADD_LIGHTCONFIGURATION_FAILURE,
  payload: error,
});

/**
 * Redux action for deleting a light configuration
 */
export const deleteLightConfiguration = (folderName) => ({
  type: DELETE_LIGHTCONFIGURATION,
  payload: folderName,
});
/**
 * Redux action for successful deletion of LightConfiguration
 */
export const deleteLightConfigurationSuccess = (response) => ({
  type: DELETE_LIGHTCONFIGURATION_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in deletion of LightConfiguration
 */
export const deleteLightConfigurationFailure = (error) => ({
  type: DELETE_LIGHTCONFIGURATION_FAILURE,
  payload: error,
});

/**
 * Redux action for filtering LightConfiguration from search box
 */
export const fillterLightConfiguration = (keyword) => ({
  type: FILLTER_LIGHTCONFIGURATION,
  payload: keyword,
});

/**
 * Redux action for selecting a template
 */
export const selectLightTemplate = (path) => ({
  type: SELECT_LIGHTTEMPLATE,
  payload: path,
});

/**
 * Redux action for selecting a version
 */
export const selectLightVersion = (path) => ({
  type: SELECT_LIGHTVERSION,
  payload: path,
});

export const fetchLightCMPtemplate = (path) => ({
  type: FETCH_LIGHTCMP_TEMPLATE,
  payload: path,
});

export const fetchLightCMPtemplateSuccess = (data) => ({
  type: FETCH_LIGHTCMP_TEMPLATE_SUCCESS,
  payload: data,
});

export const fetchLightCMPtemplateFaliure = (error) => ({
  type: FETCH_LIGHTCMP_TEMPLATE_FAILURE,
  payload: error,
});

export const getMetadataLight = () => ({
  type: GET_METADATA_LIGHT,
});

export const getMetadataLightSuccess = (metadata) => ({
  type: GET_METADATA_LIGHT_SUCCESS,
  payload: metadata,
});

export const getMetadataLightFailure = (error) => ({
  type: GET_METADATA_LIGHT_FAILURE,
  payload: error,
});

export const fetchLightCMPConfigById = (foldername) => ({
  type: FETCH_LIGHTCMP_CONIFG_BY_ID,
  payload: foldername,
});

export const fetchLightCMPConfigByIdSuccess = (model) => ({
  type: FETCH_LIGHTCMP_CONIFG_BY_ID_SUCCESS,
  payload: model,
});

export const fetchLightCMPConfigByIdFailure = (error) => ({
  type: FETCH_LIGHTCMP_CONIFG_BY_ID_FAILURE,
  payload: error,
});

export const selectLightCMPConfig = (foldername) => ({
  type: SELECT_LIGHTCMP_CONFIGURATION,
  payload: foldername,
});
