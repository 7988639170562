import React from "react";

const ErrorPage = () => {
  return (
    <>
      <div className='container-fluid'>
        <div
          className='jumbotron text-center'
          style={{ backgroundColor: "#FFFFFF" }}
        >
          <h1 className='display-4'>OOPS! Something went wrong here</h1>
          <p className='lead'>
            Looks like you do not have rights to access requested functionality.
          </p>
          <p className='lead'>
            Please contact{" "}
            <a href='mailto:support@gravito.net'>gravito support</a>
          </p>
        </div>
      </div>
      <div style={{ position: "fixed", bottom: 0, zIndex: -1 }}>
        <img
          src={require("../../../assets/illustrations/progress-hd.png").default}
          className='img-fluid'
          alt=''
        />
      </div>
    </>
  );
};
export default ErrorPage;
