/**
 * Roles Actions
 * Roles Action With Google, Facebook, Twitter and Github
 */
import {
    GET_ROLES,
    GET_ROLES_SUCCESS,
    GET_USERS_FAILURE,
    ADD_ROLES,
    ADD_ROLES_SUCCESS,
    ADD_ROLES_FAILURE,
    // UPDATE_ROLES,
    // UPDATE_ROLES_SUCCESS,
    // UPDATE_ROLES_FAILURE,
    DELETE_ROLES,
    DELETE_ROLES_SUCCESS,
    DELETE_ROLES_FAILURE, 
    REFRESH_ROLES

    
} from '../actions/types';


export const getRoles = () => ({
    type: GET_ROLES,
});



export const getRolesSuccess = (roles) => ({
    type: GET_ROLES_SUCCESS,
    payload : roles
});
export const getRolesFailure=(res)=>({
    type:GET_USERS_FAILURE,
    payload : res.response.data.error
});

export const refreshRoles = (roles) => ({
    type: REFRESH_ROLES,
    payload : roles
})

export const addRole = (role) => ({
    type: ADD_ROLES,
    payload : role
});

export const addRoleSuccess = (response) => ({
    type: ADD_ROLES_SUCCESS,
    payload : response
});
export const addRoleFailure = (res) => ({
    type: ADD_ROLES_FAILURE,
    payload : res.response.data.error
});

export const deleteRole = (role) => ({
    type: DELETE_ROLES,
    payload : role
});
export const deleteRoleSuccess=(res)=>({
    type: DELETE_ROLES_SUCCESS,
    payload:res
})
export const deleteRoleFailure=(res)=>({
    type: DELETE_ROLES_FAILURE,
    payload:res.response.data.error
})

// export const updateRole = (role) => ({
//     type: UPDATE_ROLES,
//     payload : role
// });
// export const updateRoleSuccess=(res)=>({
//     type: UPDATE_ROLES_SUCCESS,
//     payload:res
// })
// export const updateRoleFailure=(res)=>({
//     type: UPDATE_ROLES_FAILURE,
//     payload:res.response.data.error
// })


