/**
 * App Header
 */
/* eslint-disable */
import React from "react";
import MenuContent from "./menu/MenuContent";

const HeaderMenu = (props) => {
  return <MenuContent callback={props.callback} includeDomain={props.includeDomain}></MenuContent>;
};

export default HeaderMenu;
