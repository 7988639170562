/**
 * Cart Component
 */
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DropdownMenu, DropdownToggle, Form, FormGroup, Input, UncontrolledDropdown } from 'reactstrap';
import { sendFeedback } from '../../actions';
import IntlMessages from '../../util/IntlMessages';


 

const Feedback = (props) => {

  const [state, setState] = useState({
    message: '',
    feedbackOpen:false
  });
 

      // function to toggle dropdown menu
      const toggle = () => {
        setState({...state, 
          ['feedbackOpen']: !state.feedbackOpen
        });
    }

      // on change language
      const onMessageChange = (e) => {
        setState({...state, ['message']: e.target.value });
    }

    const onSubmit = () => {
      if(state.message !== ''){
        props.sendFeedback(state.message);
        setState({...state,
          ['feedbackOpen']: !state.feedbackOpen
        });
      }else{
        return
      }
    }



     return (
      <UncontrolledDropdown nav className="list-inline-item rct-dropdown" isOpen={state.feedbackOpen} toggle={toggle}>
      <DropdownToggle nav className="dropdown-group-link text-white mt-10 p-0">
         <i className="fa fa-comments-o"></i> Feedback
      </DropdownToggle>
      <DropdownMenu className="feedbackdialog">
  
      <ul className="list-unstyled mb-0  pr-20">
      <li>
      <Form className="w-100">
                    <FormGroup>
                       <Input
                          type="textarea"
                          cols="100"
                          rows="7"
                          placeholder="Your Feedback"
                          name="feedback"
                          id="feedback"
                          className="search-input"
                          onChange={(e) => onMessageChange(e)}
                       />
                    </FormGroup>
                 </Form>
            <Button id="feedbackSubmit" variant="contained" color="primary" className="ml-10 mr-10 mb-10 mt-10 pb-10 btn-xs text-white" disabled={state.message === ''} onClick={() => onSubmit()}>
              Submit
            </Button>
      </li>
          
          </ul>

      </DropdownMenu>
    </UncontrolledDropdown>
     );

}
// map state to props
const mapStateToProps = ({ settings }) => {
  return settings;
};

export default connect(mapStateToProps, {
  sendFeedback
})(Feedback);

