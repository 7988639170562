import {
  GET_DASHBOARD_CONTENT,
  GET_DASHBOARD_CONTENT_FAILURE,
  GET_DASHBOARD_CONTENT_SUCCESS,
  GET_HELP_CONTENT,
  GET_HELP_CONTENT_FAILURE,
  GET_HELP_CONTENT_SUCCESS,
  GET_REGISTRATION_CONTENT,
  GET_REGISTRATION_CONTENT_FAILURE,
  GET_REGISTRATION_CONTENT_SUCCESS,
  GET_TCFCONFIGHELP_CONTENT,
  GET_TCFCONFIGHELP_CONTENT_FAILURE,
  GET_TCFCONFIGHELP_CONTENT_SUCCESS,
  GET_WELCOME_CONTENT,
  GET_WELCOME_CONTENT_FAILURE,
  GET_WELCOME_CONTENT_SUCCESS,
} from "./types";

export const getHelpContent = (id) => {
  return {
    type: GET_HELP_CONTENT,
    payload: id,
  };
};
export const getHelpContentSuccess = (data) => {
  return {
    type: GET_HELP_CONTENT_SUCCESS,
    payload: data,
  };
};
export const getHelpContentFailure = (error) => {
  return {
    type: GET_HELP_CONTENT_FAILURE,
    payload: error,
  };
};

export const getWelcomeContent = () => {
  return {
    type: GET_WELCOME_CONTENT,
  };
};
export const getWelcomeContentSuccess = (data) => {
  return {
    type: GET_WELCOME_CONTENT_SUCCESS,
    payload: data,
  };
};
export const getWelcomeContentFailure = (error) => {
  return {
    type: GET_WELCOME_CONTENT_FAILURE,
    payload: error,
  };
};

export const getRegistrationContent = () => {
  return {
    type: GET_REGISTRATION_CONTENT,
  };
};
export const getRegistrationContentSuccess = (data) => {
  return {
    type: GET_REGISTRATION_CONTENT_SUCCESS,
    payload: data,
  };
};
export const getRegistrationContentFailure = (error) => {
  return {
    type: GET_REGISTRATION_CONTENT_FAILURE,
    payload: error,
  };
};

export const getDashboardContent = () => {
  return {
    type: GET_DASHBOARD_CONTENT,
  };
};
export const getDashboardContentSuccess = (data) => {
  return {
    type: GET_DASHBOARD_CONTENT_SUCCESS,
    payload: data,
  };
};
export const getDashboardContentFailure = (error) => {
  return {
    type: GET_DASHBOARD_CONTENT_FAILURE,
    payload: error,
  };
};

export const getTCFHelpContent = () => {
  return {
    type: GET_TCFCONFIGHELP_CONTENT,
  };
};

export const getTCFHelpContentSuccess = (data) => {
  return {
    type: GET_TCFCONFIGHELP_CONTENT_SUCCESS,
    payload: data,
  };
};

export const getTCFHelpContentFailure = (error) => {
  return {
    type: GET_TCFCONFIGHELP_CONTENT_FAILURE,
    payload: error,
  };
};
