/**
 * Redux Actions for managing crud operations on Campaigns.
 */

 import {
    GET_GVL_VERSIONS,
    GET_GVL_VERSIONS_SUCCESS,
    GET_GVL_VERSIONS_FAILURE,
    COMPARE_GVL_VERSIONS,
    COMPARE_GVL_VERSIONS_SUCCESS,
    COMPARE_GVL_VERSIONS_FAILURE,
    FILTER_GVL_FOR_VENDORS,
    FILTER_GVL_FOR_VENDORS_SUCCESS,
    FILTER_GVL_FOR_VENDORS_FAILURE,
    FILTER_GVL_BY_STATUS,
    FILTER_GVL_BY_STATUS_SUCCESS,
    FILTER_GVL_BY_STATUS_FAILURE
} from './types';

/**
 * Redux action to get GVL Versions
 */
export const getGVLVersions =()=>({
    type: GET_GVL_VERSIONS
})

/**
 * Redux action for successful fetching of GVL Versions
 */

export const getGVLVersionsSuccess =(response)=>({
    type: GET_GVL_VERSIONS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching gvl versions
 */
export const getGVLVersionsFailure =(error)=>({
    type: GET_GVL_VERSIONS_FAILURE,
    payload: error
})

/**
 * Redux action for comparing two gvl versions
 */
export const compareGVLVersions = (model) => ({
    type: COMPARE_GVL_VERSIONS,
    payload:model
})
/**
 * Redux action for successful comparison of gvl versions
 */
export const compareGVLVersionsSuccess =(response)=>({
    type: COMPARE_GVL_VERSIONS_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to compare gvl versions
 */
export const compareGVLVersionsFailure =(error)=>({
    type: COMPARE_GVL_VERSIONS_FAILURE,
    payload: error
})

/**
 * Redux action for filtering gvl for vendors
 */
 export const filterGVLForVendors = (model) => ({
    type: FILTER_GVL_FOR_VENDORS,
    payload:model
})
/**
 * Redux action for successful filteration of gvl versions for vendors
 */
export const filterGVLForVendorsSuccess =(response)=>({
    type: FILTER_GVL_FOR_VENDORS_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to filter gvl versions
 */
export const filterGVLForVendorsFailure =(error)=>({
    type: FILTER_GVL_FOR_VENDORS_FAILURE,
    payload: error
})

/**
 * Redux action for filtering gvl for vendors
 */
 export const filterGVLByStatus = (model) => ({
    type: FILTER_GVL_BY_STATUS,
    payload:model
})
/**
 * Redux action for successful filteration of gvl versions for vendors
 */
export const filterGVLByStatusSuccess =(response)=>({
    type: FILTER_GVL_BY_STATUS_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to filter gvl versions
 */
export const filterGVLByStatusFailure =(error)=>({
    type: FILTER_GVL_BY_STATUS_FAILURE,
    payload: error
})
