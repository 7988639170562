/**
 * Redux App Settings Actions
 */
import {
    COLLAPSED_SIDEBAR,
    SEND_FEEDBACK,
    SEND_FEEDBACK_FAILURE, SEND_FEEDBACK_SUCCESS, SET_LANGUAGE, SET_SIDEBAR_IMAGE, TOGGLE_MENU,
    TOGGLE_SIDEBAR_IMAGE
} from './types';

/**
 * Redux Action To Emit Collapse Sidebar
 * @param {*boolean} isCollapsed 
 */
export const collapsedSidebarAction = (isCollapsed) => ({
    type: COLLAPSED_SIDEBAR,
    isCollapsed
});

/**
 * Redux Action To Toggle Sidebar Menus
 */
export const onToggleMenu = (selectedMenu) => ({
    type: TOGGLE_MENU,
    payload: selectedMenu
});


/**
 * Redux Action To Enable/Disable Sidebar Background Image
 */
export const toggleSidebarImage = () => ({
    type: TOGGLE_SIDEBAR_IMAGE
});

/**
 * Redux Action To Set Sidebar Background Image
 */
export const setSidebarBgImageAction = (sidebarImage) => ({
    type: SET_SIDEBAR_IMAGE,
    payload: sidebarImage
});

/**
 * Redux Action To Set Language
 */
export const setLanguage = (language) => ({
    type: SET_LANGUAGE,
    payload: language
});


/**
 * Redux Action To Send Feedback
 */
export const sendFeedback = (message) => ({
    type: SEND_FEEDBACK,
    payload: message
});

/**
 * Redux Action To Send Feedback
 */
export const sendFeedbackSuccess = (response) => ({
    type: SEND_FEEDBACK_SUCCESS,
    payload:response
});

/**
 * Redux Action To Send Feedback
 */
export const sendFeedbackFailure = (error) => ({
    type: SEND_FEEDBACK_FAILURE,
    payload:error
    
});
