/* eslint-disable import/no-anonymous-default-export */
/*
    Campaign Dashboard Reducer
    
*/
import { NotificationManager } from "react-notifications";
import * as _ from "lodash";
import {
  GET_PARTNERS,
  GET_PARTNERS_SUCCESS,
  GET_PARTNERS_FAILURE,
  ADD_PARTNER,
  ADD_PARTNER_SUCCESS,
  ADD_PARTNER_FAILURE,
  UPDATE_PARTNER,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAILURE,
  DEACTIVATE_PARTNER,
  DEACTIVATE_PARTNER_SUCCESS,
  DEACTIVATE_PARTNER_FAILURE,
  ASSIGN_CUSTOMER_TO_PARTNER,
  ASSIGN_CUSTOMER_TO_PARTNER_SUCCESS,
  ASSIGN_CUSTOMER_TO_PARTNER_FAILURE,
  ADD_PARTNER_USER,
  ADD_PARTNER_USER_SUCCESS,
  ADD_PARTNER_USER_FAILURE,
  REMOVE_PARTNER_USER,
  REMOVE_PARTNER_USER_SUCCESS,
  REMOVE_PARTNER_USER_FAILURE,
  SELECT_PARTNER,
  SEARCH_PARTNER,
  GET_PARTNER_CUSTOMERS,
  GET_PARTNER_CUSTOMERS_SUCCESS,
  GET_PARTNER_CUSTOMERS_FAILURE,
  GET_PARTNERS_BY_ID,
  GET_PARTNERS_BY_ID_SUCCESS,
  GET_PARTNERS_BY_ID_FAILURE,
  EDIT_PARTNER_USER,
  EDIT_PARTNER_USER_SUCCESS,
  EDIT_PARTNER_USER_FAILURE
} from "../actions/types";

import AppConfig from "../constants/AppConfig";

// initial state
const INIT_STATE = {
  partners: null,
  filteredPartners: null,
  loading: false,
  partnerDetail: null,
  partnerStatus: null,
  selected: null,
  search: "",
  redirect: false,
  customers: [],
  addCustomerModel: null,
  addUserModel: null,
  removeUserModel:null,
  deactivatePartnerId:0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get partners
    case GET_PARTNERS:
      return { ...state, loading: true };
    // On Success...
    case GET_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        partners: action.payload,
        filteredPartners: action.payload,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_PARTNERS_FAILURE:
      NotificationManager.error(action.payload);
      return {
        ...state,
        loading: false,
        partners: [],
        filteredPartners: [],
        error: action.payload,
      };

    case ADD_PARTNER:
      let addPartnerDetails = action.payload;
      if (action.payload.countryCode && action.payload.countryCode.value) {
        addPartnerDetails.countryCode = action.payload.countryCode.value;
      } else if (
        action.payload.countryCode &&
        action.payload.countryCode.value == null
      ) {
        addPartnerDetails.countryCode = action.payload.countryCode[0].value;
      } else {
        addPartnerDetails.countryCode = "";
      }
      addPartnerDetails.isActive = true;
      addPartnerDetails.businessId = action.payload.businessId.trim();
      return { ...state, loading: true, partnerDetail: addPartnerDetails };

    case ADD_PARTNER_SUCCESS:
      NotificationManager.success("New partner added successfully.");
      return { ...state, loading: false, redirect: true };

    case ADD_PARTNER_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, error: action.payload };

    case UPDATE_PARTNER:
      let partnerDetails = action.payload;
      if (action.payload.countryCode && action.payload.countryCode.value) {
        partnerDetails.countryCode = action.payload.countryCode.value;
      } else if (
        action.payload.countryCode &&
        action.payload.countryCode.value == null
      ) {
        partnerDetails.countryCode = action.payload.countryCode[0].value;
      } else {
        partnerDetails.countryCode = "";
      }
      return { ...state, loading: true, partnerDetail: partnerDetails };

    case UPDATE_PARTNER_SUCCESS:
      NotificationManager.success("Partner updated successfully.");
      return { ...state, loading: false };

    case UPDATE_PARTNER_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, error: action.payload };

    case SEARCH_PARTNER:
      if (action.payload === "") {
        return { ...state, filteredPartners: state.partners };
      } else {
        const filtered = state.partners.filter(
          (item) =>
            item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredPartners: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case SELECT_PARTNER:
      var selectedPartner = _.filter(state.partners, { id: action.payload })[0];

      if (selectedPartner.countryCode) {
        let country = _.filter(AppConfig.countries, {
          code: selectedPartner.countryCode.trim(),
        }).map(function (a) {
          return { value: a.code, label: a.name + " (" + a.dial_code + ")" };
        });
        if (country) {
          selectedPartner.countryCode = country;
        }
      }

      return {
        ...state,
        loading: false,
        selected: selectedPartner,
      };
    case GET_PARTNER_CUSTOMERS:
      return { ...state, loading: true };
    // On Success...
    case GET_PARTNER_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload,
      };

    // On Failure, show error message.
    case GET_PARTNER_CUSTOMERS_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, customers: [] };

    case ASSIGN_CUSTOMER_TO_PARTNER:
      return { ...state, loading: true, addCustomerModel: action.payload };
    // On Success...
    case ASSIGN_CUSTOMER_TO_PARTNER_SUCCESS:
      if(state.addCustomerModel && state.addCustomerModel.assign){
        NotificationManager.success("New customer assigned to this partnership.");
      }else{
        NotificationManager.success("Customer is unassigned from this partnership.");
      }
      
      return {
        ...state,
        loading: false,
        addCustomerModel:null
      };

    // On Failure, show error message.
    case ASSIGN_CUSTOMER_TO_PARTNER_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false };

    case ADD_PARTNER_USER:
      return { ...state, loading: true, addUserModel: action.payload };
    // On Success...
    case ADD_PARTNER_USER_SUCCESS:
      NotificationManager.success("User added successfully to this partnership.");
      return {
        ...state,
        loading: false,
        addUserModel:null
      };

    // On Failure, show error message.
    case ADD_PARTNER_USER_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, addUserModel:null };
    case REMOVE_PARTNER_USER:
        return { ...state, loading: true, removeUserModel: action.payload };
      // On Success...
    case REMOVE_PARTNER_USER_SUCCESS:
      NotificationManager.success("User removed successfully from partnership.");
      return {
          ...state,
          loading: false,
          removeUserModel:null
        };
  
      // On Failure, show error message.
     case REMOVE_PARTNER_USER_FAILURE:
        NotificationManager.error(action.payload);
        return { ...state, loading: false, removeUserModel:null };      
    case GET_PARTNERS_BY_ID:
      return { ...state, loading: true };
    // On Success...
    case GET_PARTNERS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: action.payload,
      };

    // On Failure, show error message.
    case GET_PARTNERS_BY_ID_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false };
    case DEACTIVATE_PARTNER:
      return {
        ...state,
        loading: false,
        deactivatePartnerId: action.payload,
      };
    case   DEACTIVATE_PARTNER_SUCCESS:
      NotificationManager.success("Partner deactivated successfully.");  
    return {
        ...state,
        loading: false,
        
      };
    case   DEACTIVATE_PARTNER_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false };

      case EDIT_PARTNER_USER:
        return { ...state, loading: true, addUserModel: action.payload };
      // On Success...
      case EDIT_PARTNER_USER_SUCCESS:
        NotificationManager.success("User updated successfully.");
      return {
          ...state,
          loading: false,
          addUserModel:null
        };
  
      // On Failure, show error message.
      case EDIT_PARTNER_USER_FAILURE:
        NotificationManager.error(action.payload);
        return { ...state, loading: false, addUserModel:null };      
    default:
      return { ...state };
  }
};
