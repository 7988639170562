/* eslint-disable import/no-anonymous-default-export */
/*
    Custom Domain Reducer
    Curd Operations on custom domain.
*/
import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_CUSTOMDOMAIN,
  ADD_CUSTOMDOMAIN_FAILURE,
  ADD_CUSTOMDOMAIN_SUCCESS,
  DELETE_CUSTOMDOMAIN,
  DELETE_CUSTOMDOMAIN_FAILURE,
  DELETE_CUSTOMDOMAIN_SUCCESS,
  FILLTER_CUSTOMDOMAINS,
  FILLTER_CUSTOMDOMAINS_BY_TYPE,
  GET_CUSTOMDOMAINS,
  GET_CUSTOMDOMAINS_FAILURE,
  GET_CUSTOMDOMAINS_SUCCESS,
  REFRESH_CUSTOMDOMAINS,
  RENEW_CUSTOMDOMAIN,
  RENEW_CUSTOMDOMAIN_FAILURE,
  RENEW_CUSTOMDOMAIN_SUCCESS,
  SELECT_CUSTOMDOMAIN,
  VALIDATE_CUSTOMDOMAIN,
  VALIDATE_CUSTOMDOMAIN_FAILURE,
  VALIDATE_CUSTOMDOMAIN_SUCCESS,
  VALIDATE_CUSTOMDOMAIN_DNS,
  VALIDATE_CUSTOMDOMAIN_DNS_FAILURE,
  VALIDATE_CUSTOMDOMAIN_DNS_SUCCESS,
} from "../actions/types";

// initial state
const INIT_STATE = {
  customDomains: [],
  filteredCustomDomains: [],
  customDomain: null,
  search: "",
  action: "",
  loading: false,
  redirect: false,
  selected: null,
  customDomainDns: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get audiences from server.
    case GET_CUSTOMDOMAINS:
      return { ...state, loading: true };
    // On Success...
    case GET_CUSTOMDOMAINS_SUCCESS:
      return {
        ...state,
        loading: false,
        customDomains: action.payload.data,
        filteredCustomDomains: action.payload.data,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_CUSTOMDOMAINS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Refres
    case REFRESH_CUSTOMDOMAINS:
      if (state.action === "ADD") {
        NotificationManager.success("Custom domain added successfully.");
      } else {
        NotificationManager.success("Custom domain deleted successfully.");
      }
      if (state.search === "") {
        return {
          ...state,
          loading: false,
          customDomains: action.payload.data,
          filteredCustomDomains: action.payload.data,
        };
      } else {

        const filtered = action.payload.data.filter(
          (item) =>
            item.domain.toLowerCase().indexOf(state.search.toLowerCase()) >
            -1
        );
        return {
          ...state,
          loading: false,
          customDomains: action.payload.data,
          filteredCustomDomains: filtered,
        };
      }

    //ADD new custom domain
    case ADD_CUSTOMDOMAIN:
      if (action.payload === "") {
        return { ...state, error: "Please enter valid custom domain details" };
      } else {
        let customDomainModel = {
          domain: action.payload.domain,
        };

        return {
          ...state,
          loading: true,
          customDomain: customDomainModel,
          action: "ADD",
          redirect: false,
        };
      }

    // On Success, show message and redirect to listing page...
    case ADD_CUSTOMDOMAIN_SUCCESS:
      NotificationManager.success("Custom domain added successfully.");
      return { ...state, loading: false, customDomain: null, redirect: true };
    // On failure, show error message.
    case ADD_CUSTOMDOMAIN_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, customDomain: null };
    // Select content from list
    case SELECT_CUSTOMDOMAIN:
      if (action.payload === "") {
        NotificationManager.error("Invalid custom domain");
      } else {
        var selectedContent = _.filter(state.customDomains, {
          id: action.payload.id,
        });

        if (selectedContent[0]) {
          return {
            ...state,
            loading: false,
            selected: selectedContent[0],
            redirect: false,
          };
        } else {
          NotificationManager.error("Invalid custom domain");
          return { ...state, loading: false, selected: null, redirect: true };
        }
      }
      break;

    // Delete custom domain
    case DELETE_CUSTOMDOMAIN:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please enter a valid custom domain details",
        };
      } else {
        return {
          ...state,
          loading: true,
          customDomain: action.payload,
          action: "DELETE",
        };
      }

    // On Success, redirect to listing page..
    case DELETE_CUSTOMDOMAIN_SUCCESS:
      return { ...state, loading: true, customDomain: null };

    // on
    case DELETE_CUSTOMDOMAIN_FAILURE:
      NotificationManager.error("Could not delete custom domain");
      return { ...state, loading: false, customDomain: null };

    case FILLTER_CUSTOMDOMAINS:
      if (action.payload === "") {
        return { ...state, filteredCustomDomains: state.customDomains };
      } else {
        const filtered = state.customDomains.filter(
          (item) =>
            item.domain.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredCustomDomains: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case FILLTER_CUSTOMDOMAINS_BY_TYPE:
      if (action.payload === "") {
        return { ...state, filteredCustomDomains: state.customDomains };
      } else {
        const filtered = state.customDomains.filter(
          (item) =>
            item.status.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredCustomDomains: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case VALIDATE_CUSTOMDOMAIN:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please enter a valid custom domain details",
        };
      } else {
        return { ...state, loading: true, customDomain: action.payload };
      }

    // On Success, redirect to listing page..
    case VALIDATE_CUSTOMDOMAIN_SUCCESS:
      NotificationManager.success("Custom domain will be validated shortly.");
      return { ...state, loading: false, redirect: true };

    // on
    case VALIDATE_CUSTOMDOMAIN_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, redirect: true };

    case RENEW_CUSTOMDOMAIN:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please enter a valid custom domain details",
        };
      } else {
        return { ...state, loading: true, customDomain: action.payload };
      }

    // On Success, redirect to listing page..
    case RENEW_CUSTOMDOMAIN_SUCCESS:
      NotificationManager.success("Custom domain renewed successfully.");
      return { ...state, loading: false, redirect: true };

    // on
    case RENEW_CUSTOMDOMAIN_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, redirect: true };

      case VALIDATE_CUSTOMDOMAIN_DNS:
        if (action.payload === "") {
          return {
            ...state,
            error: "Please enter a valid custom domain details",
          };
        } else {
          return { ...state, loading: true, customDomainDns: action.payload };
        }
  
      // On Success, check if the response is true or false, if the response is false then the dns validation has failed.
      case VALIDATE_CUSTOMDOMAIN_DNS_SUCCESS:
        if(action.payload.data === true){
          NotificationManager.success("DNS validated successfully.");
        }else{
          NotificationManager.error("Error while, validating DNS.");
        }
        
        return { ...state, loading: false };
  
      // on
      case VALIDATE_CUSTOMDOMAIN_DNS_FAILURE:
        NotificationManager.error(action.payload);
        return { ...state, loading: false };
  
    default:
      return { ...state };
  }
};
