/* eslint-disable no-redeclare */
/* eslint-disable import/no-anonymous-default-export */
/*
    audiences Reducer
    Curd Operations on audiences.
*/
import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_AUDIENCE,
  ADD_AUDIENCE_FAILURE,
  ADD_AUDIENCE_SUCCESS,
  APPROVE_AUDIENCE,
  APPROVE_AUDIENCE_FAILURE,
  APPROVE_AUDIENCE_SUCCESS,
  DELETE_AUDIENCE,
  DELETE_AUDIENCE_FAILURE,
  DELETE_AUDIENCE_SUCCESS,
  FILLTER_AUDIENCES,
  FILLTER_AUDIENCES_BY_TYPE,
  GET_AUDIENCES,
  GET_AUDIENCES_FAILURE,
  GET_AUDIENCES_SUCCESS,
  GET_AUDIENCE_DOMAINS,
  GET_AUDIENCE_DOMAINS_FAILURE,
  GET_AUDIENCE_DOMAINS_SUCCESS,
  GET_AUDIENCE_SEGMENTS,
  GET_AUDIENCE_SEGMENTS_FAILURE,
  GET_AUDIENCE_SEGMENTS_SUCCESS,
  GET_SEGMENTS_COUNT,
  GET_SEGMENTS_COUNT_FAILURE,
  GET_SEGMENTS_COUNT_SUCCESS,
  REFRESH_AUDIENCES,
  SELECT_AUDIENCE,
  UPDATE_AUDIENCE,
  UPDATE_AUDIENCE_FAILURE,
  UPDATE_AUDIENCE_SUCCESS,
} from "../actions/types";

// initial state
const INIT_STATE = {
  audiences: [],
  filteredAudiences: [],
  audience: null,
  segments: null,
  rawSegments: null,
  domains: null,
  search: "",
  action: "",
  loading: false,
  audienceList: null,
  redirect: false,
  selected: null,
  segmentCount: 0,
  selectedSegments: null,
  segmentList: [],
  isOr: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get audiences from server.
    case GET_AUDIENCES:
      return { ...state, loading: true };
    // On Success...
    case GET_AUDIENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        audiences: action.payload.data,
        filteredAudiences: action.payload.data,
        redirect: false,
        segmentCount: 0,
      };

    // On Failure, show error message.
    case GET_AUDIENCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        segmentCount: 0,
      };

    // Refres
    case REFRESH_AUDIENCES:
      if (state.action === "UPDATE") {
        NotificationManager.success("Audience updated successfully.");
      } else if (state.action === "ADD") {
        NotificationManager.success("Audience added successfully.");
      } else {
        NotificationManager.success("Audience deleted successfully.");
      }
      if (state.search === "") {
        return {
          ...state,
          loading: false,
          audiences: action.payload.data,
          filteredAudiences: action.payload.data,
          segmentCount: 0,
        };
      } else {
        const filtered = action.payload.data.filter(
          (item) =>
            item.contentType.toLowerCase().indexOf(state.search.toLowerCase()) >
            -1
        );
        return {
          ...state,
          loading: false,
          filteredAudiences: filtered,
          audiences: action.payload.data,
          segmentCount: 0,
        };
      }

    //ADD new content
    case ADD_AUDIENCE:
      if (action.payload === "") {
        return { ...state, error: "Please enter valid audidence details" };
      } else {
        var segments = _.filter(state.rawSegments, (item) =>
          _.includes(action.payload.segments, item.name)
        );
        var segmentIds = [];
        segments.forEach((element) => {
          segmentIds.push(element.id);
        });

        var audienceModel = {
          title: action.payload.title,
          segments: action.payload.segments,
          domain: action.payload.domain,
          isAnd: action.payload.isAnd,
          status: 0,
        };

        return {
          ...state,
          loading: true,
          audience: audienceModel,
          action: "ADD",
          redirect: false,
        };
      }

    // On Success, show message and redirect to listing page...
    case ADD_AUDIENCE_SUCCESS:
      NotificationManager.success("Audience added successfully.");
      return {
        ...state,
        loading: false,
        audience: null,
        redirect: true,
        segmentCount: 0,
      };
    // On failure, show error message.
    case ADD_AUDIENCE_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, audience: null };
    // Select content from list
    case SELECT_AUDIENCE:
      if (action.payload === "") {
        NotificationManager.error("Invalid Audience");
      } else {
        var selectedContent = _.filter(state.audiences, { id: action.payload });
        var segments = _.filter(state.rawSegments, (item) =>
          _.includes(selectedContent[0].segments.segmentList, item.id)
        );
        var segmentIds = [];
        segments.forEach((element) => {
          segmentIds.push(element.name);
        });

        var audienceModel = {
          title: selectedContent[0].title,
          segments: { segmentList: selectedContent[0].segments.segmentList },
          domain: selectedContent[0].domain,
          status: selectedContent[0].status,
          id: selectedContent[0].id,
          userid: selectedContent[0].userid,
          isAnd: selectedContent[0].isAnd,
        };

        return {
          ...state,
          loading: false,
          selected: audienceModel,
          redirect: false,
          segmentCount: 0,
        };
      }
      break;
    // Update content
    case UPDATE_AUDIENCE:
      var segments = _.filter(state.rawSegments, (item) =>
        _.includes(action.payload.segments, item.name)
      );
      // var segmentIds= [];
      // segments.forEach(element => {
      //     segmentIds.push(element.id);
      // });

      var segmentIds = _.map(action.payload.segments, "value");

      var audienceModel = {
        title: action.payload.title,
        segments: segmentIds,
        domain: action.payload.domain,
        status: action.payload.status,
        isAnd: action.payload.isAnd,
        id: action.payload.id,
      };

      return {
        ...state,
        loading: true,
        audience: audienceModel,
        action: "UPDATE",
        redirect: false,
      };
    // On Success, show message and redirect to listing page....
    case UPDATE_AUDIENCE_SUCCESS:
      NotificationManager.success("Audience updated successfully.");
      return {
        ...state,
        loading: false,
        audience: null,
        selected: null,
        redirect: false,
      };

    // On Failure show error message.
    case UPDATE_AUDIENCE_FAILURE:
      NotificationManager.error("Could not update audience");
      return { ...state, loading: false, audience: null, redirect: true };

    // Delete content
    case DELETE_AUDIENCE:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid audience details" };
      } else {
        return {
          ...state,
          loading: true,
          audience: action.payload,
          action: "DELETE",
        };
      }

    // On Success, redirect to listing page..
    case DELETE_AUDIENCE_SUCCESS:
      return { ...state, loading: true, audience: null };

    // on
    case DELETE_AUDIENCE_FAILURE:
      NotificationManager.error("Could not delete content");
      return { ...state, loading: false, audience: null };

    case FILLTER_AUDIENCES:
      if (action.payload === "") {
        return { ...state, filteredAudiences: state.audiences };
      } else {
        const filtered = state.audiences.filter(
          (item) =>
            item.title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredAudiences: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case FILLTER_AUDIENCES_BY_TYPE:
      if (action.payload === "") {
        return { ...state, filteredAudiences: state.audiences };
      } else {
        const filtered = state.audiences.filter(
          (item) =>
            item.channel.toLowerCase().indexOf(action.payload.toLowerCase()) >
            -1
        );
        return {
          ...state,
          filteredAudiences: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    // Get audience segments from server.
    case GET_AUDIENCE_SEGMENTS:
      return { ...state, loading: true };
    // On Success...
    case GET_AUDIENCE_SEGMENTS_SUCCESS:
      var segmentList = [];
      if (action.payload.data !== null && action.payload.data.length > 0) {
        action.payload.data.forEach((element) => {
          var segmentItem = {
            value: element.id,
            label: element.name,
          };
          segmentList.push(segmentItem);
        });
      }

      var segments = _.map(action.payload.data, "name");
      return {
        ...state,
        loading: false,
        segments: segments,
        rawSegments: action.payload.data,
        segmentList: segmentList,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_AUDIENCE_SEGMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get audience domains from server.
    case GET_AUDIENCE_DOMAINS:
      return { ...state, loading: true };
    // On Success...
    case GET_AUDIENCE_DOMAINS_SUCCESS:
      const domains = action.payload.data.filter(
        (item) => item.isAdmin === true
      );

      return { ...state, loading: false, domains: domains, redirect: false };

    // On Failure, show error message.
    case GET_AUDIENCE_DOMAINS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case APPROVE_AUDIENCE:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid audience details" };
      } else {
        return { ...state, loading: true, audience: action.payload };
      }

    // On Success, redirect to listing page..
    case APPROVE_AUDIENCE_SUCCESS:
      NotificationManager.success("Audience approved successfully.");
      return { ...state, loading: false, redirect: true };

    // on
    case APPROVE_AUDIENCE_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, redirect: true };

    case GET_SEGMENTS_COUNT:
      var segments = _.filter(state.rawSegments, (item) =>
        _.includes(action.payload.segments, item.name)
      );
      var segmentIds = [];
      segments.forEach((element) => {
        segmentIds.push(element.id);
      });

      return {
        ...state,
        loading: true,
        selectedSegments: segmentIds,
        isOr: action.payload.isOr,
      };
    case GET_SEGMENTS_COUNT_SUCCESS:
      return { ...state, loading: false, segmentCount: action.payload.data };
    case GET_SEGMENTS_COUNT_FAILURE:
      return { ...state, loading: false, segmentCount: 0 };
    default:
      return { ...state };
  }
};
