/**
 * Audience Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addAudienceFailure,
  addAudienceSuccess,
  approveAudienceFailure,
  approveAudienceSuccess,
  deleteAudienceFailure,
  deleteAudienceSuccess,
  getAudienceDomainsFailure,
  getAudienceDomainsSuccess,
  getAudienceSegmentsFailure,
  getAudienceSegmentsSuccess,
  getAudiencesFailure,
  getAudiencesSuccess,
  getSegmentsCountFailure,
  getSegmentsCountSuccess,
  refreshAudiences,
  updateAudienceFailure,
  updateAudienceSuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const audiences = (state) => state.audiencesReducer;
export const authUser = (state) => state.authUser;

const getAudiencesFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };
  return await getAxiosRequest().get("/manage/audience", config);
};

const addAudienceToServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().post(
    "/manage/audience",
    model.audience,
    config
  );
};

const updateAudience = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().put(
    "/manage/audience",
    model.audience,
    config
  );
};

const approveAudience = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().post(
    "manage/audience/" + model.id + "/approve",
    model.audience,
    config
  );
};

const removeAudience = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().delete(
    "/manage/audience?id=" + model.id,
    config
  );
};

// const getDomainSegmentsFromServer = async () => {
//     return await getAxiosRequest().get('/user/segments');
// }

const getDomainSegmentsFromServer = async (id) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: id },
  };

  return await getAxiosRequest().get("manage/domain/segment", config);
};

const getSegmentsCountFromServer = async (segments) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: segments.domain },
  };
  return await getAxiosRequest().get(
    "manage/audience/estimate?segments=" +
      segments.selectedSegments.join(",") +
      "&IsOr=" +
      segments.isOr,
    config
  );
};

// Get consents from Server and then filter domains from consentdataset
const UserConsents = async (model) => {
  return await getAxiosRequest().get("/consent");
};
/**
 * Get Contents
 */
function* getAudiences() {
  try {
    // get current domain from authUser
    let model = yield select(authUser); // <-- get the model

    // call api to generate magic link
    const response = yield call(getAudiencesFromServer, model.currentDomain.id);
    yield put(getAudiencesSuccess(response));
  } catch (error) {
    yield put(getAudiencesFailure(error.response.data.error));
  }
}
/**
 * Add Content
 */
function* addAudience() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(audiences); // <-- get the model
    var audienceModel = {
      audience: {
        title: model.audience.title,
        domain: user.currentDomain.domain,
        segments: {
          segmentList: model.audience.segments,
        },
        status: model.audience.status,
        isAnd: model.audience.isAnd,
      },
      domain: user.currentDomain.id,
    };
    const response = yield call(addAudienceToServer, audienceModel);
    yield put(addAudienceSuccess(response));
  } catch (error) {
    yield put(addAudienceFailure(error.response.data.error));
  }
}

/**
 * Edit Content
 */
function* editAudience() {
  try {
    // call api to generate magic link
    let model = yield select(audiences); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    var audienceModel = {
      audience: {
        title: model.audience.title,
        domain: model.audience.domain,
        segments: {
          segmentList: model.audience.segments,
        },
        status: model.audience.status,
        id: model.audience.id,
        isAnd: model.audience.isAnd,
      },
      domain: user.currentDomain.id,
    };

    const response = yield call(updateAudience, audienceModel);
    yield put(updateAudienceSuccess(response));
  } catch (error) {
    yield put(updateAudienceFailure(error.response.data.error));
  }
}

/**
 * Edit Content
 */
function* approve() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(audiences); // <-- get the model
    var audienceModel = {
      id: model.audience.id,
      domain: user.currentDomain.id,
    };

    const response = yield call(approveAudience, audienceModel);
    yield put(approveAudienceSuccess(response));
  } catch (error) {
    yield put(approveAudienceFailure(error.response.data.error));
  }
}

/**
 * Edit User
 */
function* deleteAudience() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(audiences); // <-- get the model
    var audienceModel = {
      id: model.audience.id,
      domain: user.currentDomain.id,
    };
    const response = yield call(removeAudience, audienceModel);
    yield put(deleteAudienceSuccess(response));
    const refreshResponse = yield call(
      getAudiencesFromServer,
      user.currentDomain.id
    );
    yield put(refreshAudiences(refreshResponse));
  } catch (error) {
    yield put(deleteAudienceFailure(error.response.data.error));
  }
}

function* getAudienceSegments() {
  try {
    let user = yield select(authUser); // <-- get the current user
    // call api to get the segments of the domains for which the current user is admin.
    const response = yield call(
      getDomainSegmentsFromServer,
      user.currentDomain.id
    );
    yield put(getAudienceSegmentsSuccess(response));
  } catch (error) {
    yield put(getAudienceSegmentsFailure(error.response.data.error));
  }
}

function* getAudienceDomains() {
  try {
    // call api to get the domains for which the current user is admin.
    const response = yield call(UserConsents);
    yield put(getAudienceDomainsSuccess(response));
  } catch (error) {
    yield put(getAudienceDomainsFailure(error.response.data.error));
  }
}

function* getSegmentsCount() {
  try {
    let user = yield select(authUser); // <-- get the model
    let model = yield select(audiences); // <-- get the model
    // call api to get the domains for which the current user is admin.
    var audienceModel = {
      selectedSegments: model.selectedSegments,
      isOr: model.isOr,
      domain: user.currentDomain.id,
    };
    const response = yield call(getSegmentsCountFromServer, audienceModel);
    yield put(getSegmentsCountSuccess(response));
  } catch (error) {
    yield put(getSegmentsCountFailure(error.response.data.error));
  }
}

export const audienceSagas = [
  takeEvery("GET_AUDIENCES", getAudiences),
  takeEvery("ADD_AUDIENCE", addAudience),
  takeEvery("UPDATE_AUDIENCE", editAudience),
  takeEvery("APPROVE_AUDIENCE", approve),
  takeEvery("DELETE_AUDIENCE", deleteAudience),
  takeEvery("GET_AUDIENCE_SEGMENTS", getAudienceSegments),
  takeEvery("GET_AUDIENCE_DOMAINS", getAudienceDomains),
  takeEvery("GET_SEGMENTS_COUNT", getSegmentsCount),
];

/**
 * Audiences Root Saga
 */
export default function* rootSaga() {
  yield all([...audienceSagas]);
}
