/*!

 =========================================================
 * Now UI Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

import $ from "jquery";

var sidebar_mini_active = false;

$(document).ready(function () {
  if ($(".full-screen-map").length === 0 && $(".bd-docs").length === 0) {
    // On click navbar-collapse the menu will be white not transparent
    $(".collapse")
      .on("show.bs.collapse", function () {
        $(this)
          .closest(".navbar")
          .removeClass("navbar-transparent")
          .addClass("bg-white");
      })
      .on("hide.bs.collapse", function () {
        $(this)
          .closest(".navbar")
          .addClass("navbar-transparent")
          .removeClass("bg-white");
      });
    $("html").addClass("nav-open");
  }

  nowuiDashboard.initMinimizeSidebar();

  // eslint-disable-next-line no-unused-vars
  var $navbar = $(".navbar[color-on-scroll]");

  // Check if we have the class "navbar-color-on-scroll" then add the function to remove the class "navbar-transparent" so it will transform to a plain color.
  if ($(".navbar[color-on-scroll]").length !== 0) {
    nowuiDashboard.checkScrollForTransparentNavbar();
    $(window).on("scroll", nowuiDashboard.checkScrollForTransparentNavbar);
  }

  $(".form-control")
    .on("focus", function () {
      $(this).parent(".input-group").addClass("input-group-focus");
    })
    .on("blur", function () {
      $(this).parent(".input-group").removeClass("input-group-focus");
    });

  // Activate bootstrapSwitch
  $(".bootstrap-switch").each(function () {
    var $this = $(this);
    var data_on_label = $this.data("on-label") || "";
    var data_off_label = $this.data("off-label") || "";

    $this.bootstrapSwitch({
      onText: data_on_label,
      offText: data_off_label,
    });
  });
});

$(".sidebarCollapse").on("click", function () {
  $("html").toggleClass("nav-open");
  if (nowuiDashboard.misc.navbar_menu_visible === 1) {
    nowuiDashboard.misc.navbar_menu_visible = 0;
  } else {
    nowuiDashboard.misc.navbar_menu_visible = 1;
  }
});

$(document).on("click", ".navbar-toggle", function () {
  var $toggle = $(this);

  if (nowuiDashboard.misc.navbar_menu_visible === 1) {
    $("html").removeClass("nav-open");
    nowuiDashboard.misc.navbar_menu_visible = 0;
    setTimeout(function () {
      $toggle.removeClass("toggled");
      $("#bodyClick").remove();
    }, 550);
  } else {
    setTimeout(function () {
      $toggle.addClass("toggled");
    }, 580);

    var div = '<div id="bodyClick"></div>';
    $(div)
      .appendTo("body")
      .click(function () {
        $("html").removeClass("nav-open");
        nowuiDashboard.misc.navbar_menu_visible = 0;
        setTimeout(function () {
          $toggle.removeClass("toggled");
          $("#bodyClick").remove();
        }, 550);
      });

    $("html").addClass("nav-open");
    nowuiDashboard.misc.navbar_menu_visible = 1;
  }
});

$(window).resize(function () {
  // reset the seq for charts drawing animations

  if ($(".full-screen-map").length === 0 && $(".bd-docs").length === 0) {
    var $navbar = $(".navbar");
    var isExpanded = $(".navbar")
      .find('[data-toggle="collapse"]')
      .attr("aria-expanded");
    if ($navbar.hasClass("bg-white") && $(window).width() > 991) {
      $navbar.removeClass("bg-white").addClass("navbar-transparent");
    } else if (
      $navbar.hasClass("navbar-transparent") &&
      $(window).width() < 991 &&
      isExpanded !== "false"
    ) {
      $navbar.addClass("bg-white").removeClass("navbar-transparent");
    }
  }
});

var nowuiDashboard = {
  misc: {
    navbar_menu_visible: 0,
  },

  initMinimizeSidebar: function () {
    if ($(".sidebar-mini").length !== 0) {
      sidebar_mini_active = true;
    }

    $("#minimizeSidebar").click(function () {
      if (sidebar_mini_active === true) {
        $("body").removeClass("sidebar-mini");
        sidebar_mini_active = false;
        nowuiDashboard.showSidebarMessage("Sidebar mini deactivated...");
      } else {
        $("body").addClass("sidebar-mini");
        sidebar_mini_active = true;
        nowuiDashboard.showSidebarMessage("Sidebar mini activated...");
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      var simulateWindowResize = setInterval(function () {
        window.dispatchEvent(new Event("resize"));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function () {
        clearInterval(simulateWindowResize);
      }, 1000);
    });
  },

  showSidebarMessage: function (message) {
    try {
      $.notify(
        {
          icon: "now-ui-icons ui-1_bell-53",
          message: message,
        },
        {
          type: "info",
          timer: 4000,
          placement: {
            from: "top",
            align: "right",
          },
        }
      );
    } catch (e) {
      console.log(
        "Notify library is missing, please make sure you have the notifications library added."
      );
    }
  },
};
