/* eslint-disable array-callback-return */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addCookieDescriptionFailure,
  addCookieDescriptionSuccess,
  deleteCookieDescriptionFailure,
  deleteCookieDescriptionSuccess,
  getCookieDescriptionFailure,
  getCookieDescriptionSuccess,
  getCookieScanHistoryFailure,
  getCookieScanHistorySuccess,
  getCookieScanReportFailure,
  getCookieScanReportSucess,
  getCookiesScanLanguagesFailure,
  getCookiesScanLanguagesSuccess,
  scanNonGravitoDomainFailure,
  scanNonGravitoDomainSuccess,
  updateCookieDescriptionFailure,
  updateCookieDescriptionSuccess,
} from "../actions/index";
import {
  ADD_COOKIES_DESCRIPTIONS,
  DELETE_COOKIES_DESCRIPTIONS,
  GET_COOKIES_DESCRIPTIONS,
  GET_COOKIES_SCAN_HISTORY,
  GET_COOKIE_SCAN_LANGAUGES,
  GET_SCAN_COOKIES_REPORT,
  SCAN_NON_GRAVITO_DOMAIN,
  UPDATE_COOKIES_DESCRIPTIONS,
} from "../actions/types";
import { getAxiosRequest, getContextObject } from "../helpers/helpers";

const getDataFromReducer = (state) => state.scanCookiesReducer;
// const getAuthUserData = (state) => state.authUser;

const getScanReportCall = async (scanRequestObj) => {
  // model.map((u, index) => {
  //   urlsQuerry = urlsQuerry + u;
  //   if (index < model.length - 1) {
  //     urlsQuerry = urlsQuerry + "&";
  //   }
  // });

  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  scanRequestObj.customerId = currentCustomer;

  let result = await getAxiosRequest().post(
    `manage/browserscan/cookie`,
    scanRequestObj
  );
  return result;
};

const getNonGravitoDomainScanCall = async (model) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  model.customerId = currentCustomer;
  return await getAxiosRequest().post(
    "manage/browserscan/cookie/noncmp",
    model
  );
};

const getScanHistory = async () => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  return await getAxiosRequest().get(
    `/manage/browserscan/history?customerId=${currentCustomer}`
  );
};

const getCookieRepoCall = async () => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;

  if (currentCustomer !== 0) {
    return await getAxiosRequest().get(
      `/manage/browserscan/cookierepo/customer/${currentCustomer}`
    );
  }
  return await getAxiosRequest().get(`/manage/browserscan/cookierepo`);
};
const addCookieRepoCall = async (model) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  if (currentCustomer !== 0) {
    model.customerId = currentCustomer;
    return await getAxiosRequest().post(
      `manage/browserscan/cookierepo/customer/${currentCustomer}`,
      model
    );
  }
  model.customerId = currentCustomer;
  return await getAxiosRequest().post(`manage/browserscan/cookierepo`, model);
};
const deleteCookieRepoCall = async (model) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  if (currentCustomer !== 0) {
    return await getAxiosRequest().delete(
      `manage/browserscan/cookierepo/customer/${currentCustomer}?id=${model.id}`
    );
  }
  return await getAxiosRequest().delete(
    `manage/browserscan/cookierepo?id=${model.id}`
  );
};
const updateCookieRepoCall = async (model) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  if (currentCustomer !== 0) {
    model.customerId = currentCustomer;
    return await getAxiosRequest().put(
      `manage/browserscan/cookierepo/customer/${currentCustomer}`,
      model
    );
  }
  model.customerId = currentCustomer;
  return await getAxiosRequest().put(`manage/browserscan/cookierepo`, model);
};

const getScanLanguagesCall = async () => {
  return await getAxiosRequest().get("manage/browserscan/Language");
};

function* scanReports() {
  try {
    let reducerData = yield select(getDataFromReducer);
    let scanRequestObj = reducerData.scanRequestObj;
    let response = yield call(getScanReportCall, scanRequestObj);
    if (response) {
      yield put(getCookieScanReportSucess(response.data));
    }
  } catch (error) {
    yield put(getCookieScanReportFailure(error));
  }
}
function* scanHistory() {
  try {
    let response = yield call(getScanHistory);
    if (response) {
      yield put(getCookieScanHistorySuccess(response.data));
    }
  } catch (error) {
    yield put(getCookieScanHistoryFailure(error));
  }
}

function* getCookieRepo() {
  try {
    // let isCustomerAdmin=authData
    let response = yield call(getCookieRepoCall);
    if (response) {
      yield put(getCookieDescriptionSuccess(response.data));
    }
  } catch (error) {
    yield put(getCookieDescriptionFailure(error));
  }
}
function* addCookieRepo() {
  try {
    let reducerData = yield select(getDataFromReducer);
    let model = reducerData.currentDescription;
    let response = yield call(addCookieRepoCall, model);
    if (response) {
      yield put(addCookieDescriptionSuccess(response.data));
    }
  } catch (error) {
    yield put(addCookieDescriptionFailure(error));
  }
}
function* deleteCookieRepo() {
  try {
    let reducerData = yield select(getDataFromReducer);
    let model = reducerData.currentDescription;
    let response = yield call(deleteCookieRepoCall, model);
    if (response) {
      let updatedResponse = yield call(getCookieRepoCall);

      if (updatedResponse) {
        yield put(deleteCookieDescriptionSuccess(updatedResponse.data));
      }
    }
  } catch (error) {
    yield put(deleteCookieDescriptionFailure(error));
  }
}
function* updateCookieRepo() {
  try {
    let reducerData = yield select(getDataFromReducer);
    let model = reducerData.currentDescription;
    let response = yield call(updateCookieRepoCall, model);
    if (response) {
      yield put(updateCookieDescriptionSuccess(response.data));
    }
  } catch (error) {
    yield put(updateCookieDescriptionFailure(error));
  }
}
function* scanNonGravitoDomain() {
  try {
    let reducerData = yield select(getDataFromReducer);
    let model = reducerData.nonGravitoDomain;
    let response = yield call(getNonGravitoDomainScanCall, model);
    if (response) {
      yield put(scanNonGravitoDomainSuccess(response.data));
    }
  } catch (error) {
    yield put(scanNonGravitoDomainFailure(error));
  }
}

function* cookieScanLanguages() {
  try {
    let response = yield call(getScanLanguagesCall);
    if (response) {
      yield put(getCookiesScanLanguagesSuccess(response.data));
    }
  } catch (error) {
    yield put(getCookiesScanLanguagesFailure(error));
  }
}

const cookieScanSagas = [
  takeEvery(GET_SCAN_COOKIES_REPORT, scanReports),
  takeEvery(GET_COOKIES_SCAN_HISTORY, scanHistory),
  takeEvery(GET_COOKIES_DESCRIPTIONS, getCookieRepo),
  takeEvery(ADD_COOKIES_DESCRIPTIONS, addCookieRepo),
  takeEvery(DELETE_COOKIES_DESCRIPTIONS, deleteCookieRepo),
  takeEvery(UPDATE_COOKIES_DESCRIPTIONS, updateCookieRepo),
  takeEvery(SCAN_NON_GRAVITO_DOMAIN, scanNonGravitoDomain),
  takeEvery(GET_COOKIE_SCAN_LANGAUGES, cookieScanLanguages),
];

export default function* root() {
  yield all([...cookieScanSagas]);
}
