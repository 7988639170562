/* eslint-disable import/no-anonymous-default-export */
/**
 * Roles User Reducers
 */
import cookie from "react-cookies";
import { NotificationManager } from "react-notifications";
import {
  ADD_ROLES,
  ADD_ROLES_FAILURE,
  ADD_ROLES_SUCCESS,
  DELETE_ROLES,
  DELETE_ROLES_FAILURE,
  DELETE_ROLES_SUCCESS,
  GET_ROLES,
  GET_ROLES_FAILURE,
  GET_ROLES_SUCCESS,
  REFRESH_ROLES,
} from "../actions/types";

/**
 * initial Roles user
 */

//get the details from the cookie
const cookieData = cookie.load("gravitoUid");

const INIT_STATE = {
  roles: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  // if the GravitoUi Cookie exists then get the emails and JWT Token from the Cookie
  if (cookieData) {
    state.user = { email: cookieData.email, token: cookieData.token };
  }
  switch (action.type) {
    // USER LOGIN with the magic link request
    case GET_ROLES:
      return { ...state, loading: true };

    case GET_ROLES_SUCCESS:
      return { ...state, loading: false, roles: action.payload.data };

    case GET_ROLES_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false };

    case REFRESH_ROLES:
      return { ...state, loading: false, roles: action.payload.data };

    case ADD_ROLES:
      return { ...state, loading: true, role: action.payload };

    case ADD_ROLES_SUCCESS:
      NotificationManager.success("Role added Successfully");
      return { ...state, loading: false };
    case ADD_ROLES_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false };

    case DELETE_ROLES:
      return { ...state, loading: true, role: action.payload };
    case DELETE_ROLES_SUCCESS:
      NotificationManager.success("Role deleted Successfully");
      return { ...state, loading: false };
    case DELETE_ROLES_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false };

    // case   UPDATE_ROLES:
    //     return{...state,loading:true}
    // case UPDATE_ROLES_SUCCESS:
    //     NotificationManager.success('Role updated Successfully');
    //     return {}
    // case UPDATE_ROLES_FAILURE:
    //     NotificationManager.error(action.payload);
    //     return {...state,loading:false}

    default:
      return { ...state };
  }
};
