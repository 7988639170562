/* eslint-disable import/no-anonymous-default-export */
import {
  GET_DASHBOARD_CONTENT,
  GET_DASHBOARD_CONTENT_FAILURE,
  GET_DASHBOARD_CONTENT_SUCCESS,
  GET_HELP_CONTENT,
  GET_HELP_CONTENT_FAILURE,
  GET_HELP_CONTENT_SUCCESS,
  GET_REGISTRATION_CONTENT,
  GET_REGISTRATION_CONTENT_FAILURE,
  GET_REGISTRATION_CONTENT_SUCCESS,
  GET_TCFCONFIGHELP_CONTENT,
  GET_TCFCONFIGHELP_CONTENT_FAILURE,
  GET_TCFCONFIGHELP_CONTENT_SUCCESS,
  GET_WELCOME_CONTENT,
  GET_WELCOME_CONTENT_FAILURE,
  GET_WELCOME_CONTENT_SUCCESS,
} from "../actions/types";

const initState = {
  contentID: null,
  contentData: null,
  contentLoading: false,
  welcomeContent: [],
  registrationContent: [],
  dashboardContent: [],
  tcfHelpContent: [],
};
export default (state = initState, action) => {
  switch (action.type) {
    case GET_HELP_CONTENT:
      return { ...state, contentID: action.payload, contentLoading: true };
    case GET_HELP_CONTENT_SUCCESS:
      return { ...state, contentData: action.payload, contentLoading: false };
    case GET_HELP_CONTENT_FAILURE:
      return { ...state, contentLoading: false };

    case GET_WELCOME_CONTENT:
      return { ...state, contentLoading: true };
    case GET_WELCOME_CONTENT_SUCCESS:
      return {
        ...state,
        welcomeContent: action.payload,
        contentLoading: false,
      };
    case GET_WELCOME_CONTENT_FAILURE:
      return { ...state, contentLoading: false, welcomeContent: [] };

    case GET_REGISTRATION_CONTENT:
      return { ...state, contentLoading: true };
    case GET_REGISTRATION_CONTENT_SUCCESS:
      let content = [];
      content = action.payload;
      return { ...state, registrationContent: content, contentLoading: false };
    case GET_REGISTRATION_CONTENT_FAILURE:
      return { ...state, contentLoading: false, registrationContent: [] };
    case GET_DASHBOARD_CONTENT:
      return { ...state, contentLoading: true };
    case GET_DASHBOARD_CONTENT_SUCCESS:
      let dashcontent = [];
      dashcontent = action.payload;
      return { ...state, dashboardContent: dashcontent, contentLoading: false };
    case GET_DASHBOARD_CONTENT_FAILURE:
      return { ...state, contentLoading: false, dashboardContent: [] };
    case GET_TCFCONFIGHELP_CONTENT:
      return { ...state, contentLoading: true };
    case GET_TCFCONFIGHELP_CONTENT_SUCCESS:
      let tcfHelpContent = [];
      tcfHelpContent = action.payload;
      return {
        ...state,
        tcfHelpContent: tcfHelpContent,
        contentLoading: false,
      };
    case GET_TCFCONFIGHELP_CONTENT_FAILURE:
      return { ...state, contentLoading: false, tcfHelpContent: [] };
    default:
      return { ...state };
  }
};
