/**
 * Redux Actions for managing crud operations on Campaigns.
 */

import {
    GET_CAMPAIGNS,
    GET_CAMPAIGNS_SUCCESS,
    GET_CAMPAIGNS_FAILURE,
    ADD_CAMPAIGN,
    ADD_CAMPAIGN_SUCCESS,
    ADD_CAMPAIGN_FAILURE,
    UPDATE_CAMPAIGN,
    UPDATE_CAMPAIGN_SUCCESS,
    UPDATE_CAMPAIGN_FAILURE,
    DELETE_CAMPAIGN,
    DELETE_CAMPAIGN_SUCCESS,
    DELETE_CAMPAIGN_FAILURE,
    FILLTER_CAMPAIGNS,
    REFRESH_CAMPAIGNS,
    SELECT_CAMPAIGN,
    GET_CAMPAIGN_DOMAINS,
    GET_CAMPAIGN_DOMAINS_SUCCESS,
    GET_CAMPAIGN_DOMAINS_FAILURE,
    GET_CAMPAIGN_CONSTELLATIONS,
    GET_CAMPAIGN_CONSTELLATIONS_SUCCESS,
    GET_CAMPAIGN_CONSTELLATIONS_FAILURE,
    GET_CAMPAIGN_AUDIENCES,
    GET_CAMPAIGN_AUDIENCES_SUCCESS,
    GET_CAMPAIGN_AUDIENCES_FAILURE,
    PUBLISH_CAMPAIGN,
    PUBLISH_CAMPAIGN_SUCCESS,
    PUBLISH_CAMPAIGN_FAILURE,
    GET_CAMPAIGN_SEGMENTS,
    GET_CAMPAIGN_SEGMENTS_SUCCESS,
    GET_CAMPAIGN_SEGMENTS_FAILURE
} from './types';

/**F
 * Redux action to get Campaigns
 */
export const getCampaigns =()=>({
    type: GET_CAMPAIGNS
})

/**
 * Redux action for successful fetching of Campaigns
 */

export const getCampaignsSuccess =(response)=>({
    type: GET_CAMPAIGNS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaigns
 */
export const getCampaignsFailure =(error)=>({
    type: GET_CAMPAIGNS_FAILURE,
    payload: error
})


/**
 * Redux action for fetching Campaigns after add/edit/delete of Campaign
 */
export const refreshCampaigns = (response) => ({
    type: REFRESH_CAMPAIGNS,
    payload: response
});

/**
 * Redux action for adding new Campaign
 */
export const addCampaign = (model) => ({
    type: ADD_CAMPAIGN,
    payload:model
})
/**
 * Redux action for successful addition of Campaign
 */
export const addCampaignSuccess =(response)=>({
    type: ADD_CAMPAIGN_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to add new Campaign
 */
export const addCampaignFailure =(error)=>({
    type: ADD_CAMPAIGN_FAILURE,
    payload: error
})

/**
 * Redux action for editing a Campaign
 */
export const updateCampaign =(model)=>({
    type: UPDATE_CAMPAIGN,
    payload:model
})
/**
 * Redux action for successful editing of a Campaign
 */
export const updateCampaignSuccess =(response)=>({
    type: UPDATE_CAMPAIGN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to edit a Campaign
 */
export const updateCampaignFailure =(error)=>({
    type: UPDATE_CAMPAIGN_FAILURE,
    payload: error
})

/**
 * Redux action for deleting a Campaign
 */
export const deleteCampaign =(id)=>({
    type: DELETE_CAMPAIGN,
    payload:id
})
/**
 * Redux action for successful deletion of Campaign
 */
export const deleteCampaignSuccess =(response)=>({
    type: DELETE_CAMPAIGN_SUCCESS,
    payload: response
})
/**getCampaignConstellations
 * Redux action for failure in deletion of Campaign
 */
export const deleteCampaignFailure =(error)=>({
    type: DELETE_CAMPAIGN_FAILURE,
    payload: error
})

/**
 * Redux action for filtering Campaigns from search box
 */
export const fillterCampaigns=(keyword)=>({
    type: FILLTER_CAMPAIGNS,
    payload: keyword
})
/**
 * Redux action for selecting a Campaign
 */
export const selectCampaign=(id)=>({
    type: SELECT_CAMPAIGN,
    payload: id
})

/**
 * Redux action to get Campaigns Constellations
 */
export const getCampaignConstellations =()=>({
    type: GET_CAMPAIGN_CONSTELLATIONS
})

/**
 * Redux action for successful fetching of Campaign Constellations
 */

export const getCampaignConstellationsSuccess =(response)=>({
    type: GET_CAMPAIGN_CONSTELLATIONS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaign Constellations
 */
export const getCampaignConstellationsFailure =(error)=>({
    type: GET_CAMPAIGN_CONSTELLATIONS_FAILURE,
    payload: error
})

/**
 * Redux action to get Campaigns DOMAINS
 */
export const getCampaignDomains =()=>({
    type: GET_CAMPAIGN_DOMAINS
})

/**
 * Redux action for successful fetching of Campaigns domains
 */

export const getCampaignDomainsSuccess =(response)=>({
    type: GET_CAMPAIGN_DOMAINS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaigns domains
 */
export const getCampaignDomainsFailure =(error)=>({
    type: GET_CAMPAIGN_DOMAINS_FAILURE,
    payload: error
})


/**
 * Redux action to get Campaigns Audiences
 */
export const getCampaignAudiences =()=>({
    type: GET_CAMPAIGN_AUDIENCES
})

/**
 * Redux action for successful fetching of Campaigns Audiences
 */

export const getCampaignAudiencesSuccess =(response)=>({
    type: GET_CAMPAIGN_AUDIENCES_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaigns Audiences
 */
export const getCampaignAudiencesFailure =(error)=>({
    type: GET_CAMPAIGN_AUDIENCES_FAILURE,
    payload: error
})


export const publishCampaign =()=>({
    type: PUBLISH_CAMPAIGN,
})
/**
 * Redux action for successful publishing of a Campaign
 */
export const publishCampaignSuccess =(response)=>({
    type: PUBLISH_CAMPAIGN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to publish a Campaign
 */
export const publishCampaignFailure =(error)=>({
    type: PUBLISH_CAMPAIGN_FAILURE,
    payload: error
})

/**F
 * Redux action to get Campaign segments
 */
export const getCampaignSegments =()=>({
    type: GET_CAMPAIGN_SEGMENTS
})

/**
 * Redux action for successful fetching of Campaign segments
 */

export const getCampaignSegmentsSuccess =(response)=>({
    type: GET_CAMPAIGN_SEGMENTS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaign segments
 */
export const getCampaignSegmentsFailure =(error)=>({
    type: GET_CAMPAIGN_SEGMENTS_FAILURE,
    payload: error
})