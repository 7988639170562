/* eslint-disable import/no-anonymous-default-export */
/*
    Config Reducer
    Currently on upload users from template is implemented. More functionality might be added later.
*/
import { NotificationManager } from "react-notifications";
import {
  UPLOAD_USERS,
  UPLOAD_USERS_FAILURE,
  UPLOAD_USERS_SUCCESS,
} from "../actions/types";

// initial state
const INIT_STATE = {
  usersFile: null,
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Upload users from template
    case UPLOAD_USERS:
      return { ...state, usersFile: action.payload, loading: true };
    // On successful upload, show message and reinitialize the state.
    case UPLOAD_USERS_SUCCESS:
      NotificationManager.success("User list successfully uploaded.");
      return { ...state, loading: false, usersFile: null };
    // On failure to upload users, show error message and reinitialize the state.
    case UPLOAD_USERS_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, usersFile: null };
    default:
      return { ...state };
  }
};
