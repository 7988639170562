/**
 * Roles Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { uploadUsersFailure, uploadUsersSuccess } from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const contents = (state) => state.configReducer;
export const authUser = (state) => state.authUser;

const upload = async (model) => {
  var config = {};
  config = {
    headers: {
      "Content-type": "multipart/form-data",
      DOMAIN_KEY: model.domain,
    },
  };
  return await getAxiosRequest().post(
    "manage/config/upload",
    model.content,
    config
  );
};

/**
 * Edit User
 */
function* uploadUsersToServer() {
  try {
    // call api to generate magic link
    let model = yield select(contents); // <-- get the model
    let user = yield select(authUser);

    var uploadModel = {
      content: model.usersFile,
      domain: user.currentDomain.id,
    };

    const response = yield call(upload, uploadModel);
    yield put(uploadUsersSuccess(response));
  } catch (error) {
    yield put(uploadUsersFailure(error.response.data.error));
  }
}

export const configSagas = [takeEvery("UPLOAD_USERS", uploadUsersToServer)];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...configSagas]);
}
