/**
 * Roles Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  getEventsForDomainProfileFailure,
  getEventsForDomainProfileSuccess,
  geUsersForDomainFailure,
  geUsersForDomainSuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const domainProfiles = (state) => state.domainUserReducer;
export const authUser = (state) => state.authUser;

const getDomainProfilesFromServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model },
  };
  return await getAxiosRequest().get("/manage/domain/profile", config);
};

const getSelectedProfileEventsFromServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domainId },
  };

  // following code is added to encode + sign - specific for Nokia requirement. As they were using temp email addresses with + signs.
  // if email address contains + then it will encoded
  // else normal user email will be used for searching.
  var emailAddress = "";
  var length = model.userId.length;
  var pos = model.userId.indexOf("+"); // 2
  if (pos !== -1) {
    emailAddress =
      model.userId.substr(0, pos) +
      encodeURIComponent("+") +
      model.userId.substr(pos + 1, length);
  } else {
    emailAddress = model.userId;
  }

  return await getAxiosRequest().get(
    "/manage/domain/profile/events?email=" + emailAddress,
    config
  );
};

/**
 * Get Domain Users
 */
function* getDomainProfiles() {
  try {
    let user = yield select(authUser); // <-- get the model
    // call api to get domain profiles....
    const response = yield call(
      getDomainProfilesFromServer,
      user.currentDomain.id
    );
    yield put(geUsersForDomainSuccess(response.data));
  } catch (error) {
    yield put(geUsersForDomainFailure(error.response.data.error));
  }
}

/**
 * Get Communication events captured for the selected user
 */
function* getProfileEvents() {
  try {
    let user = yield select(authUser); // <-- get the model
    let domainProfile = yield select(domainProfiles);
    var model = {
      userId: domainProfile.selectedProfile,
      domainId: user.currentDomain.id,
    };
    // call api to get domain profiles....
    const response = yield call(getSelectedProfileEventsFromServer, model);
    yield put(getEventsForDomainProfileSuccess(response));
  } catch (error) {
    yield put(getEventsForDomainProfileFailure(error.response.data.error));
  }
}

export const domainUsersSagas = [
  takeEvery("GET_USERS_FOR_DOMAIN", getDomainProfiles),
  takeEvery("GET_EVENTS_FOR_DOMAIN_PROFILE", getProfileEvents),
];

/**
 * Domain User Sagas
 */
export default function* rootSaga() {
  yield all([...domainUsersSagas]);
}
