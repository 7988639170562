import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { GET_ALL_FP_APPS, ADD_FP_APP, UPDATE_FP_APP } from "../actions/types";
import {
  getALLFPAppsSuccess,
  getALLFPAppsFailure,
  addFPAppsSuccess,
  addFPAppsFailure,
  updateFPAppsSuccess,
  updateFPAppsFailure,
} from "../actions/index";
import { getAxiosRequest } from "../helpers/helpers";

const reducer = (state) => state.FirstPartyAppsReducer;

async function updateAppsCall(model) {
  return await getAxiosRequest().put("/admin/firstpartyapp", model);
}

async function addAppsCall(model) {
  return await getAxiosRequest().post("/admin/firstpartyapp", model);
}
async function getAllAppsCall() {
  return await getAxiosRequest().get("admin/firstpartyapps");
}

function* getAllApps() {
  try {
    let result = yield call(getAllAppsCall);
    if (result) {
      console.log("sucess", result);
      yield put(getALLFPAppsSuccess(result.data));
    }
  } catch (error) {
    yield put(getALLFPAppsFailure(error));
  }
}

function* addApp() {
  try {
    let reducerData = yield select(reducer);
    let model = reducerData.addModel;
    if (model) {
      let res = yield call(addAppsCall, model);
      if (res) {
        yield put(addFPAppsSuccess());
      }
    }
  } catch (error) {
    yield put(addFPAppsFailure());
  }
}

function* updateApp() {
  try {
    let reducerData = yield select(reducer);
    let model = reducerData.addModel;
    if (model) {
      let res = yield call(updateAppsCall, model);
      if (res) {
        yield put(updateFPAppsSuccess());
      }
    }
  } catch (error) {
    yield put(updateFPAppsFailure());
  }
}

export const AppSagas = [
  takeEvery(GET_ALL_FP_APPS, getAllApps),
  takeEvery(ADD_FP_APP, addApp),
  takeEvery(UPDATE_FP_APP, updateApp),
];

export default function* rootSaga() {
  yield all([...AppSagas]);
}
