/**
 * App Routes
 */
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { getCurrentUser } from "../actions";
// async component
import {
  AsyncCampaignDashboardComponent,
  AsyncCookieScan,
  AsyncCustomerDashboardComponent,
  AsyncDevicesComponent,
  AsyncDomainSegmentComponent,
  AsyncDomainSegmentsComponent,
  AsyncDomainUsersComponent,
  AsyncManageAccount,
  AsyncManageApplicationsComponent,
  AsyncManageAudiencesComponent,
  AsyncManageCampaignsComponent,
  AsyncManageConnectors,
  AsyncManageConstellationsComponent,
  AsyncManageContentsComponent,
  AsyncManageCustomDomainsComponent,
  AsyncManageCustomerDomainsComponent,
  AsyncManageCustomersComponent,
  AsyncManageDomainPropertiesComponent,
  AsyncManageDomainsComponent,
  AsyncManageLightConfigurationsComponent,
  AsyncManagePartnersComponent,
  AsyncManageRequestsComponent,
  AsyncManageRolesComponent,
  AsyncManageTCFConfigurationsComponent,
  AsyncManageUsersComponent,
  AsyncManageVouchersComponent,
  AsyncProfilesComponent,
  AsyncReportingComponent,
  AsyncUploadComponent,
  AsyncManageCookieRepoComponent,
  AsyncManageCustomerDomainScanComponent,
  AsyncGVLUpdateComponent,
  AsyncFPConfiguratorComponent,
  AsyncDomainCountComponent,
  AsyncCertCheckComponent,
  AsyncFirstPartyComponent,
  AsyncCustomerDomainComponent,
  AsyncCompareTrendsComponent,
} from "../components/AsyncComponent/AsyncComponent";
// app default layout
import RctAppLayout from "../components/RctAppLayout";
import ErrorPage from "../routes/pages/errorpage";
import RedirectPage from "../routes/pages/redirectpage";
import { Redirect } from "react-router-dom/cjs/react-router-dom";

const AuthRoutes = (props) => {
  const authUser = useSelector((state) => state.authUser);
  const refreshCallback = () => {
    props.history.push("/");
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("currentemail") === null ||
      sessionStorage.getItem("currentemail") === ""
    ) {
      props.getCurrentUser();
    }
  }, []);

  // useEffect(() => {
  //   if (!authUser?.isAdmin) {
  //     // window.location.href = AppConfig.appV2Url;
  //   }
  // }, [authUser?.isAdmin]);

  if (!authUser?.isAdmin) {
    return (
      <>
        {/* <Route path="/redirect" component={RedirectPage} /> */}
        <Redirect to={"/redirect"} />
        <Route path="" component={RedirectPage} />
      </>
    );
  }

  return (
    <RctAppLayout
      refresh={refreshCallback}
      register={false}
      currentPath={props.location.pathname.split("/")[1]}
    >
      <Switch>
        <Route
          path="/customerdashboard"
          component={AsyncCustomerDashboardComponent}
        />
        <Route path="/comparetrends" component={AsyncCompareTrendsComponent} />
        <Route path="/reports" component={AsyncReportingComponent} />
        {/* <Route path='/reporting' component={AsyncReportingComponent} /> */}
        {props.authUser.isAdmin ? (
          <Route path="/profiles" component={AsyncProfilesComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/devices" component={AsyncDevicesComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/upload" component={AsyncUploadComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/roles" component={AsyncManageRolesComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/users" component={AsyncManageUsersComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/domains" component={AsyncManageDomainsComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/customers" component={AsyncManageCustomersComponent} />
        ) : null}
        {props.authUser.isPartner ? (
          <Route path="/customers" component={AsyncManageCustomersComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/partners" component={AsyncManagePartnersComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/vouchers" component={AsyncManageVouchersComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/domainCounts" component={AsyncDomainCountComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/certCheck" component={AsyncCertCheckComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/firstpartyApps" component={AsyncFirstPartyComponent} />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route
            path="/customerDomainApps"
            component={AsyncCustomerDomainComponent}
          />
        ) : null}
        {props.authUser.isAdmin ? (
          <Route path="/requests" component={AsyncManageRequestsComponent} />
        ) : null}
        <Route path="/contents" component={AsyncManageContentsComponent} />
        <Route
          path="/constellations"
          component={AsyncManageConstellationsComponent}
        />
        <Route path="/campaigns" component={AsyncManageCampaignsComponent} />
        <Route path="/audiences" component={AsyncManageAudiencesComponent} />
        <Route
          path="/domain-segments"
          component={AsyncDomainSegmentsComponent}
        />
        <Route
          path="/campaigndashboard"
          component={AsyncCampaignDashboardComponent}
        />
        <Route
          path="/domainproperties"
          component={AsyncManageDomainPropertiesComponent}
        />
        <Route path="/domainprofiles" component={AsyncDomainUsersComponent} />
        <Route
          path="/customerdomains"
          component={AsyncManageCustomerDomainsComponent}
        />
        <Route path="/segments" component={AsyncDomainSegmentComponent} />
        <Route
          path="/applications"
          component={AsyncManageApplicationsComponent}
        />
        <Route
          path="/managecustomdomains"
          component={AsyncManageCustomDomainsComponent}
        />
        <Route
          path="/tcfconfig"
          component={AsyncManageTCFConfigurationsComponent}
        />
        <Route
          path="/lightconfig"
          component={AsyncManageLightConfigurationsComponent}
        />
        <Route path="/connectors" component={AsyncManageConnectors} />
        <Route path="/cookiescan" component={AsyncCookieScan} />
        <Route path="/cookierepo" component={AsyncManageCookieRepoComponent} />
        <Route
          path="/customerdomainscan"
          component={AsyncManageCustomerDomainScanComponent}
        />
        <Route path="/gvlupdates" component={AsyncGVLUpdateComponent} />

        <Route
          path="/firstpartyconfigurator"
          component={AsyncFPConfiguratorComponent}
        />
        {props.authUser.customerAdmin ? (
          <Route path="/manageaccount" component={AsyncManageAccount} />
        ) : null}
        <Route path="" component={ErrorPage} />
      </Switch>
    </RctAppLayout>
  );
};

const mapStateToProps = ({ authUser }) => {
  return { authUser };
};
export default withRouter(
  connect(mapStateToProps, { getCurrentUser })(AuthRoutes)
);
