/**
 * Audience Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addCampaignFailure,
  addCampaignSuccess,
  deleteCampaignFailure,
  deleteCampaignSuccess,
  getCampaignAudiencesFailure,
  getCampaignAudiencesSuccess,
  getCampaignConstellationsFailure,
  getCampaignConstellationsSuccess,
  getCampaignDomainsFailure,
  getCampaignDomainsSuccess,
  getCampaignSegmentsFailure,
  getCampaignSegmentsSuccess,
  getCampaignsFailure,
  getCampaignsSuccess,
  publishCampaignFailure,
  publishCampaignSuccess,
  refreshCampaigns,
  updateCampaignFailure,
  updateCampaignSuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const campaigns = (state) => state.campaignReducer;
export const authUser = (state) => state.authUser;

const getCampaignsFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };
  return await getAxiosRequest().get("/manage/Campaign", config);
};

const addCampaignToServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().post(
    "/manage/Campaign",
    model.campaign,
    config
  );
};

const updateCampaign = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().put(
    "/manage/Campaign",
    model.campaign,
    config
  );
};

const removeCampaign = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };

  return await getAxiosRequest().delete(
    "/manage/Campaign?id=" + model.id,
    config
  );
};

const getConstellationsFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };
  return await getAxiosRequest().get("/manage/constellation/published", config);
};

const getAudiencesFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };
  return await getAxiosRequest().get("/manage/audience", config);
};

// Get consents from Server and then filter domains from consentdataset
const UserConsents = async () => {
  return await getAxiosRequest().get("/consent");
};

const publishCampaign = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().post(
    "/manage/Campaign/" + model.id + "/publish",
    model,
    config
  );
};

const getSegmentsFromServer = async (id) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: id },
  };

  return await getAxiosRequest().get("manage/domain/segment", config);
};

/**
 * Get Contents
 */
function* getCampaigns() {
  try {
    let user = yield select(authUser); // <-- get the model
    // call api to generate magic link
    const response = yield call(getCampaignsFromServer, user.currentDomain.id);
    yield put(getCampaignsSuccess(response));
  } catch (error) {
    yield put(getCampaignsFailure(error.response.data.error));
  }
}
/**
 * Add Content
 */
function* addCampaign() {
  try {
    let user = yield select(authUser);
    // call api to generate magic link
    let model = yield select(campaigns); // <-- get the model
    //check if user has added or removed a segment for post execution
    var postExecutionSegmentChange = model.campaign.postExecutionSegmentChange;
    //check if user has added or removed a segment for open event
    var onOpenSegmentChange = model.campaign.onOpenSegmentChange;
    //check if user has added or removed a segment for click event
    var onClickSegmentChange = model.campaign.onClickSegmentChange;
    if (model.campaign.postExecutionSegmentChange.length > 0) {
      postExecutionSegmentChange.forEach((element) => {
        element.domain = user.currentDomain.domain;
      });
    }
    if (model.campaign.onOpenSegmentChange.length > 0) {
      onOpenSegmentChange.forEach((element) => {
        element.domain = user.currentDomain.domain;
      });
    }
    if (model.campaign.onClickSegmentChange.length > 0) {
      onClickSegmentChange.forEach((element) => {
        element.domain = user.currentDomain.domain;
      });
    }
    var campaignModel = {
      campaign: {
        title: model.campaign.title,
        domain: user.currentDomain.domain,
        constellation: model.campaign.constellation,
        audienceIdFK: model.campaign.audienceIdFK,
        status: model.campaign.status,
        isTransactional: model.campaign.isTransactional,
        postExecutionSegmentChange: postExecutionSegmentChange,
        onOpenSegmentChange: onOpenSegmentChange,
        onClickSegmentChange: onClickSegmentChange,
      },
      domain: user.currentDomain.id,
    };
    const response = yield call(addCampaignToServer, campaignModel);
    yield put(addCampaignSuccess(response));
  } catch (error) {
    yield put(addCampaignFailure(error.response.data.error));
  }
}

/**
 * Edit Campaign
 */
function* editCampaign() {
  try {
    let user = yield select(authUser);
    // call api to generate magic link
    let model = yield select(campaigns); // <-- get the model
    var postExecutionSegmentChange = model.campaign.postExecutionSegmentChange;
    //check if user has added or removed a segment for open event
    var onOpenSegmentChange = model.campaign.onOpenSegmentChange;
    //check if user has added or removed a segment for click event
    var onClickSegmentChange = model.campaign.onClickSegmentChange;
    if (model.campaign.postExecutionSegmentChange.length > 0) {
      postExecutionSegmentChange.forEach((element) => {
        element.domain = user.currentDomain.domain;
      });
    }
    if (model.campaign.onOpenSegmentChange.length > 0) {
      onOpenSegmentChange.forEach((element) => {
        element.domain = user.currentDomain.domain;
      });
    }
    if (model.campaign.onClickSegmentChange.length > 0) {
      onClickSegmentChange.forEach((element) => {
        element.domain = user.currentDomain.domain;
      });
    }

    model.campaign.postExecutionSegmentChange = postExecutionSegmentChange;
    model.campaign.onOpenSegmentChange = onOpenSegmentChange;
    model.campaign.onClickSegmentChange = onClickSegmentChange;

    var campaignModel = {
      campaign: model.campaign,
      domain: user.currentDomain.id,
    };
    const response = yield call(updateCampaign, campaignModel);
    yield put(updateCampaignSuccess(response));
  } catch (error) {
    yield put(updateCampaignFailure(error.response.data.error));
  }
}

/**
 * Delete Campaign
 */
function* deleteCampaign() {
  try {
    let user = yield select(authUser);
    // call api to generate magic link
    let model = yield select(campaigns); // <-- get the model
    var campaignModel = {
      id: model.campaign.id,
      domain: user.currentDomain.id,
    };
    const response = yield call(removeCampaign, campaignModel);
    yield put(deleteCampaignSuccess(response));
    const refreshResponse = yield call(
      getCampaignsFromServer,
      user.currentDomain.id
    );
    yield put(refreshCampaigns(refreshResponse));
  } catch (error) {
    yield put(deleteCampaignFailure(error.response.data.error));
  }
}

function* getCampaignConstellations() {
  try {
    let user = yield select(authUser);
    // call api to get the segments of the domains for which the current user is admin.
    const response = yield call(
      getConstellationsFromServer,
      user.currentDomain.id
    );
    yield put(getCampaignConstellationsSuccess(response));
  } catch (error) {
    yield put(getCampaignConstellationsFailure(error.response.data.error));
  }
}

function* getCampaignAudiences() {
  try {
    let user = yield select(authUser);
    // call api to get the segments of the domains for which the current user is admin.
    const response = yield call(getAudiencesFromServer, user.currentDomain.id);
    yield put(getCampaignAudiencesSuccess(response));
  } catch (error) {
    yield put(getCampaignAudiencesFailure(error.response.data.error));
  }
}

function* getCampaignDomains() {
  try {
    let user = yield select(authUser);
    // call api to get the domains for which the current user is admin.
    const response = yield call(UserConsents, user.currentDomain.id);
    yield put(getCampaignDomainsSuccess(response));
  } catch (error) {
    yield put(getCampaignDomainsFailure(error.response.data.error));
  }
}

/**
 * Publish Campaign
 */
function* publish() {
  try {
    let user = yield select(authUser);
    let model = yield select(campaigns); // <-- get the model
    var campaignModel = {
      id: model.selected.id,
      domain: user.currentDomain.id,
    };
    const response = yield call(publishCampaign, campaignModel);
    yield put(publishCampaignSuccess(response));
  } catch (error) {
    yield put(publishCampaignFailure(error.response.data.error));
  }
}

function* getCampaignSegments() {
  let user = yield select(authUser);
  try {
    const response = yield call(getSegmentsFromServer, user.currentDomain.id);
    if (response) {
      yield put(getCampaignSegmentsSuccess(response.data));
    }
  } catch (error) {
    yield put(getCampaignSegmentsFailure(error));
  }
}

export const campaignSagas = [
  takeEvery("GET_CAMPAIGNS", getCampaigns),
  takeEvery("ADD_CAMPAIGN", addCampaign),
  takeEvery("UPDATE_CAMPAIGN", editCampaign),
  takeEvery("DELETE_CAMPAIGN", deleteCampaign),
  takeEvery("GET_CAMPAIGN_CONSTELLATIONS", getCampaignConstellations),
  takeEvery("GET_CAMPAIGN_DOMAINS", getCampaignDomains),
  takeEvery("GET_CAMPAIGN_AUDIENCES", getCampaignAudiences),
  takeEvery("GET_CAMPAIGN_SEGMENTS", getCampaignSegments),
  takeEvery("PUBLISH_CAMPAIGN", publish),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...campaignSagas]);
}
