/**
 * Sidebar Content
 */
 import Dialog from "@material-ui/core/Dialog";
 import DialogActions from "@material-ui/core/DialogActions";
 import DialogContent from "@material-ui/core/DialogContent";
 import DialogContentText from "@material-ui/core/DialogContentText";
 import DialogTitle from "@material-ui/core/DialogTitle";
 import React, { useState } from "react";
 import { Scrollbars } from "react-custom-scrollbars";
 import { connect } from "react-redux";
 import { Link, withRouter } from "react-router-dom";
 import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
 import { Button } from "semantic-ui-react";
 // redux actions
 import {
   getDomainUsers,
   logOff,
   setUserDomain,
 } from "../../actions";
 import AppConfig from "../../constants/AppConfig";
 import IntlMessages from "../../util/IntlMessages";
 
 import Tooltip from "@material-ui/core/Tooltip";
 
 const SubHeader = (props) => {
   const [state, setState] = useState({
     customizer: false,
     isMobileSearchFormVisible: false,
     settingsDropdownOpen: false,
     navigateToTerms: false,
     openConfirmationAlert: false, // confirmation to change domain
     openCustomerConfirmationAlert: false, // confirmation to change customer
     selectedDomain: null,
     selectedCustomer: null,
     redirectOnDomainChange: false,
     fixed: true,
   });
 
   const DomainSelector = () => (
     <UncontrolledDropdown
       hidden={props.authUser.isAdmin}
       nav
       className='list-inline-item rct-dropdown mr-50'
     >
       <Tooltip id='tooltip-icon' color='primary' placement='top-end' title={<div>Change Domain</div>}>
       <DropdownToggle caret nav className='dropdown-group-link headerDomain'>
         <i className='ti-world'></i> {props.authUser.currentDomain.domain}
       </DropdownToggle>
       </Tooltip>
       <DropdownMenu right>
         <Scrollbars
           className='rct-scroll'
           autoHeight
           autoHeightMin={100}
           autoHeightMax={280}
         >
           <ul className='list-unstyled mb-0'>
             {props.authUser.mappedDomains !== null ? (
               props.authUser.mappedDomains.map((domain, index) => (
                 <li
                   key={domain.id + index}
                   className='dropdown-item headerDomainLink'
                   onClick={(event) =>
                     onSelectDomain(
                       domain.id,
                       domain.domain,
                       domain.customerId,
                       event
                     )
                   }
                 >
                   {domain.domain}
                 </li>
               ))
             ) : (
               <li>Domains Not Avaliable</li>
             )}
           </ul>
         </Scrollbars>
       </DropdownMenu>
     </UncontrolledDropdown>
   );
 
 
   // on domain change, show confirmation dialog
   const onSelectDomain = (id, domain, customer, event) => {
     sessionStorage.setItem("domainChangePath", props.location.pathname);
     event.preventDefault();
     setState({
       ...state,
       openConfirmationAlert: true,
       selectedDomain: { id: id, domain: domain, customerId: customer },
     });
   };
 
 
   /**
    * On confirmation, redirect user to the current page with new domain context
    */
   const changeDomain = () => {
     props.setUserDomain(state.selectedDomain);
     props.getDomainUsers();
     setTimeout(() => {
       props.callback();
     }, 2000);
   };
 
 
   
   const { isNormalUser } = props.authUser;
 
   return (
     <div
       className='pos-f-t'
       style={{ marginLeft: "-12px", marginRight: "-12px" }}
     >
       {!isNormalUser ? (
         <div
           id='nav'
           style={{
       
             borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
           }}
         >
           <Dialog
             open={state.openConfirmationAlert}
             onClose={() => setState({ ...state, openConfirmationAlert: false })}
             aria-labelledby='alert-dialog-title'
             aria-describedby='alert-dialog-description'
           >
             <DialogTitle id='alert-dialog-title'>
               {"Are you sure, you want to switch the domain?"}
             </DialogTitle>
             <DialogContent>
               <DialogContentText id='alert-dialog-description'>
                 Please make sure that all changes are saved.
               </DialogContentText>
             </DialogContent>
             <DialogActions>
               <Button
                 id='Disagree'
                 secondary
                 onClick={() =>
                   setState({ ...state, openConfirmationAlert: false })
                 }
                 className='text-white btn-danger'
               >
                 Cancel
               </Button>
               <Button
                 id='Agree'
                 primary
                 onClick={() => changeDomain()}
                 className='text-white'
                 autoFocus
               >
                 Ok
               </Button>
             </DialogActions>
           </Dialog>
 
 
 
           <div className=' d-flex justify-content-end' id='navbarSupportedContent'>
             <div>    
             {/*  if the normal user has logged in then do not show the domain selected  */}
             {!isNormalUser ? <DomainSelector></DomainSelector> : null}
 
             </div>
           </div>
         </div>
       ) : null}
     </div>
   );
 };
 
 // map state to props
 const mapStateToProps = ({ sidebar, authUser }) => {
   return { sidebar, authUser };
 };
 
 export default withRouter(
   connect(mapStateToProps, {
     setUserDomain,
     getDomainUsers,
     logOff,
   })(SubHeader)
 );
 