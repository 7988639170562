/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
 import {
    CONFIRM_TEAMMEMBER_INVITE,
    CONFIRM_TEAMMEMBER_INVITE_FAILURE,
    CONFIRM_TEAMMEMBER_INVITE_SUCCESS
} from '../actions/types';

/**
 * Redux Action To confirm team member invitation
 */
export const confirmTeamInvite = (token) => ({
    type: CONFIRM_TEAMMEMBER_INVITE,
    payload: token
});

/**
 * Redux Action confirmation Success
 */
export const confirmTeamInviteSuccess = () => ({
    type: CONFIRM_TEAMMEMBER_INVITE_SUCCESS
});


/**
 * Redux Action confirmation Failure
 */
export const confirmTeamInviteFailure = (error) => ({
    type: CONFIRM_TEAMMEMBER_INVITE_FAILURE,
    payload: error
})

