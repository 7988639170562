/* eslint-disable import/no-anonymous-default-export */
// sidebar nav links
import AppConfig from "../../../constants/AppConfig";

export default {
  gravitoAdmin1: [
    {
      menu_title: "Config",
      menu_icon: "ti-user",
      child_routes: [
        {
          path: "/profiles",
          menu_icon: "ti-user",
          menu_title: "Profiles",
        },
        {
          path: "/devices",
          menu_icon: "ti-tablet",
          menu_title: "Devices",
        },
        {
          path: "/upload",
          menu_icon: "ti-upload",
          menu_title: "Upload",
        },
      ],
    },
  ],

  gravitoAdmin2: [
    {
      menu_title: "Users",
      menu_icon: "ti-settings",
      child_routes: [
        {
          path: "/roles",
          menu_icon: "ti-lock",
          menu_title: "Manage roles",
        },
        {
          path: "/users",
          menu_icon: "ti-lock",
          menu_title: "Manage users",
        },
      ],
    },
  ],
  gravitoAdmin3: [
    {
      menu_title: "Customers",
      menu_icon: "ti-settings",
      child_routes: [
        {
          path: "/customers",
          menu_icon: "ti-world",
          menu_title: "Manage customers",
        },
        {
          path: "/domains",
          menu_icon: "ti-world",
          menu_title: "Manage domains",
        },
        {
          path: "/vouchers",
          menu_icon: "ti-world",
          menu_title: "Manage vouchers",
        },
        {
          path: "/requests",
          menu_icon: "ti-world",
          menu_title: "Manage requests",
        },
      ],
    },
  ],
  gravitoAdmin4: [
    {
      menu_title: "Partners",
      menu_icon: "ti-settings",
      path: "/partners",
    },
  ],
  gravitoAdmin5: [
    {
      menu_title: "Features",
      menu_icon: "ti-settings",
      child_routes: [
        {
          path: "/cookiescan",
          menu_icon: "ti-rocket",
          menu_title: "Cookie Scan",
        },
        {
          path: "/cookierepo",
          menu_icon: "ti-rocket",
          menu_title: "Cookie Repo",
        },
        {
          path: "/customerdomainscan",
          menu_icon: "ti-rocket",
          menu_title: "Customer Domain Scan",
        },
      ],
    },
  ],

  topLevelItems: [
    {
      path: "/customerdashboard",
      menu_icon: "ti-dashboard",
      menu_title: "Dashboard",
    },
    {
      path: "/reports",
      menu_icon: "ti-bar-chart",
      menu_title: "Reporting",
    },
  ],
  customerForPartners: [
    {
      path: "/customers",
      menu_icon: "ti-world",
      menu_title: "Customers",
    },
  ],

  consentManagement: [
    {
      menu_title: "CMP",
      menu_icon: "ti-files",
      child_routes: [
        {
          path: "/lightconfig",
          menu_icon: "ti-panel",
          menu_title: "LightCMP configurator",
        },
        {
          path: "/tcfconfig",
          menu_icon: "ti-panel",
          menu_title: "TCF configurator",
        },
      ],
    },
  ],
  domainManagement: [
    {
      menu_title: "Domains",
      menu_icon: "ti-files",
      child_routes: [
        {
          path: "/customerdomains",
          menu_icon: "ti-world",
          menu_title: "Manage domains",
        },

        {
          path: "/managecustomdomains",
          menu_icon: "ti-palette",
          menu_title: "First party domains",
        },
        // {
        //   path: "/connectors",
        //   menu_icon: "ti-link",
        //   menu_title: "Connectors",
        // },
      ],
    },
  ],
  // IAM: [
  //   {
  //     path: "/applications",
  //     menu_icon: "ti-loop",
  //     menu_title: "IAM & Apps",
  //   },
  // ],
  contentManagement: [
    {
      menu_title: "Contents",
      menu_icon: "ti-files",
      child_routes: [
        {
          path: "/contents",
          menu_icon: "ti-pencil-alt",
          menu_title: "Content",
        },

        {
          path: "/constellations",
          menu_icon: " ti-gallery",
          menu_title: "Constellations",
        },
      ],
    },
  ],
  segments: [
    {
      menu_title: "Audiences",
      menu_icon: "ti-files",
      child_routes: [
        {
          menu_title: "Manage Audience",
          menu_icon: "ti-list",
          path: "/audiences",
        },
        {
          path: "/segments",
          menu_icon: "ti-pencil-alt",
          menu_title: "Manage segments",
        },
      ],
    },
  ],
  // audiences: [
  //   {
  //     path: "/audiences",
  //     menu_icon: " fa fa-users",
  //     menu_title: "Audiences",
  //   },
  // ],
  campaigns: [
    {
      menu_title: "Campaigns",
      menu_icon: "ti-files",
      child_routes: [
        {
          menu_title: "List of Campaigns",
          menu_icon: "ti-list",
          path: "/campaigndashboard",
        },
        {
          path: "/campaigns",
          menu_icon: "ti-rocket",
          menu_title: "Manage campaigns",
        },
      ],
    },
  ],
  features: [
    {
      menu_title: "Features",
      menu_icon: "ti-files",
      child_routes: [
        {
          menu_title: "IAM & Apps",
          menu_icon: "ti-list",
          path: "/applications",
        },
        {
          path: "/domainprofiles",
          menu_icon: "ti-rocket",
          menu_title: "CRM",
        },
        {
          path: "/connectors",
          menu_icon: "ti-rocket",
          menu_title: "Connectors",
        },
        {
          path: "/cookiescan",
          menu_icon: "ti-rocket",
          menu_title: "Cookie Scan",
        },
        {
          path: "/cookierepo",
          menu_icon: "ti-rocket",
          menu_title: "Cookie Repo",
        },
        {
          path: "/customerdomainscan",
          menu_icon: "ti-rocket",
          menu_title: "Customer Domain Scan",
        },
        {
          path: "/gvlupdates",
          menu_icon: "ti-rocket",
          menu_title: "GVL Updates",
        },
        {
          path: "/firstpartyconfigurator",
          menu_icon: "ti-rocket",
          menu_title: "First Party Configurator",
        },
      ],
    },
  ],

  // profiles: [
  //   {
  //     menu_title: "Profiles",
  //     menu_icon: "ti-lock",
  //     path: "/domainprofiles",
  //   },
  // ],

  settings: [
    {
      menu_title: "Settings",
      menu_icon: "ti-user",
      path: AppConfig.accountUrl,
    },
  ],

  welcome: [
    {
      menu_title: "Home",
      menu_icon: "ti-home",
      path: "/welcome",
    },
  ],

  register: [
    {
      menu_title: "Register",
      menu_icon: "ti-home",
      path: "/register",
    },
  ],
  gravitoAdmin6: [
    {
      menu_title: "Gravito Admin",
      menu_icon: "ti-settings",
      child_routes: [
        {
          menu_title: "Domain Counts",
          menu_icon: "ti-home",
          path: "/domainCounts",
        },
        {
          menu_title: "Cert Check",
          menu_icon: "ti-home",
          path: "/certCheck",
        },
        {
          menu_title: "First Party Apps",
          menu_icon: "ti-home",
          path: "/firstpartyApps",
        },
        {
          menu_title: "Customer Domain Apps",
          menu_icon: "ti-home",
          path: "/customerDomainApps",
        },
      ],
    },
  ],
};
