import {
  GET_ALL_CD_APPS,
  GET_ALL_CD_APPS_FAILURE,
  GET_ALL_CD_APPS_SUCCESS,
  SEARCH_CD_APPS,
  FILTER_CD_APPS_BY_STATUS,
  ADD_CD_APP,
  ADD_CD_APP_FAILURE,
  ADD_CD_APP_SUCCESS,
  UPDATE_CD_APP,
  UPDATE_CD_APP_FAILURE,
  UPDATE_CD_APP_SUCCESS,
  DELETE_CD_APP,
  DELETE_CD_APP_FAILURE,
  DELETE_CD_APP_SUCCESS,
  STATUS_CHANGE_CD_APP,
  STATUS_CHANGE_CD_APP_FAILURE,
  STATUS_CHANGE_CD_APP_SUCCESS,
} from "../actions/types";
import { NotificationManager } from "react-notifications";

const initState = {
  loading: false,
  appsList: [],
  filteredApps: [],
  addModel: null,
  deleteModel: null,
  statusChangeModel: null,
  redirect: false,
  refresh: false,
};
export default (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_CD_APPS:
      return { ...state, loading: true };

    case GET_ALL_CD_APPS_SUCCESS:
      return {
        ...state,
        loading: false,
        appsList: action.payload,
        filteredApps: action.payload,
        redirect: false,
        refresh: false,
      };

    case GET_ALL_CD_APPS_FAILURE:
      NotificationManager.error("Error while fetching Apps");
      return { ...state, loading: false, appsList: [], filteredApps: [] };
    case SEARCH_CD_APPS:
      let filteredApps = [...state.appsList].filter((a) =>
        a.domainName.includes(action.payload)
      );
      return { ...state, filteredApps: filteredApps };
    case FILTER_CD_APPS_BY_STATUS:
      const filtered = state.appsList.filter((item) => {
        if (action.payload === "") {
          return true;
        } else {
          return item.isActive === action.payload;
        }
      });
      return {
        ...state,
        filteredApps: filtered,
      };
    case ADD_CD_APP:
      return { ...state, loading: true, addModel: action.payload };
    case ADD_CD_APP_SUCCESS:
      return { ...state, loading: false, addModel: null, redirect: true };
    case ADD_CD_APP_FAILURE:
      NotificationManager.error("Error while adding Apps");
      return { ...state, loading: false, addModel: null };
    case UPDATE_CD_APP:
      return { ...state, loading: true, addModel: action.payload };
    case UPDATE_CD_APP_SUCCESS:
      return { ...state, loading: false, addModel: null, redirect: true };
    case UPDATE_CD_APP_FAILURE:
      NotificationManager.error("Error while updating App");
      return { ...state, loading: false, addModel: null };
    case DELETE_CD_APP:
      return { ...state, loading: true, deleteModel: action.payload };
    case DELETE_CD_APP_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteModel: null,
        redirect: false,
        refresh: true,
      };
    case DELETE_CD_APP_FAILURE:
      NotificationManager.error("Error while deleting App");
      return { ...state, loading: false, deleteModel: null };
    default:
      return { ...state };
    case STATUS_CHANGE_CD_APP:
      return { ...state, loading: true, statusChangeModel: action.payload };
    case STATUS_CHANGE_CD_APP_SUCCESS:
      NotificationManager.success("Status changed successfully");
      return {
        ...state,
        loading: false,
        statusChangeModel: null,
        redirect: false,
        refresh: true,
      };
    case STATUS_CHANGE_CD_APP_FAILURE:
      NotificationManager.error("Error while changing status of App");
      return { ...state, loading: false, statusChangeModel: null };
  }
};
