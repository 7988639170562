/**
 * Manage User Reducer
 */
/* eslint-disable */
import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
// action types
import {
  ASSIGN_CUSTOMER_ADMIN,
  ASSIGN_CUSTOMER_ADMIN_FAILURE,
  ASSIGN_CUSTOMER_ADMIN_SUCCESS,
  DELETE_USER,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  FILLTER_USERS_BY_TYPE,
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USER_ASSIGN_DOMAINS,
  GET_USER_ASSIGN_DOMAINS_FAILURE,
  GET_USER_ASSIGN_DOMAINS_SUCCESS,
  GET_USER_BY_EMAIL,
  GET_USER_BY_EMAIL_FAILURE,
  GET_USER_BY_EMAIL_SUCCESS,
  SEARCH_USER,
  SELECT_USER,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  USER_ASSIGN_DOMAINS,
  USER_ASSIGN_DOMAINS_FAILURE,
  USER_ASSIGN_DOMAINS_SUCCESS,
  LOGIN_AS_USER,
  LOGIN_AS_USER_SUCCESS,
  LOGIN_AS_USER_FAILURE
} from "../actions/types";
import AppConfig from "../constants/AppConfig";
// initial state
const INIT_STATE = {
  users: [],
  filteredUsers: [],
  roles: [],
  user: null,
  search: "",
  filterType: "",
  action: "",
  redirect: false,
  error: null,
  domains: [],
  userDomainList: null,
  customerAdmin: null,
  filterEmail: null,
  userByEmail: null,
  redirectToLists: false,
  domainList: [],
  loginAsUser:null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get default domain consents
    case GET_USERS:
      return { ...state, loading: true };
    // Success in getting default domain consents, set the response in the state...
    case GET_USERS_SUCCESS:
      // Remove device from role list, as we are not loading device type users.
      return {
        ...state,
        loading: false,
        users: action.payload.data.users,
        roles: _.reject(action.payload.data.roles, ["Name", "Device"]),
        filteredUsers: action.payload.data.users,
        redirect: false,
      };
    // failure in fetching the default domain data
    case GET_USERS_FAILURE:
      NotificationManager.error("Could not load users");
      return { ...state, loading: false };

    case UPDATE_USER:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid user details" };
      } else {
        return {
          ...state,
          loading: true,
          user: action.payload,
          userByEmail: action.payload,
        };
      }
    case UPDATE_USER_SUCCESS:
      NotificationManager.success("User updated successfully.");

      return {
        ...state,
        loading: false,
        user: null,
        redirect: false,
        redirectToLists: true,
      };
    // failure in updating users
    case UPDATE_USER_FAILURE:
      NotificationManager.error("Could not update user");
      return { ...state, loading: false, user: null, redirect: true };
    case DELETE_USER:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid user details" };
      } else {
        return {
          ...state,
          loading: false,
          user: action.payload,
          action: "DELETE",
        };
      }
    case DELETE_USER_SUCCESS:
      NotificationManager.success("User deleted successfully.");

      return { ...state, loading: true, user: null, redirect: true };
    // failure in updating users
    case DELETE_USER_FAILURE:
      NotificationManager.error("Could not delete user");
      return { ...state, loading: false, user: null };

    case SEARCH_USER:
      if (action.payload === "") {
        return { ...state, filteredUsers: state.users };
      } else {
        const filtered = state.filteredUsers.filter(
          (item) =>
            item.userName.toLowerCase().indexOf(action.payload.toLowerCase()) >
            -1
        );
        return {
          ...state,
          filteredUsers: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    // Filter users by type...
    case FILLTER_USERS_BY_TYPE:
      if (action.payload === "") {
        return { ...state, filteredUsers: state.users };
      } else {
        const filtered = state.users.filter(
          (item) => item.roles.indexOf(action.payload.toUpperCase()) > -1
        );
        return {
          ...state,
          filteredUsers: filtered,
          filterType: action.payload.toLowerCase(),
        };
      }

    // Select User...
    case SELECT_USER:
      if (action.payload === "") {
        return { ...state, user: null };
      } else {
        return { ...state, user: action.payload, redirectToLists: false };
      }
    // get domain list
    case GET_USER_ASSIGN_DOMAINS:
      return { ...state, loading: true };
    // Success in getting domain list
    case GET_USER_ASSIGN_DOMAINS_SUCCESS:
      var domainList = [];
      if (action.payload.data !== null && action.payload.data.length > 0) {
        action.payload.data.forEach((element) => {
          var domainItem = {
            value: element.id,
            label: element.domain,
          };
          domainList.push(domainItem);
        });
      }

      return {
        ...state,
        loading: false,
        domains: action.payload.data,
        domainList: domainList,
      };
    // failure in fetching the domain list
    case GET_USER_ASSIGN_DOMAINS_FAILURE:
      NotificationManager.error("Could not load domains");
      return { ...state, loading: false };

    case USER_ASSIGN_DOMAINS:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid user details" };
      } else {
        return {
          ...state,
          loading: true,
          userDomainList: action.payload.domainDataSet,
        };
      }
    case USER_ASSIGN_DOMAINS_SUCCESS:
      NotificationManager.success("Domains were assigned successfully..");
      return {
        ...state,
        loading: false,
        userDomainList: null,
        redirect: false,
      };
    // failure in updating users
    case USER_ASSIGN_DOMAINS_FAILURE:
      NotificationManager.error(
        "Could not assign domains for the selected user."
      );
      return { ...state, loading: false, userDomainList: null, redirect: true };
    case ASSIGN_CUSTOMER_ADMIN:
      return { ...state, loading: true, customerAdmin: action.payload };
    case ASSIGN_CUSTOMER_ADMIN_SUCCESS:
      if (state.customerAdmin) {
        NotificationManager.success(
          state.user.userName + " has been promoted to customer admin role"
        );
      } else {
        NotificationManager.success(
          state.user.userName + " has been demoted from customer admin role"
        );
      }

      return { ...state, loading: false, customerAdmin: null };
    case ASSIGN_CUSTOMER_ADMIN_FAILURE:
      return { ...state, loading: false, customerAdmin: null };
    case GET_USER_BY_EMAIL:
      return { ...state, filterEmail: action.payload, loading: true };
    case GET_USER_BY_EMAIL_SUCCESS:
      return {
        ...state,
        userByEmail: action.payload[0],
        roles: _.reject(action.payload[1], ["Name", "Device"]),
        loading: false,
      };
    case GET_USER_BY_EMAIL_FAILURE:
      NotificationManager.error("Could load user with given email.");
      return { ...state, loading: false };
    case LOGIN_AS_USER:
        if (action.payload === "") {
          return { ...state, error: "Please enter a valid user details" };
        } else {
          return {
            ...state,
            loading: true
          };
        }
      case LOGIN_AS_USER_SUCCESS:
        //NotificationManager.success("User updated successfully.");
        sessionStorage.clear();
        sessionStorage.setItem('gravitoassist', true);
        setTimeout(() => {
          window.location = `${AppConfig.appV2Url}`;
        }, 1000);
        return {
          ...state,
          loading: false,
        
        };
      // failure in updating users
      case LOGIN_AS_USER_FAILURE:
        NotificationManager.error("Could not login as user");
        return { ...state, loading: false };
    // Update default domain consents
    default:
      return { ...state };
  }
};
