/**
 * App.js Layout Start Here
 */
 import { MuiThemeProvider } from "@material-ui/core/styles";
 import React from "react";
 import {  Route, withRouter } from "react-router-dom";
 // app routes
 import NonAuthRoutes from "../routes/NonAuthRoutes";

 /**
  * Initial Path To Check Whether User Is Logged In Or Not
  */
 const InitialPath = ({ component: Component, authUser, ...rest }) => (
   <Route
     {...rest}
     render={(props) =>
 
         <Component {...props} />
     
     }
   />
 );
 
 
 
 const LandingContainer = (props) => {
  return (
           <InitialPath
             path={`${props.match.url}`}
             component={NonAuthRoutes}
           />
   );
 };
 
 
 export default withRouter(
 (LandingContainer)
 );
 