/**
 * Redux Actions for managing crud operations on Campaigns.
 */

 import {
    GET_PARTNERS,
    GET_PARTNERS_SUCCESS,
    GET_PARTNERS_FAILURE,
    ADD_PARTNER,
    ADD_PARTNER_SUCCESS,
    ADD_PARTNER_FAILURE,
    UPDATE_PARTNER,
    UPDATE_PARTNER_SUCCESS,
    UPDATE_PARTNER_FAILURE,
    DEACTIVATE_PARTNER,
    DEACTIVATE_PARTNER_SUCCESS,
    DEACTIVATE_PARTNER_FAILURE,
    ASSIGN_CUSTOMER_TO_PARTNER,
    ASSIGN_CUSTOMER_TO_PARTNER_SUCCESS,
    ASSIGN_CUSTOMER_TO_PARTNER_FAILURE,
    ADD_PARTNER_USER,
    ADD_PARTNER_USER_SUCCESS,
    ADD_PARTNER_USER_FAILURE,
    REMOVE_PARTNER_USER,
    REMOVE_PARTNER_USER_SUCCESS,
    REMOVE_PARTNER_USER_FAILURE,
    SEARCH_PARTNER,
    SELECT_PARTNER,
    GET_PARTNER_CUSTOMERS,
    GET_PARTNER_CUSTOMERS_SUCCESS,
    GET_PARTNER_CUSTOMERS_FAILURE,
    GET_PARTNERS_BY_ID_SUCCESS,
    GET_PARTNERS_BY_ID_FAILURE,
    EDIT_PARTNER_USER,
    EDIT_PARTNER_USER_SUCCESS,
    EDIT_PARTNER_USER_FAILURE
} from './types';

/**
 * Redux action to get partners
 */
export const getPartners =()=>({
    type: GET_PARTNERS
})
export const getPartnersSuccess =(response)=>({
    type: GET_PARTNERS_SUCCESS,
    payload: response
})
export const getPartnersFailure =(error)=>({
    type: GET_PARTNERS_FAILURE,
    payload: error
})


/**
 * Redux actions to add a partner
 */
export const addPartner=(model)=>({
    type: ADD_PARTNER,
    payload: model
});

export const addPartnerSuccess=(response)=>({
    type: ADD_PARTNER_SUCCESS,
    payload: response
});

export const addPartnerFailure=(error)=>({
    type: ADD_PARTNER_FAILURE,
    payload: error
});




/**
 * Redux actions to update a partner
 */
 export const updatePartner=(model)=>({
    type: UPDATE_PARTNER,
    payload: model
});

export const updatePartnerSuccess=(response)=>({
    type: UPDATE_PARTNER_SUCCESS,
    payload: response
});

export const updatePartnerFailure=(error)=>({
    type: UPDATE_PARTNER_FAILURE,
    payload: error
});


/**
 * Redux actions to deactivate a partner
 */
 export const deactivatePartner=(id)=>({
    type: DEACTIVATE_PARTNER,
    payload: id
});

export const deactivatePartnerSuccess=(response)=>({
    type: DEACTIVATE_PARTNER_SUCCESS,
    payload: response
});

export const deactivatePartnerFailure=(error)=>({
    type: DEACTIVATE_PARTNER_FAILURE,
    payload: error
});


/**
 * Redux actions to assign a customer to partner
 */
 export const assignCustomerToPartner=(model)=>({
    type: ASSIGN_CUSTOMER_TO_PARTNER,
    payload: model
});

export const assignCustomerToPartnerSuccess=(response)=>({
    type: ASSIGN_CUSTOMER_TO_PARTNER_SUCCESS,
    payload: response
});

export const assignCustomerToPartnerFailure=(error)=>({
    type: ASSIGN_CUSTOMER_TO_PARTNER_FAILURE,
    payload: error
});


/**
 * Redux actions to add a partner user
 */
 export const addPartnerUser=(model)=>({
    type: ADD_PARTNER_USER,
    payload: model
});

export const addPartnerUserSuccess=(response)=>({
    type: ADD_PARTNER_USER_SUCCESS,
    payload: response
});

export const addPartnerUserFailure=(error)=>({
    type: ADD_PARTNER_USER_FAILURE,
    payload: error
});


/**
 * Redux actions to edit a partner user
 */
 export const editPartnerUser=(model)=>({
    type: EDIT_PARTNER_USER,
    payload: model
});

export const editPartnerUserSuccess=(response)=>({
    type: EDIT_PARTNER_USER_SUCCESS,
    payload: response
});

export const editPartnerUserFailure=(error)=>({
    type: EDIT_PARTNER_USER_FAILURE,
    payload: error
});

/**
 * Redux actions to assign a customer to partner
 */
 export const removePartnerUser=(model)=>({
    type: REMOVE_PARTNER_USER,
    payload: model
});

export const removePartnerUserSuccess=(response)=>({
    type: REMOVE_PARTNER_USER_SUCCESS,
    payload: response
});

export const removePartnerUserFailure=(error)=>({
    type: REMOVE_PARTNER_USER_FAILURE,
    payload: error
});

/**
 * Redux action for selecting partner.
 */
 export const selectPartner=(partner)=>({
    type: SELECT_PARTNER,
    payload: partner
})

/**
 * Redux Action On Search partner
 */
 export const searchPartner = (searchText) => ({
    type: SEARCH_PARTNER,
    payload: searchText
});


/**
 * Redux action to get customers list for assigning a customer to partner
 */
 export const getPartnerCustomers =()=>({
    type: GET_PARTNER_CUSTOMERS
})
export const getPartnerCustomersSuccess =(response)=>({
    type: GET_PARTNER_CUSTOMERS_SUCCESS,
    payload: response
})
export const getPartnerCustomersFailure =(error)=>({
    type: GET_PARTNER_CUSTOMERS_FAILURE,
    payload: error
})

export const getPartnerByIdSuccess =(response)=>({
    type: GET_PARTNERS_BY_ID_SUCCESS,
    payload: response
})
export const getPartnerByIdFailure =(error)=>({
    type: GET_PARTNERS_BY_ID_FAILURE,
    payload: error
})
