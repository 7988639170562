/**
 * Roles Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  assignCustomerAdminFailure,
  assignCustomerAdminSuccess,
  assignUserDomainsFailure,
  assignUserDomainsSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  getAssignDomainsFailure,
  getAssignDomainsSuccess,
  getUserByEmailFailure,
  getUserByEmailSuccess,
  getUsersFailure,
  getUsersSuccess,
  updateUserFailure,
  updateUserSuccess,
  loginAsUserSuccess,
  loginAsUserFailure
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const users = (state) => state.userReducer;

const getUsersFromServer = async () => {
  return await getAxiosRequest().get("/user/users");
};

const getUsersDomainsFromServer = async () => {
  return await getAxiosRequest().get("/domain");
};

const updateUser = async (model) => {
  return await getAxiosRequest().put("/user/roles", model);
};

const updateUserDomains = async (model) => {
  return await getAxiosRequest().put("/user/domains", model);
};

const updateUserCustomerAdmin = async (model) => {
  return await getAxiosRequest().put("/user/customeradmin", model);
};
const removeUser = async (model) => {
  return await getAxiosRequest().delete("/user?email=" + model.email);
};

const getUserByEmailCall = async (email) => {
  var encodedEmail = encodeURIComponent(email);

  return await getAxiosRequest().get(`/user/byemail?email=${encodedEmail}`);
};

const loginUser = async (model) => {
  return await getAxiosRequest().post("/account/loginasuser", model);
};

/**
 * Get Users
 */
function* getUsers() {
  try {
    // call api to generate magic link
    const response = yield call(getUsersFromServer);
    yield put(getUsersSuccess(response));
  } catch (error) {
    yield put(getUsersFailure(error.response.data.error));
  }
}

/**
 * Get Domains
 */
function* getDomains() {
  try {
    // call api to generate magic link
    const response = yield call(getUsersDomainsFromServer);
    yield put(getAssignDomainsSuccess(response));
  } catch (error) {
    yield put(getAssignDomainsFailure(error.response.data.error));
  }
}

/**
 * Edit User
 */
function* editUser() {
  try {
    // call api to generate magic link
    let model = yield select(users); // <-- get the model
    var userModel = {
      UserName: model.user.email,
      Roles: model.user.roles,
      PhoneNumber: model.user.phoneNumber,
    };
    const response = yield call(updateUser, userModel);
    yield put(updateUserSuccess(response));
  } catch (error) {
    yield put(updateUserFailure("Failed..."));
  }
}

/**
 * Edit User
 */
function* updateDomains() {
  try {
    // call api to generate magic link
    let model = yield select(users); // <-- get the model
    var domainsModel = {
      userName: model.user.userName,
      domains: model.userDomainList,
    };
    const response = yield call(updateUserDomains, domainsModel);
    yield put(assignUserDomainsSuccess(response));
  } catch (error) {
    yield put(assignUserDomainsFailure("Failed..."));
  }
}

/**
 * Edit User
 */
function* updateCustomerAdmin() {
  try {
    let model = yield select(users); // <-- get the model
    var domainsModel = {
      userName: model.user.userName,
      isCustomerAdmin: true,
    };

    const response = yield call(updateUserCustomerAdmin, domainsModel);
    yield put(assignCustomerAdminSuccess(response));
  } catch (error) {
    yield put(assignCustomerAdminFailure("Failed..."));
  }
}

/**
 * Delete User
 */
function* deleteUser() {
  try {
    // call api to generate magic link
    let model = yield select(users); // <-- get the model
    var userModel = {
      email: model.user.email,
    };
    const response = yield call(removeUser, userModel);
    yield put(deleteUserSuccess(response));
    const refreshResponse = yield call(getUsersFromServer);
    yield put(getUsersSuccess(refreshResponse));
  } catch (error) {
    yield put(deleteUserFailure("Failed..."));
  }
}
function* getUserByEmailFromServer() {
  try {
    var userModel = yield select(users);
    var email = userModel.filterEmail;
    const response = yield call(getUserByEmailCall, email);
    if (response) {
      yield put(getUserByEmailSuccess(response.data));
    }
  } catch (e) {
    yield put(getUserByEmailFailure(e));
  }
}

/**
 * login as user
 */
 function* login() {
  try {
    // call api to generate magic link
    let model = yield select(users); // <-- get the model
    var userModel = {
      email: model.filterEmail
     
    };
    const response = yield call(loginUser, userModel);
    yield put(loginAsUserSuccess(response));
  } catch (error) {
    yield put(loginAsUserFailure("Failed..."));
  }
}

export const usersSagas = [
  takeEvery("GET_USERS", getUsers),
  takeEvery("UPDATE_USER", editUser),
  takeEvery("DELETE_USER", deleteUser),
  takeEvery("GET_USER_ASSIGN_DOMAINS", getDomains),
  takeEvery("USER_ASSIGN_DOMAINS", updateDomains),
  takeEvery("ASSIGN_CUSTOMER_ADMIN", updateCustomerAdmin),
  takeEvery("GET_USER_BY_EMAIL", getUserByEmailFromServer),
  takeEvery("LOGIN_AS_USER", login),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...usersSagas]);
}
