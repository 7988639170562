/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-unused-vars */
/*
    applications Reducer
    Curd Operations on applications.
*/
import { NotificationManager } from "react-notifications";
import {
  CONFIRM_TEAMMEMBER_INVITE,
  CONFIRM_TEAMMEMBER_INVITE_FAILURE,
  CONFIRM_TEAMMEMBER_INVITE_SUCCESS,
} from "../actions/types";
// app config
import AppConfig from "../constants/AppConfig";

// initial state
const INIT_STATE = {
  confirmationToken: null,
  loading: false,
  error: null,
  redirect: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONFIRM_TEAMMEMBER_INVITE:
      if (action.payload === "") {
        return {
          ...state,
          error: "We faced problem while validating your invitation.",
        };
      }

      return {
        ...state,
        loading: true,
        confirmationToken: action.payload,
        error: null,
      };

    // On Success, show message
    case CONFIRM_TEAMMEMBER_INVITE_SUCCESS:
      NotificationManager.success(
        `Congratulations ! Your registration is successful, please login to access Gravito.`
      );

      setTimeout(() => {
        window.location = `${AppConfig.appUrl}`;
      }, 1000);

      return {
        ...state,
        loading: false,
        confirmationToken: null,
        redirect: true,
        error: null,
      };

    // On Failure show error message.
    case CONFIRM_TEAMMEMBER_INVITE_FAILURE:
      if (action.payload) {
        NotificationManager.warning(
          `${action.payload}`
        );
      } else {
        NotificationManager.error(
          `We could not confirm your registration, please contact gravito support.`
        );
      }
    
      return {
        ...state,
        loading: false,
        confirmationToken: null,
        redirect: false,
        error: "confirmationError",
      };

    default:
      return { ...state };
  }
};
