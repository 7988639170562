/**
 * Manage Users Actions
 */
import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    SEARCH_USER,
    REFRESH_USERS,
    FILLTER_USERS_BY_TYPE,
    SELECT_USER, 
    GET_USER_ASSIGN_DOMAINS,
    GET_USER_ASSIGN_DOMAINS_SUCCESS,
    GET_USER_ASSIGN_DOMAINS_FAILURE,
    USER_ASSIGN_DOMAINS,
    USER_ASSIGN_DOMAINS_SUCCESS,
    USER_ASSIGN_DOMAINS_FAILURE,
    ASSIGN_CUSTOMER_ADMIN,
    ASSIGN_CUSTOMER_ADMIN_SUCCESS,
    ASSIGN_CUSTOMER_ADMIN_FAILURE,
    GET_USER_BY_EMAIL,
    GET_USER_BY_EMAIL_FAILURE,
    GET_USER_BY_EMAIL_SUCCESS,
    LOGIN_AS_USER,
    LOGIN_AS_USER_SUCCESS,
    LOGIN_AS_USER_FAILURE

} from './types';

/**
 * Redux Action To Fetch  Users
 */
export const getUsers = () => ({
    type: GET_USERS
})
/**
 * Redux Action for success in fetching users.
 */
export const getUsersSuccess = (response) => ({
    type: GET_USERS_SUCCESS,
    payload: response
});
/**
 * Redux Action for failure in fetching users.
 */

export const getUsersFailure = (error) => ({
    type: GET_USERS_FAILURE,
    payload: error
});

/**
 * Redux Action for refer
 */

export const refreshUsers = (response) => ({
    type: REFRESH_USERS,
    payload: response
});



/**
 * Redux Action To Update  User
 */
export const updateUser = (model) => ({
    type: UPDATE_USER,
    payload:model
})

export const updateUserSuccess = (response) => ({
    type: UPDATE_USER_SUCCESS,
    payload: response
});

export const updateUserFailure = (error) => ({
    type: UPDATE_USER_FAILURE,
    payload: error
});


/**
 * Redux Action To Delete  User
 */
export const deleteUser = (model) => ({
    type: DELETE_USER,
    payload:model
})

export const deleteUserSuccess = (response) => ({
    type: DELETE_USER_SUCCESS,
    payload: response
});

export const deleteUserFailure = (error) => ({
    type: DELETE_USER_FAILURE,
    payload: error
});

/**
 * Redux Action On Search Consents
 */
export const searchUser = (searchText) => ({
    type: SEARCH_USER,
    payload: searchText
});


/**
 * Redux action for filtering users by type.
 */
export const fillterUsersByType=(keyword)=>({
    type: FILLTER_USERS_BY_TYPE,
    payload: keyword
})

/**
 * Redux action for filtering users by type.
 */
export const selectUser=(user)=>({
    type: SELECT_USER,
    payload: user
})


/**
 * Redux Action To Fetch  Domain list
 */
export const getAssignDomains = () => ({
    type: GET_USER_ASSIGN_DOMAINS
})
/**
 * Redux Action for success in fetching domains.
 */
export const getAssignDomainsSuccess = (response) => ({
    type: GET_USER_ASSIGN_DOMAINS_SUCCESS,
    payload: response
});
/**
 * Redux Action for failure in fetching domains.
 */

export const getAssignDomainsFailure = (error) => ({
    type: GET_USER_ASSIGN_DOMAINS_FAILURE,
    payload: error
});


/**
 * Redux Action To Update  User Domains if the user is domain admin
 */
export const assignUserDomains = (model) => ({
    type: USER_ASSIGN_DOMAINS,
    payload:model
})

export const assignUserDomainsSuccess = (response) => ({
    type: USER_ASSIGN_DOMAINS_SUCCESS,
    payload: response
});

export const assignUserDomainsFailure = (error) => ({
    type: USER_ASSIGN_DOMAINS_FAILURE,
    payload: error
});


/**
 * Redux Action To Update  isCustomerAdmin flag for user
 */
export const assignCustomerAdmin = (model) => ({
    type: ASSIGN_CUSTOMER_ADMIN,
    payload:model
})

export const assignCustomerAdminSuccess = (response) => ({
    type: ASSIGN_CUSTOMER_ADMIN_SUCCESS,
    payload: response
});

export const assignCustomerAdminFailure = (error) => ({
    type: ASSIGN_CUSTOMER_ADMIN_FAILURE,
    payload: error
});

export const getUserByEmail = (email) => ({
    type: GET_USER_BY_EMAIL,
    payload:email
})

export const getUserByEmailSuccess = (response) => ({
    type: GET_USER_BY_EMAIL_SUCCESS,
    payload:response
})

export const getUserByEmailFailure = (error) => ({
    type: GET_USER_BY_EMAIL_FAILURE,
    payload:error
})

/**
 * Redux Action To Login as  User
 */
 export const loginAsUser = (model) => ({
    type: LOGIN_AS_USER,
    payload:model
})

export const loginAsUserSuccess = (response) => ({
    type: LOGIN_AS_USER_SUCCESS,
    payload: response
});

export const loginAsUserFailure = (error) => ({
    type: LOGIN_AS_USER_FAILURE,
    payload: error
});
