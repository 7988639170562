/* eslint-disable array-callback-return */
/**
 * Audience Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addDomainFailure,
  addDomainSuccess,
  deleteDomainFailure,
  deleteDomainSuccess,
  generateDomainKeyFailure,
  generateDomainKeySuccess,
  getCustomerDomainsFailure,
  getCustomerDomainsSuccess,
  getDomainByIdFailure,
  getDomainByIdSuccess,
  getDomainCustomersFailure,
  getDomainCustomersSuccess,
  getDomainsFailure,
  getDomainsSuccess,
  refreshDomains,
  revokeAccessFailure,
  revokeAccessSuccess,
  testDomainWebhookFailure,
  testDomainWebhookSuccess,
  updateDomainFailure,
  updateDomainSuccess,
  requestDomainDeletionSuccess,
  requestDomainDeletionFailure,
} from "../actions";
import { GET_DOMAIN_BY_ID } from "../actions/types";
// app config
import AppConfig from "../constants/AppConfig";
import {
  getAxiosRequest,
  getAxiosRequestWithoutCredentials,
  getContextObject,
} from "../helpers/helpers";

export const domains = (state) => state.domainReducer;
export const authUser = (state) => state.authUser;

const getDomainsFromServer = async () => {
  return await getAxiosRequest().get("/domain");
};

const getCustomerDomainsFromServer = async () => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  return await getAxiosRequest().get(`/customer/${currentCustomer}/domain`);
};

const getCustomersFromServer = async () => {
  return await getAxiosRequest().get("/customer");
};

const addDomainToServer = async (model) => {
  return await getAxiosRequest().post("/domain", model);
};

const updateDomain = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.id },
  };
  return await getAxiosRequest().put("/manage/domain", model, config);
};

const removeDomain = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.id },
  };
  return await getAxiosRequest().delete(
    "/manage/domain?id=" + model.id,
    config
  );
};

const requestDomainDeletion = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.id },
  };
  return await getAxiosRequest().post(
    "/manage/domain/deletionrequest?id=" + model.id,
    {},
    config
  );
};

const generateDomainKey = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.id },
  };
  return await getAxiosRequest().post(
    "/manage/domain/generatetoken",
    model,
    config
  );
};

const getDomainByIdCall = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.selectedId },
  };
  return await getAxiosRequest().get(
    `manage/domain/${model.selectedId}`,
    config
  );
};

const webhookTest = async (model) => {
  var config = {};
  config = {
    headers: {},
  };

  let postUrl = "";
  // get details from selected domain..
  if (model.domain) {
    if (model.domain.consentChangeWebHook) {
      postUrl = model.domain.consentChangeWebHook.url;
      if (model.domain.consentChangeWebHook.headerValues) {
        model.domain.consentChangeWebHook.headerValues.map((item, index) => {
          config.headers[item.header] = item.value;
        });
      }
    }
  }
  if (postUrl !== "") {
    try {
      return await getAxiosRequestWithoutCredentials().post(
        postUrl,
        model.requestPayload,
        config
      );
    } catch (err) {
      return null;
    }
  } else {
    return null;
  }
};

/**
 * Get Domains
 */
function* getDomains() {
  try {
    // call api to generate magic link
    const response = yield call(getDomainsFromServer);
    yield put(getDomainsSuccess(response));
  } catch (error) {
    yield put(getDomainsFailure(error.response.data.error));
  }
}

/**
 * Get Customer Domains
 */
function* getCustomerDomains() {
  try {
    // call api to generate magic link
    const response = yield call(getCustomerDomainsFromServer);
    yield put(getCustomerDomainsSuccess(response));
  } catch (error) {
    yield put(getCustomerDomainsFailure(error.response.data.error));
  }
}

/**
 * Get Customers
 */
function* getCustomers() {
  try {
    // call api to generate magic link
    const response = yield call(getCustomersFromServer);
    yield put(getDomainCustomersSuccess(response));
  } catch (error) {
    yield put(getDomainCustomersFailure(error.response.data.error));
  }
}
/**
 * Add Domain
 */
function* addDomain() {
  try {
    // call api to generate magic link
    let model = yield select(domains); // <-- get the model
    const response = yield call(addDomainToServer, model.domain);
    yield put(addDomainSuccess(response));
  } catch (error) {
    yield put(addDomainFailure(error.response.data.error));
  }
}

/**
 * Edit Domain
 */
function* editDomain() {
  try {
    // call api to generate magic link
    let model = yield select(domains); // <-- get the model

    const response = yield call(updateDomain, model.domain);
    yield put(updateDomainSuccess(response));
  } catch (error) {
    yield put(updateDomainFailure(error.response.data.error));
  }
}

/**
 * Delete Domain
 */
function* deleteDomain() {
  try {
    // call api to generate magic link
    let model = yield select(domains); // <-- get the model
    var domainModel = {
      id: model.domain.id,
    };
    const response = yield call(removeDomain, domainModel);
    yield put(deleteDomainSuccess(response));
    var isPartner = getContextObject() ? getContextObject().isPartner : false;
    if (!isPartner) {
      const refreshResponse = yield call(getDomainsFromServer);
      yield put(refreshDomains(refreshResponse));
    } else {
      const refreshResponse = yield call(getCustomerDomainsFromServer);
      yield put(getCustomerDomainsSuccess(refreshResponse));
    }
  } catch (error) {
    yield put(deleteDomainFailure("We faced problem, please try again."));
  }
}

/**
 * Generate Access Token
 */
function* generateKey() {
  try {
    // call api to generate magic link
    let model = yield select(domains); // <-- get the model
    const response = yield call(
      generateDomainKey,
      model.domainForKeyGeneration
    );
    model.domainForKeyGeneration.accessToken = response.data;
    const updateResponse = yield call(
      updateDomain,
      model.domainForKeyGeneration
    );
    yield put(generateDomainKeySuccess(updateResponse));
    let user = yield select(authUser); // <-- get the model

    if (user.isAdmin) {
      const refreshResponse = yield call(getDomainsFromServer);
      yield put(refreshDomains(refreshResponse));
    } else {
      const refreshResponse = yield call(getCustomerDomainsFromServer);
      yield put(getCustomerDomainsSuccess(refreshResponse));
    }
  } catch (error) {
    yield put(generateDomainKeyFailure(error.response.data.error));
  }
}

/**
 * Revoke Access
 */
function* revokeAccess() {
  try {
    // call api to generate magic link
    let model = yield select(domains); // <-- get the model
    model.domainForKeyGeneration.accessToken = null;
    const updateResponse = yield call(
      updateDomain,
      model.domainForKeyGeneration
    );
    yield put(revokeAccessSuccess(updateResponse));

    let user = yield select(authUser); // <-- get the model
    if (user.isAdmin) {
      const refreshResponse = yield call(getDomainsFromServer);
      yield put(refreshDomains(refreshResponse));
    } else {
      const refreshResponse = yield call(getCustomerDomainsFromServer);
      yield put(getCustomerDomainsSuccess(refreshResponse));
    }
  } catch (error) {
    yield put(revokeAccessFailure(error.response.data.error));
  }
}

function* getDomainByIdFromServer() {
  try {
    let model = yield select(domains); // <-- get the model
    const response = yield call(getDomainByIdCall, model);
    if (response) {
      yield put(getDomainByIdSuccess(response.data));
    }
  } catch (error) {
    yield put(getDomainByIdFailure(error));
  }
}

/**
 * Test Domain Webhook
 */
function* testWebhook() {
  try {
    let model = yield select(domains); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model
    let payload = AppConfig.webhookPayload;
    payload.Domain = user.currentDomain.domain;
    payload.UpdatedTs = new Date().getTime();

    let webhookModel = {
      requestPayload: payload,
      domain: model.currentDomain,
    };

    const response = yield call(webhookTest, webhookModel);

    yield put(testDomainWebhookSuccess(response));
  } catch (error) {
    yield put(testDomainWebhookFailure(error.response.data.error));
  }
}

/**
 * Request  Domain Delete
 */
function* requestDeletion() {
  try {
    // call api to generate magic link
    let model = yield select(domains); // <-- get the model
    var domainModel = {
      id: model.domain.id,
    };

    const response = yield call(requestDomainDeletion, domainModel);
    yield put(requestDomainDeletionSuccess(response));
  } catch (error) {
    yield put(requestDomainDeletionFailure(error.response.data.error));
  }
}

export const domainSagas = [
  takeEvery("GET_DOMAINS", getDomains),
  takeEvery("GET_CUSTOMER_DOMAINS", getCustomerDomains),
  takeEvery("ADD_DOMAIN", addDomain),
  takeEvery("UPDATE_DOMAIN", editDomain),
  takeEvery("DELETE_DOMAIN", deleteDomain),
  takeEvery("GET_DOMAIN_CUSTOMERS", getCustomers),
  takeEvery("GENERATE_DOMAIN_KEY", generateKey),
  takeEvery("REVOKE_ACCESS", revokeAccess),
  takeEvery("TEST_DOMAIN_WEBHOOK", testWebhook),
  takeEvery("REQUEST_DOMAIN_DELETION", requestDeletion),
  takeEvery(GET_DOMAIN_BY_ID, getDomainByIdFromServer),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...domainSagas]);
}
