/* eslint-disable import/no-anonymous-default-export */
import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import {
  COMPARE_GVL_VERSIONS,
  COMPARE_GVL_VERSIONS_FAILURE,
  COMPARE_GVL_VERSIONS_SUCCESS,
  FILTER_GVL_BY_STATUS,
  FILTER_GVL_BY_STATUS_FAILURE,
  FILTER_GVL_BY_STATUS_SUCCESS,
  FILTER_GVL_FOR_VENDORS,
  FILTER_GVL_FOR_VENDORS_FAILURE,
  FILTER_GVL_FOR_VENDORS_SUCCESS,
  GET_GVL_VERSIONS,
  GET_GVL_VERSIONS_FAILURE,
  GET_GVL_VERSIONS_SUCCESS,
} from "../actions/types";
const initState = {
  gvlLoading: false,
  gvlVersions: [],

  gvlTitles: [],
  gvlData: [],
  filteredGVLData: [],
  compareModel: null,
  currentLastUpdatedDate:null,
  previousLastUpdatedDate:null
};

function sorter(x, y) {
  var pre = ["string", "number", "bool"];
  if (typeof x !== typeof y)
    return pre.indexOf(typeof y) - pre.indexOf(typeof x);

  if (x === y) return 0;
  else return x > y ? 1 : -1;
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_GVL_VERSIONS:
      return {
        ...state,
        gvlLoading: true,
        gvlVersions: [],
      };

    case GET_GVL_VERSIONS_SUCCESS:
      return {
        ...state,
        gvlLoading: false,
        gvlVersions: action.payload.data ? action.payload.data : [],
      };

    case GET_GVL_VERSIONS_FAILURE:
      return {
        ...state,
        gvlLoading: false,
        gvlVersions: [],
      };

    case COMPARE_GVL_VERSIONS:
      return {
        ...state,
        gvlLoading: true,
        compareModel: action.payload,
      };

    case COMPARE_GVL_VERSIONS_SUCCESS:
      return {
        ...state,
        gvlLoading: false,
        gvlData: action.payload.data.result,
        filteredGVLData: action.payload.data.result,
        currentLastUpdatedDate:moment(action.payload.data.currentLastUpdateDate).format("MMMM Do YYYY"),
        previousLastUpdatedDate:moment(action.payload.data.previousLastUpdateDate).format("MMMM Do YYYY"),

      };

    case COMPARE_GVL_VERSIONS_FAILURE:
      return {
        ...state,
        gvlLoading: false,
      };

    case GET_GVL_VERSIONS_FAILURE:
      NotificationManager.error("Error while fetching gvl versions");

    case FILTER_GVL_BY_STATUS:
      var filters = action.payload;
      var data = [];

      var filterArray = [];

      if (filters.vendoradd) {
        filterArray.push("added");
      }
      if (filters.vendordelete) {
        filterArray.push("deleted");
      }
      if (filters.vendorchange) {
        filterArray.push("changed");
      }

      if (filterArray.length > 0) {
        data = [];
        filterArray.forEach((element) => {
          var filteredData = _.filter(state.gvlData, { status: element });
          filteredData.forEach((dataElement) => {
            data.push(dataElement);
          });
        });
      } else {
        data = state.gvlData;
      }

      return {
        ...state,
        filteredGVLData: data,
      };
    case FILTER_GVL_BY_STATUS_SUCCESS:
    //NotificationManager.error("Error while fetching gvl versions");

    case FILTER_GVL_BY_STATUS_FAILURE:
    //NotificationManager.error("Error while fetching gvl versions");

    case FILTER_GVL_FOR_VENDORS:
      var vendors = action.payload.split(",");
      var data = [];

      if (vendors.length > 0) {
        data = [];

        vendors.forEach((vendor) => {
          state.filteredGVLData.filter((eachElement) => {
            if (eachElement.currentVendor.id === vendor) {
              data.push(eachElement);
            }
          });
        });
      } else {
        data = state.filteredGVLData;
      }

      return {
        ...state,
        filteredGVLData: data,
      };
    case FILTER_GVL_FOR_VENDORS_SUCCESS:
    //NotificationManager.error("Error while fetching gvl versions");

    case FILTER_GVL_FOR_VENDORS_FAILURE:
      //NotificationManager.error("Error while fetching gvl versions");

      return { ...state, gvlLoading: false };

    default:
      return { ...state };
  }
};
