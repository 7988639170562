/**
 * Redux Actions for managing crud operations on Campaigns.
 */

import {
GET_DOMAIN_PROPERTIES,
GET_DOMAIN_PROPERTIES_SUCCESS,
GET_DOMAIN_PROPERTIES_FAILURE,
ADD_DOMAIN_PROPERTY,
ADD_DOMAIN_PROPERTY_SUCCESS,
ADD_DOMAIN_PROPERTY_FAILURE,
UPDATE_DOMAIN_PROPERTY,
UPDATE_DOMAIN_PROPERTY_SUCCESS,
UPDATE_DOMAIN_PROPERTY_FAILURE,
DELETE_DOMAIN_PROPERTY,
DELETE_DOMAIN_PROPERTY_SUCCESS,
DELETE_DOMAIN_PROPERTY_FAILURE,
REFRESH_DOMAIN_PROPERTIES,
SELECT_DOMAIN_PROPERTY,
ACTIVATE_DOMAIN_PROPERTY, 
ACTIVATE_DOMAIN_PROPERTY_FAILURE,
ACTIVATE_DOMAIN_PROPERTY_SUCCESS,
GET_DOMAINS_FOR_PROPERTY,
GET_DOMAINS_FOR_PROPERTY_SUCCESS, 
GET_DOMAINS_FOR_PROPERTY_FAILURE,
GET_DOMAIN_PROPERTIES_FOR_SELECTED_DOMAIN
} from './types';

/**
 * Redux action to get Domain Properties
 */
export const getDomainProperties =()=>({
    type: GET_DOMAIN_PROPERTIES
})

/**
 * Redux action for successful fetching of Domain Properties
 */

export const getDomainPropertiesSuccess =(response)=>({
    type: GET_DOMAIN_PROPERTIES_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Domain Properties
 */
export const getDomainPropertiesFailure =(error)=>({
    type: GET_DOMAIN_PROPERTIES_FAILURE,
    payload: error
})


/**
 * Redux action for fetching Domains after add/edit/delete of Domain
 */
export const refreshDomainProperties = (response) => ({
    type: REFRESH_DOMAIN_PROPERTIES,
    payload: response
});

/**
 * Redux action for adding new Domain Property
 */
export const addDomainProperty = (model) => ({
    type: ADD_DOMAIN_PROPERTY,
    payload:model
})
/**
 * Redux action for successful addition of Domain Property
 */
export const addDomainPropertySuccess =(response)=>({
    type: ADD_DOMAIN_PROPERTY_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to add new Domain Property
 */
export const addDomainPropertyFailure =(error)=>({
    type: ADD_DOMAIN_PROPERTY_FAILURE,
    payload: error
})

/**
 * Redux action for editing a Domain
 */
export const updateDomainProperty =(model)=>({
    type: UPDATE_DOMAIN_PROPERTY,
    payload:model
})
/**
 * Redux action for successful editing of a Domain
 */
export const updateDomainPropertySuccess =(response)=>({
    type: UPDATE_DOMAIN_PROPERTY_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to edit a Domain
 */
export const updateDomainPropertyFailure =(error)=>({
    type: UPDATE_DOMAIN_PROPERTY_FAILURE,
    payload: error
})

/**
 * Redux action for deleting a Domain
 */
export const deleteDomainProperty =(index)=>({
    type: DELETE_DOMAIN_PROPERTY,
    payload:index
})
/**
 * Redux action for successful deletion of Domain
 */
export const deleteDomainPropertySuccess =(response)=>({
    type: DELETE_DOMAIN_PROPERTY_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in deletion of Domain
 */
export const deleteDomainPropertyFailure =(error)=>({
    type: DELETE_DOMAIN_PROPERTY_FAILURE,
    payload: error
})

/**
 * Redux action for selecting a Domain 
 */
export const selectDomainProperty=(index)=>({
    type: SELECT_DOMAIN_PROPERTY,
    payload: index
})

/**
 * Redux action for activating new Domain Property
 */
export const activateDomainProperty = (model) => ({
    type: ACTIVATE_DOMAIN_PROPERTY,
    payload:model
})
/**
 * Redux action for successful addition of Domain Property
 */
export const activateDomainPropertySuccess =(response)=>({
    type: ACTIVATE_DOMAIN_PROPERTY_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to add new Domain Property
 */
export const activateDomainPropertyFailure =(error)=>({
    type: ACTIVATE_DOMAIN_PROPERTY_FAILURE,
    payload: error
})


/**
 * Redux action to get Domains
 */
export const getDomainsForProperty =()=>({
    type: GET_DOMAINS_FOR_PROPERTY
})

/**
 * Redux action for successful fetching of Domains
 */

export const getDomainsForPropertySuccess =(response)=>({
    type: GET_DOMAINS_FOR_PROPERTY_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Domains
 */
export const getDomainsForPropertyFailure =(error)=>({
    type: GET_DOMAINS_FOR_PROPERTY_FAILURE,
    payload: error
})

export const getPropertiesForSelectedDomain = (domain)=>({
    type:GET_DOMAIN_PROPERTIES_FOR_SELECTED_DOMAIN,
    payload:domain
})