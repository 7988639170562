/**
 * Redux Actions for managing crud operations on Campaigns.
 */

import {
  GET_CUSTOMERDASHBOARDDATA,
  GET_CUSTOMERDASHBOARDDATA_SUCCESS,
  GET_CUSTOMERDASHBOARDDATA_FAILURE,
  SELECT_DASHBOARD_CUSTOMER,
  GET_CUSTOMER_LIST_FOR_DASHBOARD,
  GET_CUSTOMER_LIST_FOR_DASHBOARD_SUCCESS,
  GET_CUSTOMER_LIST_FOR_DASHBOARD_FAILURE,
} from "./types";

/**
 * Redux action to get data for customer dashboard
 */
export const getCustomerDashboardData = (data) => ({
  type: GET_CUSTOMERDASHBOARDDATA,
  payload: data,
});

/**
 * Redux action for successful fetching of Domains
 */

export const getCustomerDashboardDataSuccess = (response) => ({
  type: GET_CUSTOMERDASHBOARDDATA_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching Domains
 */
export const getCustomerDashboardDataFailure = (error) => ({
  type: GET_CUSTOMERDASHBOARDDATA_FAILURE,
  payload: error,
});

export const selectDashboardCustomer = (customer) => ({
  type: SELECT_DASHBOARD_CUSTOMER,
  payload: customer,
});

/**
 * Redux action to get list of customers
 */
export const getCustomersForDashboard = () => ({
  type: GET_CUSTOMER_LIST_FOR_DASHBOARD,
});

/**
 * Redux action for successful fetching of customers
 */

export const getCustomersForDashboardSuccess = (response) => ({
  type: GET_CUSTOMER_LIST_FOR_DASHBOARD_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching customers
 */
export const getCustomersForDashboardFailure = (error) => ({
  type: GET_CUSTOMER_LIST_FOR_DASHBOARD_FAILURE,
  payload: error,
});
