/* eslint-disable import/no-anonymous-default-export */
/**
 * Auth User Reducers
 */
import { NotificationManager } from "react-notifications";
import AES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
// app config
import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  CHECK_CUSTOMER_ADMIN_STATUS,
  CHECK_CUSTOMER_ADMIN_STATUS_FAILURE,
  CHECK_CUSTOMER_ADMIN_STATUS_SUCCESS,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_DOMAIN_USERS,
  GET_DOMAIN_USERS_FAILURE,
  GET_DOMAIN_USERS_SUCCESS,
  GET_FIRSTPARTY_DOMAINS,
  GET_FIRSTPARTY_DOMAINS_FAILURE,
  GET_FIRSTPARTY_DOMAINS_SUCCESS,
  GET_USER_DOMAINS,
  GET_USER_DOMAINS_FAILURE,
  GET_USER_DOMAINS_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_SUCCESS,
  SET_USER_DOMAIN,
  SET_USER_CUSTOMER,
} from "../actions/types";
// app config
import AppConfig from "../constants/AppConfig";
import { createContextHash, getContextObject } from "../helpers/helpers";
import * as _ from "lodash";
/**
 * initial auth user
 */

const INIT_STATE = {
  user: null,
  email: "",
  loading: false,
  error: "",
  socialuser: null, // This will be used for the social login
  deleteUser: null,
  userDeleted: false,
  userDomains: [],
  currentDomain: "",
  isAdmin: false,
  customerAdmin: false,
  serverState: null,
  isNormalUser: false,
  firstPartyDomains: [],
  userCustomerDomain: null,
  mappedCustomers: [],
  mappedDomains: [],
  currentCustomer: "",
  isPartner: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // USER LOGIN with the magic link request
    case GET_USER_DOMAINS:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid email address" };
      } else {
        return { ...state, loading: true };
      }
    // MAGIC LINK Successfully generated and sent to the given email address
    case GET_USER_DOMAINS_SUCCESS:
      if (action.payload.data !== null && action.payload.data.length > 0) {
        return {
          ...state,
          loading: false,
          userDomains: action.payload.data,
          currentDomain: {
            id: action.payload.data[0].id,
            domain: action.payload.data[0].domain,
            customerId: action.payload.data[0].customerId,
            customerName: action.payload.data[0].customerName,
          },
        };
      } else {
        return { ...state, loading: false, userDomains: action.payload.data };
      }

    // FAILURE to generate magic link
    case GET_USER_DOMAINS_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false };
    // USER Chooses to login with social login facebook or google for now...
    case SET_USER_DOMAIN:
      return { ...state, loading: false, currentDomain: action.payload };

    case SET_USER_CUSTOMER:
      let usercontext = getContextObject();
      let selectedCustomer = action.payload;

      // get domains from the CustomerDomainMapping for the first customer in the customer list
      var mappedDomains = [];
      if (selectedCustomer) {
        var domainList = _.filter(state.userCustomerDomain, {
          CustomerId: selectedCustomer.id,
        });
        if (domainList[0].Domain) {
          domainList[0].Domain.forEach((domain) => {
            mappedDomains.push({
              domain: domain.DomainName,
              id: domain.DomainDocumentId,
              customerId: selectedCustomer.id,
            });
          });
        } else {
          mappedDomains = [];
        }
      }
      // get the context from hashed value
      if (usercontext) {
        usercontext.customerId = selectedCustomer.id;
        // refresh hash of the context and store it in sessionStorage
        createContextHash(usercontext);
      }
      // return another state param isNormalUser to show the registration routes...
      return {
        ...state,
        loading: false,
        mappedDomains: mappedDomains,
        currentDomain: mappedDomains.length > 0 ? mappedDomains[0] : "",
        currentCustomer: selectedCustomer,
      };

    case GET_DOMAIN_USERS:
      return { ...state, loading: false };
    case GET_DOMAIN_USERS_SUCCESS:
      //get domain users and set it into sessionStorage
      sessionStorage.setItem(
        "domainUsers",
        JSON.stringify(action.payload.data)
      );
      return { ...state, loading: false };
    case GET_DOMAIN_USERS_FAILURE:
      return { ...state, loading: false };
    case CHECK_CUSTOMER_ADMIN_STATUS:
      return { ...state, loading: true };
    case CHECK_CUSTOMER_ADMIN_STATUS_SUCCESS:
      return { ...state, loading: false, customerAdmin: action.payload.data };
    case CHECK_CUSTOMER_ADMIN_STATUS_FAILURE:
      return { ...state, loading: false, customerAdmin: false };
    case AUTHENTICATE_USER:
      return { ...state, loading: true };
    case AUTHENTICATE_USER_SUCCESS:
      var isGravitoAdmin = false;
      var isAdminUser = false;
      var isPartner = false;
      // check if the user accessing the admin portal is admin or domain admin user.
      isAdminUser =
        action.payload.data.roles.includes("ADMIN") ||
        action.payload.data.roles.includes("DOMAIN_ADMIN") ||
        action.payload.data.roles.includes("PARTNER");

      if (action.payload.data.roles) {
        isGravitoAdmin = action.payload.data.roles.includes("ADMIN");
      }

      if (!isGravitoAdmin) {
        //window.location.href = AppConfig.appV2Url;
      }

      if (action.payload.data.roles) {
        isPartner = action.payload.data.roles.includes("PARTNER");
      }

      // get details about users customership and then populate it for showing the customer and domain list.
      var mappedCustomers = [];
      var mappedDomains = [];
      let userCustomerDomainMapping = null;
      // if user is not a gravito admin then resolve the customer data
      if (!isGravitoAdmin && action.payload.data.customerDomainList) {
        userCustomerDomainMapping =
          action.payload.data.customerDomainList[0].CustomerMaster;
        const customerList = [
          ...new Map(
            userCustomerDomainMapping.map((item) => [item["CustomerId"], item])
          ).values(),
        ];

        if (customerList.length > 0) {
          customerList.forEach((element) => {
            mappedCustomers.push({
              name: element.CustomerName,
              id: element.CustomerId,
              useCM: element.UseContentManagementFeature,
            });
          });
          customerList[0].Domain.forEach((domain) => {
            var item = {
              domain: domain.DomainName,
              id: domain.DomainDocumentId,
              customerId: customerList[0].CustomerId,
            };
            mappedDomains.push(item);
          });
        }
      }

      // check if there are any mapped customers, they will not be there for new users.
      if (mappedCustomers[0]) {
        var context = {
          isPartner: isPartner,
          customerId: mappedCustomers[0].id,
        };
        // create hash of the context and store it in sessionStorage
        createContextHash(context);
      }

      // return another state param isNormalUser to show the registration routes...
      return {
        ...state,
        loading: false,
        serverState: true,
        user: action.payload.data,
        isAdmin: isGravitoAdmin,
        isNormalUser: !isAdminUser,
        isPartner: isPartner,
        userCustomerDomain: userCustomerDomainMapping,
        mappedCustomers: mappedCustomers,
        mappedDomains: mappedDomains,
        currentDomain: mappedDomains.length > 0 ? mappedDomains[0] : "",
        currentCustomer: mappedCustomers.length > 0 ? mappedCustomers[0] : "",
      };

    case AUTHENTICATE_USER_FAILURE:
      // localStorage.clear();
      // NotificationManager.error('Sorry, we could not authenticate as Gravito administration user, please try again later.');
      //window.location = `${AppConfig.loginUrl}`
      return { ...state, loading: false, serverState: false };
    case LOGOUT_USER:
      return { ...state };

    case LOGOUT_USER_SUCCESS:
      sessionStorage.clear();
      localStorage.clear();
      setTimeout(() => {
        window.location = `${AppConfig.appUrl}`;
      }, 1000);

      return { ...state, user: null };

    case LOGOUT_USER_FAILURE:
      return { ...state };

    case GET_CURRENT_USER:
      return { ...state };

    case GET_CURRENT_USER_SUCCESS:
      sessionStorage.setItem("currentemail", action.payload);
      return { ...state };
    case GET_FIRSTPARTY_DOMAINS:
      return { ...state };
    case GET_FIRSTPARTY_DOMAINS_SUCCESS:
      return { ...state, firstPartyDomains: action.payload };
    case GET_FIRSTPARTY_DOMAINS_FAILURE:
      return { ...state };
    default:
      return { ...state, loading: false };
  }
};
