import {
  GET_ALL_CD_APPS,
  GET_ALL_CD_APPS_FAILURE,
  GET_ALL_CD_APPS_SUCCESS,
  SEARCH_CD_APPS,
  FILTER_CD_APPS_BY_STATUS,
  ADD_CD_APP,
  ADD_CD_APP_FAILURE,
  ADD_CD_APP_SUCCESS,
  UPDATE_CD_APP,
  UPDATE_CD_APP_FAILURE,
  UPDATE_CD_APP_SUCCESS,
  DELETE_CD_APP,
  DELETE_CD_APP_FAILURE,
  DELETE_CD_APP_SUCCESS,
  STATUS_CHANGE_CD_APP,
  STATUS_CHANGE_CD_APP_FAILURE,
  STATUS_CHANGE_CD_APP_SUCCESS,
} from "./types";

export const getALLCDApps = () => {
  return {
    type: GET_ALL_CD_APPS,
  };
};
export const getALLCDAppsSuccess = (data) => {
  return {
    type: GET_ALL_CD_APPS_SUCCESS,
    payload: data,
  };
};

export const getALLCDAppsFailure = (error) => {
  return {
    type: GET_ALL_CD_APPS_FAILURE,
    payload: error,
  };
};

export const searchCDApps = (name) => {
  return {
    type: SEARCH_CD_APPS,
    payload: name,
  };
};

export const filterAppsByStatus = (status) => {
  return {
    type: FILTER_CD_APPS_BY_STATUS,
    payload: status,
  };
};

export const addCDApps = (model) => ({ type: ADD_CD_APP, payload: model });
export const addCDAppsSuccess = (data) => ({
  type: ADD_CD_APP_SUCCESS,
  payload: data,
});
export const addCDAppsFailure = (error) => ({
  type: ADD_CD_APP_FAILURE,
  payload: error,
});

export const updateCDApps = (model) => ({
  type: UPDATE_CD_APP,
  payload: model,
});
export const updateCDAppsSuccess = (data) => ({
  type: UPDATE_CD_APP_SUCCESS,
  payload: data,
});
export const updateCDAppsFailure = (error) => ({
  type: UPDATE_CD_APP_FAILURE,
  payload: error,
});

export const deleteCDApps = (model) => ({
  type: DELETE_CD_APP,
  payload: model,
});
export const deleteCDAppsSuccess = (data) => ({
  type: DELETE_CD_APP_SUCCESS,
  payload: data,
});
export const deleteCDAppsFailure = (error) => ({
  type: DELETE_CD_APP_FAILURE,
  payload: error,
});

export const changeCDAppStatus = (model) => ({
  type: STATUS_CHANGE_CD_APP,
  payload: model,
});
export const changeCDAppStatusSuccess = (data) => ({
  type: STATUS_CHANGE_CD_APP_SUCCESS,
  payload: data,
});
export const changeCDAppStatusFailure = (error) => ({
  type: STATUS_CHANGE_CD_APP_FAILURE,
  payload: error,
});
