/**
 * Admin Profile Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addDomainLicenseFailure,
  addDomainLicenseSuccess,
  createManualVouchersFailure,
  createManualVouchersSuccess,
  deleteTeamMemberFailure,
  deleteTeamMemberSuccess,
  getCreditsListFailure,
  getCreditsListSuccess,
  getCurrentCustomerFailure,
  getCurrentCustomerSuccess,
  getCurrentCustomerVouchersFailure,
  getCurrentCustomerVouchersSuccess,
  getLedgerBalanceFailure,
  getLedgerBalanceSuccess,
  getUserProfileSuccess,
  getVoucherByCodeFailure,
  getVoucherByCodeSuccess,
  getVouchersFailure,
  getVouchersSuccess,
  inviteTeamMemberFailure,
  inviteTeamMemberSuccess,
  purchaseCreditsFailure,
  purchaseCreditsSuccess,
  redeemVoucherFailure,
  redeemVoucherSuccess,
  registerNewDomainsFailure,
  registerNewDomainsSuccess,
  saveCustomerBasicInfoFailure,
  saveCustomerBasicInfoSuccess,
  updateDomainLicenseFailure,
  updateDomainLicenseSuccess,
  updateUserProfileFailure,
  updateUserProfileSuccess,
} from "../actions";
import {
  GET_CREDITS_LIST,
  GET_LEDGER_BALANCE,
  PURCHASE_CREDITS,
} from "../actions/types";
import { getAxiosRequest, getContextObject } from "../helpers/helpers";

export const manageaccount = (state) => state.manageAccountReducer;
export const auth = (state) => state.authUser;
// get the partner role flag and current customer in the context into local variables, these will be used to differentially call apis

const getVouchersFromServer = async () => {
  return await getAxiosRequest().get("/Voucher/report");
};

const getCreditsListFromServer = async () => {
  return await getAxiosRequest().get("Voucher/Types");
};

const getCurrentCustomerFromServer = async () => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  return await getAxiosRequest().get(`/customer/current/${currentCustomer}`);
};

const getCurrentCustomerCosts = async () => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  return await getAxiosRequest().get(
    `/customer/${currentCustomer}/costing/all`
  );
};

const getCurrentCustomerDomainLicenses = async () => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  return await getAxiosRequest().get(
    `/customer/${currentCustomer}/domainlicense`
  );
};

const purchaseCreditsFromServer = async (model) => {
  return await getAxiosRequest().post("/payment/stripe", model);
  // throw Error("custom Error");
};

const addLicense = async (model) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  model.customerId = currentCustomer;
  return await getAxiosRequest().post(
    `/customer/${currentCustomer}/domainlicense`,
    model
  );
};

const updateLicense = async (model) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  return await getAxiosRequest().put(
    `/customer/${currentCustomer}/domainlicense/${model.id}/end`,
    model
  );
};

const saveCustomerBasicInfoToServer = async (model) => {
  var config = {};

  return await getAxiosRequest().put("/customer/basicinfo", model, config);
};

const registerNewDomainsForCustomer = async (model) => {
  return await getAxiosRequest().put("/customer", model);
};

const getCurrentCustomerVouchersFromServer = async () => {
  return await getAxiosRequest().get(`Voucher/Redemption/My`);
};

const createManualVoucher = async (value) => {
  var config = {};

  var model = {
    value: value,
  };

  return await getAxiosRequest().post("/Voucher/Manual", model, config);
};

const redeemVoucherForCustomer = async (model) => {
  var currentCustomer = getContextObject() ? getContextObject().customerId : 0;
  return await getAxiosRequest().put(`/voucher/${currentCustomer}/${model}`);
};

const getVoucherByCodeFromServer = async (code) => {
  return await getAxiosRequest().get(`/Voucher/${code}`);
};

const getCurrentUser = async () => {
  return await getAxiosRequest().get("/user");
};

const getLedgerBalanceCall = async () => {
  return await getAxiosRequest().get("/customer/ledgerbalance");
};

// Method to update the current user profile
const UpdateUserProfile = async (model) => {
  return await getAxiosRequest().put("/user", model);
};

const sendInvite = async (model) => {
  var config = {};
  return await getAxiosRequest().post("/account/invite", model, config);
};

const deleteMember = async (id) => {
  return await getAxiosRequest().delete(`/customer/customeruser?id=${id}`);
};

/**
 * Get Current Customer
 */
function* getCurrentCustomer() {
  try {
    // call api to generate magic link
    const [customerData, costData, licensesData] = yield all([
      call(getCurrentCustomerFromServer),
      call(getCurrentCustomerCosts),
      call(getCurrentCustomerDomainLicenses),
    ]);

    var response = {
      customer: customerData.data,
      costs: costData.data,
      licenses: licensesData.data,
    };

    yield put(getCurrentCustomerSuccess(response));
  } catch (error) {
    console.log("error", error);
    yield put(getCurrentCustomerFailure(error.response.data.error));
  }
}
/**
 * Save Customer basic info
 */
function* saveCustomerBasicInfo() {
  try {
    // get admin profile
    let model = yield select(manageaccount); // <-- get the model

    const response = yield call(
      saveCustomerBasicInfoToServer,
      model.currentCustomer
    );
    yield put(saveCustomerBasicInfoSuccess(response));
    // refresh the customer information with server details
    try {
      // call api to get customer details
      const [customerData, costData, licensesData] = yield all([
        call(getCurrentCustomerFromServer),
        call(getCurrentCustomerCosts),
        call(getCurrentCustomerDomainLicenses),
      ]);

      var refreshResponse = {
        customer: customerData.data,
        costs: costData.data,
        licenses: licensesData.data,
      };

      yield put(getCurrentCustomerSuccess(refreshResponse));
    } catch (error) {
      yield put(getCurrentCustomerFailure(error.response.data.error));
    }
  } catch (error) {
    yield put(saveCustomerBasicInfoFailure(error.response.data.error));
  }
}

/**
 * Register new domains for customer
 */
function* registerNewDomains() {
  try {
    // get new domain list
    let model = yield select(manageaccount); // <-- get the model
    const response = yield call(
      registerNewDomainsForCustomer,
      model.updatedCustomer
    );
    yield put(registerNewDomainsSuccess(response));

    // refresh the customer information with server details
    try {
      // call api to get customer details

      const [customerData, costData, licensesData] = yield all([
        call(getCurrentCustomerFromServer),
        call(getCurrentCustomerCosts),
        call(getCurrentCustomerDomainLicenses),
      ]);

      var refreshResponse = {
        customer: customerData.data,
        costs: costData.data,
        licenses: licensesData.data,
      };

      yield put(getCurrentCustomerSuccess(refreshResponse));
    } catch (error) {
      yield put(getCurrentCustomerFailure(error.response.data.error));
    }
  } catch (error) {
    if (error.response) {
      yield put(registerNewDomainsFailure(error.response.data.error));
    } else {
      yield put(registerNewDomainsFailure(error));
    }
  }
}

/**
 * Get Current Customer Vouchers
 */
function* getCurrentCustomerVouchers() {
  try {
    // call api to generate magic link
    const response = yield call(getCurrentCustomerVouchersFromServer);
    yield put(getCurrentCustomerVouchersSuccess(response));
  } catch (error) {
    yield put(getCurrentCustomerVouchersFailure(error.response.data.error));
  }
}

/**
 * Create new manual voucher
 */
function* createVoucher() {
  try {
    // get admin profile
    let model = yield select(manageaccount); // <-- get the model

    const response = yield call(createManualVoucher, model.voucher);
    yield put(createManualVouchersSuccess(response));
    //Refresh voucher list
    try {
      // call api to get vouchers details
      const fetchResponse = yield call(getVouchersFromServer);
      yield put(getVouchersSuccess(fetchResponse));
    } catch (fetchError) {
      yield put(getVouchersFailure(fetchError.response.data.error));
    }
  } catch (error) {
    yield put(createManualVouchersFailure(error.response.data.error));
  }
}

/**
 * Redeem voucher for customer
 */
function* redeemVoucher() {
  try {
    // get new domain list
    let model = yield select(manageaccount); // <-- get the model
    const response = yield call(redeemVoucherForCustomer, model.voucher);
    yield put(redeemVoucherSuccess(response));
    // refresh the customer information with server details
    // refresh the customer information with server details
    try {
      // call api to get customer details

      const [customerData, costData, licensesData] = yield all([
        call(getCurrentCustomerFromServer),
        call(getCurrentCustomerCosts),
        call(getCurrentCustomerDomainLicenses),
      ]);

      var refreshResponse = {
        customer: customerData.data,
        costs: costData.data,
        licenses: licensesData.data,
      };

      yield put(getCurrentCustomerSuccess(refreshResponse));
    } catch (error) {
      yield put(getCurrentCustomerFailure(error.response.data.error));
    }
  } catch (error) {
    yield put(redeemVoucherFailure(error.response.data.error));
  }
}

/**
 * Get  Vouchers
 */
function* getVouchers() {
  try {
    // call api to generate magic link
    const response = yield call(getVouchersFromServer);
    yield put(getVouchersSuccess(response));
  } catch (error) {
    yield put(getVouchersFailure(error.response.data.error));
  }
}

/**
 * Get  Voucher by code
 */
function* getVoucherByCode() {
  try {
    let model = yield select(manageaccount); // <-- get the model
    const response = yield call(getVoucherByCodeFromServer, model.voucher);
    yield put(getVoucherByCodeSuccess(response));
  } catch (error) {
    yield put(getVoucherByCodeFailure(error.response.data.error));
  }
}

/**
 * Send invitation to onboard a team member
 */
function* sendInviteToTeamMember() {
  try {
    // get admin profile
    let model = yield select(manageaccount); // <-- get the model

    const response = yield call(sendInvite, model.inviteModel);
    yield put(inviteTeamMemberSuccess(response));
  } catch (error) {
    yield put(inviteTeamMemberFailure(error.response.data.error));
  }
}

/**
 * Delete an existing team member
 */
function* deleteTeamMember() {
  try {
    // get admin profile
    let model = yield select(manageaccount); // <-- get the model

    const response = yield call(deleteMember, model.selectedTeamMember);
    yield put(deleteTeamMemberSuccess(response));
    // refresh the customer information with server details
    try {
      // call api to get customer details

      const [customerData, costData, licensesData] = yield all([
        call(getCurrentCustomerFromServer),
        call(getCurrentCustomerCosts),
        call(getCurrentCustomerDomainLicenses),
      ]);

      var refreshResponse = {
        customer: customerData.data,
        costs: costData.data,
        licenses: licensesData.data,
      };

      yield put(getCurrentCustomerSuccess(refreshResponse));
    } catch (error) {
      yield put(getCurrentCustomerFailure(error.response.data.error));
    }
  } catch (error) {
    yield put(deleteTeamMemberFailure(error.response.data.error));
  }
}

function* getUser() {
  try {
    // call api to generate magic link
    const response = yield call(getCurrentUser);
    yield put(getUserProfileSuccess(response.data));
  } catch (error) {}
}

/**
 * Update current user profile
 */
function* updateUser() {
  try {
    let model = yield select(manageaccount); // <-- get the model
    const response = yield call(UpdateUserProfile, model.updateUser);
    yield put(updateUserProfileSuccess(response));
    try {
      // call api to generate magic link
      const userResponse = yield call(getCurrentUser);
      yield put(getUserProfileSuccess(userResponse.data));
    } catch (error) {}
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return null;
    }
    yield put(updateUserProfileFailure(error));
  }
}

function* ledgerBalance() {
  try {
    const response = yield call(getLedgerBalanceCall);
    if (response) {
      yield put(getLedgerBalanceSuccess(response.data));
    }
  } catch (error) {
    yield put(getLedgerBalanceFailure(error));
  }
}

/**
 * Add new domain license
 */
function* addDomainLicense() {
  try {
    // get admin profile
    let model = yield select(manageaccount); // <-- get the model

    const response = yield call(addLicense, model.addDomainLicenseModel);
    yield put(addDomainLicenseSuccess(response));

    const [customerData, costData, licensesData] = yield all([
      call(getCurrentCustomerFromServer),
      call(getCurrentCustomerCosts),
      call(getCurrentCustomerDomainLicenses),
    ]);

    var customerResponse = {
      customer: customerData.data,
      costs: costData.data,
      licenses: licensesData.data,
    };

    yield put(getCurrentCustomerSuccess(customerResponse));
  } catch (error) {
    yield put(addDomainLicenseFailure(error.response.data.error));
  }
}

/**
 * Deactive  domain license
 */
function* deactivateDomainLicense() {
  try {
    // get admin profile
    let model = yield select(manageaccount); // <-- get the model

    const response = yield call(updateLicense, model.updateDomainLicenseModel);
    yield put(updateDomainLicenseSuccess(response));
    const [customerData, costData, licensesData] = yield all([
      call(getCurrentCustomerFromServer),
      call(getCurrentCustomerCosts),
      call(getCurrentCustomerDomainLicenses),
    ]);

    var customerResponse = {
      customer: customerData.data,
      costs: costData.data,
      licenses: licensesData.data,
    };

    yield put(getCurrentCustomerSuccess(customerResponse));
  } catch (error) {
    yield put(updateDomainLicenseFailure(error.response.data.error));
  }
}

function* purchaseCreditsGenerator() {
  try {
    let reducerData = yield select(manageaccount);
    let model = reducerData.creditToken;
    let response = yield call(purchaseCreditsFromServer, model);
    if (response) {
      yield put(purchaseCreditsSuccess(response.data));
    }
  } catch (error) {
    yield put(purchaseCreditsFailure(error));
  }
}

function* getCreditsListGenertor() {
  try {
    let response = yield call(getCreditsListFromServer);
    if (response) {
      yield put(getCreditsListSuccess(response.data));
    }
  } catch (error) {
    yield put(getCreditsListFailure(error));
  }
}

export const manageAccountSagas = [
  takeEvery("GET_CURRENT_CUSTOMER", getCurrentCustomer),
  takeEvery("SAVE_CUSTOMER_BASIC_INFO", saveCustomerBasicInfo),
  takeEvery("REGISTER_NEW_DOMAINS", registerNewDomains),
  takeEvery("GET_CURRENT_CUSTOMER_VOUCHERS", getCurrentCustomerVouchers),
  takeEvery("REDEEM_VOUCHER", redeemVoucher),
  takeEvery("GET_VOUCHERS", getVouchers),
  takeEvery("CREATE_MANUAL_VOUCHER", createVoucher),
  takeEvery("GET_VOUCHER_BY_CODE", getVoucherByCode),
  takeEvery("INVITE_TEAMMEMBER", sendInviteToTeamMember),
  takeEvery("GET_USER_PROFILE", getUser),
  takeEvery("UPDATE_USER_PROFILE", updateUser),
  takeEvery(GET_LEDGER_BALANCE, ledgerBalance),
  takeEvery("DELETE_TEAMMEMBER", deleteTeamMember),
  takeEvery("ADD_DOMAIN_LICENSE", addDomainLicense),
  takeEvery("UPDATE_DOMAIN_LICENSE", deactivateDomainLicense),
  takeEvery(PURCHASE_CREDITS, purchaseCreditsGenerator),
  takeEvery(GET_CREDITS_LIST, getCreditsListGenertor),
];

/**
 * Audiences Root Saga
 */
export default function* rootSaga() {
  yield all([...manageAccountSagas]);
}
