/* eslint-disable array-callback-return */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addConnectorFailure,
  addConnectorSuccess,
  deleteConnectorFailure,
  deleteConnectorSuccess,
  getConnectorRundataFailure,
  getConnectorRundataSuccess,
  getConnectorsFailure,
  getConnectorsSuccess,
  getConnectorTypesFailure,
  getConnectorTypesSuccess,
  testConnectorFailure,
  testConnectorSuccess,
  updateConnectorFailure,
  updateConnectorSuccess,
} from "../actions/index";
import {
  ADD_CONNECTOR,
  DELETE_CONNECTOR,
  GET_CONNECTORS,
  GET_CONNECTOR_RUNDATA,
  GET_CONNECTOR_TYPES,
  TEST_CONNECTOR,
  UPDATE_CONNECTOR,
} from "../actions/types";
import { getAxiosRequest } from "../helpers/helpers";

const getReducerData = (state) => state.connectorsReducer;
export const authUser = (state) => state.authUser;

const getConnectorsCall = async (cId) => {
  let result = await getAxiosRequest().get(`/manage/Connector/Customer/${cId}`);
  return result;
};

const getConnectorTypesCall = async () => {
  let result = await getAxiosRequest().get("manage/Connector/Types");
  return result;
};

const addConnectorCall = async (model) => {
  let result = await getAxiosRequest().post("/manage/Connector", model);

  return result;
};

const updateConnectorCall = async (model) => {
  let result = await getAxiosRequest().put("/manage/Connector", model);

  return result;
};

const deleteConnectorCall = async (model) => {
  // let result = await getAxiosRequest().delete(
  //   `/manage/Connector?id=${model.id}&disable=${model.isDisable}`
  // );
  let connector = { ...model.connector, isActive: false };
  let result = await getAxiosRequest().put("/manage/Connector", connector);

  return result;
};
const testConnectorCall = async (model) => {
  let result = await getAxiosRequest().get(
    `/manage/Connector/Test?id=${model.id}`
  );
  return result;
};

const getRundataCall = async (model) => {
  let result = await getAxiosRequest().get(
    `manage/Connector/RunData?CustomerId=${model.customerID}&FromDateTime=${model.fromDate}&ToDateTime=${model.toDate}`
  );
  return result;
};

function* connectors() {
  try {
    let user = yield select(authUser);
    // user.currentDomain
    let response = yield call(getConnectorsCall, user.currentDomain.customerId);
    let connectorTypes = yield call(getConnectorTypesCall);
    if (response) {
      let types = {};
      connectorTypes.data.map((t) => {
        types[t.id] = t.value;
      });
      let connector = response.data;
      if (connector) {
        connector.config = JSON.parse(connector.config);
      }
      yield put(
        getConnectorsSuccess({
          connector: response.data,
          connectorTypes: types,
        })
      );
    }
  } catch (error) {
    yield put(getConnectorsFailure(error));
  }
}

function* connectorTypes() {
  try {
    // user.currentDomain
    let response = yield call(getConnectorTypesCall);
    if (response) {
      let types = {};
      response.data.map((t) => {
        types[t.id] = t.value;
      });
      yield put(getConnectorTypesSuccess(types));
    }
  } catch (error) {
    console.log(error);
    yield put(getConnectorTypesFailure(error));
  }
}

function* addConnector() {
  try {
    const reducer = yield select(getReducerData);
    let user = yield select(authUser);
    // user.currentDomain
    let model = {
      customerId: parseInt(user.currentDomain.customerId),
      ...reducer.connector,
    };
    let response = yield call(addConnectorCall, model);
    if (response) {
      yield put(addConnectorSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(addConnectorFailure(error));
  }
}

function* updateConnector() {
  try {
    const reducer = yield select(getReducerData);
    let user = yield select(authUser);
    // user.currentDomain
    let model = {
      customerId: parseInt(user.currentDomain.customerId),
      ...reducer.connector,
    };
    let response = yield call(updateConnectorCall, model);
    if (response) {
      yield put(updateConnectorSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(updateConnectorFailure(error));
  }
}

function* deleteConnector() {
  try {
    const reducer = yield select(getReducerData);
    let user = yield select(authUser);
    // user.currentDomain

    let model = {
      customerId: parseInt(user.currentDomain.customerId),
      connector: reducer.connectorToDelete,
    };
    let response = yield call(deleteConnectorCall, model);
    if (response) {
      yield put(deleteConnectorSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(deleteConnectorFailure(error));
  }
}

function* testConnector() {
  try {
    const reducer = yield select(getReducerData);
    // user.currentDomain
    let model = {
      id: reducer.connectorID,
    };
    let response = yield call(testConnectorCall, model);
    if (response) {
      yield put(testConnectorSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(testConnectorFailure(error));
  }
}

function* getRundata() {
  try {
    const reducer = yield select(getReducerData);
    let user = yield select(authUser);
    // user.currentDomain
    let model = {
      customerID: parseInt(user.currentDomain.customerId),
      ...reducer.querryData,
    };
    let response = yield call(getRundataCall, model);
    if (response) {
      yield put(getConnectorRundataSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(getConnectorRundataFailure(error));
  }
}

export const connectorsSaga = [
  takeEvery(GET_CONNECTORS, connectors),
  takeEvery(GET_CONNECTOR_TYPES, connectorTypes),
  takeEvery(ADD_CONNECTOR, addConnector),
  takeEvery(UPDATE_CONNECTOR, updateConnector),
  takeEvery(DELETE_CONNECTOR, deleteConnector),
  takeEvery(TEST_CONNECTOR, testConnector),
  takeEvery(GET_CONNECTOR_RUNDATA, getRundata),
];

export default function* rootSaga() {
  yield all([...connectorsSaga]);
}
