/**
 * App Light Theme
 */
import { createMuiTheme } from '@material-ui/core/styles';
import AppConfig from '../../constants/AppConfig';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: AppConfig.themeColors.primary
        },
        secondary: {
            main: AppConfig.themeColors.secondary
        },
        info: {
            main: AppConfig.themeColors.info
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Poppins'
          ].join(','),
      },
      overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "0.8em",
          color: "white",
          backgroundColor: AppConfig.themeColors.primary,
          
        }
      }
    }
});

export default theme;