import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  deleteSegmentFailure,
  deleteSegmentSuccess,
  getSegments,
  getSegmentsFailure,
  getSegmentsSuccess,
  saveSegmentFailure,
  saveSegmentSuccess,
  updateSegmentsFailure,
  updateSegmentsSuccess,
} from "../actions/SegmentsAction";
import {
  DELETE_SEGMENT,
  GET_SEGMENTS,
  SAVE_SEGMENT,
  UPDATE_SEGMENT,
} from "../actions/types";
import { getAxiosRequest } from "../helpers/helpers";

const segments = (state) => state.segmentsReducer;
export const authUser = (state) => state.authUser;

const postSegmentToServer = async (segment) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: segment.domainId },
  };

  return await getAxiosRequest().post("manage/domain/segment", segment, config);
};

const getSegmentsFromServer = async (id) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: id },
  };

  return await getAxiosRequest().get("manage/domain/segment", config);
};

const getSegmentCounts = async () => {
  return await getAxiosRequest().get("/user/segmentcount");
};

const updateSegmentsFromServer = async (segment) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: segment.domainId },
  };
  return await getAxiosRequest().put("manage/domain/segment", segment, config);
};
const deleteSegmentFromServer = async (modal) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: modal.domainId },
  };
  return await getAxiosRequest().delete(
    `manage/domain/segment?id=${modal.id}`,
    config
  );
};

function* saveSegmentCall() {
  let segment = yield select(segments);
  let customer = yield select(authUser);
  let segmentModal = {
    // origin: "origin",
    name: segment.title,
    weight: 0,
    isPublic: true,
    cost: 0,
    isDomainSpecific: segment.isDomainSpecific,
    isCustomerSpecific: segment.isCustomerSpecific,
    isAdminOnly: segment.isAdminOnly,
    customerId: customer.currentDomain.customerId,
    customerName: customer.currentDomain.customerName,
    domainId: customer.currentDomain.id,
    domain: customer.currentDomain.domain,
    // createdAt: "2019-11-29T19:57:39.197Z",
    // createdBy: "string",
    // lastUpdatedAt: "2019-11-29T19:57:39.197Z",
    // lastUpdatedBy: "string",
    isActive: true,
  };
  try {
    const response = yield call(postSegmentToServer, segmentModal);
    if (response) {
      yield put(saveSegmentSuccess());
    }
  } catch (error) {
    yield put(saveSegmentFailure(error));
  }
}
function* getSegmentsCall() {
  let customer = yield select(authUser);
  try {
    const [segments, segmentCounts] = yield all([
      call(getSegmentsFromServer, customer.currentDomain.id),
      call(getSegmentCounts),
    ]);
    var response = { segments: segments.data, counts: segmentCounts.data };

    if (response) {
      yield put(getSegmentsSuccess(response));
    }
  } catch (error) {
    yield put(getSegmentsFailure(error));
  }
}
function* updateSegmentsCall() {
  let segment = yield select(segments);
  segment = segment.selectedSegment;
  let customer = yield select(authUser);

  let segmentModal = {
    // origin: "origin",
    id: segment.id,
    name: segment.name,
    weight: 0,
    isPublic: true,
    cost: 0,
    isDomainSpecific: segment.isDomainSpecific,
    isCustomerSpecific: segment.isCustomerSpecific,
    isAdminOnly: segment.isAdminOnly,
    customerId: customer.currentDomain.customerId,
    customerName: customer.currentDomain.customerName,
    domainId: customer.currentDomain.id,
    domain: customer.currentDomain.domain,
    // createdAt: "2019-11-29T19:57:39.197Z",
    // createdBy: "string",
    // lastUpdatedAt: "2019-11-29T19:57:39.197Z",
    // lastUpdatedBy: "string",
    isActive: true,
  };
  try {
    const response = yield call(updateSegmentsFromServer, segmentModal);
    if (response) {
      yield put(updateSegmentsSuccess(response.data));
    }
  } catch (error) {
    yield put(updateSegmentsFailure(error));
  }
}
function* deleteSegmentCall() {
  let segment = yield select(segments);
  let modal = segment.segmentToDelete;

  try {
    const response = yield call(deleteSegmentFromServer, modal);
    if (response) {
      yield put(getSegments());
      yield put(deleteSegmentSuccess(response.data));
    }
  } catch (error) {
    yield put(deleteSegmentFailure(error));
  }
}

export const segmentsSagas = [
  takeEvery(SAVE_SEGMENT, saveSegmentCall),
  takeEvery(GET_SEGMENTS, getSegmentsCall),
  takeEvery(UPDATE_SEGMENT, updateSegmentsCall),
  takeEvery(DELETE_SEGMENT, deleteSegmentCall),

  //takeEvery('GET_CONTENT_DOMAINS', getContentDomains),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...segmentsSagas]);
}
