/**
 * TCF configuration Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addTCFConfigurationFailure,
  addTCFConfigurationSuccess,
  deleteTCFConfigurationFailure,
  deleteTCFConfigurationSuccess,
  fetchTCFCMPConfigByIdFailure,
  fetchTCFCMPConfigByIdSuccess,
  fetchTCFCMPtemplateFaliure,
  fetchTCFCMPtemplateSuccess,
  getMetadataTCFFailure,
  getMetadataTCFSuccess,
  getTCFConfigurationsFailure,
  getTCFConfigurationsSuccess,
  getTCFTemplatesFailure,
  getTCFTemplatesSuccess,
  refreshTCFConfigurations,
} from "../actions";
import {
  FETCH_TCFCMP_CONIFG_BY_ID,
  FETCH_TCFCMP_TEMPLATE,
  GET_METADATA_TCF,
  GET_TCFCONFIGURATIONS,
  GET_TCFTEMPLATES,
} from "../actions/types";
import { getAxiosRequest } from "../helpers/helpers";

export const configurations = (state) => state.tcfConfigurationReducer;
export const authUser = (state) => state.authUser;

const getTCFConfigurationsFromContainer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain.id },
  };
  return await getAxiosRequest().get(
    `/cmpconfigurator/tcfconfig/${currentDomain.customerId}`,
    config
  );
};

const getTCFTemplateFromContainer = async (currentDomain, path) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain.id },
  };
  return await getAxiosRequest().get(
    "/cmpconfigurator/templates/tcf/" + path,
    config
  );
};

const getTCFConfigurationsTemplates = async () => {
  var config = {};

  return await getAxiosRequest().get(`/cmpconfigurator/templates/tcf`, config);
};
const getTCFConfigurationsSdk = async () => {
  var config = {};

  return await getAxiosRequest().get(`/cmpconfigurator/sdk`, config);
};
const getTCFConfigurationsbundles = async () => {
  var config = {};

  return await getAxiosRequest().get(`/cmpconfigurator/cmpbundle`, config);
};

const addTCFConfigurationToContainer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain, "content-type": "application/json" },
  };

  return await getAxiosRequest().post(
    `/cmpconfigurator/tcfconfig`,
    model,
    config
  );
};

const fetchTCFConfigurationFromContainer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain, "content-type": "application/json" },
  };

  return await getAxiosRequest().get(`/cmpconfigurator/${model.id}`, config);
};

const removeTCFConfigurationFromContainer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  let path = model.path.replace("tcf/", "");
  return await getAxiosRequest().delete(
    `/cmpconfigurator/TCFconfig/${path}`,
    config
  );
};

/**
 * Get TCFConfigurations
 */
function* getTCFConfigurations() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    const response = yield call(
      getTCFConfigurationsFromContainer,
      user.currentDomain
    );
    yield put(getTCFConfigurationsSuccess(response));
  } catch (error) {
    yield put(getTCFConfigurationsFailure(error.response.data.error));
  }
}
/**
 * Add TCF Configuration
 */
function* addTCFConfiguration() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(configurations); // <-- get the model

    var postModel = {
      customerId: parseInt(user.currentDomain.customerId),
      domain: user.currentDomain.id,
      title: model.configTitle,
      type: 1,
      template: model.templatePath ? model.templatePath : "NA",
      sdkVersion: model.sdkVersion,
      bundleVersion: model.bundleVersion,
      config: JSON.stringify(model.configuration),
      mapper: JSON.stringify(model.mapper),
    };

    const response = yield call(addTCFConfigurationToContainer, postModel);
    yield put(addTCFConfigurationSuccess(response));
  } catch (error) {
    yield put(addTCFConfigurationFailure(error.response.data.error));
  }
}

/**
 * delete TCF Configuration
 */
function* deleteTCFConfiguration() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(configurations); // <-- get the model
    var modelToDelete = {
      ...model.configFolderNameToDelete,
    };
    const response = yield call(
      removeTCFConfigurationFromContainer,
      modelToDelete
    );
    yield put(deleteTCFConfigurationSuccess(response));
    const refreshResponse = yield call(
      getTCFConfigurationsFromContainer,
      user.currentDomain
    );
    yield put(refreshTCFConfigurations(refreshResponse));
  } catch (error) {
    yield put(deleteTCFConfigurationFailure(error.response.data.error));
  }
}

function* getTCFTemplates() {
  try {
    let response = yield call(getTCFConfigurationsTemplates);
    if (response) {
      yield put(getTCFTemplatesSuccess(response.data));
    }
  } catch (error) {
    console.log("error", error);
    yield put(getTCFTemplatesFailure(error));
  }
}

function* fetchTCFCMPTemplatesSaga() {
  try {
    let TCFCMPReducer = yield select(configurations);
    let user = yield select(authUser); //
    let path = TCFCMPReducer.templatePath;
    let response = yield call(
      getTCFTemplateFromContainer,
      user.currentDomain,
      path
    );
    if (response) {
      yield put(fetchTCFCMPtemplateSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchTCFCMPtemplateFaliure(error));
  }
}

function* getMetadataTCFCMP() {
  try {
    const [templates, sdk, bundles] = yield all([
      call(getTCFConfigurationsTemplates),
      call(getTCFConfigurationsSdk),
      call(getTCFConfigurationsbundles),
    ]);
    let obj = {
      templates: templates.data,
      sdkFiles: sdk.data,
      bundles: bundles.data,
    };
    yield put(getMetadataTCFSuccess(obj));
  } catch (error) {
    console.log(error);
    yield put(getMetadataTCFFailure());
  }
}

function* getTCFConfigurationByID() {
  try {
    let TCFCMPReducer = yield select(configurations);
    let user = yield select(authUser);

    let model = {
      customerId: user.currentDomain.customerId,
      id: TCFCMPReducer.referenceConfigFolderName,
    };
    let response = yield call(fetchTCFConfigurationFromContainer, model);
    if (response) {
      yield put(fetchTCFCMPConfigByIdSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchTCFCMPConfigByIdFailure(error));
  }
}

export const TCFConfigurationSagas = [
  takeEvery(GET_TCFCONFIGURATIONS, getTCFConfigurations),
  takeEvery("ADD_TCFCONFIGURATION", addTCFConfiguration),
  takeEvery("DELETE_TCFCONFIGURATION", deleteTCFConfiguration),
  takeEvery(GET_TCFTEMPLATES, getTCFTemplates),
  takeEvery(FETCH_TCFCMP_TEMPLATE, fetchTCFCMPTemplatesSaga),
  takeEvery(GET_METADATA_TCF, getMetadataTCFCMP),
  takeEvery(FETCH_TCFCMP_CONIFG_BY_ID, getTCFConfigurationByID),
];

/**
 * TCF Configuration Root Saga
 */
export default function* rootSaga() {
  yield all([...TCFConfigurationSagas]);
}
