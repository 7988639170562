import {
  ADD_COOKIES_DESCRIPTIONS,
  ADD_COOKIES_DESCRIPTIONS_FAILURE,
  ADD_COOKIES_DESCRIPTIONS_SUCCESS,
  DELETE_COOKIES_DESCRIPTIONS,
  DELETE_COOKIES_DESCRIPTIONS_FAILURE,
  DELETE_COOKIES_DESCRIPTIONS_SUCCESS,
  FILTER_COOKIES_DESCRIPTION,
  GET_COOKIES_DESCRIPTIONS,
  GET_COOKIES_DESCRIPTIONS_FAILURE,
  GET_COOKIES_DESCRIPTIONS_SUCCESS,
  GET_COOKIES_SCAN_HISTORY,
  GET_COOKIES_SCAN_HISTORY_FAILURE,
  GET_COOKIES_SCAN_HISTORY_SUCCESS,
  GET_COOKIE_SCAN_LANGAUGES,
  GET_COOKIE_SCAN_LANGAUGES_FAILURE,
  GET_COOKIE_SCAN_LANGAUGES_SUCCESS,
  GET_SCAN_COOKIES_REPORT,
  GET_SCAN_COOKIES_REPORT_FAILURE,
  GET_SCAN_COOKIES_REPORT_SUCCESS,
  NEW_SCAN_COOKIES_REPORT,
  SCAN_NON_GRAVITO_DOMAIN,
  SCAN_NON_GRAVITO_DOMAIN_FAILURE,
  SCAN_NON_GRAVITO_DOMAIN_SUCCESS,
  SEARCH_COOKIES_DESCRIPTION,
  UPDATE_COOKIES_DESCRIPTIONS,
  UPDATE_COOKIES_DESCRIPTIONS_FAILURE,
  UPDATE_COOKIES_DESCRIPTIONS_SUCCESS,
} from "./types";

export const getCookieScanReport = (urls) => {
  return {
    type: GET_SCAN_COOKIES_REPORT,
    payload: urls,
  };
};
export const getCookieScanReportSucess = (data) => {
  return {
    type: GET_SCAN_COOKIES_REPORT_SUCCESS,
    payload: data,
  };
};

export const getCookieScanReportFailure = (error) => {
  return {
    type: GET_SCAN_COOKIES_REPORT_FAILURE,
    payload: error,
  };
};

export const newScanCookiesReport = () => {
  return {
    type: NEW_SCAN_COOKIES_REPORT,
  };
};

export const getCookieScanHistory = () => {
  return {
    type: GET_COOKIES_SCAN_HISTORY,
  };
};

export const getCookieScanHistorySuccess = (data) => {
  return {
    type: GET_COOKIES_SCAN_HISTORY_SUCCESS,
    payload: data,
  };
};
export const getCookieScanHistoryFailure = (error) => {
  return {
    type: GET_COOKIES_SCAN_HISTORY_FAILURE,
    payload: error,
  };
};

export const getCookieDescription = () => {
  return {
    type: GET_COOKIES_DESCRIPTIONS,
  };
};
export const getCookieDescriptionSuccess = (descriptions) => {
  return {
    type: GET_COOKIES_DESCRIPTIONS_SUCCESS,
    payload: descriptions,
  };
};
export const getCookieDescriptionFailure = (error) => {
  return {
    type: GET_COOKIES_DESCRIPTIONS_FAILURE,
    payload: error,
  };
};

export const addCookieDescription = (description) => {
  return {
    type: ADD_COOKIES_DESCRIPTIONS,
    payload: description,
  };
};
export const addCookieDescriptionSuccess = (data) => {
  return {
    type: ADD_COOKIES_DESCRIPTIONS_SUCCESS,
    payload: data,
  };
};
export const addCookieDescriptionFailure = (error) => {
  return {
    type: ADD_COOKIES_DESCRIPTIONS_FAILURE,
    payload: error,
  };
};

export const updateCookieDescription = (description) => {
  return {
    type: UPDATE_COOKIES_DESCRIPTIONS,
    payload: description,
  };
};
export const updateCookieDescriptionSuccess = (data) => {
  return {
    type: UPDATE_COOKIES_DESCRIPTIONS_SUCCESS,
    payload: data,
  };
};
export const updateCookieDescriptionFailure = (error) => {
  return {
    type: UPDATE_COOKIES_DESCRIPTIONS_FAILURE,
    payload: error,
  };
};

export const deleteCookieDescription = (description) => {
  return {
    type: DELETE_COOKIES_DESCRIPTIONS,
    payload: description,
  };
};
export const deleteCookieDescriptionSuccess = (data) => {
  return {
    type: DELETE_COOKIES_DESCRIPTIONS_SUCCESS,
    payload: data,
  };
};
export const deleteCookieDescriptionFailure = (error) => {
  return {
    type: DELETE_COOKIES_DESCRIPTIONS_FAILURE,
    payload: error,
  };
};

export const searchCookieDescription = (value) => {
  return {
    type: SEARCH_COOKIES_DESCRIPTION,
    payload: value,
  };
};
export const filterCookieDescription = (type) => {
  return {
    type: FILTER_COOKIES_DESCRIPTION,
    payload: type,
  };
};

export const scanNonGravitoDomain = (domainObj) => {
  return {
    type: SCAN_NON_GRAVITO_DOMAIN,
    payload: domainObj,
  };
};

export const scanNonGravitoDomainSuccess = (report) => {
  return {
    type: SCAN_NON_GRAVITO_DOMAIN_SUCCESS,
    payload: report,
  };
};

export const scanNonGravitoDomainFailure = (error) => {
  return {
    type: SCAN_NON_GRAVITO_DOMAIN_FAILURE,
    payload: error,
  };
};
export const getCookiesScanLanguages = () => {
  return {
    type: GET_COOKIE_SCAN_LANGAUGES,
  };
};

export const getCookiesScanLanguagesSuccess = (languages) => {
  return {
    type: GET_COOKIE_SCAN_LANGAUGES_SUCCESS,
    payload: languages,
  };
};

export const getCookiesScanLanguagesFailure = (error) => {
  return {
    type: GET_COOKIE_SCAN_LANGAUGES_FAILURE,
    payload: error,
  };
};
