/**
 * Auth Actions for config actions such as upload users using csv files. Futher functionalities will get added later.
 */
import {
    UPLOAD_USERS,
    UPLOAD_USERS_FAILURE,
    UPLOAD_USERS_SUCCESS
} from './types';

/**
 * Redux Action To upload users using csv templates
 */

export const uploadUsers=(payload)=>({
    type: UPLOAD_USERS,
    payload: payload
})

/**
 * Redux Action for successful uploading of users.
 */

export const uploadUsersSuccess =(response)=>({
    type: UPLOAD_USERS_SUCCESS,
    payload: response
})
/**
 * Redux Action for failure in uploading the users. 
 */

export const uploadUsersFailure =(error)=>({
    type: UPLOAD_USERS_FAILURE,
    payload: error
})

