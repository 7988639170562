/**
 * App Routes
 */
import $ from "jquery";
import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { CircularProgress } from "@material-ui/core";

import { getHelpContent } from "../../actions";
import HelpComponent from "../../routes/components/helpComponent";
// Components
import HeaderMenu from "../Header/Header";
import SubHeader from "../SubHeader";
const MainApp = (props) => {
  const [state, setState] = useState({
    windowWidth: $(window).width(),
    windowHeight: $(window).height(),
    isHelpDrawerOpen: false,
  });

  const domainDependentRoutes = [
    "audiences",
    "contents",
    "campaigns",
    "segments",
    "constellations",
    "campaigndashboard",
  ];

  useEffect(() => {
    if (props.currentPath) {
      // if (props.currentPath === "lightconfig") {
      //   props.getHelpContent("ligthCMPConfigV2");
      //   return;
      // }
      props.getHelpContent(props.currentPath);
    }
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return function cleanup() {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [props.currentPath]);

  const updateDimensions = () => {
    setState({
      ...state,
      ["windowWidth"]: $(window).width(),
      ["windowHeight"]: $(window).height(),
    });
  };

  const handleHelpDrawerToggle = () => {
    setState({ ...state, isHelpDrawerOpen: !state.isHelpDrawerOpen });
  };
  const closeHelpDrawer = () => {
    setState({ ...state, isHelpDrawerOpen: false });
  };

  const renderPage = () => {
    const { children } = props;

    return (
      <div className="content">
        {/* {domainDependentRoutes.includes(props.currentPath)?<SubHeader callback={refreshCallback}/>:null} */}
        {children}
      </div>
    );
  };

  const refreshCallback = () => {
    props.refresh();
  };

  return (
    <div className="container-fluid">
      <div className="wrapper">
        <HeaderMenu
          callback={refreshCallback}
          includeDomain={domainDependentRoutes.includes(props.currentPath)}
        />
        {renderPage()}
        <Drawer
          anchor={"right"}
          open={state.isHelpDrawerOpen}
          variant="temporary"
          onClose={closeHelpDrawer}
        >
          <div
            style={{
              width: "50vw",
              minWidth: "200px",
              height: "100vh",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Tooltip
              id="tooltip-icon"
              placement="bottom"
              title={<div>Close</div>}
            >
              <IconButton
                id="closeHelp"
                className="text-info iconbtn"
                aria-label="Close help"
                style={{ position: "fixed", right: 20, top: 0 }}
                onClick={closeHelpDrawer}
              >
                <i className=" fa fa-close deletebutton"></i>
              </IconButton>
            </Tooltip>

            {props.contentLoading ? null : props.contentData &&
              props.contentData.items[0] ? (
              <HelpComponent helpContent={props.contentData}></HelpComponent>
            ) : null}
          </div>
        </Drawer>
        {/* <Footer/> */}
        <button
          onClick={handleHelpDrawerToggle}
          className="btn helpButton transform-90"
          style={{ position: "fixed", top: "50vh" }}
        >
          Guide me{" "}
          {state.isHelpDrawerOpen ? (
            <i className="ml-10 fa fa-arrow-up"></i>
          ) : (
            <i className="ml-10 fa fa-arrow-down"></i>
          )}
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ helpReducer }) => {
  return { ...helpReducer };
};

export default connect(mapStateToProps, {
  getHelpContent,
})(MainApp);
//export default MainApp
