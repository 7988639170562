import {
  GET_CONNECTORS,
  GET_CONNECTORS_FAILURE,
  GET_CONNECTORS_SUCCESS,
  GET_CONNECTOR_TYPES,
  GET_CONNECTOR_TYPES_FAILURE,
  GET_CONNECTOR_TYPES_SUCCESS,
  ADD_CONNECTOR,
  ADD_CONNECTOR_FAILURE,
  ADD_CONNECTOR_SUCCESS,
  UPDATE_CONNECTOR,
  UPDATE_CONNECTOR_FAILURE,
  UPDATE_CONNECTOR_SUCCESS,
  DELETE_CONNECTOR,
  DELETE_CONNECTOR_FAILURE,
  DELETE_CONNECTOR_SUCCESS,
  TEST_CONNECTOR,
  TEST_CONNECTOR_FAILURE,
  TEST_CONNECTOR_SUCCESS,
  GET_CONNECTOR_RUNDATA,
  GET_CONNECTOR_RUNDATA_FAILURE,
  GET_CONNECTOR_RUNDATA_SUCCESS,
} from "./types";

export const getConnectors = (customerId) => {
  return {
    type: GET_CONNECTORS,
    payload: customerId,
  };
};
export const getConnectorsSuccess = (connector) => {
  return {
    type: GET_CONNECTORS_SUCCESS,
    payload: connector,
  };
};
export const getConnectorsFailure = (error) => {
  return {
    type: GET_CONNECTORS_FAILURE,
    payload: error,
  };
};

export const getConnectorTypes = () => {
  return {
    type: GET_CONNECTOR_TYPES,
  };
};
export const getConnectorTypesSuccess = (connectorTypes) => {
  return {
    type: GET_CONNECTOR_TYPES_SUCCESS,
    payload: connectorTypes,
  };
};
export const getConnectorTypesFailure = (error) => {
  return {
    type: GET_CONNECTOR_TYPES_FAILURE,
    payload: error,
  };
};

export const addConnector = (connectors) => {
  return {
    type: ADD_CONNECTOR,
    payload: connectors,
  };
};
export const addConnectorSuccess = (data) => {
  return {
    type: ADD_CONNECTOR_SUCCESS,
    payload: data,
  };
};
export const addConnectorFailure = (error) => {
  return {
    type: ADD_CONNECTOR_FAILURE,
    payload: error,
  };
};

export const updateConnector = (connectors) => {
  return {
    type: UPDATE_CONNECTOR,
    payload: connectors,
  };
};
export const updateConnectorSuccess = (data) => {
  return {
    type: UPDATE_CONNECTOR_SUCCESS,
    payload: data,
  };
};
export const updateConnectorFailure = (error) => {
  return {
    type: UPDATE_CONNECTOR_FAILURE,
    payload: error,
  };
};

export const deleteConnector = (deleteData) => {
  return {
    type: DELETE_CONNECTOR,
    payload: deleteData,
  };
};
export const deleteConnectorSuccess = (data) => {
  return {
    type: DELETE_CONNECTOR_SUCCESS,
    payload: data,
  };
};
export const deleteConnectorFailure = (error) => {
  return {
    type: DELETE_CONNECTOR_FAILURE,
    payload: error,
  };
};

export const testConnector = (connectorID) => {
  return {
    type: TEST_CONNECTOR,
    payload: connectorID,
  };
};
export const testConnectorSuccess = (data) => {
  return {
    type: TEST_CONNECTOR_SUCCESS,
    payload: data,
  };
};
export const testConnectorFailure = (error) => {
  return {
    type: TEST_CONNECTOR_FAILURE,
    payload: error,
  };
};

export const getConnectorRundata = (querryData) => {
  return {
    type: GET_CONNECTOR_RUNDATA,
    payload: querryData,
  };
};
export const getConnectorRundataSuccess = (history) => {
  return {
    type: GET_CONNECTOR_RUNDATA_SUCCESS,
    payload: history,
  };
};
export const getConnectorRundataFailure = (error) => {
  return {
    type: GET_CONNECTOR_RUNDATA_FAILURE,
    payload: error,
  };
};
