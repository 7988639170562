/**
 * Redux Actions for managing TCF CMP configurations in CDN Container.
 */

import {
  GET_TCFCONFIGURATIONS,
  GET_TCFCONFIGURATIONS_SUCCESS,
  GET_TCFCONFIGURATIONS_FAILURE,
  ADD_TCFCONFIGURATION,
  ADD_TCFCONFIGURATION_SUCCESS,
  ADD_TCFCONFIGURATION_FAILURE,
  DELETE_TCFCONFIGURATION,
  DELETE_TCFCONFIGURATION_SUCCESS,
  DELETE_TCFCONFIGURATION_FAILURE,
  FILLTER_TCFCONFIGURATION,
  REFRESH_TCFCONFIGURATION,
  GET_TCFTEMPLATES,
  GET_TCFTEMPLATES_SUCCESS,
  GET_TCFTEMPLATES_FAILURE,
  GET_TCFVERSIONS,
  GET_TCFVERSIONS_SUCCESS,
  GET_TCFVERSIONS_FAILURE,
  SELECT_TCFTEMPLATE,
  SELECT_TCFVERSION,
  FETCH_TCFCMP_TEMPLATE,
  FETCH_TCFCMP_TEMPLATE_FAILURE,
  FETCH_TCFCMP_TEMPLATE_SUCCESS,
  GET_METADATA_TCF,
  GET_METADATA_TCF_FAILURE,
  GET_METADATA_TCF_SUCCESS,
  FETCH_TCFCMP_CONIFG_BY_ID,
  FETCH_TCFCMP_CONIFG_BY_ID_FAILURE,
  FETCH_TCFCMP_CONIFG_BY_ID_SUCCESS,
  SELECT_TCFCMP_CONFIGURATION,
} from "./types";

/**
 * Redux action to get TCFConfigurations
 */
export const getTCFConfigurations = () => ({
  type: GET_TCFCONFIGURATIONS,
});

/**
 * Redux action for successful fetching of TCFConfigurations
 */

export const getTCFConfigurationsSuccess = (response) => ({
  type: GET_TCFCONFIGURATIONS_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching TCFConfigurations
 */
export const getTCFConfigurationsFailure = (error) => ({
  type: GET_TCFCONFIGURATIONS_FAILURE,
  payload: error,
});

/**
 * Redux action to get TCFTemplates
 */
export const getTCFTemplates = () => ({
  type: GET_TCFTEMPLATES,
});

/**
 * Redux action for successful fetching of TCFTemplates
 */

export const getTCFTemplatesSuccess = (response) => ({
  type: GET_TCFTEMPLATES_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching TCFTemplates
 */
export const getTCFTemplatesFailure = (error) => ({
  type: GET_TCFTEMPLATES_FAILURE,
  payload: error,
});

/**
 * Redux action to get TCFVersions
 */
export const getTCFVersions = () => ({
  type: GET_TCFVERSIONS,
});

/**
 * Redux action for successful fetching of TCFVersions
 */

export const getTCFVersionsSuccess = (response) => ({
  type: GET_TCFVERSIONS_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching TCFVersions
 */
export const getTCFVersionsFailure = (error) => ({
  type: GET_TCFVERSIONS_FAILURE,
  payload: error,
});

/**
 * Redux action for fetching TCFconfigurations after add/delete of Application
 */
export const refreshTCFConfigurations = (response) => ({
  type: REFRESH_TCFCONFIGURATION,
  payload: response,
});

/**
 * Redux action for adding new light configuration
 */
export const addTCFConfiguration = (model) => ({
  type: ADD_TCFCONFIGURATION,
  payload: model,
});
/**
 * Redux action for successful addition of TCFConfiguration
 */
export const addTCFConfigurationSuccess = (response) => ({
  type: ADD_TCFCONFIGURATION_SUCCESS,
  payload: response,
});

/**
 * Redux action for failing to add new TCF configuration
 */
export const addTCFConfigurationFailure = (error) => ({
  type: ADD_TCFCONFIGURATION_FAILURE,
  payload: error,
});

/**
 * Redux action for deleting a light configuration
 */
export const deleteTCFConfiguration = (folderName) => ({
  type: DELETE_TCFCONFIGURATION,
  payload: folderName,
});
/**
 * Redux action for successful deletion of TCFConfiguration
 */
export const deleteTCFConfigurationSuccess = (response) => ({
  type: DELETE_TCFCONFIGURATION_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in deletion of TCFConfiguration
 */
export const deleteTCFConfigurationFailure = (error) => ({
  type: DELETE_TCFCONFIGURATION_FAILURE,
  payload: error,
});

/**
 * Redux action for filtering TCFConfiguration from search box
 */
export const fillterTCFConfiguration = (keyword) => ({
  type: FILLTER_TCFCONFIGURATION,
  payload: keyword,
});

/**
 * Redux action for selecting a template
 */
export const selectTCFTemplate = (path) => ({
  type: SELECT_TCFTEMPLATE,
  payload: path,
});

/**
 * Redux action for selecting a version
 */
export const selectTCFVersion = (path) => ({
  type: SELECT_TCFVERSION,
  payload: path,
});

export const fetchTCFCMPtemplate = (path) => ({
  type: FETCH_TCFCMP_TEMPLATE,
  payload: path,
});

export const fetchTCFCMPtemplateSuccess = (data) => ({
  type: FETCH_TCFCMP_TEMPLATE_SUCCESS,
  payload: data,
});

export const fetchTCFCMPtemplateFaliure = (error) => ({
  type: FETCH_TCFCMP_TEMPLATE_FAILURE,
  payload: error,
});

export const getMetadataTCF = () => ({
  type: GET_METADATA_TCF,
});

export const getMetadataTCFSuccess = (metadata) => ({
  type: GET_METADATA_TCF_SUCCESS,
  payload: metadata,
});

export const getMetadataTCFFailure = (error) => ({
  type: GET_METADATA_TCF_FAILURE,
  payload: error,
});

export const fetchTCFCMPConfigById = (foldername) => ({
  type: FETCH_TCFCMP_CONIFG_BY_ID,
  payload: foldername,
});

export const fetchTCFCMPConfigByIdSuccess = (model) => ({
  type: FETCH_TCFCMP_CONIFG_BY_ID_SUCCESS,
  payload: model,
});

export const fetchTCFCMPConfigByIdFailure = (error) => ({
  type: FETCH_TCFCMP_CONIFG_BY_ID_FAILURE,
  payload: error,
});

export const selectTCFCMPConfig = (foldername) => ({
  type: SELECT_TCFCMP_CONFIGURATION,
  payload: foldername,
});
