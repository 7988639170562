import {
  GET_GRAVITO_DOMAIN_COUNT,
  GET_GRAVITO_DOMAIN_COUNT_FAILURE,
  GET_GRAVITO_DOMAIN_COUNT_SUCCESS,
  GET_GRAVITO_FP_DOMAIN_COUNT,
  GET_GRAVITO_FP_DOMAIN_COUNT_FAILURE,
  GET_GRAVITO_FP_DOMAIN_COUNT_SUCCESS,
  CERTIFICATE_CHECK,
  CERTIFICATE_CHECK_SUCCESS,
  CERTIFICATE_CHECK_FAILURE,
  GET_GRAVITO_FP_DOMAINS,
  GET_GRAVITO_FP_DOMAINS_SUCCESS,
  GET_GRAVITO_FP_DOMAINS_FAILURE,
} from "../actions/types";

import { NotificationManager } from "react-notifications";

const initState = {
  loading: false,
  fpDomains: null,
  gravitoDomains: null,
  validatedDomains: [],
  renewedDomains: [],
  customerListForValidated: [],
  customerListForRenewed: [],
  customerId: null,
};

const getCustomerList = (list) => {
  let customerList = [];
  list.map((item, i) => {
    let index = customerList.findIndex((c) => c.id === item.CustomerId);
    if (index === -1) {
      customerList.push({
        id: item.CustomerId,
        name: item.Customer,
      });
    }
    return;
  });
  return customerList;
};

const updateValidatedDomain = (updatedDomains, olddomains) => {
  updatedDomains.forEach((ud) => {
    let ind = olddomains.findIndex((od) => od.Domain === ud.Domain);
    if (ind !== -1) {
      olddomains[ind] = ud;
    }
  });
  return olddomains;
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_GRAVITO_DOMAIN_COUNT:
      return { ...state, loading: true };
    case GET_GRAVITO_DOMAIN_COUNT_SUCCESS:
      return { ...state, gravitoDomains: action.payload, loading: false };
    case GET_GRAVITO_DOMAIN_COUNT_FAILURE:
      NotificationManager.error(
        "Opps, we faced problems while fetching domains."
      );
      return { ...state, loading: false };
    case GET_GRAVITO_FP_DOMAIN_COUNT:
      return { ...state, loading: true };
    case GET_GRAVITO_FP_DOMAIN_COUNT_SUCCESS:
      return { ...state, fpDomains: action.payload, loading: false };
    case GET_GRAVITO_FP_DOMAIN_COUNT_FAILURE:
      NotificationManager.error(
        "Opps, we faced problems while fetching domains."
      );
      return { ...state, loading: false };
    case GET_GRAVITO_FP_DOMAINS:
      return { ...state, loading: true };
    case GET_GRAVITO_FP_DOMAINS_SUCCESS:
      return {
        ...state,
        validatedDomains: JSON.parse(action.payload.validated),
        renewedDomains: JSON.parse(action.payload.renewed),
        customerListForRenewed: getCustomerList(
          JSON.parse(action.payload.renewed)
        ),
        customerListForValidated: getCustomerList(
          JSON.parse(action.payload.validated)
        ),
        loading: false,
      };
    case GET_GRAVITO_FP_DOMAINS_FAILURE:
      NotificationManager.error(
        "Opps, we faced problems while fetching domains."
      );
      return { ...state, loading: false };
    case CERTIFICATE_CHECK:
      return { ...state, customerId: action.payload, loading: true };
    case CERTIFICATE_CHECK_SUCCESS:
      return {
        ...state,
        validatedDomains: updateValidatedDomain(
          JSON.parse(action.payload),
          state.validatedDomains
        ),
        loading: false,
      };
    case CERTIFICATE_CHECK_FAILURE:
      NotificationManager.error(
        "Opps, we faced problems while validating certificates for selected customer."
      );
      return { ...state, loading: false };
    default:
      return { ...state };
  }
};

export default reducer;
