/**
 * Helpers Functions
 */
import moment from 'moment';
import axios from 'axios';
import AES from "crypto-js/aes";
import CryptoENC from 'crypto-js/enc-utf8';
import AppConfig from '../constants/AppConfig'
/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

export function createContextHash(object){
    var ciphertext = AES.encrypt(JSON.stringify(object), 'grvcontext');
    sessionStorage.setItem('context', ciphertext);
}

export function getContextObject(){
    if(sessionStorage.getItem('context')){
        var _ciphertext = AES.decrypt(sessionStorage.getItem('context'), 'grvcontext');
        return JSON.parse(_ciphertext.toString(CryptoENC));
    }else{
        return null;
    }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
    let time = timestamp * 1000;
    let formatDate = format ? format : 'MM-DD-YYYY';
    return moment(time).format(formatDate);
}

/**
 * Get Date
 */
export function formatGivenDate(date, format) {

    let formatDate = format ? format : 'MMM-DD-YYYY';
    return moment(date).format(formatDate);
}



/**
 * Convert Date To Timestamp
*/
export function convertDateToTimeStamp(date, format) {
    let formatDate = format ? format : 'YYYY-MM-DD';
    return moment(date, formatDate).unix();
}

export const getAxiosRequest = () => {
    var instance = axios.create({
        baseURL: `${AppConfig.apiPath}`,
        withCredentials:true,
     });
     instance.interceptors.response.use(
         response=>{
             
             return response
            
        
        },error=>{
         
            
            if(error.response.status===401){
               
              //window.location=`${AppConfig.loginUrl}`
            // document.location.reload();
            }else{
                return Promise.reject({ ...error })
            }
            
        }
     )
   
    return instance;
}


export const getIdentityAxiosRequest = () => {
    var instance = axios.create({
        baseURL: `${AppConfig.identityApiPath}`,
        withCredentials:true,
     });
     instance.interceptors.response.use(
         response=>{
             
             return response
            
        
        },error=>{
         
            
            if(error.response.status===401){
               
              //window.location=`${AppConfig.loginUrl}`
            // document.location.reload();
            }else{
                return Promise.reject({ ...error })
            }
            
        }
     )
   
    return instance;
}


export const getAxiosRequestWithoutCredentials = () => {
    var instance = axios.create({
        baseURL: `${AppConfig.apiPath}`,
        withCredentials:false,
     });
     instance.interceptors.response.use(
         response=>{
             
             return response
            
        
        },error=>{
         
            
            if(error.response.status===401){
               
              window.location=`${AppConfig.loginUrl}`
            // document.location.reload();
            }else{
                return Promise.reject({ ...error })
            }
            
        }
     )
   
    return instance;
}