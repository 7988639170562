/**
 * Redux Actions for managing crud operations on Audiences.
 */

import {
    GET_AUDIENCES,
    GET_AUDIENCES_SUCCESS,
    GET_AUDIENCES_FAILURE,
    ADD_AUDIENCE,
    ADD_AUDIENCE_SUCCESS ,
    ADD_AUDIENCE_FAILURE ,
    UPDATE_AUDIENCE,
    UPDATE_AUDIENCE_SUCCESS ,
    UPDATE_AUDIENCE_FAILURE ,
    DELETE_AUDIENCE ,
    DELETE_AUDIENCE_SUCCESS,
    DELETE_AUDIENCE_FAILURE,
    FILLTER_AUDIENCES,
    REFRESH_AUDIENCES,
    FILLTER_AUDIENCES_BY_TYPE,
    SELECT_AUDIENCE,
    GET_AUDIENCE_DOMAINS,
    GET_AUDIENCE_DOMAINS_SUCCESS,
    GET_AUDIENCE_DOMAINS_FAILURE,
    GET_AUDIENCE_SEGMENTS,
    GET_AUDIENCE_SEGMENTS_SUCCESS,
    GET_AUDIENCE_SEGMENTS_FAILURE,
    APPROVE_AUDIENCE,
    APPROVE_AUDIENCE_SUCCESS,
    APPROVE_AUDIENCE_FAILURE,
    GET_SEGMENTS_COUNT,
    GET_SEGMENTS_COUNT_SUCCESS,
    GET_SEGMENTS_COUNT_FAILURE
} from './types';

/**
 * Redux action to get AUDIENCES
 */
export const getAudiences =()=>({
    type: GET_AUDIENCES
})

/**
 * Redux action for successful fetching of AUDIENCES
 */

export const getAudiencesSuccess =(response)=>({
    type: GET_AUDIENCES_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching AUDIENCES
 */
export const getAudiencesFailure =(error)=>({
    type: GET_AUDIENCES_FAILURE,
    payload: error
})


/**
 * Redux action for fetching AUDIENCES after add/edit/delete of Audience
 */
export const refreshAudiences = (response) => ({
    type: REFRESH_AUDIENCES,
    payload: response
});

/**
 * Redux action for adding new Audience
 */
export const addAudience = (model) => ({
    type: ADD_AUDIENCE,
    payload:model
})
/**
 * Redux action for successful addition of Audience
 */
export const addAudienceSuccess =(response)=>({
    type: ADD_AUDIENCE_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to add new Audience
 */
export const addAudienceFailure =(error)=>({
    type: ADD_AUDIENCE_FAILURE,
    payload: error
})

/**
 * Redux action for editing a Audience
 */
export const updateAudience =(model)=>({
    type: UPDATE_AUDIENCE,
    payload:model
})
/**
 * Redux action for successful editing of a Audience
 */
export const updateAudienceSuccess =(response)=>({
    type: UPDATE_AUDIENCE_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to edit a Audience
 */
export const updateAudienceFailure =(error)=>({
    type: UPDATE_AUDIENCE_FAILURE,
    payload: error
})

/**
 * Redux action for deleting a Audience
 */
export const deleteAudience =(id)=>({
    type: DELETE_AUDIENCE,
    payload:id
})
/**
 * Redux action for successful deletion of Audience
 */
export const deleteAudienceSuccess =(response)=>({
    type: DELETE_AUDIENCE_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in deletion of Audience
 */
export const deleteAudienceFailure =(error)=>({
    type: DELETE_AUDIENCE_FAILURE,
    payload: error
})

/**
 * Redux action for filtering Audiences from search box
 */
export const fillterAudiences=(keyword)=>({
    type: FILLTER_AUDIENCES,
    payload: keyword
})
/**
 * Redux action for filtering Audiences by type.
 */
export const fillterAudiencesByType=(type)=>({
    type: FILLTER_AUDIENCES_BY_TYPE,
    payload: type
})
/**
 * Redux action for selecting a Audience
 */
export const selectAudience=(id)=>({
    type: SELECT_AUDIENCE,
    payload: id
})

/**
 * Redux action to get AUDIENCE SEGMENTS
 */
export const getAudienceSegments =()=>({
    type: GET_AUDIENCE_SEGMENTS
})

/**
 * Redux action for successful fetching of AUDIENCE segments
 */

export const getAudienceSegmentsSuccess =(response)=>({
    type: GET_AUDIENCE_SEGMENTS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching AUDIENCE Segments
 */
export const getAudienceSegmentsFailure =(error)=>({
    type: GET_AUDIENCE_SEGMENTS_FAILURE,
    payload: error
})

/**
 * Redux action to get AUDIENCE DOMAINS
 */
export const getAudienceDomains =()=>({
    type: GET_AUDIENCE_DOMAINS
})

/**
 * Redux action for successful fetching of AUDIENCE domains
 */

export const getAudienceDomainsSuccess =(response)=>({
    type: GET_AUDIENCE_DOMAINS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching AUDIENCE domains
 */
export const getAudienceDomainsFailure =(error)=>({
    type: GET_AUDIENCE_DOMAINS_FAILURE,
    payload: error
})

/**
 * Redux action for approve a Audience
 */
export const approveAudience =(model)=>({
    type: APPROVE_AUDIENCE,
    payload:model
})
/**
 * Redux action for successful editing of a Audience
 */
export const approveAudienceSuccess =(response)=>({
    type: APPROVE_AUDIENCE_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to edit a Audience
 */
export const approveAudienceFailure =(error)=>({
    type: APPROVE_AUDIENCE_FAILURE,
    payload: error
})

/**
 * Redux action to get SEGMENT Count
 */
export const getSegmentsCount =(segmentsModel)=>({
    type: GET_SEGMENTS_COUNT,
    payload:segmentsModel
})

/**
 * Redux action for successful fetching of segments count
 */

export const getSegmentsCountSuccess =(response)=>({
    type: GET_SEGMENTS_COUNT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching segments count
 */
export const getSegmentsCountFailure =(error)=>({
    type: GET_SEGMENTS_COUNT_FAILURE,
    payload: error
})
