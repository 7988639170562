/**
 * App Redux Action Types
 */
export const COLLAPSED_SIDEBAR = "COLLAPSED_SIDEBAR";
export const DARK_MODE = "DARK_MODE";
export const BOXED_LAYOUT = "BOXED_LAYOUT";
export const RTL_LAYOUT = "RTL_LAYOUT";
export const MINI_SIDEBAR = "MINI_SIDEBAR";
export const SEARCH_FORM_ENABLE = "SEARCH_FORM_ENABLE";
export const CHANGE_THEME_COLOR = "CHANGE_THEME_COLOR";
export const TOGGLE_SIDEBAR_IMAGE = "TOGGLE_SIDEBAR_IMAGE";
export const SET_SIDEBAR_IMAGE = "SET_SIDEBAR_IMAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const START_USER_TOUR = "START_USER_TOUR";
export const STOP_USER_TOUR = "STOP_USER_TOUR";
export const SEND_FEEDBACK = "SEND_FEEDBACK";
export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS";
export const SEND_FEEDBACK_FAILURE = "SEND_FEEDBACK_FAILURE";

// Roles Actions
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const ADD_ROLES = "ADD_ROLES";
export const ADD_ROLES_SUCCESS = "ADD_ROLES_SUCCESS";
export const ADD_ROLES_FAILURE = "ADD_ROLES_FAILURE";

export const DELETE_ROLES = "DELETE_ROLES";
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAILURE = "DELETE_ROLES_FAILURE";
export const REFRESH_ROLES = "REFRESH_ROLES";

// export const UPDATE_ROLES = 'UPDATE_ROLE';
// export const UPDATE_ROLES_SUCCESS='UPDATE_ROLES_SUCCESS';
// export const UPDATE_ROLES_FAILURE='UPDATE_ROLES_FAILURE';

// sidebar
export const TOGGLE_MENU = "TOGGLE_MENU";

// Auth Actions
export const GET_USER_DOMAINS = "GET_USER_DOMAINS";
export const GET_USER_DOMAINS_SUCCESS = "GET_USER_DOMAINS_SUCCESS";
export const GET_USER_DOMAINS_FAILURE = "GET_USER_DOMAINS_FAILURE";
export const SET_USER_DOMAIN = "SET_USER_DOMAIN";
export const SET_USER_CUSTOMER = "SET_USER_CUSTOMER";
export const GET_DOMAIN_USERS = "GET_DOMAIN_USERS";
export const GET_DOMAIN_USERS_SUCCESS = "GET_DOMAIN_USERS_SUCCESS";
export const GET_DOMAIN_USERS_FAILURE = "GET_DOMAIN_USERS_FAILURE";
export const CHECK_CUSTOMER_ADMIN_STATUS = "CHECK_CUSTOMER_ADMIN_STATUS";
export const CHECK_CUSTOMER_ADMIN_STATUS_SUCCESS =
  "CHECK_CUSTOMER_ADMIN_STATUS_SUCCESS";
export const CHECK_CUSTOMER_ADMIN_STATUS_FAILURE =
  "CHECK_CUSTOMER_ADMIN_STATUS_FAILURE";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";

// Manage User Actions
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const SEARCH_USER = "SEARCH_USER";
export const FILLTER_USERS_BY_TYPE = "FILLTER_USERS_BY_TYPE";
export const REFRESH_USERS = "REFRESH_USERS";
export const SELECT_USER = "SELECT_USER";
export const GET_USER_ASSIGN_DOMAINS = "GET_USER_ASSIGN_DOMAINS";
export const GET_USER_ASSIGN_DOMAINS_SUCCESS =
  "GET_USER_ASSIGN_DOMAINS_SUCCESS";
export const GET_USER_ASSIGN_DOMAINS_FAILURE =
  "GET_USER_ASSIGN_DOMAINS_FAILURE";
export const USER_ASSIGN_DOMAINS = "USER_ASSIGN_DOMAINS";
export const USER_ASSIGN_DOMAINS_SUCCESS = "USER_ASSIGN_DOMAINS_SUCCESS";
export const USER_ASSIGN_DOMAINS_FAILURE = "USER_ASSIGN_DOMAINS_FAILURE";
export const ASSIGN_CUSTOMER_ADMIN = "ASSIGN_CUSTOMER_ADMIN";
export const ASSIGN_CUSTOMER_ADMIN_SUCCESS = "ASSIGN_CUSTOMER_ADMIN_SUCCESS";
export const ASSIGN_CUSTOMER_ADMIN_FAILURE = "ASSIGN_CUSTOMER_ADMIN_FAILURE";
export const LOGIN_AS_USER = "LOGIN_AS_USER";
export const LOGIN_AS_USER_SUCCESS = "LOGIN_AS_USER_SUCCESS";
export const LOGIN_AS_USER_FAILURE = "LOGIN_AS_USER_FAILURE";

export const GET_CONTENTS = "GET_CONTENTS";
export const GET_CONTENTS_SUCCESS = "GET_CONTENTS_SUCCESS";
export const GET_CONTENTS_FAILURE = "GET_CONTENTS_FAILURE";
export const ADD_CONTENT = "ADD_CONTENT";
export const ADD_CONTENT_SUCCESS = "ADD_CONTENT_SUCCESS";
export const ADD_CONTENT_FAILURE = "ADD_CONTENT_FAILURE";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS";
export const UPDATE_CONTENT_FAILURE = "UPDATE_CONTENT_FAILURE";
export const DELETE_CONTENT = "DELETE_CONTENT";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
export const DELETE_CONTENT_FAILURE = "DELETE_CONTENT_FAILURE";
export const FILLTER_CONTENT = "FILLTER_CONTENT";
export const FILLTER_CONTENT_BY_TYPE = "FILLTER_CONTENT_BY_TYPE";
export const REFRESH_CONTENTS = "REFRESH_CONTENTS";
export const SELECT_CONTENT = "SELECT_CONTENT";
export const GET_CONTENT_DOMAINS = "GET_CONTENT_DOMAINS";
export const GET_CONTENT_DOMAINS_SUCCESS = "GET_CONTENT_DOMAINS_SUCCESS";
export const GET_CONTENT_DOMAINS_FAILURE = "GET_CONTENT_DOMAINS_FAILURE";

//Config section actions
export const UPLOAD_USERS = "UPLOAD_USERS";
export const UPLOAD_USERS_SUCCESS = "UPLOAD_USERS_SUCCESS";
export const UPLOAD_USERS_FAILURE = "UPLOAD_USERS_FAILURE";

// Manage Constellation Actions
export const GET_CHANNELS = "GET_CHANNELS";
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNELS_FAILURE = "GET_CHANNELS_FAILURE";
export const GET_CONSTELLATION = "GET_CONSTELLATION";
export const GET_CONSTELLATION_SUCCESS = "GET_CONSTELLATION_SUCCESS";
export const GET_CONSTELLATION_FAILURE = "GET_CONSTELLATION_FAILURE";
export const ADD_CONSTELLATION = "ADD_CONSTELLATION";
export const ADD_CONSTELLATION_SUCCESS = "ADD_CONSTELLATION_SUCCESS";
export const ADD_CONSTELLATION_FAILURE = "ADD_CONSTELLATION_FAILURE";
export const UPDATE_CONSTELLATION = "UPDATE_CONSTELLATION";
export const UPDATE_CONSTELLATION_SUCCESS = "UPDATE_CONSTELLATION_SUCCESS";
export const UPDATE_CONSTELLATION_FAILURE = "UPDATE_CONSTELLATION_FAILURE";
export const DELETE_CONSTELLATION = "DELETE_CONSTELLATION";
export const DELETE_CONSTELLATION_SUCCESS = "DELETE_CONSTELLATION_SUCCESS";
export const DELETE_CONSTELLATION_FAILURE = "DELETE_CONSTELLATION_FAILURE";
export const FILLTER_CONSTELLATION = "FILLTER_CONSTELLATION";
export const FILLTER_CONSTELLATION_BY_TYPE = "FILLTER_CONSTELLATION_BY_TYPE";
export const REFRESH_CONSTELLATION = "REFRESH_CONSTELLATION";
export const GET_CONSTELLATION_BY_ID = "GET_CONSTELLATION_BY_ID";
export const GET_CONSTELLATION_BY_ID_SUCCESS =
  "GET_CONSTELLATION_BY_ID_SUCCESS";
export const GET_CONSTELLATION_BY_ID_FAILURE =
  "GET_CONSTELLATION_BY_ID_FAILURE";
export const SELECT_CONSTELLATION = "SELECT_CONSTELLATION";
export const CONSTELLATION_ERROR = "CONSTELLATION_ERROR";
export const SEND_UAT_EMAIL = "SEND_UAT_EMAIL";
export const SEND_UAT_EMAIL_SUCCESS = "SEND_UAT_EMAIL_SUCCESS";
export const SEND_UAT_EMAIL_FAILURE = "SEND_UAT_EMAIL_FAILURE";
export const PUBLISH_CONSTELLATION = "PUBLISH_CONSTELLATION";
export const PUBLISH_CONSTELLATION_SUCCESS = "PUBLISH_CONSTELLATION_SUCCESS";
export const PUBLISH_CONSTELLATION_FAILURE = "PUBLISH_CONSTELLATION_FAILURE";
export const GET_CONSTELLATION_CONTENTS = "GET_CONSTELLATION_CONTENTS";
export const GET_CONSTELLATION_CONTENTS_SUCCESS =
  "GET_CONSTELLATION_CONTENTS_SUCCESS";
export const GET_CONSTELLATION_CONTENTS_FAILURE =
  "GET_CONSTELLATION_CONTENTS_FAILURE";
export const GET_CONSTELLATION_DOMAINS = "GET_CONSTELLATION_DOMAINS";
export const GET_CONSTELLATION_DOMAINS_SUCCESS =
  "GET_CONSTELLATION_DOMAINS_SUCCESS";
export const GET_CONSTELLATION_DOMAINS_FAILURE =
  "GET_CONSTELLATION_DOMAINS_FAILURE";

//Domain Segment Actions
export const GET_DOMAIN_SEGMENTS = "GET_DOMAIN_SEGMENTS";
export const GET_DOMAIN_SEGMENTS_SUCCESS = "GET_DOMAIN_SEGMENTS_SUCCESS";
export const GET_DOMAIN_SEGMENTS_FAILURE = "GET_DOMAIN_SEGMENTS_FAILURE";
export const SEARCH_DOMAIN_SEGMENTS = "EARCH_DOMAIN_SEGMENTS";
export const SORT_DOMAIN_SEGMENTS = "SORT_DOMAIN_SEGMENTS";

// Audiences actions

export const GET_AUDIENCES = "GET_AUDIENCES";
export const GET_AUDIENCES_SUCCESS = "GET_AUDIENCES_SUCCESS";
export const GET_AUDIENCES_FAILURE = "GET_AUDIENCES_FAILURE";
export const ADD_AUDIENCE = "ADD_AUDIENCE";
export const ADD_AUDIENCE_SUCCESS = "ADD_AUDIENCE_SUCCESS";
export const ADD_AUDIENCE_FAILURE = "ADD_AUDIENCE_FAILURE";
export const UPDATE_AUDIENCE = "UPDATE_AUDIENCE";
export const UPDATE_AUDIENCE_SUCCESS = "UPDATE_AUDIENCE_SUCCESS";
export const UPDATE_AUDIENCE_FAILURE = "UPDATE_AUDIENCE_FAILURE";
export const DELETE_AUDIENCE = "DELETE_AUDIENCE";
export const DELETE_AUDIENCE_SUCCESS = "DELETE_AUDIENCE_SUCCESS";
export const DELETE_AUDIENCE_FAILURE = "DELETE_AUDIENCE_FAILURE";
export const FILLTER_AUDIENCES = "FILLTER_AUDIENCES";
export const REFRESH_AUDIENCES = "REFRESH_AUDIENCES";
export const FILLTER_AUDIENCES_BY_TYPE = "FILLTER_AUDIENCES_BY_TYPE";
export const SELECT_AUDIENCE = "SELECT_AUDIENCE";
export const GET_AUDIENCE_DOMAINS = "GET_AUDIENCE_DOMAINS";
export const GET_AUDIENCE_DOMAINS_SUCCESS = "GET_AUDIENCE_DOMAINS_SUCCESS";
export const GET_AUDIENCE_DOMAINS_FAILURE = "GET_AUDIENCE_DOMAINS_FAILURE";
export const GET_AUDIENCE_SEGMENTS = "GET_AUDIENCE_SEGMENTS";
export const GET_AUDIENCE_SEGMENTS_SUCCESS = "GET_AUDIENCE_SEGMENTS_SUCCESS";
export const GET_AUDIENCE_SEGMENTS_FAILURE = "GET_AUDIENCE_SEGMENTS_FAILURE";
export const APPROVE_AUDIENCE = "APPROVE_AUDIENCE";
export const APPROVE_AUDIENCE_SUCCESS = "APPROVE_AUDIENCE_SUCCESS";
export const APPROVE_AUDIENCE_FAILURE = "APPROVE_AUDIENCE_FAILURE";
export const GET_SEGMENTS_COUNT = "GET_SEGMENTS_COUNT";
export const GET_SEGMENTS_COUNT_SUCCESS = "GET_SEGMENTS_COUNT_SUCCESS";
export const GET_SEGMENTS_COUNT_FAILURE = "GET_SEGMENTS_COUNT_FAILURE";

// Campaign actions
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAILURE = "GET_CAMPAIGNS_FAILURE";
export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS";
export const ADD_CAMPAIGN_FAILURE = "ADD_CAMPAIGN_FAILURE";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILURE = "UPDATE_CAMPAIGN_FAILURE";
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";
export const FILLTER_CAMPAIGNS = "FILLTER_CAMPAIGNS";
export const REFRESH_CAMPAIGNS = "REFRESH_CAMPAIGNS";
export const FILLTER_CAMPAIGNS_BY_TYPE = "FILLTER_CAMPAIGNS_BY_TYPE";
export const SELECT_CAMPAIGN = "SELECT_CAMPAIGN";
export const GET_CAMPAIGN_AUDIENCES = "GET_CAMPAIGN_AUDIENCES";
export const GET_CAMPAIGN_AUDIENCES_SUCCESS = "GET_CAMPAIGN_AUDIENCES_SUCCESS";
export const GET_CAMPAIGN_AUDIENCES_FAILURE = "GET_CAMPAIGN_AUDIENCES_FAILURE";
export const GET_CAMPAIGN_DOMAINS = "GET_CAMPAIGN_DOMAINS";
export const GET_CAMPAIGN_DOMAINS_SUCCESS = "GET_CAMPAIGN_DOMAINS_SUCCESS";
export const GET_CAMPAIGN_DOMAINS_FAILURE = "GET_CAMPAIGN_DOMAINS_FAILURE";
export const GET_CAMPAIGN_CONSTELLATIONS = "GET_CAMPAIGN_CONSTELLATIONS";
export const GET_CAMPAIGN_CONSTELLATIONS_SUCCESS =
  "GET_CAMPAIGN_CONSTELLATIONS_SUCCESS";
export const GET_CAMPAIGN_CONSTELLATIONS_FAILURE =
  "GET_CAMPAIGN_CONSTELLATIONS_FAILURE";
export const PUBLISH_CAMPAIGN = "PUBLISH_CAMPAIGN";
export const PUBLISH_CAMPAIGN_SUCCESS = "PUBLISH_CAMPAIGN_SUCCESS";
export const PUBLISH_CAMPAIGN_FAILURE = "PUBLISH_CAMPAIGN_FAILURE";
export const GET_CAMPAIGN_SEGMENTS = "GET_CAMPAIGN_SEGMENTS";
export const GET_CAMPAIGN_SEGMENTS_SUCCESS = "GET_CAMPAIGN_SEGMENTS_SUCCESS";
export const GET_CAMPAIGN_SEGMENTS_FAILURE = "GET_CAMPAIGN_SEGMENTS_FAILURE";

// Campaign Dashboard actions
export const GET_DASHBOARD_CAMPAIGNS = "GET_DASHBOARD_CAMPAIGNS";
export const GET_DASHBOARD_CAMPAIGNS_SUCCESS =
  "GET_DASHBOARD_CAMPAIGNS_SUCCESS";
export const GET_DASHBOARD_CAMPAIGNS_FAILURE =
  "GET_DASHBOARD_CAMPAIGNS_FAILURE";
export const GET_CAMPAIGNS_STATS = "GET_CAMPAIGNS_STATS";
export const GET_CAMPAIGNS_STATS_SUCCESS = "GET_CAMPAIGNS_STATS_SUCCESS";
export const GET_CAMPAIGNS_STATS_FAILURE = "GET_CAMPAIGNS_STATS_FAILURE";
export const EXECUTE_CAMPAIGN = "EXECUTE_CAMPAIGN";
export const EXECUTE_CAMPAIGN_SUCCESS = "EXECUTE_CAMPAIGN_SUCCESS";
export const EXECUTE_CAMPAIGN_FAILURE = "EXECUTE_CAMPAIGN_FAILURE";
export const STOP_CAMPAIGN = "STOP_CAMPAIGN";
export const STOP_CAMPAIGN_SUCCESS = "STOP_CAMPAIGN_SUCCESS";
export const STOP_CAMPAIGN_FAILURE = "STOP_CAMPAIGN_FAILURE";
export const SEARCH_DASHBOARD_CAMPAIGN = "SEARCH_DASHBOARD_CAMPAIGN";
export const SORT_DASHBOARD = "SORT_DASHBOARD";

// DOMAIN Actions

export const GET_DOMAINS = "GET_DOMAINS";
export const GET_DOMAINS_SUCCESS = "GET_DOMAINS_SUCCESS";
export const GET_DOMAINS_FAILURE = "GET_DOMAINS_FAILURE";
export const ADD_DOMAIN = "ADD_DOMAIN";
export const ADD_DOMAIN_SUCCESS = "ADD_DOMAIN_SUCCESS";
export const ADD_DOMAIN_FAILURE = "ADD_DOMAIN_FAILURE";
export const UPDATE_DOMAIN = "UPDATE_DOMAIN";
export const UPDATE_DOMAIN_SUCCESS = "UPDATE_DOMAIN_SUCCESS";
export const UPDATE_DOMAIN_FAILURE = "UPDATE_DOMAIN_FAILURE";
export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const DELETE_DOMAIN_SUCCESS = "DELETE_DOMAIN_SUCCESS";
export const DELETE_DOMAIN_FAILURE = "DELETE_DOMAIN_FAILURE";
export const FILLTER_DOMAINS = "FILLTER_DOMAINS";
export const REFRESH_DOMAINS = "REFRESH_DOMAINS";
export const SELECT_DOMAIN = "SELECT_DOMAIN";
export const GET_DOMAIN_CUSTOMERS = "GET_DOMAIN_CUSTOMERS";
export const GET_DOMAIN_CUSTOMERS_SUCCESS = "GET_DOMAIN_CUSTOMERS_SUCCESS";
export const GET_DOMAIN_CUSTOMERS_FAILURE = "GET_DOMAIN_CUSTOMERS_FAILURE";
export const GENERATE_DOMAIN_KEY = "GENERATE_DOMAIN_KEY";
export const GENERATE_DOMAIN_KEY_SUCCESS = "GENERATE_DOMAIN_KEY_SUCCESS";
export const GENERATE_DOMAIN_KEY_FAILURE = "GENERATE_DOMAIN_KEY_FAILURE";
export const REVOKE_ACCESS = "REVOKE_ACCESS";
export const REVOKE_ACCESS_SUCCESS = "REVOKE_ACCESS_SUCCESS";
export const REVOKE_ACCESS_FAILURE = "REVOKE_ACCESS_FAILURE";
export const GET_CUSTOMER_DOMAINS = "GET_CUSTOMER_DOMAINS";
export const GET_CUSTOMER_DOMAINS_SUCCESS = "GET_CUSTOMER_DOMAINS_SUCCESS";
export const GET_CUSTOMER_DOMAINS_FAILURE = "GET_CUSTOMER_DOMAINS_FAILURE";
export const GET_DOMAIN_BY_ID = "GET_DOMAIN_BY_ID";
export const GET_DOMAIN_BY_ID_SUCCESS = "GET_DOMAIN_BY_ID_SUCCESS";
export const GET_DOMAIN_BY_ID_FAILURE = "GET_DOMAIN_BY_ID_FAILURE";
export const TEST_DOMAIN_WEBHOOK = "TEST_DOMAIN_WEBHOOK";
export const TEST_DOMAIN_WEBHOOK_SUCCESS = "TEST_DOMAIN_WEBHOOK_SUCCESS";
export const TEST_DOMAIN_WEBHOOK_FAILURE = "TEST_DOMAIN_WEBHOOK_FAILURE";
export const REQUEST_DOMAIN_DELETION = "REQUEST_DOMAIN_DELETION";
export const REQUEST_DOMAIN_DELETION_SUCCESS =
  "REQUEST_DOMAIN_DELETION_SUCCESS";
export const REQUEST_DOMAIN_DELETION_FAILURE =
  "REQUEST_DOMAIN_DELETION_FAILURE";

// MANAGE CUSTOMERS
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const GET_CUSTOMER_BY_ID_SUCCESS = "GET_CUSTOMER_BY_ID_SUCCESS";
export const GET_CUSTOMER_BY_ID_FAILURE = "GET_CUSTOMER_BY_ID_FAILURE";
export const GET_CUSTOMER_SCAN = "GET_CUSTOMER_SCAN";
export const GET_CUSTOMER_SCAN_SUCCESS = "GET_CUSTOMER_SCAN_SUCCESS";
export const GET_CUSTOMER_SCAN_FAILURE = "GET_CUSTOMER_SCAN_FAILURE";
export const DELETE_CUSTOMER_SCAN_DOMAIN = "DELETE_CUSTOMER_SCAN_DOMAIN";
export const DELETE_CUSTOMER_SCAN_DOMAIN_SUCCESS =
  "DELETE_CUSTOMER_SCAN_DOMAIN_SUCCESS";
export const DELETE_CUSTOMER_SCAN_DOMAIN_FAILURE =
  "DELETE_CUSTOMER_SCAN_DOMAIN_FAILURE";
export const ADD_CUSTOMER_SCAN_DOMAIN = "ADD_CUSTOMER_SCAN_DOMAIN";
export const ADD_CUSTOMER_SCAN_DOMAIN_SUCCESS =
  "ADD_CUSTOMER_SCAN_DOMAIN_SUCCESS";
export const ADD_CUSTOMER_SCAN_DOMAIN_FAILURE =
  "ADD_CUSTOMER_SCAN_DOMAIN_FAILURE";
export const REFRESH_CUSTOMERS = "REFRESH_CUSTOMERS";
export const CANCEL_ONBOARDING = "CANCEL_ONBOARDING";
export const CANCEL_ONBOARDING_FAILURE = "CANCEL_ONBOARDING_FAILURE";
export const CANCEL_ONBOARDING_SUCCESS = "CANCEL_ONBOARDING_SUCCESS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";
export const ONBOARD_CUSTOMER = "ONBOARD_CUSTOMER";
export const ONBOARD_CUSTOMER_SUCCESS = "ONBOARD_CUSTOMER_SUCCESS";
export const ONBOARD_CUSTOMER_FAILURE = "ONBOARD_CUSTOMER_FAILURE";
export const HOLD_CUSTOMER = "HOLD_CUSTOMER";
export const HOLD_CUSTOMER_SUCCESS = "HOLD_CUSTOMER_SUCCESS";
export const HOLD_CUSTOMER_FAILURE = "HOLD_CUSTOMER_FAILURE";
export const SEARCH_CUSTOMERS = "SEARCH_CUSTOMERS";
export const SEARCH_DOMAINS = "SEARCH_DOMAINS";
export const REFRESH_CUSTOMER_DOMAIN_LIST = "REFRESH_CUSTOMER_DOMAIN_LIST";
export const REFRESH_CUSTOMER_DOMAIN_LIST_SUCCESS =
  "REFRESH_CUSTOMER_DOMAIN_LIST_SUCCESS";
export const REFRESH_CUSTOMER_DOMAIN_LIST_FAILURE =
  "REFRESH_CUSTOMER_DOMAIN_LIST_FAILURE";
export const SELECT_A_CUSTOMER = "SELECT_A_CUSTOMER";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAILURE = "ADD_CUSTOMER_FAILURE";
export const UPDATE_CUSTOMER_TYPE = "UPDATE_CUSTOMER_TYPE";
export const UPDATE_CUSTOMER_TYPE_SUCCESS = "UPDATE_CUSTOMER_TYPE_SUCCESS";
export const UPDATE_CUSTOMER_TYPE_FAILURE = "UPDATE_CUSTOMER_TYPE_FAILURE";
// CUSTOMER DASHBOARD ACTIONS
export const GET_CUSTOMER_EVENT_REPORT = "GET_CUSTOMER_EVENT_REPORT";
export const GET_CUSTOMER_EVENT_REPORT_SUCCESS =
  "GET_CUSTOMER_EVENT_REPORT_SUCCESS";
export const GET_CUSTOMER_EVENT_REPORT_FAILURE =
  "GET_CUSTOMER_EVENT_REPORT_FAILURE";
export const GET_CUSTOMER_LEDGER_REPORT = "GET_CUSTOMER_LEDGER_REPORT";
export const GET_CUSTOMER_LEDGER_REPORT_SUCCESS =
  "GET_CUSTOMER_LEDGER_REPORT_SUCCESS";
export const GET_CUSTOMER_LEDGER_REPORT_FAILURE =
  "GET_CUSTOMER_LEDGER_REPORT_FAILURE";
export const GET_CUSTOMER_CONSENT_REPORT = "GET_CUSTOMER_CONSENT_REPORT";
export const GET_CUSTOMER_CONSENT_REPORT_SUCCESS =
  "GET_CUSTOMER_CONSENT_REPORT_SUCCESS";
export const GET_CUSTOMER_CONSENT_REPORT_FAILURE =
  "GET_CUSTOMER_CONSENT_REPORT_FAILURE";

export const GET_CUSTOMER_CONSUMER_REPORT = "GET_CUSTOMER_CONSUMER_REPORT";
export const GET_CUSTOMER_CONSUMER_REPORT_SUCCESS =
  "GET_CUSTOMER_CONSUMER_REPORT_SUCCESS";
export const GET_CUSTOMER_CONSUMER_REPORT_FAILURE =
  "GET_CUSTOMER_CONSUMER_REPORT_FAILURE";

export const FILTER_CUSTOMER_CONSENT_REPORT = "FILTER_CUSTOMER_CONSENT_REPORT";

export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS";
export const GET_CUSTOMER_LIST_FAILURE = "GET_CUSTOMER_LIST_FAILURE";
export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const EXECUTE_PAYMENT = "EXECUTE_PAYMENT";
export const EXECUTE_PAYMENT_SUCCESS = "EXECUTE_PAYMENT_SUCCESS";
export const EXECUTE_PAYMENT_FAILURE = "EXECUTE_PAYMENT_FAILURE";
export const REFUND_PAYMENT = "REFUND_PAYMENT";
export const REFUND_PAYMENT_SUCCESS = "REFUND_PAYMENT_SUCCESS";
export const REFUND_PAYMENT_FAILURE = "REFUND_PAYMENT_FAILURE";
export const GET_CHANNEL_COUNT_REPORT = "GET_CHANNEL_COUNT_REPORT";
export const GET_CHANNEL_COUNT_REPORT_SUCCESS =
  "GET_CHANNEL_COUNT_REPORT_SUCCESS";
export const GET_CHANNEL_COUNT_REPORT_FAILURE =
  "GET_CHANNEL_COUNT_REPORT_FAILURE";
export const SELECT_CUSTOMER_DOMAIN = "SELECT_CUSTOMER_DOMAIN";
export const SELECT_CUSTOMER_DOMAIN_SUCCESS = "SELECT_CUSTOMER_DOMAIN_SUCCESS";
export const SELECT_CUSTOMER_DOMAIN_FAILURE = "SELECT_CUSTOMER_DOMAIN_FAILURE";
export const GET_COST_PER_ACTIVITY = "GET_COST_PER_ACTIVITY";
export const GET_COST_PER_ACTIVITY_SUCCESS = "GET_COST_PER_ACTIVITY_SUCCESS";
export const GET_COST_PER_ACTIVITY_FAILURE = "GET_COST_PER_ACTIVITY_FAILURE";
export const GET_COST_PER_CONTACT = "GET_COST_PER_CONTACT";
export const GET_COST_PER_CONTACT_SUCCESS = "GET_COST_PER_CONTACT_SUCCESS";
export const GET_COST_PER_CONTACT_FAILURE = "GET_COST_PER_CONTACT_FAILURE";

export const GET_CONSENT_CHECK_COUNT = "GET_CONSENT_CHECK_COUNT";
export const GET_CONSENT_CHECK_COUNT_SUCCESS =
  "GET_CONSENT_CHECK_COUNT_SUCCESS";
export const GET_CONSENT_CHECK_COUNT_FAILURE =
  "GET_CONSENT_CHECK_COUNT_FAILURE";
export const GET_DOMAIN_EVENTS = "GET_DOMAIN_EVENTS";
export const GET_DOMAIN_EVENTS_SUCCESS = "GET_DOMAIN_EVENTS_SUCCESS";
export const GET_DOMAIN_EVENTS_FAILURE = "GET_DOMAIN_EVENTS_FAILURE";

// MANAGE DOMAIN PROPERTIES
export const GET_DOMAINS_FOR_PROPERTY = "GET_DOMAINS_FOR_PROPERTY";
export const GET_DOMAINS_FOR_PROPERTY_SUCCESS =
  "GET_DOMAINS_FOR_PROPERTY_SUCCESS";
export const GET_DOMAINS_FOR_PROPERTY_FAILURE =
  "GET_DOMAINS_FOR_PROPERTY_FAILURE";
export const GET_DOMAIN_PROPERTIES = "GET_DOMAIN_PROPERTIES";
export const GET_DOMAIN_PROPERTIES_SUCCESS = "GET_DOMAIN_PROPERTIES_SUCCESS";
export const GET_DOMAIN_PROPERTIES_FAILURE = "GET_DOMAIN_PROPERTIES_FAILURE";
export const ADD_DOMAIN_PROPERTY = "ADD_DOMAIN_PROPERTY";
export const ADD_DOMAIN_PROPERTY_SUCCESS = "ADD_DOMAIN_PROPERTY_SUCCESS";
export const ADD_DOMAIN_PROPERTY_FAILURE = "ADD_DOMAIN_PROPERTY_FAILURE";
export const UPDATE_DOMAIN_PROPERTY = "UPDATE_DOMAIN_PROPERTY";
export const UPDATE_DOMAIN_PROPERTY_SUCCESS = "UPDATE_DOMAIN_PROPERTY_SUCCESS";
export const UPDATE_DOMAIN_PROPERTY_FAILURE = "UPDATE_DOMAIN_PROPERTY_FAILURE";
export const DELETE_DOMAIN_PROPERTY = "DELETE_DOMAIN_PROPERTY";
export const DELETE_DOMAIN_PROPERTY_SUCCESS = "DELETE_DOMAIN_PROPERTY_SUCCESS";
export const DELETE_DOMAIN_PROPERTY_FAILURE = "DELETE_DOMAIN_PROPERTY_FAILURE";
export const REFRESH_DOMAIN_PROPERTIES = "REFRESH_DOMAIN_PROPERTIES";
export const SELECT_DOMAIN_PROPERTY = "SELECT_DOMAIN_PROPERTY";
export const ACTIVATE_DOMAIN_PROPERTY = "ACTIVATE_DOMAIN_PROPERTY";
export const ACTIVATE_DOMAIN_PROPERTY_SUCCESS =
  "ACTIVATE_DOMAIN_PROPERTY_SUCCESS";
export const ACTIVATE_DOMAIN_PROPERTY_FAILURE =
  "ACTIVATE_DOMAIN_PROPERTY_FAILURE";
export const GET_DOMAIN_PROPERTIES_FOR_SELECTED_DOMAIN =
  "GET_DOMAIN_PROPERTIES_FOR_SELECTED_DOMAIN";

// Manage Domain User Actions
export const GET_USERS_FOR_DOMAIN = "GET_USERS_FOR_DOMAIN";
export const GET_USERS_FOR_DOMAIN_SUCCESS = "GET_USERS_FOR_DOMAIN_SUCCESS";
export const GET_USERS_FOR_DOMAIN_FAILURE = "GET_USERS_FOR_DOMAIN_FAILURE";
export const GET_EVENTS_FOR_DOMAIN_PROFILE = "GET_EVENTS_FOR_DOMAIN_PROFILE";
export const GET_EVENTS_FOR_DOMAIN_PROFILE_SUCCESS =
  "GET_EVENTS_FOR_DOMAIN_PROFILE_SUCCESS";
export const GET_EVENTS_FOR_DOMAIN_PROFILE_FAILURE =
  "GET_EVENTS_FOR_DOMAIN_PROFILE_FAILURE";

export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const AUTHENTICATE_USER_SUCCESS = "AUTHENTICATE_USER_SUCCESS";
export const AUTHENTICATE_USER_FAILURE = "AUTHENTICATE_USER_FAILURE";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";

// New dashboard types
export const CHANGE_DOMAIN = "CHANGE_DOMAIN";
export const CHANGE_DOMAIN_SUCCESS = "CHANGE_DOMAIN_SUCCESS";
export const CHANGE_DOMAIN_FAILURE = "CHANGE_DOMAIN_FAILURE";
export const GET_DOMAIN_CAMPAIGN_REPORT = "GET_DOMAIN_CAMPAIGN_REPORT";
export const GET_DOMAIN_CAMPAIGN_REPORT_SUCCESS =
  "GET_DOMAIN_CAMPAIGN_REPORT_SUCCESS";
export const GET_DOMAIN_CAMPAIGN_REPORT_FAILURE =
  "GET_DOMAIN_CAMPAIGN_REPORT_FAILURE";
export const GET_DOMAIN_LEDGER_REPORT = "GET_DOMAIN_LEDGER_REPORT";
export const GET_DOMAIN_LEDGER_REPORT_SUCCESS =
  "GET_DOMAIN_LEDGER_REPORT_SUCCESS";
export const GET_DOMAIN_LEDGER_REPORT_FAILURE =
  "GET_DOMAIN_LEDGER_REPORT_FAILURE";

//Manage Segments Types
export const SAVE_SEGMENT = "SAVE_SEGMENT";
export const SAVE_SEGMENT_SUCCESS = "SAVE_SEGMENT_SUCCESS";
export const SAVE_SEGMENT_FAILURE = "SAVE_SEGMENT_FAILURE";
export const GET_SEGMENTS = "GET_SEGMENTS";
export const GET_SEGMENTS_SUCCESS = "GET_SEGMENTS_SUCCESS";
export const GET_SEGMENTS_FAILURE = "GET_SEGMENTS_FAILURE";
export const SELECT_SEGMENT = "SELECT_SEGMENT";
export const UPDATE_SEGMENT = "UPDATE_SEGMENT";
export const UPDATE_SEGMENT_SUCCESS = "UPDATE_SEGMENT_SUCCESS";
export const UPDATE_SEGMENT_FAILURE = "UPDATE_SEGMENT_FAILURE";
export const DELETE_SEGMENT = "DELETE_SEGMENT";
export const DELETE_SEGMENT_SUCCESS = "DELETE_SEGMENT_SUCCESS";
export const DELETE_SEGMENT_FAILURE = "DELETE_SEGMENT_FAILURE";
export const FILTER_SEGMENT = "FILTER_SEGMENT";
export const SORT_SEGMENT = "SORT_SEGMENT";

//get user by email
export const GET_USER_BY_EMAIL = "GET_USER_BY_EMAIL";
export const GET_USER_BY_EMAIL_SUCCESS = "GET_USER_BY_EMAIL_SUCCESS";
export const GET_USER_BY_EMAIL_FAILURE = "GET_USER_BY_EMAIL_FAILURE";

// Applications actions

export const GET_APPLICATIONS = "GET_APPLICATIONS";
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
export const GET_APPLICATIONS_FAILURE = "GET_APPLICATIONS_FAILURE";
export const ADD_APPLICATION = "ADD_APPLICATION";
export const ADD_APPLICATION_SUCCESS = "ADD_APPLICATION_SUCCESS";
export const ADD_APPLICATION_FAILURE = "ADD_APPLICATION_FAILURE";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const UPDATE_APPLICATION_SUCCESS = "UPDATE_APPLICATION_SUCCESS";
export const UPDATE_APPLICATION_FAILURE = "UPDATE_APPLICATION_FAILURE";
export const DELETE_APPLICATION = "DELETE_APPLICATION";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_FAILURE = "DELETE_APPLICATION_FAILURE";
export const FILLTER_APPLICATIONS = "FILLTER_APPLICATIONS";
export const REFRESH_APPLICATIONS = "REFRESH_APPLICATIONS";
export const FILLTER_APPLICATIONS_BY_TYPE = "FILLTER_APPLICATIONS_BY_TYPE";
export const SELECT_APPLICATION = "SELECT_APPLICATION";

// Custom Domains actions

export const GET_CUSTOMDOMAINS = "GET_CUSTOMDOMAINS";
export const GET_CUSTOMDOMAINS_SUCCESS = "GET_CUSTOMDOMAINS_SUCCESS";
export const GET_CUSTOMDOMAINS_FAILURE = "GET_CUSTOMDOMAINS_FAILURE";
export const ADD_CUSTOMDOMAIN = "ADD_CUSTOMDOMAIN";
export const ADD_CUSTOMDOMAIN_SUCCESS = "ADD_CUSTOMDOMAIN_SUCCESS";
export const ADD_CUSTOMDOMAIN_FAILURE = "ADD_CUSTOMDOMAIN_FAILURE";
export const DELETE_CUSTOMDOMAIN = "DELETE_CUSTOMDOMAIN";
export const DELETE_CUSTOMDOMAIN_SUCCESS = "DELETE_CUSTOMDOMAIN_SUCCESS";
export const DELETE_CUSTOMDOMAIN_FAILURE = "DELETE_CUSTOMDOMAIN_FAILURE";
export const FILLTER_CUSTOMDOMAINS = "FILLTER_CUSTOMDOMAINS";
export const REFRESH_CUSTOMDOMAINS = "REFRESH_CUSTOMDOMAINS";
export const FILLTER_CUSTOMDOMAINS_BY_TYPE = "FILLTER_CUSTOMDOMAINS_BY_TYPE";
export const SELECT_CUSTOMDOMAIN = "SELECT_CUSTOMDOMAIN";
export const VALIDATE_CUSTOMDOMAIN = "VALIDATE_CUSTOMDOMAIN";
export const VALIDATE_CUSTOMDOMAIN_SUCCESS = "VALIDATE_CUSTOMDOMAIN_SUCCESS";
export const VALIDATE_CUSTOMDOMAIN_FAILURE = "VALIDATE_CUSTOMDOMAIN_FAILURE";
export const RENEW_CUSTOMDOMAIN = "RENEW_CUSTOMDOMAIN";
export const RENEW_CUSTOMDOMAIN_SUCCESS = "RENEW_CUSTOMDOMAIN_SUCCESS";
export const RENEW_CUSTOMDOMAIN_FAILURE = "RENEW_CUSTOMDOMAIN_FAILURE";
export const VALIDATE_CUSTOMDOMAIN_DNS = "VALIDATE_CUSTOMDOMAIN_DNS";
export const VALIDATE_CUSTOMDOMAIN_DNS_SUCCESS =
  "VALIDATE_CUSTOMDOMAIN_DNS_SUCCESS";
export const VALIDATE_CUSTOMDOMAIN_DNS_FAILURE =
  "VALIDATE_CUSTOMDOMAIN_DNS_FAILURE";
// CMP Configuration common actions

export const GET_GRAVITOSDK = "GET_GRAVITOSDK";
export const GET_GRAVITOSDK_SUCCESS = "GET_GRAVITOSDK_SUCCESS";
export const GET_GRAVITOSDK_FAILURE = "GET_GRAVITOSDK_FAILURE";

// Light Configuration actions

export const GET_LIGHTCONFIGURATIONS = "GET_LIGHTCONFIGURATIONS";
export const GET_LIGHTCONFIGURATIONS_SUCCESS =
  "GET_LIGHTCONFIGURATIONS_SUCCESS";
export const GET_LIGHTCONFIGURATIONS_FAILURE =
  "GET_LIGHTCONFIGURATIONS_FAILURE";
export const GET_LIGHTTEMPLATES = "GET_LIGHTTEMPLATES";
export const GET_LIGHTTEMPLATES_SUCCESS = "GET_LIGHTTEMPLATES_SUCCESS";
export const GET_LIGHTTEMPLATES_FAILURE = "GET_LIGHTTEMPLATES_FAILURE";
export const GET_LIGHTVERSIONS = "GET_LIGHTVERSIONS";
export const GET_LIGHTVERSIONS_SUCCESS = "GET_LIGHTVERSIONS_SUCCESS";
export const GET_LIGHTVERSIONS_FAILURE = "GET_LIGHTVERSIONS_FAILURE";
export const ADD_LIGHTCONFIGURATION = "ADD_LIGHTCONFIGURATION";
export const ADD_LIGHTCONFIGURATION_SUCCESS = "ADD_LIGHTCONFIGURATION_SUCCESS";
export const ADD_LIGHTCONFIGURATION_FAILURE = "ADD_LIGHTCONFIGURATION_FAILURE";
export const DELETE_LIGHTCONFIGURATION = "DELETE_LIGHTCONFIGURATION";
export const DELETE_LIGHTCONFIGURATION_SUCCESS =
  "DELETE_LIGHTCONFIGURATION_SUCCESS";
export const DELETE_LIGHTCONFIGURATION_FAILURE =
  "DELETE_LIGHTCONFIGURATION_FAILURE";
export const FILLTER_LIGHTCONFIGURATION = "FILLTER_LIGHTCONFIGURATION";
export const REFRESH_LIGHTCONFIGURATION = "REFRESH_LIGHTCONFIGURATION";
export const SELECT_LIGHTTEMPLATE = "SELECT_LIGHTTEMPLATE";
export const SELECT_LIGHTVERSION = "SELECT_LIGHTVERSION";
export const FETCH_LIGHTCMP_TEMPLATE = "FETCH_LIGHTCMP_TEMPLATE";
export const FETCH_LIGHTCMP_TEMPLATE_SUCCESS =
  "FETCH_LIGHTCMP_TEMPLATE_SUCCESS";
export const FETCH_LIGHTCMP_TEMPLATE_FAILURE =
  "FETCH_LIGHTCMP_TEMPLATE_FAILURE";

export const SELECT_LIGHTCMP_CONFIGURATION = "SELECT_LIGHTCMP_CONFIGURATION";
export const SELECT_LIGHTCMP_CONFIGURATION_SUCCESS =
  "SELECT_LIGHTCMP_CONFIGURATION_SUCCESS";
export const SELECT_LIGHTCMP_CONFIGURATION_FAILURE =
  "SELECT_LIGHTCMP_CONFIGURATION_FAILURE";

export const GET_METADATA_LIGHT = "GET_METADATA_LIGHT";
export const GET_METADATA_LIGHT_SUCCESS = "GET_METADATA_LIGHT_SUCCESS";
export const GET_METADATA_LIGHT_FAILURE = "GET_METADATA_LIGHT_FAILURE";

export const FETCH_LIGHTCMP_CONIFG_BY_ID = "FETCH_LIGHTCMP_CONIFG_BY_ID";
export const FETCH_LIGHTCMP_CONIFG_BY_ID_SUCCESS =
  "FETCH_LIGHTCMP_CONIFG_BY_ID_SUCCESS";
export const FETCH_LIGHTCMP_CONIFG_BY_ID_FAILURE =
  "FETCH_LIGHTCMP_CONIFG_BY_ID_FAILURE";

// TCF Configuration actions

export const GET_TCFCONFIGURATIONS = "GET_TCFCONFIGURATIONS";
export const GET_TCFCONFIGURATIONS_SUCCESS = "GET_TCFCONFIGURATIONS_SUCCESS";
export const GET_TCFCONFIGURATIONS_FAILURE = "GET_TCFCONFIGURATIONS_FAILURE";
export const GET_TCFTEMPLATES = "GET_TCFTEMPLATES";
export const GET_TCFTEMPLATES_SUCCESS = "GET_TCFTEMPLATES_SUCCESS";
export const GET_TCFTEMPLATES_FAILURE = "GET_TCFTEMPLATES_FAILURE";
export const GET_TCFVERSIONS = "GET_TCFVERSIONS";
export const GET_TCFVERSIONS_SUCCESS = "GET_TCFVERSIONS_SUCCESS";
export const GET_TCFVERSIONS_FAILURE = "GET_TCFVERSIONS_FAILURE";
export const ADD_TCFCONFIGURATION = "ADD_TCFCONFIGURATION";
export const ADD_TCFCONFIGURATION_SUCCESS = "ADD_TCFCONFIGURATION_SUCCESS";
export const ADD_TCFCONFIGURATION_FAILURE = "ADD_TCFCONFIGURATION_FAILURE";
export const DELETE_TCFCONFIGURATION = "DELETE_TCFCONFIGURATION";
export const DELETE_TCFCONFIGURATION_SUCCESS =
  "DELETE_TCFCONFIGURATION_SUCCESS";
export const DELETE_TCFCONFIGURATION_FAILURE =
  "DELETE_TCFCONFIGURATION_FAILURE";
export const FILLTER_TCFCONFIGURATION = "FILLTER_TCFCONFIGURATION";
export const REFRESH_TCFCONFIGURATION = "REFRESH_TCFCONFIGURATION";
export const SELECT_TCFTEMPLATE = "SELECT_TCFTEMPLATE";
export const SELECT_TCFVERSION = "SELECT_TCFVERSION";
export const FETCH_TCFCMP_TEMPLATE = "FETCH_TCFCMP_TEMPLATE";
export const FETCH_TCFCMP_TEMPLATE_SUCCESS = "FETCH_TCFCMP_TEMPLATE_SUCCESS";
export const FETCH_TCFCMP_TEMPLATE_FAILURE = "FETCH_TCFCMP_TEMPLATE_FAILURE";

export const GET_METADATA_TCF = "GET_METADATA_TCF";
export const GET_METADATA_TCF_SUCCESS = "GET_METADATA_TCF_SUCCESS";
export const GET_METADATA_TCF_FAILURE = "GET_METADATA_TCF_FAILURE";

export const FETCH_TCFCMP_CONIFG_BY_ID = "FETCH_TCFCMP_CONIFG_BY_ID";
export const FETCH_TCFCMP_CONIFG_BY_ID_SUCCESS =
  "FETCH_TCFCMP_CONIFG_BY_ID_SUCCESS";
export const FETCH_TCFCMP_CONIFG_BY_ID_FAILURE =
  "FETCH_TCFCMP_CONIFG_BY_ID_FAILURE";

export const SELECT_TCFCMP_CONFIGURATION = "SELECT_TCFCMP_CONFIGURATION";
export const SELECT_TCFCMP_CONFIGURATION_SUCCESS =
  "SELECT_TCFCMP_CONFIGURATION_SUCCESS";
export const SELECT_TCFCMP_CONFIGURATION_FAILURE =
  "SELECT_TCFCMP_CONFIGURATION_FAILURE";

export const GET_HELP_CONTENT = "GET_HELP_CONTENT";
export const GET_HELP_CONTENT_SUCCESS = "GET_HELP_CONTENT_SUCCESS";
export const GET_HELP_CONTENT_FAILURE = "GET_HELP_CONTENT_FAILURE";

export const GET_WELCOME_CONTENT = "GET_WELCOME_CONTENT";
export const GET_WELCOME_CONTENT_SUCCESS = "GET_WELCOME_CONTENT_SUCCESS";
export const GET_WELCOME_CONTENT_FAILURE = "GET_WELCOME_CONTENT_FAILURE";

export const GET_REGISTRATION_CONTENT = "GET_REGISTRATION_CONTENT";
export const GET_REGISTRATION_CONTENT_SUCCESS =
  "GET_REGISTRATION_CONTENT_SUCCESS";
export const GET_REGISTRATION_CONTENT_FAILURE =
  "GET_REGISTRATION_CONTENT_FAILURE";

export const GET_DASHBOARD_CONTENT = "GET_DASHBOARD_CONTENT";
export const GET_DASHBOARD_CONTENT_SUCCESS = "GET_DASHBOARD_CONTENT_SUCCESS";
export const GET_DASHBOARD_CONTENT_FAILURE = "GET_DASHBOARD_CONTENT_FAILURE";

export const GET_TCFCONFIGHELP_CONTENT = "GET_TCFCONFIGHELP_CONTENT";
export const GET_TCFCONFIGHELP_CONTENT_SUCCESS =
  "GET_TCFCONFIGHELP_CONTENT_SUCCESS";
export const GET_TCFCONFIGHELP_CONTENT_FAILURE =
  "GET_TCFCONFIGHELP_CONTENT_FAILURE";

// Customer Dashboard Types
export const GET_CUSTOMERDASHBOARDDATA = "GET_CUSTOMERDASHBOARDDATA";
export const GET_CUSTOMERDASHBOARDDATA_SUCCESS =
  "GET_CUSTOMERDASHBOARDDATA_SUCCESS";
export const GET_CUSTOMERDASHBOARDDATA_FAILURE =
  "GET_CUSTOMERDASHBOARDDATA_FAILURE";
export const SELECT_DASHBOARD_CUSTOMER = "SELECT_DASHBOARD_CUSTOMER";
export const GET_CUSTOMER_LIST_FOR_DASHBOARD =
  "GET_CUSTOMER_LIST_FOR_DASHBOARD";
export const GET_CUSTOMER_LIST_FOR_DASHBOARD_SUCCESS =
  "GET_CUSTOMER_LIST_FOR_DASHBOARD_SUCCESS";
export const GET_CUSTOMER_LIST_FOR_DASHBOARD_FAILURE =
  "GET_CUSTOMER_LIST_FOR_DASHBOARD_FAILURE";

// Compare Trends Types
export const GET_TRENDS_DATA = "GET_TRENDS_DATA";
export const GET_TRENDS_DATA_SUCCESS = "GET_TRENDS_DATA_SUCCESS";
export const GET_TRENDS_DATA_FAILURE = "GET_TRENDS_DATA_FAILURE";
export const SELECT_TRENDS_CUSTOMER = "SELECT_TRENDS_CUSTOMER";
export const GET_CUSTOMER_LIST_FOR_TRENDS = "GET_CUSTOMER_LIST_FOR_TRENDS";
export const GET_CUSTOMER_LIST_FOR_TRENDS_SUCCESS =
  "GET_CUSTOMER_LIST_FOR_TRENDS_SUCCESS";
export const GET_CUSTOMER_LIST_FOR_TRENDS_FAILURE =
  "GET_CUSTOMER_LIST_FOR_TRENDS_FAILURE";
export const SET_CURRENT_CUSTOMER_DOMAINS = "SET_CURRENT_CUSTOMER_DOMAINS";
export const CLEAR_DOMAIN_LIST = "CLEAR_DOMAIN_LIST";
//Connectors Actionstypes

export const GET_CONNECTORS = "GET_CONNECTORS";
export const GET_CONNECTORS_SUCCESS = "GET_CONNECTORS_SUCCESS";
export const GET_CONNECTORS_FAILURE = "GET_CONNECTORS_FAIURE";

export const GET_CONNECTOR_TYPES = "GET_CONNECTOR_TYPES";
export const GET_CONNECTOR_TYPES_SUCCESS = "GET_CONNECTOR_TYPES_SUCCESS";
export const GET_CONNECTOR_TYPES_FAILURE = "GET_CONNECTOR_TYPES_FAIURE";

export const ADD_CONNECTOR = "ADD_CONNECTOR";
export const ADD_CONNECTOR_SUCCESS = "ADD_CONNECTOR_SUCCESS";
export const ADD_CONNECTOR_FAILURE = "ADD_CONNECTOR_FAIURE";

export const UPDATE_CONNECTOR = "UPDATE_CONNECTOR";
export const UPDATE_CONNECTOR_SUCCESS = "UPDATE_CONNECTOR_SUCCESS";
export const UPDATE_CONNECTOR_FAILURE = "UPDATE_CONNECTOR_FAIURE";

export const DELETE_CONNECTOR = "DELETE_CONNECTOR";
export const DELETE_CONNECTOR_SUCCESS = "DELETE_CONNECTOR_SUCCESS";
export const DELETE_CONNECTOR_FAILURE = "DELETE_CONNECTOR_FAIURE";

export const TEST_CONNECTOR = "TEST_CONNECTOR";
export const TEST_CONNECTOR_SUCCESS = "TEST_CONNECTOR_SUCCESS";
export const TEST_CONNECTOR_FAILURE = "DELETE_CONNECTOR_FAIURE";

export const GET_CONNECTOR_RUNDATA = "GET_CONNECTOR_RUNDATA";
export const GET_CONNECTOR_RUNDATA_SUCCESS = "GET_CONNECTOR_RUNDATA_SUCCESS";
export const GET_CONNECTOR_RUNDATA_FAILURE = "GET_CONNECTOR_RUNDATA_FAILURE";

//Customer Registration
export const SAVE_CUSTOMER = "SAVE_CUSTOMER";
export const SAVE_CUSTOMER_SUCCESS = "SAVE_CUSTOMER_SUCCESS";
export const SAVE_CUSTOMER_FAILURE = "SAVE_CUSTOMER_FAILURE";

//Scan Cookies

export const GET_SCAN_COOKIES_REPORT = "GET_SCAN_COOKIES_REPORT";
export const GET_SCAN_COOKIES_REPORT_SUCCESS =
  "GET_SCAN_COOKIES_REPORT_SUCCESS";
export const GET_SCAN_COOKIES_REPORT_FAILURE =
  "GET_SCAN_COOKIES_REPORT_FAILURE";

export const SCAN_NON_GRAVITO_DOMAIN = "SCAN_NON_GRAVITO_DOMAIN";
export const SCAN_NON_GRAVITO_DOMAIN_SUCCESS =
  "SCAN_NON_GRAVITO_DOMAIN_SUCCESS";
export const SCAN_NON_GRAVITO_DOMAIN_FAILURE =
  "SCAN_NON_GRAVITO_DOMAIN_FAILURE";

export const NEW_SCAN_COOKIES_REPORT = "NEW_SCAN_COOKIES_REPORT";

export const GET_COOKIES_SCAN_HISTORY = "GET_COOKIES_SCAN_HISTORY";
export const GET_COOKIES_SCAN_HISTORY_SUCCESS =
  "GET_COOKIES_SCAN_HISTORY_SUCCESS";
export const GET_COOKIES_SCAN_HISTORY_FAILURE =
  "GET_COOKIES_SCAN_HISTORY_FAILURE";

export const GET_COOKIES_DESCRIPTIONS = "GET_COOKIES_DESCRIPTIONS";
export const GET_COOKIES_DESCRIPTIONS_SUCCESS =
  "GET_COOKIES_DESCRIPTIONS_SUCCESS";
export const GET_COOKIES_DESCRIPTIONS_FAILURE =
  "GET_COOKIES_DESCRIPTIONS_FAILURE";

export const ADD_COOKIES_DESCRIPTIONS = "ADD_COOKIES_DESCRIPTIONS";
export const ADD_COOKIES_DESCRIPTIONS_SUCCESS =
  "ADD_COOKIES_DESCRIPTIONS_SUCCESS";
export const ADD_COOKIES_DESCRIPTIONS_FAILURE =
  "ADD_COOKIES_DESCRIPTIONS_FAILURE";

export const UPDATE_COOKIES_DESCRIPTIONS = "UPDATE_COOKIES_DESCRIPTIONS";
export const UPDATE_COOKIES_DESCRIPTIONS_SUCCESS =
  "UPDATE_COOKIES_DESCRIPTIONS_SUCCESS";
export const UPDATE_COOKIES_DESCRIPTIONS_FAILURE =
  "UPDATE_COOKIES_DESCRIPTIONS_FAILURE";

export const DELETE_COOKIES_DESCRIPTIONS = "DELETE_COOKIES_DESCRIPTIONS";
export const DELETE_COOKIES_DESCRIPTIONS_SUCCESS =
  "DELETE_COOKIES_DESCRIPTIONS_SUCCESS";
export const DELETE_COOKIES_DESCRIPTIONS_FAILURE =
  "DELETE_COOKIES_DESCRIPTIONS_FAILURE";

export const SEARCH_COOKIES_DESCRIPTION = "SEARCH_COOKIES_DESCRIPTION";

export const FILTER_COOKIES_DESCRIPTION = "FILTER__COOKIES_DESCRIPTION";

export const GET_COOKIE_SCAN_LANGAUGES = "GET_COOKIE_SCAN_LANGAUGES";
export const GET_COOKIE_SCAN_LANGAUGES_SUCCESS =
  "GET_COOKIE_SCAN_LANGAUGES_SUCCESS";
export const GET_COOKIE_SCAN_LANGAUGES_FAILURE =
  "GET_COOKIE_SCAN_LANGAUGES_FAILURE";

// ADMIN PROFILE Types

export const GET_CURRENT_CUSTOMER = "GET_CURRENT_CUSTOMER";
export const GET_CURRENT_CUSTOMER_SUCCESS = "GET_CURRENT_CUSTOMER_SUCCESS";
export const GET_CURRENT_CUSTOMER_FAILURE = "GET_CURRENT_CUSTOMER_FAILURE";

//Customer Update for basic info
export const SAVE_CUSTOMER_BASIC_INFO = "SAVE_CUSTOMER_BASIC_INFO";
export const SAVE_CUSTOMER_BASIC_INFO_SUCCESS =
  "SAVE_CUSTOMER_BASIC_INFO_SUCCESS";
export const SAVE_CUSTOMER_BASIC_INFO_FAILURE =
  "SAVE_CUSTOMER_BASIC_INFO_FAILURE";

//chnage cost center month
export const CHANGE_COST_CENTER_MONTH = "CHANGE_COST_CENTER_MONTH";

//Register new domains for a customer
export const REGISTER_NEW_DOMAINS = "REGISTER_NEW_DOMAINS";
export const REGISTER_NEW_DOMAINS_SUCCESS = "REGISTER_NEW_DOMAINS_SUCCESS";
export const REGISTER_NEW_DOMAINS_FAILURE = "REGISTER_NEW_DOMAINS_FAILURE";

//Create new voucher
export const CREATE_MANUAL_VOUCHER = "CREATE_MANUAL_VOUCHER";
export const CREATE_MANUAL_VOUCHER_SUCCESS = "CREATE_MANUAL_VOUCHER_SUCCESS";
export const CREATE_MANUAL_VOUCHER_FAILURE = "CREATE_MANUAL_VOUCHER_FAILURE";

// get vouchers for current customer
export const GET_CURRENT_CUSTOMER_VOUCHERS = "GET_CURRENT_CUSTOMER_VOUCHERS";
export const GET_CURRENT_CUSTOMER_VOUCHERS_SUCCESS =
  "GET_CURRENT_CUSTOMER_VOUCHERS_SUCCESS";
export const GET_CURRENT_CUSTOMER_VOUCHERS_FAILURE =
  "GET_CURRENT_CUSTOMER_VOUCHERS_FAILURE";

// redeem voucher by code
export const REDEEM_VOUCHER = "REDEEM_VOUCHER";
export const REDEEM_VOUCHER_SUCCESS = "REDEEM_VOUCHER_SUCCESS";
export const REDEEM_VOUCHER_FAILURE = "REDEEM_VOUCHER_FAILURE";

//Get vouchers list for gravito admin user
export const GET_VOUCHERS = "GET_VOUCHERS";
export const GET_VOUCHERS_SUCCESS = "GET_VOUCHERS_SUCCESS";
export const GET_VOUCHERS_FAILURE = "GET_VOUCHERS_FAILURE";

//Sort vouchers list for gravito admin user
export const FILTER_VOUCHERS_BY_STATUS = "FILTER_VOUCHERS_BY_STATUS";

//Get voucher by code
export const GET_VOUCHER_BY_CODE = "GET_VOUCHER_BY_CODE";
export const GET_VOUCHER_BY_CODE_SUCCESS = "GET_VOUCHER_BY_CODE_SUCCESS";
export const GET_VOUCHER_BY_CODE_FAILURE = "GET_VOUCHER_BY_CODE_FAILURE";

// Send invite to a team member
export const INVITE_TEAMMEMBER = "INVITE_TEAMMEMBER";
export const INVITE_TEAMMEMBER_SUCCESS = "INVITE_TEAMMEMBER_SUCCESS";
export const INVITE_TEAMMEMBER_FAILURE = "INVITE_TEAMMEMBER_FAILURE";

/*
  TYPES for Team member Invite Confirmation
*/
// Confirm Invitation
export const CONFIRM_TEAMMEMBER_INVITE = "CONFIRM_TEAMMEMBER_INVITE";
export const CONFIRM_TEAMMEMBER_INVITE_SUCCESS =
  "CONFIRM_TEAMMEMBER_INVITE_SUCCESS";
export const CONFIRM_TEAMMEMBER_INVITE_FAILURE =
  "CONFIRM_TEAMMEMBER_INVITE_FAILURE";

// Delete team member
export const DELETE_TEAMMEMBER = "DELETE_TEAMMEMBER";
export const DELETE_TEAMMEMBER_SUCCESS = "DELETE_TEAMMEMBER_SUCCESS";
export const DELETE_TEAMMEMBER_FAILURE = "DELETE_TEAMMEMBER_FAILURE";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const GET_LEDGER_BALANCE = "GET_LEDGER_BALANCE";
export const GET_LEDGER_BALANCE_SUCCESS = "GET_LEDGER_BALANCE_SUCCESS";
export const GET_LEDGER_BALANCE_FAILURE = "GET_LEDGER_BALANCE_SUCCESS";

// Manage Licenses functionality in Manage account

export const GET_DOMAIN_LICENSES = "GET_DOMAIN_LICENSES";

export const ADD_DOMAIN_LICENSE = "ADD_DOMAIN_LICENSE";
export const ADD_DOMAIN_LICENSE_SUCCESS = "ADD_DOMAIN_LICENSE_SUCCESS";
export const ADD_DOMAIN_LICENSE_FAILURE = "ADD_DOMAIN_LICENSE_FAILURE";

// Activate of deactivate domain license.
export const UPDATE_DOMAIN_LICENSE = "UPDATE_DOMAIN_LICENSE";
export const UPDATE_DOMAIN_LICENSE_SUCCESS = "UPDATE_DOMAIN_LICENSE_SUCCESS";
export const UPDATE_DOMAIN_LICENSE_FAILURE = "UPDATE_DOMAIN_LICENSE_FAILURE";

export const GET_FIRSTPARTY_DOMAINS = "GET_FIRSTPARTY_DOMAINS";
export const GET_FIRSTPARTY_DOMAINS_SUCCESS = "GET_FIRSTPARTY_DOMAINS_SUCCESS";
export const GET_FIRSTPARTY_DOMAINS_FAILURE = "GET_FIRSTPARTY_DOMAINS_FAILURE";

/** Manage requests functionality for Gravito Admin */

export const GET_DOMAIN_REQUESTS = "GET_DOMAIN_REQUESTS";
export const GET_DOMAIN_REQUESTS_SUCCESS = "GET_DOMAIN_REQUESTS_SUCCESS";
export const GET_DOMAIN_REQUESTS_FAILURE = "GET_DOMAIN_REQUESTS_FAILURE";
export const PROCESS_DOMAIN_REQUESTS = "PROCESS_DOMAIN_REQUESTS";
export const PROCESS_DOMAIN_REQUESTS_SUCCESS =
  "PROCESS_DOMAIN_REQUESTS_SUCCESS";
export const PROCESS_DOMAIN_REQUESTS_FAILURE =
  "PROCESS_DOMAIN_REQUESTS_FAILURE";

// PARTNER MANAGEMENT FUNCTIONALITY FOR GRAVITO ADMIN
export const GET_PARTNERS = "GET_PARTNERS";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAILURE = "GET_PARTNERS_FAILURE";
export const ADD_PARTNER = "ADD_PARTNER";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_FAILURE = "ADD_PARTNER_FAILURE";
export const UPDATE_PARTNER = "UPDATE_PARTNER";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAILURE = "UPDATE_PARTNER_FAILURE";
export const DEACTIVATE_PARTNER = "DEACTIVATE_PARTNER";
export const DEACTIVATE_PARTNER_SUCCESS = "DEACTIVATE_PARTNER_SUCCESS";
export const DEACTIVATE_PARTNER_FAILURE = "DEACTIVATE_PARTNER_FAILURE";
export const ASSIGN_CUSTOMER_TO_PARTNER = "ASSIGN_CUSTOMER_TO_PARTNER";
export const ASSIGN_CUSTOMER_TO_PARTNER_SUCCESS =
  "ASSIGN_CUSTOMER_TO_PARTNER_SUCCESS";
export const ASSIGN_CUSTOMER_TO_PARTNER_FAILURE =
  "ASSIGN_CUSTOMER_TO_PARTNER_FAILURE";
export const ADD_PARTNER_USER = "ADD_PARTNER_USER";
export const ADD_PARTNER_USER_SUCCESS = "ADD_PARTNER_USER_SUCCESS";
export const ADD_PARTNER_USER_FAILURE = "ADD_PARTNER_USER_FAILURE";
export const EDIT_PARTNER_USER = "EDIT_PARTNER_USER";
export const EDIT_PARTNER_USER_SUCCESS = "EDIT_PARTNER_USER_SUCCESS";
export const EDIT_PARTNER_USER_FAILURE = "EDIT_PARTNER_USER_FAILURE";

export const REMOVE_PARTNER_USER = "REMOVE_PARTNER_USER";
export const REMOVE_PARTNER_USER_SUCCESS = "REMOVE_PARTNER_USER_SUCCESS";
export const REMOVE_PARTNER_USER_FAILURE = "REMOVE_PARTNER_USER_FAILURE";
export const SEARCH_PARTNER = "SEARCH_PARTNER";
export const SELECT_PARTNER = "SELECT_PARTNER";
export const GET_PARTNER_CUSTOMERS = "GET_PARTNER_CUSTOMERS";
export const GET_PARTNER_CUSTOMERS_SUCCESS = "GET_PARTNER_CUSTOMERS_SUCCESS";
export const GET_PARTNER_CUSTOMERS_FAILURE = "GET_PARTNER_CUSTOMERS_FAILURE";
export const GET_PARTNERS_BY_ID = "GET_PARTNERS_BY_ID";
export const GET_PARTNERS_BY_ID_SUCCESS = "GET_PARTNERS_BY_ID_SUCCESS";
export const GET_PARTNERS_BY_ID_FAILURE = "GET_PARTNERS_BY_ID_FAILURE";

// CUSTOMER DOMAIN SCAN MANAGEMENT FUNCTIONALITY FOR GRAVITO ADMIN
// export const GET_CUSTOMER_DOMAINS = "GET_CUSTOMER_DOMAINS";
// export const GET_CUSTOMER_DOMAINS_SUCCESS = "GET_CUSTOMER_DOMAINS_SUCCESS";
// export const GET_CUSTOMER_DOMAINS_FAILURE = "GET_CUSTOMER_DOMAINS_FAILURE";
// export const ADD_PARTNER = "ADD_PARTNER";
// export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
// export const ADD_PARTNER_FAILURE = "ADD_PARTNER_FAILURE";
// export const UPDATE_PARTNER = "UPDATE_PARTNER";
// export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
// export const UPDATE_PARTNER_FAILURE = "UPDATE_PARTNER_FAILURE";
// export const DEACTIVATE_PARTNER = "DEACTIVATE_PARTNER";
// export const DEACTIVATE_PARTNER_SUCCESS = "DEACTIVATE_PARTNER_SUCCESS";
// export const DEACTIVATE_PARTNER_FAILURE = "DEACTIVATE_PARTNER_FAILURE";
// export const ASSIGN_CUSTOMER_TO_PARTNER = "ASSIGN_CUSTOMER_TO_PARTNER";
// export const ASSIGN_CUSTOMER_TO_PARTNER_SUCCESS =
//   "ASSIGN_CUSTOMER_TO_PARTNER_SUCCESS";
// export const ASSIGN_CUSTOMER_TO_PARTNER_FAILURE =
//   "ASSIGN_CUSTOMER_TO_PARTNER_FAILURE";
// export const ADD_PARTNER_USER = "ADD_PARTNER_USER";
// export const ADD_PARTNER_USER_SUCCESS = "ADD_PARTNER_USER_SUCCESS";
// export const ADD_PARTNER_USER_FAILURE = "ADD_PARTNER_USER_FAILURE";
// export const EDIT_PARTNER_USER = "EDIT_PARTNER_USER";
// export const EDIT_PARTNER_USER_SUCCESS = "EDIT_PARTNER_USER_SUCCESS";
// export const EDIT_PARTNER_USER_FAILURE = "EDIT_PARTNER_USER_FAILURE";

// export const REMOVE_PARTNER_USER = "REMOVE_PARTNER_USER";
// export const REMOVE_PARTNER_USER_SUCCESS = "REMOVE_PARTNER_USER_SUCCESS";
// export const REMOVE_PARTNER_USER_FAILURE = "REMOVE_PARTNER_USER_FAILURE";
// export const SELECT_PARTNER = "SELECT_PARTNER";
// export const GET_PARTNER_CUSTOMERS = "GET_PARTNER_CUSTOMERS";
// export const GET_PARTNER_CUSTOMERS_SUCCESS = "GET_PARTNER_CUSTOMERS_SUCCESS";
// export const GET_PARTNER_CUSTOMERS_FAILURE = "GET_PARTNER_CUSTOMERS_FAILURE";
// export const GET_PARTNERS_BY_ID = "GET_PARTNERS_BY_ID";
// export const GET_PARTNERS_BY_ID_SUCCESS = "GET_PARTNERS_BY_ID_SUCCESS";
// export const GET_PARTNERS_BY_ID_FAILURE = "GET_PARTNERS_BY_ID_FAILURE";

// PARTNER CUSTOMER MANAGEMENT
export const GET_PARTNER_CUSTOMER_LIST = "GET_PARTNER_CUSTOMER_LIST";
export const GET_PARTNER_CUSTOMER_LIST_SUCCESS =
  "GET_PARTNER_CUSTOMER_LIST_SUCCESS";
export const GET_PARTNER_CUSTOMER_LIST_FAILURE =
  "GET_PARTNER_CUSTOMER_LIST_FAILURE";
export const ADD_PARTNER_CUSTOMER = "ADD_PARTNER_CUSTOMER";
export const ADD_PARTNER_CUSTOMER_SUCCESS = "ADD_PARTNER_CUSTOMER_SUCCESS";
export const ADD_PARTNER_CUSTOMER_FAILURE = "ADD_PARTNER_CUSTOMER_FAILURE";
export const UPDATE_PARTNER_CUSTOMER = "UPDATE_PARTNER_CUSTOMER";
export const UPDATE_PARTNER_CUSTOMER_SUCCESS =
  "UPDATE_PARTNER_CUSTOMER_SUCCESS";
export const UPDATE_PARTNER_CUSTOMER_FAILURE =
  "UPDATE_PARTNER_CUSTOMER_FAILURE";
export const DELETE_PARTNER_CUSTOMER = "DELETE_PARTNER_CUSTOMER";
export const DELETE_PARTNER_CUSTOMER_SUCCESS =
  "DELETE_PARTNER_CUSTOMER_SUCCESS";
export const DELETE_PARTNER_CUSTOMER_FAILURE =
  "DELETE_PARTNER_CUSTOMER_FAILURE";
export const FILTER_PARTNER_CUSTOMER_LIST = "FILTER_PARTNER_CUSTOMER_LIST";
export const SELECT_PARTNER_CUSTOMER = "SELECT_PARTNER_CUSTOMER";

/** Actions for the GVL Update feed feature */
export const GET_GVL_VERSIONS = "GET_GVL_VERSIONS";
export const GET_GVL_VERSIONS_SUCCESS = "GET_GVL_VERSIONS_SUCCESS";
export const GET_GVL_VERSIONS_FAILURE = "GET_GVL_VERSIONS_FAILURE";

export const COMPARE_GVL_VERSIONS = "COMPARE_GVL_VERSIONS";
export const COMPARE_GVL_VERSIONS_SUCCESS = "COMPARE_GVL_VERSIONS_SUCCESS";
export const COMPARE_GVL_VERSIONS_FAILURE = "COMPARE_GVL_VERSIONS_FAILURE";

export const FILTER_GVL_BY_STATUS = "FILTER_GVL_BY_STATUS";
export const FILTER_GVL_BY_STATUS_SUCCESS = "FILTER_GVL_BY_STATUS_SUCCESS";
export const FILTER_GVL_BY_STATUS_FAILURE = "FILTER_GVL_BY_STATUS_FAILURE";

export const FILTER_GVL_FOR_VENDORS = "FILTER_GVL_FOR_VENDORS";
export const FILTER_GVL_FOR_VENDORS_SUCCESS = "FILTER_GVL_FOR_VENDORS_SUCCESS";
export const FILTER_GVL_FOR_VENDORS_FAILURE = "FILTER_GVL_FOR_VENDORS_FAILURE";

export const PURCHASE_CREDITS = "PURCHASE_CREDITS";
export const PURCHASE_CREDITS_SUCCESS = "PURCHASE_CREDIT_SUCCESS";
export const PURCHASE_CREDITS_FAILURE = "PURCHASE_CREDITS_FAILURE";

export const GET_CREDITS_LIST = "GET_CREDITS_LIST";
export const GET_CREDITS_LIST_SUCCESS = "GET_CREDITS_LIST_SUCCESS";
export const GET_CREDITS_LIST_FAILURE = "GET_CREDITS_LIST_SUCCESS";

//Domain Counts App

export const GET_GRAVITO_DOMAIN_COUNT = "GET_GRAVITO_DOMAIN_COUNT";
export const GET_GRAVITO_DOMAIN_COUNT_SUCCESS =
  "GET_GRAVITO_DOMAIN_COUNT_SUCCESS";
export const GET_GRAVITO_DOMAIN_COUNT_FAILURE =
  "GET_GRAVITO_DOMAIN_COUNT_FAILURE";

export const GET_GRAVITO_FP_DOMAIN_COUNT = "GET_GRAVITO_FP_DOMAIN_COUNT";
export const GET_GRAVITO_FP_DOMAIN_COUNT_SUCCESS =
  "GET_GRAVITO_FP_DOMAIN_COUNT_SUCCESS";
export const GET_GRAVITO_FP_DOMAIN_COUNT_FAILURE =
  "GET_GRAVITO_FP_DOMAIN_COUNT_FAILURE";

export const GET_GRAVITO_FP_DOMAINS = "GET_GRAVITO_FP_DOMAINS";
export const GET_GRAVITO_FP_DOMAINS_SUCCESS = "GET_GRAVITO_FP_DOMAINS_SUCCESS";
export const GET_GRAVITO_FP_DOMAINS_FAILURE = "GET_GRAVITO_FP_DOMAINS_FAILURE";

export const CERTIFICATE_CHECK = "CERTIFICATE_CHECK";
export const CERTIFICATE_CHECK_SUCCESS = "CERTIFICATE_CHECK_SUCCESS";
export const CERTIFICATE_CHECK_FAILURE = "CERTIFICATE_CHECK_FAILURE";
export const GET_ALL_FP_APPS = "GET_ALL_FP_APPS";
export const GET_ALL_FP_APPS_SUCCESS = "GET_ALL_FP_APPS_SUCCESS";
export const GET_ALL_FP_APPS_FAILURE = "GET_ALL_FP_APPS_FAILURE";

export const SEARCH_FP_APPS = "SEARCH_FP_APPS";
// export const SEARCH_FP_APPS_SUCCESS = "SEARCH_FP_APPS_SUCCESS";
// export const SEARCH_FP_APPS_FAILURE = "SEARCH_FP_APPS_FAILURE";

export const ADD_FP_APP = "ADD_FP_APP";
export const ADD_FP_APP_SUCCESS = "ADD_FP_APP_SUCCESS";
export const ADD_FP_APP_FAILURE = "ADD_FP_APP_FAILURE";

export const UPDATE_FP_APP = "UPDATE_FP_APP";
export const UPDATE_FP_APP_SUCCESS = "UPDATE_FP_APP_SUCCESS";
export const UPDATE_FP_APP_FAILURE = "UPDATE_FP_APP_FAILURE";

// Customer Domain Apps
export const GET_ALL_CD_APPS = "GET_ALL_CD_APPS";
export const GET_ALL_CD_APPS_SUCCESS = "GET_ALL_CD_APPS_SUCCESS";
export const GET_ALL_CD_APPS_FAILURE = "GET_ALL_CD_APPS_FAILURE";

export const SEARCH_CD_APPS = "SEARCH_CD_APPS";
export const FILTER_CD_APPS_BY_STATUS = "FILTER_CD_APPS_BY_STATUS";
// export const SEARCH_CD_APPS_SUCCESS = "SEARCH_CD_APPS_SUCCESS";
// export const SEARCH_CD_APPS_FAILURE = "SEARCH_CD_APPS_FAILURE";

export const ADD_CD_APP = "ADD_CD_APP";
export const ADD_CD_APP_SUCCESS = "ADD_CD_APP_SUCCESS";
export const ADD_CD_APP_FAILURE = "ADD_CD_APP_FAILURE";

export const UPDATE_CD_APP = "UPDATE_CD_APP";
export const UPDATE_CD_APP_SUCCESS = "UPDATE_CD_APP_SUCCESS";
export const UPDATE_CD_APP_FAILURE = "UPDATE_CD_APP_FAILURE";

export const DELETE_CD_APP = "DELETE_CD_APP";
export const DELETE_CD_APP_SUCCESS = "DELETE_CD_APP_SUCCESS";
export const DELETE_CD_APP_FAILURE = "DELETE_CD_APP_FAILURE";

export const STATUS_CHANGE_CD_APP = "STATUS_CHANGE_CD_APP";
export const STATUS_CHANGE_CD_APP_FAILURE = "STATUS_CHANGE_CD_APP_FAILURE";
export const STATUS_CHANGE_CD_APP_SUCCESS = "STATUS_CHANGE_CD_APP_SUCCESS";
