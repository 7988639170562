/* eslint-disable no-dupe-keys */
/* eslint-disable import/no-anonymous-default-export */
/*
    Constellation Reducer
    Curd Operations on Constellation.
*/

import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_CONSTELLATION,
  ADD_CONSTELLATION_FAILURE,
  ADD_CONSTELLATION_SUCCESS,
  DELETE_CONSTELLATION,
  DELETE_CONSTELLATION_FAILURE,
  DELETE_CONSTELLATION_SUCCESS,
  FILLTER_CONSTELLATION,
  FILLTER_CONSTELLATION_BY_TYPE,
  GET_CHANNELS,
  GET_CHANNELS_FAILURE,
  GET_CHANNELS_SUCCESS,
  GET_CONSTELLATION,
  GET_CONSTELLATION_BY_ID,
  GET_CONSTELLATION_BY_ID_FAILURE,
  GET_CONSTELLATION_BY_ID_SUCCESS,
  GET_CONSTELLATION_CONTENTS,
  GET_CONSTELLATION_CONTENTS_FAILURE,
  GET_CONSTELLATION_CONTENTS_SUCCESS,
  GET_CONSTELLATION_DOMAINS,
  GET_CONSTELLATION_DOMAINS_FAILURE,
  GET_CONSTELLATION_DOMAINS_SUCCESS,
  GET_CONSTELLATION_FAILURE,
  GET_CONSTELLATION_SUCCESS,
  PUBLISH_CONSTELLATION,
  PUBLISH_CONSTELLATION_FAILURE,
  PUBLISH_CONSTELLATION_SUCCESS,
  REFRESH_CONSTELLATION,
  SELECT_CONSTELLATION,
  SEND_UAT_EMAIL,
  SEND_UAT_EMAIL_FAILURE,
  SEND_UAT_EMAIL_SUCCESS,
  UPDATE_CONSTELLATION,
  UPDATE_CONSTELLATION_FAILURE,
  UPDATE_CONSTELLATION_SUCCESS,
} from "../actions/types";

// initial state, channels are hardcoded for the time being, these should be populated from the server metdata.
const INIT_STATE = {
  error: null,
  domains: null,
  constellations: [],
  filteredConstellations: [],
  constellation: null,
  searchText: "",
  filterType: "",
  action: "",
  uat: false,
  loading: false,
  channel: [
    {
      name: "SMS",
      value: 1,
      ContentType: ["simpletext"],
    },
    {
      name: "Email",
      value: 0,
      ContentType: ["simpletext", "heroimage", "subject", "body", "rawhtml"],
    },
    {
      name: "Mobile",
      value: 2,
      ContentType: ["heroimage", "simpletext"],
    },
    {
      name: "Web",
      value: 3,
      ContentType: ["heroimage", "simpletext", "rawhtml", "body"],
    },
    {
      name: "Leaflet",
      value: 4,
      ContentType: ["simpletext", "heroimage", "body"],
    },
    {
      name: "Webhook",
      value: 5,
      ContentType: ["heroimage", "simpletext"],
    },
    {
      name: "Ad",
      value: 6,
      ContentType: ["heroimage", "body", "rawhtml"],
    },
  ],
  contents: null,
  redirect: false,
  selectedConstellation: null,
  constellationContents: null,
  publishedContent: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get the channels...
    case GET_CHANNELS:
      return { ...state, loading: true };
    case GET_CHANNELS_SUCCESS:
      return { ...state, loading: false, channel: state.channel };
    case GET_CHANNELS_FAILURE:
      NotificationManager.error("Channels not found");
      return { ...state, loading: false, error: "channels not found" };

    // Get Constellation Contents.
    case GET_CONSTELLATION_CONTENTS:
      return { ...state, loading: true, redirect: false };
    // On success populate contents...
    case GET_CONSTELLATION_CONTENTS_SUCCESS:
      return { ...state, loading: false, contents: action.payload.data };
    // On failure show error....
    case GET_CONSTELLATION_CONTENTS_FAILURE:
      NotificationManager.error(
        "Oops, we could not load your constellations, please try again later."
      );
      return { ...state, loading: false, contents: null };
    // Get constellations created by the currently logged in user...
    case GET_CONSTELLATION:
      return { ...state, loading: true };
    // On Success populate constellations and filtered constellations which is used for filtering.
    case GET_CONSTELLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        constellations: action.payload.data,
        filteredConstellations: action.payload.data,
        constellation: null,
        contents: null,
        selectedConstellation: null,
        redirect: false,
        error: null,
      };
    // On Failure populate error attribute in state.
    case GET_CONSTELLATION_FAILURE:
      return { ...state, loading: false, error: action.payload };
    // Select constellation from list.
    case SELECT_CONSTELLATION:
      if (action.payload === "") {
        return { ...state, error: "Invalid Constellation" };
      } else {
        return {
          ...state,
          loading: true,
          selectedConstellation: action.payload,
          uat: action.payload.uat,
        };
      }
    // Get constellation by id for edit.
    case GET_CONSTELLATION_BY_ID:
      return { ...state, loading: true };
    // On Success, populate selected constellation and also contents required for constellations of given channel.
    case GET_CONSTELLATION_BY_ID_SUCCESS:
      // Get the channel content type configuration
      var channelValues = _.find(state.channel, {
        value: state.selectedConstellation.channel,
      });
      var contentList = channelValues.ContentType;

      var ChannelContentList = [];
      for (let i = 0; contentList.length > i; i++) {
        var contentDis = _.filter(action.payload.contents, {
          contentType: contentList[i],
        });
        if (contentDis) {
          contentDis.forEach((element) => {
            ChannelContentList.push(element);
          });
        }
      }
      return {
        ...state,
        loading: false,
        constellationContents: action.payload.constellation,
        contents: ChannelContentList,
        loading: false,
      };
    // on Failure show error....
    case GET_CONSTELLATION_BY_ID_FAILURE:
      NotificationManager.error(
        "Oops, we could not load selected constellation, please try again later."
      );
      return { ...state, loading: false, error: "Invalid Constellation Id" };
    // Refresh constellation after crud operations...
    case REFRESH_CONSTELLATION:
      return {
        ...state,
        constellations: action.payload.data,
        filteredConstellations: action.payload.data,
        loading: false,
      };

    // Add new constellation
    case ADD_CONSTELLATION:
      if (action.payload === "") {
        return { ...state, error: "Please enter valid content details" };
      } else {
        if (action.payload.channel !== 5) {
          delete action.payload.webHook;
        } else {
          action.payload.webHook.headerValues.forEach((element) => {
            delete element.id;
          });
        }
        return {
          ...state,
          loading: true,
          constellation: action.payload,
          action: "ADD",
        };
      }

    // On Success, show message and redirect to listing page.
    case ADD_CONSTELLATION_SUCCESS:
      NotificationManager.success("constellation added successfully");
      return { ...state, loading: false, constellation: null, redirect: true };
    // On failure show error message.
    case ADD_CONSTELLATION_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, constellation: null };

    // Edit selected constellation...
    case UPDATE_CONSTELLATION:
      return { ...state, loading: true, constellation: action.payload };
    // On Success show message and redirect to listing page
    case UPDATE_CONSTELLATION_SUCCESS:
      NotificationManager.success("constellation updated successfully");
      return { ...state, loading: false, constellation: null, redirect: false };
    // On Failure show error.
    case UPDATE_CONSTELLATION_FAILURE:
      NotificationManager.error("Could not update content");
      return { ...state, loading: false, constellation: null };
    // Delete constellation
    case DELETE_CONSTELLATION:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid content details" };
      } else {
        return {
          ...state,
          loading: true,
          constellation: action.payload,
          action: "DELETE",
        };
      }

    // On Success, refresh list.
    case DELETE_CONSTELLATION_SUCCESS:
      NotificationManager.success("constellation deleted successfully");
      return { ...state, loading: true, constellation: null };

    // On failure show error message.
    case DELETE_CONSTELLATION_FAILURE:
      NotificationManager.error("Could not delete content");
      return { ...state, loading: false, constellation: null };
    // Filter constellation.
    case FILLTER_CONSTELLATION:
      if (action.payload === "") {
        return { ...state, filteredConstellations: state.constellations };
      } else {
        const filtered = state.constellations.filter(
          (item) =>
            item.title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredConstellations: filtered,
          searchText: action.payload.toLowerCase(),
        };
      }

    // Filter constellation by type...
    case FILLTER_CONSTELLATION_BY_TYPE:
      if (action.payload === "") {
        return { ...state, filteredConstellations: state.constellations };
      } else {
        const filtered = state.constellations.filter(
          (item) => item.channel === action.payload
        );
        return {
          ...state,
          filteredConstellations: filtered,
          filterType: action.payload,
        };
      }

    // Send UAT email
    case SEND_UAT_EMAIL:
      return { ...state, loading: true };

    // On Success, show message and redirect to listing page.
    case SEND_UAT_EMAIL_SUCCESS:
      NotificationManager.success("Email has been sent to you!");
      return {
        ...state,
        loading: false,
        uat: true,
        selectedConstellation: action.payload.data,
      };
    // On failure show error message.
    case SEND_UAT_EMAIL_FAILURE:
      NotificationManager.error(
        "We encountered a problem while sending UAT email. Please try again later."
      );
      return { ...state, loading: false };

    // Publish Constellation
    case PUBLISH_CONSTELLATION:
      return { ...state, loading: true };

    // On Success, show message and redirect to listing page.
    case PUBLISH_CONSTELLATION_SUCCESS:
      NotificationManager.success("Constellation published successfully.");
      return { ...state, loading: false, redirect: true };
    // On failure show error message.
    case PUBLISH_CONSTELLATION_FAILURE:
      NotificationManager.error(
        "We encountered a problem while publishing constellation. Please try again later."
      );
      return { ...state, loading: false, redirect: true };

    // Get audience domains from server.
    case GET_CONSTELLATION_DOMAINS:
      return { ...state, loading: true };
    // On Success...
    case GET_CONSTELLATION_DOMAINS_SUCCESS:
      const domains = action.payload.data.filter(
        (item) => item.isAdmin === true
      );

      return { ...state, loading: false, domains: domains, redirect: false };

    // On Failure, show error message.
    case GET_CONSTELLATION_DOMAINS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
