/**
 * Customer Registration Actions
 */
import {
SAVE_CUSTOMER,
SAVE_CUSTOMER_SUCCESS,
SAVE_CUSTOMER_FAILURE
} from './types';



/**
 * Redux Action To Register a new customer.
 */
export const customerRegistration = (customer) => ({
    type: SAVE_CUSTOMER,
    payload:customer
})

export const customerRegistrationSuccess = (response) => ({
    type: SAVE_CUSTOMER_SUCCESS,
    payload: response
});

export const customerRegistrationFailure = (error) => ({
    type: SAVE_CUSTOMER_FAILURE,
    payload: error
});

