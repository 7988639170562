/**
 * Partner management Sagas
 */
import { all, call, put, takeEvery, select } from "redux-saga/effects";
import { getAxiosRequest } from "../helpers/helpers";

import {
  getPartnersSuccess,
  getPartnersFailure,
  addPartnerSuccess,
  addPartnerFailure,
  updatePartnerSuccess,
  updatePartnerFailure,
  getPartnerCustomersSuccess,
  getPartnerCustomersFailure,
  assignCustomerToPartnerSuccess,
  assignCustomerToPartnerFailure,
  addPartnerUserSuccess,
  addPartnerUserFailure,
  getPartnerByIdSuccess,
  deactivatePartnerSuccess,
  deactivatePartnerFailure,
  removePartnerUserSuccess,
  removePartnerUserFailure,
  editPartnerUserSuccess,
  editPartnerUserFailure,
} from "../actions";

export const partnerReducer = (state) => state.partnerReducer;

const getPartnersFromServer = async () => {
  return await getAxiosRequest().get("/partner");
};

/**
 * Get customer
 */
function* getPartners() {
  try {
    const response = yield call(getPartnersFromServer);
    yield put(getPartnersSuccess(response.data));
  } catch (error) {
    yield put(getPartnersFailure(error.response.data.error));
  }
}

const deletePartner = async (id) => {
  return await getAxiosRequest().delete(`/partner/${id}`);
};

/**
 * deactivate partner
 */
function* deactivatePartner() {
  try {
    let partner = yield select(partnerReducer);
    const response = yield call(deletePartner, partner.deactivatePartnerId);
    yield put(deactivatePartnerSuccess(response.data));
    const refPartner = yield call(getPartners);
    yield put(getPartnersSuccess(refPartner.data));
  } catch (error) {
    yield put(deactivatePartnerFailure(error.response.data.error));
  }
}

const getPartnerByIdFromServer = async (id) => {
  return await getAxiosRequest().get(`/partner/${id}`);
};

/**
 * Get customer
 */

const getCustomersFromServer = async () => {
  return await getAxiosRequest().get("/customer");
};

/**
 * Get customer
 */
function* getPartnerCustomers() {
  try {
    let partner = yield select(partnerReducer);

    const response = yield call(getCustomersFromServer);
    yield put(getPartnerCustomersSuccess(response.data));
  } catch (error) {
    yield put(getPartnerCustomersFailure(error.response.data.error));
  }
}

const updatePartnerToServer = async (partnerDetail) => {
  return await getAxiosRequest().put("/partner", partnerDetail);
};

function* updatePartner() {
  try {
    let partner = yield select(partnerReducer);
    const response = yield call(updatePartnerToServer, partner.partnerDetail);
    yield put(updatePartnerSuccess(response));
    const refPartner = yield call(
      getPartnerByIdFromServer,
      partner.selected.id
    );
    yield put(getPartnerByIdSuccess(refPartner.data));
  } catch (error) {
    yield put(updatePartnerFailure(error.response.data.error));
  }
}

const addPartnerToServer = async (partnerDetail) => {
  return await getAxiosRequest().post("/partner", partnerDetail);
};

function* addPartner() {
  try {
    let partner = yield select(partnerReducer);
    const response = yield call(addPartnerToServer, partner.partnerDetail);
    yield put(addPartnerSuccess(response));
    const refresponse = yield call(getPartnersFromServer);
    yield put(getPartnersSuccess(refresponse.data));
  } catch (error) {
    yield put(addPartnerFailure(error.response.data.error));
  }
}

const customerToPartner = async (model) => {
  return await getAxiosRequest().put(
    `/partner/${model.partnerId}/customer/${model.customerId}/assign/${model.assign}`
  );
};

/**
 * Assign customer to partner
 */
function* assignCustomerToPartner() {
  try {
    let partner = yield select(partnerReducer);

    const response = yield call(customerToPartner, partner.addCustomerModel);
    yield put(assignCustomerToPartnerSuccess(response.data));
    const refPartner = yield call(
      getPartnerByIdFromServer,
      partner.selected.id
    );
    yield put(getPartnerByIdSuccess(refPartner.data));
  } catch (error) {
    yield put(assignCustomerToPartnerFailure(error.response.data.error));
  }
}

const partnerUser = async (model) => {
  return await getAxiosRequest().post(
    `/partner/${model.partnerId}/user`,
    model
  );
};

/**
 * Add new partner user
 */
function* addNewPartnerUser() {
  try {
    let partner = yield select(partnerReducer);

    const response = yield call(partnerUser, partner.addUserModel);
    yield put(addPartnerUserSuccess(response.data));
    const refPartner = yield call(
      getPartnerByIdFromServer,
      partner.selected.id
    );
    yield put(getPartnerByIdSuccess(refPartner.data));
  } catch (error) {
    yield put(addPartnerUserFailure(error.response.data.error));
  }
}

/**
 * Edit partner user
 */

const editPartnerMember = async (model) => {
  return await getAxiosRequest().put(`/partner/${model.partnerId}/user`, model);
};

function* editPartnerUser() {
  try {
    let partner = yield select(partnerReducer);

    const response = yield call(editPartnerMember, partner.addUserModel);
    yield put(editPartnerUserSuccess(response.data));
    const refPartner = yield call(
      getPartnerByIdFromServer,
      partner.selected.id
    );
    yield put(getPartnerByIdSuccess(refPartner.data));
  } catch (error) {
    yield put(editPartnerUserFailure(error.response.data.error));
  }
}

const removePartnerUser = async (model) => {
  return await getAxiosRequest().delete(
    `/partner/${model.partnerId}/user/${model.userId}`
  );
};

/**
 * Add new partner user
 */
function* removeExistingPartnerUser() {
  try {
    let partner = yield select(partnerReducer);

    const response = yield call(removePartnerUser, partner.removeUserModel);
    yield put(removePartnerUserSuccess(response.data));
    const refPartner = yield call(
      getPartnerByIdFromServer,
      partner.selected.id
    );
    yield put(getPartnerByIdSuccess(refPartner.data));
  } catch (error) {
    yield put(removePartnerUserFailure(error.response.data.error));
  }
}

export const partnerSagas = [
  takeEvery("GET_PARTNERS", getPartners),
  takeEvery("UPDATE_PARTNER", updatePartner),
  takeEvery("ADD_PARTNER", addPartner),
  takeEvery("GET_PARTNER_CUSTOMERS", getPartnerCustomers),
  takeEvery("ASSIGN_CUSTOMER_TO_PARTNER", assignCustomerToPartner),
  takeEvery("ADD_PARTNER_USER", addNewPartnerUser),
  takeEvery("REMOVE_PARTNER_USER", removeExistingPartnerUser),
  takeEvery("DEACTIVATE_PARTNER", deactivatePartner),
  takeEvery("EDIT_PARTNER_USER", editPartnerUser),
];

/**
 * Default Partner Root Saga
 */
export default function* rootSaga() {
  yield all([...partnerSagas]);
}
