/**
 * Redux Actions for managing crud operations on contents.
 */

import {
    GET_CONTENTS,
    GET_CONTENTS_SUCCESS,
    GET_CONTENTS_FAILURE,
    ADD_CONTENT,
    ADD_CONTENT_SUCCESS ,
    ADD_CONTENT_FAILURE ,
    UPDATE_CONTENT,
    UPDATE_CONTENT_SUCCESS ,
    UPDATE_CONTENT_FAILURE ,
    DELETE_CONTENT ,
    DELETE_CONTENT_SUCCESS,
    DELETE_CONTENT_FAILURE,
    FILLTER_CONTENT,
    REFRESH_CONTENTS,
    FILLTER_CONTENT_BY_TYPE,
    SELECT_CONTENT,
    GET_CONTENT_DOMAINS,
    GET_CONTENT_DOMAINS_SUCCESS,
    GET_CONTENT_DOMAINS_FAILURE
} from './types';

/**
 * Redux action to get contents
 */
export const getContents =()=>({
    type: GET_CONTENTS
})

/**
 * Redux action for successful fetching of contents
 */

export const getContentsSuccess =(response)=>({
    type: GET_CONTENTS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching contents
 */
export const getContentsFailure =(error)=>({
    type: GET_CONTENTS_FAILURE,
    payload: error
})


/**
 * Redux action for fetching contents after add/edit/delete of content
 */
export const refreshContents = (response) => ({
    type: REFRESH_CONTENTS,
    payload: response
});

/**
 * Redux action for adding new content
 */
export const addContent = (model) => ({
    type: ADD_CONTENT,
    payload:model
})
/**
 * Redux action for successful addition of content
 */
export const addContentSuccess =(response)=>({
    type: ADD_CONTENT_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to add new content
 */
export const addContentFailure =(error)=>({
    type: ADD_CONTENT_FAILURE,
    payload: error
})

/**
 * Redux action for editing a content
 */
export const updateContent =(model)=>({
    type: UPDATE_CONTENT,
    payload:model
})
/**
 * Redux action for successful editing of a content
 */
export const updateContentSuccess =(response)=>({
    type: UPDATE_CONTENT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to edit a content
 */
export const updateContentFailure =(error)=>({
    type: UPDATE_CONTENT_FAILURE,
    payload: error
})

/**
 * Redux action for deleting a content
 */
export const deleteContent =(id)=>({
    type: DELETE_CONTENT,
    payload:id
})
/**
 * Redux action for successful deletion of content
 */
export const deleteContentSuccess =(response)=>({
    type: DELETE_CONTENT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in deletion of content
 */
export const deleteContentFailure =(error)=>({
    type: DELETE_CONTENT_FAILURE,
    payload: error
})

/**
 * Redux action for filtering contents from search box
 */
export const fillterContents=(keyword)=>({
    type: FILLTER_CONTENT,
    payload: keyword
})
/**
 * Redux action for filtering contents by type.
 */
export const fillterContentsByType=(type)=>({
    type: FILLTER_CONTENT_BY_TYPE,
    payload: type
})
/**
 * Redux action for selecting a content
 */
export const selectContent=(id)=>({
    type: SELECT_CONTENT,
    payload: id
})

/**
 * Redux action to get contents
 */
export const getContentDomains =()=>({
    type: GET_CONTENT_DOMAINS
})

/**
 * Redux action for successful fetching of contents
 */

export const getContentDomainsSuccess =(response)=>({
    type: GET_CONTENT_DOMAINS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching contents
 */
export const getContentDomainsFailure =(error)=>({
    type: GET_CONTENT_DOMAINS_FAILURE,
    payload: error
})
