/* eslint-disable import/no-anonymous-default-export */
/*
    Campaign Dashboard Reducer
    
*/
import { NotificationManager } from "react-notifications";
import * as _ from "lodash";
import {
    GET_PARTNER_CUSTOMER_LIST,
    GET_PARTNER_CUSTOMER_LIST_SUCCESS,
    GET_PARTNER_CUSTOMER_LIST_FAILURE,
    ADD_PARTNER_CUSTOMER,
    ADD_PARTNER_CUSTOMER_SUCCESS,
    ADD_PARTNER_CUSTOMER_FAILURE,
    UPDATE_PARTNER_CUSTOMER,
    UPDATE_PARTNER_CUSTOMER_SUCCESS,
    UPDATE_PARTNER_CUSTOMER_FAILURE,
    DELETE_PARTNER_CUSTOMER,
    DELETE_PARTNER_CUSTOMER_SUCCESS,
    DELETE_PARTNER_CUSTOMER_FAILURE,
    FILTER_PARTNER_CUSTOMER_LIST,
    SELECT_PARTNER_CUSTOMER
} from "../actions/types";

import AppConfig from "../constants/AppConfig";

// initial state
const INIT_STATE = {
  customers: null,
  filteredcustomers: null,
  loading: false,
  customerDetail: null,
  selected: null,
  search: "",
  redirect: false,
  removeCustomerModel: null,
  deactivateCustomerId:0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get partners
    case GET_PARTNER_CUSTOMER_LIST:
      return { ...state, loading: true };
    // On Success...
    case GET_PARTNER_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload,
        filteredCustomers: action.payload,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_PARTNER_CUSTOMER_LIST_FAILURE:
      NotificationManager.error(action.payload);
      return {
        ...state,
        loading: false,
        customers: [],
        filteredCustomers: [],
        error: action.payload,
      };

    case ADD_PARTNER_CUSTOMER:
      let addCustomerDetails = action.payload;
      if (action.payload.countryCode && action.payload.countryCode.value) {
        addCustomerDetails.countryCode = action.payload.countryCode.value;
      } else if (
        action.payload.countryCode &&
        action.payload.countryCode.value == null
      ) {
        addCustomerDetails.countryCode = action.payload.countryCode[0].value;
      } else {
        addCustomerDetails.countryCode = "";
      }
      addCustomerDetails.isActive = true;
      addCustomerDetails.businessId = action.payload.businessId.trim();
      return { ...state, loading: true, customerDetail: addCustomerDetails };

    case ADD_PARTNER_CUSTOMER_SUCCESS:
      NotificationManager.success("New customer added successfully.");
      return { ...state, loading: false, redirect: true };

    case ADD_PARTNER_CUSTOMER_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, error: action.payload };

    case UPDATE_PARTNER_CUSTOMER:
      let customerDetails = action.payload;
      if (action.payload.countryCode && action.payload.countryCode.value) {
        customerDetails.countryCode = action.payload.countryCode.value;
      } else if (
        action.payload.countryCode &&
        action.payload.countryCode.value == null
      ) {
        customerDetails.countryCode = action.payload.countryCode[0].value;
      } else {
        customerDetails.countryCode = "";
      }
      return { ...state, loading: true, customerDetail: customerDetails };

    case UPDATE_PARTNER_CUSTOMER_SUCCESS:
      NotificationManager.success("Customer updated successfully.");
      return { ...state, loading: false };

    case UPDATE_PARTNER_CUSTOMER_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, error: action.payload };

    case FILTER_PARTNER_CUSTOMER_LIST:
      if (action.payload === "") {
        return { ...state, filteredCustomers: state.customers };
      } else {
        const filtered = state.customers.filter(
          (item) =>
            item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredCustomers: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case SELECT_PARTNER_CUSTOMER:
      var selectedCustomer = _.filter(state.customers, { id: action.payload })[0];

      if (selectedCustomer.countryCode) {
        let country = _.filter(AppConfig.countries, {
          code: selectedCustomer.countryCode.trim(),
        }).map(function (a) {
          return { value: a.code, label: a.name + " (" + a.dial_code + ")" };
        });
        if (country) {
            selectedCustomer.countryCode = country;
        }
      }

      return {
        ...state,
        loading: false,
        selected: selectedCustomer,
      };
    case DELETE_PARTNER_CUSTOMER:
        return { ...state, loading: true, removeCustomerModel: action.payload };
      // On Success...
    case DELETE_PARTNER_CUSTOMER_SUCCESS:
        return {
          ...state,
          loading: false,
          removeCustomerModel:null
        };
  
      // On Failure, show error message.
     case DELETE_PARTNER_CUSTOMER_FAILURE:
        NotificationManager.error(action.payload);
        return { ...state, loading: false, removeCustomerModel:null };      
        default:
      return { ...state };
  }
};
