/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/**
 * Customer Dashboard Sagas
 */
import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";
import {
  getCustomerDashboardDataFailure,
  getCustomerDashboardDataSuccess,
  getCustomersForDashboardFailure,
  getCustomersForDashboardSuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const dashboard = (state) => state.customerDashboardReducer;
export const authUser = (state) => state.authUser;

// Base64-urlencodes the input string
function base64urlencode(str) {
  // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// Return the base64-urlencoded sha256 hash for the PKCE challenge

const getCustomerDashboardFromServer = async (model) => {
  var payload = {
    noOfDays: model.days,
    domainList: model.filterDomains ? model.filterDomains : [],
  };

  return await getAxiosRequest().post(
    `/customer/${model.customerId}/domaincounts`,
    payload
  );
};

const getCustomerDashboardEventsFromServer = async (model) => {
  var payload = {
    noOfDays: model.days,
    domainList: model.filterDomains ? model.filterDomains : [],
  };

  return await getAxiosRequest().post(
    `/customer/${model.customerId}/domaineventcounts`,
    payload
  );
};

const getCustomerListFromServer = async () => {
  return await getAxiosRequest().get("/customer");
};

/**
 * Get Customer Dashboard Data
 */
function* getCustomerDashboardData() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model
    let dashboardDetails = yield select(dashboard);
    var model = {};
    if (dashboardDetails.selectedCustomer === null) {
      model = {
        customerId: user.currentDomain.customerId,
        days: dashboardDetails.filterDays,
        filterDomains: dashboardDetails.filterDomains,
      };
    } else {
      model = {
        customerId: dashboardDetails.selectedCustomer.id,
        days: dashboardDetails.filterDays,
        filterDomains: dashboardDetails.filterDomains,
      };
    }

    const [cdpdata, cmpdata] = yield all([
      call(getCustomerDashboardFromServer, model),
      call(getCustomerDashboardEventsFromServer, model),
    ]);

    var response = {
      profiles: cdpdata.data,
      events: cmpdata.data,
    };

    // call api to generate magic link
    //const response = yield call(getCustomerDashboardFromServer, model);

    // const identityResponse = yield call(getIndentityToken);
    // console.log(identityResponse);
    yield put(getCustomerDashboardDataSuccess(response));
  } catch (error) {
    if (error.response.status === 403) {
      yield delay(2000);
      try {
        let user = yield select(authUser); // <-- get the model
        let dashboardDetails = yield select(dashboard);
        var model = {};
        if (dashboardDetails.selectedCustomer === null) {
          model = {
            customerId: user.currentDomain.customerId,
            days: dashboardDetails.filterDays,
            filterDomains: dashboardDetails.filterDomains,
          };
        } else {
          model = {
            customerId: dashboardDetails.selectedCustomer.id,
            days: dashboardDetails.filterDays,
            filterDomains: dashboardDetails.filterDomains,
          };
        }

        const [cdpdata, cmpdata] = yield all([
          call(getCustomerDashboardFromServer, model),
          call(getCustomerDashboardEventsFromServer, model),
        ]);

        var response = {
          profiles: cdpdata.data,
          events: cmpdata.data,
        };

        // const identityResponse = yield call(getIndentityToken);
        // console.log(identityResponse);
        yield put(getCustomerDashboardDataSuccess(response));
      } catch (error) {
        yield put(getCustomerDashboardDataFailure(error.response.data.error));
      }
    }
    yield put(getCustomerDashboardDataFailure(error.response.data.error));
  }
}

/**
 * Get Customer List
 */
function* getCustomers() {
  try {
    const response = yield call(getCustomerListFromServer);
    yield put(getCustomersForDashboardSuccess(response));
  } catch (error) {
    yield put(getCustomersForDashboardFailure(error.response.data.error));
  }
}

export const customerDashboardSagas = [
  takeEvery("GET_CUSTOMERDASHBOARDDATA", getCustomerDashboardData),
  takeEvery("GET_CUSTOMER_LIST_FOR_DASHBOARD", getCustomers),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...customerDashboardSagas]);
}
