/* eslint-disable import/no-anonymous-default-export */
/*
    applications Reducer
    Curd Operations on applications.
*/
import * as _ from "lodash";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import {
  ADD_DOMAIN_LICENSE,
  ADD_DOMAIN_LICENSE_FAILURE,
  ADD_DOMAIN_LICENSE_SUCCESS,
  CHANGE_COST_CENTER_MONTH,
  CREATE_MANUAL_VOUCHER,
  CREATE_MANUAL_VOUCHER_FAILURE,
  CREATE_MANUAL_VOUCHER_SUCCESS,
  DELETE_TEAMMEMBER,
  DELETE_TEAMMEMBER_FAILURE,
  DELETE_TEAMMEMBER_SUCCESS,
  FILTER_VOUCHERS_BY_STATUS,
  GET_CREDITS_LIST,
  GET_CREDITS_LIST_FAILURE,
  GET_CREDITS_LIST_SUCCESS,
  GET_CURRENT_CUSTOMER,
  GET_CURRENT_CUSTOMER_FAILURE,
  GET_CURRENT_CUSTOMER_SUCCESS,
  GET_CURRENT_CUSTOMER_VOUCHERS,
  GET_CURRENT_CUSTOMER_VOUCHERS_FAILURE,
  GET_CURRENT_CUSTOMER_VOUCHERS_SUCCESS,
  GET_DOMAIN_LICENSES,
  GET_LEDGER_BALANCE,
  GET_LEDGER_BALANCE_FAILURE,
  GET_LEDGER_BALANCE_SUCCESS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_VOUCHERS,
  GET_VOUCHERS_FAILURE,
  GET_VOUCHERS_SUCCESS,
  GET_VOUCHER_BY_CODE,
  GET_VOUCHER_BY_CODE_FAILURE,
  GET_VOUCHER_BY_CODE_SUCCESS,
  INVITE_TEAMMEMBER,
  INVITE_TEAMMEMBER_FAILURE,
  INVITE_TEAMMEMBER_SUCCESS,
  PURCHASE_CREDITS,
  PURCHASE_CREDITS_FAILURE,
  PURCHASE_CREDITS_SUCCESS,
  REDEEM_VOUCHER,
  REDEEM_VOUCHER_FAILURE,
  REDEEM_VOUCHER_SUCCESS,
  REGISTER_NEW_DOMAINS,
  REGISTER_NEW_DOMAINS_FAILURE,
  REGISTER_NEW_DOMAINS_SUCCESS,
  SAVE_CUSTOMER_BASIC_INFO,
  SAVE_CUSTOMER_BASIC_INFO_FAILURE,
  SAVE_CUSTOMER_BASIC_INFO_SUCCESS,
  UPDATE_DOMAIN_LICENSE,
  UPDATE_DOMAIN_LICENSE_FAILURE,
  UPDATE_DOMAIN_LICENSE_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_SUCCESS,
} from "../actions/types";
import AppConfig from "../constants/AppConfig";

// initial state
const INIT_STATE = {
  currentCustomer: null,
  customerVouchers: [],
  gravitoVouchers: [],
  filteredGravitoVouchers: [],
  voucher: null,
  voucherByCode: null,
  voucherSearchError: null,
  loading: false,
  error: null,
  filterBy: "",
  inviteModel: null,
  currentUser: null,
  updateUser: null,
  ledgerBalance: null,
  selectedTeamMember: null,
  updatedCustomer: null,
  costs: null,
  constMonths: [],
  activeMonth: null,
  allcosts: [],
  licenses: [],
  nonLicensedDomains: [],
  addDomainLicenseModel: null,
  updateDomainLicenseModel: null,
  creditToken: null,
  paymentSuccessStatus: false,
  paymentError: false,
  creditList: [],
};

const getMonthsForCost = (allCosts) => {
  let res = [];
  if (allCosts && allCosts.length > 0) {
    allCosts = _.orderBy(allCosts, ["key"], ["asc"]);
    let monthKey = "key";
    const getMonthFromDate = (dateString) => {
      let date = moment(dateString, "YYYY-MM-DD");
      let month = date.format("MMMM");
      let year = date.format("YYYY");
      let currentMonth = moment().format("MMMM");
      let currentYear = moment().format("YYYY");
      let currentText =
        currentMonth === month && currentYear === year ? "(Current)" : "";
      return {
        label: `${month}-${year} ${currentText}`,
        sourceDate: dateString,
      };
    };
    allCosts.forEach((cost, index) => {
      let costMonth = getMonthFromDate(cost[monthKey]);
      res.push(costMonth);
    });
    return _.uniqBy([...res], "label");
  }
  return res;
};

const getCostForMonth = (allCost, month) => {
  if (month) {
    let res = _.find(allCost, (c, i) => {
      return c.key === month.sourceDate;
    });
    return res;
  } else {
    return null;
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get current customer from server.
    case GET_CURRENT_CUSTOMER:
      return { ...state, loading: true };
    // On Success...
    case GET_CURRENT_CUSTOMER_SUCCESS:
      let currentCustomer = null;
      if (action.payload.customer && action.payload.customer.countryCode) {
        let country = _.filter(AppConfig.countries, {
          code: action.payload.customer.countryCode.trim(),
        }).map(function (a) {
          return { value: a.code, label: a.name + " (" + a.dial_code + ")" };
        });

        currentCustomer = action.payload.customer;
        currentCustomer.countryCode = country;
      } else {
        currentCustomer = action.payload.customer;
        currentCustomer.countryCode = "";
      }

      var licensedDomains = action.payload.licenses.map(
        (license) => license.domainId
      );

      const nonLicensedDomains = [...currentCustomer.domains];
      licensedDomains.forEach((element) => {
        _.remove(nonLicensedDomains, {
          id: element,
        });
      });

      let costs = getCostForMonth(
        action.payload.costs,
        getMonthsForCost(action.payload.costs)[
          getMonthsForCost(action.payload.costs).length - 1
        ]
      );
      return {
        ...state,
        loading: false,
        currentCustomer: currentCustomer,
        allcosts: action.payload.costs,
        costMonths: getMonthsForCost(action.payload.costs),
        activeMonth:
          getMonthsForCost(action.payload.costs).length > 0
            ? getMonthsForCost(action.payload.costs)[
                getMonthsForCost(action.payload.costs).length - 1
              ]
            : "",
        costs: costs ? JSON.parse(costs.value) : null,
        licenses: action.payload.licenses,
        nonLicensedDomains: nonLicensedDomains,
      };
    // On Failure, show error message.
    case GET_CURRENT_CUSTOMER_FAILURE:
      return { ...state, loading: false, error: action.payload };

    //ADD new content
    case SAVE_CUSTOMER_BASIC_INFO:
      if (action.payload === "") {
        return { ...state, error: "Please enter valid customer details" };
      } else {
        let currentCustomer = state.currentCustomer;
        currentCustomer.name = action.payload.name;
        currentCustomer.businessId = action.payload.businessId;
        currentCustomer.address = action.payload.address;
        currentCustomer.phone = action.payload.phone;
        if (action.payload.countryCode && action.payload.countryCode.value) {
          currentCustomer.countryCode = action.payload.countryCode.value;
        } else if (
          action.payload.countryCode &&
          action.payload.countryCode.value == null
        ) {
          currentCustomer.countryCode = action.payload.countryCode[0].value;
        } else {
          currentCustomer.countryCode = "";
        }

        //;
        return {
          ...state,
          loading: true,
          currentCustomer: currentCustomer,
          customerVouchers: currentCustomer.vouchers,
          ledgerBalance: currentCustomer.ledgerBalance,
        };
      }

    // On Success, show message and redirect to listing page...
    case SAVE_CUSTOMER_BASIC_INFO_SUCCESS:
      NotificationManager.success("Business details are updated successfully.");
      return { ...state, loading: false };
    // On failure, show error message.
    case SAVE_CUSTOMER_BASIC_INFO_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false };
    // Update content
    case REGISTER_NEW_DOMAINS:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please provide valid list of domains for registration",
        };
      }

      var customer = state.currentCustomer;

      if (customer.countryCode.length > 0) {
        customer.countryCode = customer.countryCode[0].value
          ? customer.countryCode[0].value
          : "";
      } else {
        customer.countryCode = "";
      }

      customer.requestedDomains = action.payload;

      return {
        ...state,
        loading: true,
        updatedCustomer: customer,
      };

    // On Success, show message and redirect to listing page....
    case REGISTER_NEW_DOMAINS_SUCCESS:
      NotificationManager.success(
        "New domain(s) were successfully added your customership."
      );
      return {
        ...state,
        loading: false,
      };

    // On Failure show error message.
    case REGISTER_NEW_DOMAINS_FAILURE:
      NotificationManager.error(
        "We could not add new domains to your customership."
      );
      return { ...state, loading: false };

    // Get current customer from server.
    case GET_CURRENT_CUSTOMER_VOUCHERS:
      return { ...state, loading: true };
    // On Success...
    case GET_CURRENT_CUSTOMER_VOUCHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerVouchers: action.payload.data,
      };
    // On Failure, show error message.
    case GET_CURRENT_CUSTOMER_VOUCHERS_FAILURE:
      NotificationManager.error(
        "We could not fetch vouchers for your account."
      );
      return {
        ...state,
        loading: false,
        error: action.payload,
        customerVouchers: [],
      };

    case CREATE_MANUAL_VOUCHER:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please provide  valid details for creating a voucher.",
        };
      }

      return {
        ...state,
        loading: true,
        voucher: action.payload,
      };

    // On Success, show message
    case CREATE_MANUAL_VOUCHER_SUCCESS:
      NotificationManager.success("New voucher was created successfully.");
      return {
        ...state,
        loading: false,
        voucher: null,
      };

    // On Failure show error message.
    case CREATE_MANUAL_VOUCHER_FAILURE:
      NotificationManager.error("We could not add new voucher.");
      return { ...state, loading: false, voucher: null };

    case REDEEM_VOUCHER:
      if (action.payload === "") {
        return {
          ...state,
          error: "Please provide valid valid details for redeeming a voucher.",
        };
      }

      return {
        ...state,
        loading: true,
        voucher: action.payload,
      };

    // On Success, show message
    case REDEEM_VOUCHER_SUCCESS:
      NotificationManager.success(
        "Voucher was successfully redeemed and created were added to your account."
      );
      return {
        ...state,
        loading: false,
        voucher: null,
        voucherByCode: null,
        voucherSearchError: null,
      };

    // On Failure show error message.
    case REDEEM_VOUCHER_FAILURE:
      NotificationManager.error("We could not redeem your voucher.");
      return { ...state, loading: false, voucher: null };

    // Get current customer from server.
    case GET_VOUCHERS:
      return { ...state, loading: true };
    // On Success...
    case GET_VOUCHERS_SUCCESS:
      if (action.payload.data) {
        let filteredData = action.payload.data.Vouchers;
        if (state.filterBy !== "") {
          filteredData = action.payload.data.Vouchers.filter(
            (item) =>
              item.Status.toLowerCase().indexOf(state.filterBy.toLowerCase()) >
              -1
          );
        }
        return {
          ...state,
          loading: false,
          gravitoVouchers: action.payload.data.Vouchers,
          filteredGravitoVouchers: filteredData,
        };
      } else {
        return {
          ...state,
          loading: false,
          gravitoVouchers: [],
          filteredGravitoVouchers: [],
        };
      }

    // On Failure, show error message.
    case GET_VOUCHERS_FAILURE:
      NotificationManager.error("We could not fetch vouchers.");
      return {
        ...state,
        loading: false,
        error: action.payload,
        customerVouchers: [],
        filteredGravitoVouchers: [],
      };

    // Get current customer from server.
    case GET_VOUCHER_BY_CODE:
      return { ...state, loading: true, voucher: action.payload };
    // On Success...
    case GET_VOUCHER_BY_CODE_SUCCESS:
      var notFound = null;
      if (action.payload.data === null) {
        notFound = "This voucher is not valid, please check !!";
      }
      return {
        ...state,
        loading: false,
        voucherByCode: action.payload.data,
        voucherSearchError: notFound,
      };
    // On Failure, show error message.
    case GET_VOUCHER_BY_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: "This voucher is not valid, please check !!",
        customerVouchers: [],
      };

    // Get current customer from server.
    case FILTER_VOUCHERS_BY_STATUS:
      const filtered = state.gravitoVouchers.filter(
        (item) =>
          item.Status.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
      );
      return {
        ...state,
        loading: true,
        filteredGravitoVouchers: filtered,
        filterBy: action.payload.toLowerCase(),
      };

    case INVITE_TEAMMEMBER:
      if (action.payload === "") {
        return {
          ...state,
          error:
            "Please provide valid details of your team member for invitation.",
        };
      }

      return {
        ...state,
        loading: true,
        inviteModel: action.payload,
      };

    // On Success, show message
    case INVITE_TEAMMEMBER_SUCCESS:
      NotificationManager.success(
        `An invite was successfully sent to ${state.inviteModel.email}.`
      );
      return {
        ...state,
        loading: false,
        inviteModel: null,
      };

    // On Failure show error message.
    case INVITE_TEAMMEMBER_FAILURE:
      if (action.payload) {
        NotificationManager.warning(
          `We can not send invite as, ${state.inviteModel.email} ${action.payload}`
        );
      } else {
        NotificationManager.error(
          `We could not send an invite to ${state.inviteModel.email} .`
        );
      }

      return { ...state, loading: false, inviteModel: null };

    case GET_USER_PROFILE:
      return { ...state };

    case GET_USER_PROFILE_SUCCESS:
      let currentUser = null;
      if (action.payload && action.payload.countryCode) {
        let country = _.filter(AppConfig.countries, {
          dial_code: action.payload.countryCode.trim(),
        }).map(function (a) {
          return {
            value: a.dial_code,
            label: a.name + " (" + a.dial_code + ")",
          };
        });

        currentUser = action.payload;
        currentUser.countryCode = country;
      } else {
        currentUser = action.payload;
        currentUser.countryCode = "";
      }
      return { ...state, currentUser: currentUser };

    case UPDATE_USER_PROFILE:
      let user = null;
      user = action.payload;
      user.countryCode = action.payload.countryCode.value;
      return { ...state, updateUser: user, loading: true };

    case UPDATE_USER_PROFILE_SUCCESS:
      NotificationManager.success(`Your profile is updated successfully.`);

      return { ...state, loading: false };

    case UPDATE_USER_PROFILE_FAILURE:
      NotificationManager.error(
        `Failed to update your profile please try later.`
      );
      return { ...state };
    case GET_LEDGER_BALANCE:
      return { ...state, loading: true };
    case GET_LEDGER_BALANCE_SUCCESS:
      return { ...state, loading: false, ledgerBalance: action.payload };
    case GET_LEDGER_BALANCE_FAILURE:
      NotificationManager.error(`Failed to get ledger report balance.`);
      return { ...state, loading: false };
    case DELETE_TEAMMEMBER:
      if (action.payload === null || action.payload === "") {
        return {
          ...state,
          error:
            "Please provide valid details of your team member for deletion.",
        };
      }
      return { ...state, loading: true, selectedTeamMember: action.payload };
    case DELETE_TEAMMEMBER_SUCCESS:
      NotificationManager.success(
        `Selected team member has been removed successfully.`
      );

      return { ...state, loading: false };

    case DELETE_TEAMMEMBER_FAILURE:
      NotificationManager.error(
        `Sorry, we could not remove the selected team member, please contact Gravito support.`
      );

      return { ...state, loading: false };

    case ADD_DOMAIN_LICENSE:
      if (action.payload === null || action.payload === "") {
        return {
          ...state,
          error: "Please provide valid details for adding a domain license.",
        };
      }

      var addModel = {
        domainId: action.payload,
        customerId: 0,
      };
      return { ...state, loading: true, addDomainLicenseModel: addModel };
    case GET_DOMAIN_LICENSES:
      return { ...state, loading: false, addDomainLicenseModel: null };

    case ADD_DOMAIN_LICENSE_SUCCESS:
      NotificationManager.success(
        `A new License was added for selected domain.`
      );

      return { ...state, loading: false, addDomainLicenseModel: null };

    case ADD_DOMAIN_LICENSE_FAILURE:
      NotificationManager.error(`${action.payload}`);

      return { ...state, loading: false, addDomainLicenseModel: null };
    case UPDATE_DOMAIN_LICENSE:
      if (action.payload === null || action.payload === "") {
        return {
          ...state,
          error: "Please provide valid details for updating a domain license.",
        };
      }
      var updateLicenseModel = {
        id: action.payload,
        customerId: 0,
      };
      return {
        ...state,
        loading: true,
        updateDomainLicenseModel: updateLicenseModel,
      };
    case UPDATE_DOMAIN_LICENSE_SUCCESS:
      NotificationManager.success(`Domain license deactivated successfully.`);

      return { ...state, loading: false, updateDomainLicenseModel: null };

    case UPDATE_DOMAIN_LICENSE_FAILURE:
      NotificationManager.error(`${action.payload}`);

      return { ...state, loading: false, updateDomainLicenseModel: null };
    case CHANGE_COST_CENTER_MONTH: {
      let costsObj = getCostForMonth(state.allcosts, action.payload);
      let costs = costsObj ? JSON.parse(costsObj.value) : null;
      return { ...state, activeMonth: action.payload, costs: costs };
    }
    case PURCHASE_CREDITS: {
      let tokenModel = action.payload;
      return {
        ...state,
        loading: true,
        creditToken: tokenModel,
        paymentSuccessStatus: false,
        paymentError: false,
      };
    }
    case PURCHASE_CREDITS_SUCCESS: {
      NotificationManager.success(`Successfully purchased the credits`);
      return {
        ...state,
        loading: false,
        creditToken: null,
        paymentSuccessStatus: true,
        paymentError: false,
      };
    }
    case PURCHASE_CREDITS_FAILURE: {
      NotificationManager.error("Error while purchasing credit");
      return {
        ...state,
        loading: false,
        paymentSuccessStatus: false,
        paymentError: true,
      };
    }

    case GET_CREDITS_LIST: {
      return { ...state, loading: true };
    }
    case GET_CREDITS_LIST_SUCCESS: {
      return { ...state, creditList: action.payload };
    }
    case GET_CREDITS_LIST_FAILURE: {
      NotificationManager.error("Error while fetching credits list");
      return {
        ...state,
        loading: false,
        creditList: [],
      };
    }
    default:
      return { ...state };
  }
};
