/* eslint-disable no-unused-vars */
/**
 * App Config File
 */
// /*** localhost config
const AppConfigLocal = {
  appLogo: require("../assets/img/gravito_logo.png"), // App Logo
  appUrl: "http://localhost:3100", // App Url
  appV2Url: "https://adminv2.gravito.net", // admin v2 url for redirection
  loginUrl: "http://localhost:3100/landing",
  accountUrl: "http://localhost:3000",
  appLocalUrl: "/assets/data/",
  cookieDomain: "gravito.net", // 'https://*.gravito.net'
  cookieSecure: true,
  brandName: "Gravito", // Brand Name
  apiPath: "http://localhost:60627/api", // Gravito API Path
  identityApiPath: "http://localhost:44363/api", // Gravito Identity API Path
  identityLoginPreview: "http://localhost:44363/auth/loginpreview?clientId=",
  facebookAppId: "191618938156957",
  googleClientId:
    "35785192605-ur4910kfhiejtrahuibcvp6cn9ov7kvu.apps.googleusercontent.com",
  lightCMPConfigPreviewUrl:
    "https://gravitodevcdn.blob.core.windows.net/cmppreview/lightCMPiframe.html",
  gravitoCDNUrl: "https://gravitocdn.blob.core.windows.net/",
  tcfCMPConfigPreviewUrl:
    "https://gravitodevcdn.blob.core.windows.net/cmppreview/tcfCMPiframe.html",
  navCollapsed: false, // Sidebar collapse
  darkMode: false, // Dark Mode
  boxLayout: false, // Box Layout
  rtlLayout: false, // RTL Layout
  miniSidebar: false, // Mini Sidebar
  sidebarActiveFilter: "dark", // Select Sidebar Color You can Choose following color 'primary', 'blue', 'warning', 'info', 'danger','success','purple'
  enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
  sidebarImage: require("../assets/img/boxed-bg.png"), // Select sidebar image
  locale: {
    languageId: "english",
    locale: "en_US",
    name: "English",
    icon: "en",
  },
  controls: {
    subject: [
      {
        key: "subject",
        label: "subject",
        props: { required: true, placeholder: "Enter Subject" },
      },
    ],
    body: [
      {
        key: "body",
        label: "body",
        type: "richtext",
        props: { required: true, placeholder: "Enter Body Content" },
      },
    ],
    simpletext: [
      {
        key: "simpletext",
        label: "simpletext",
        props: { placeholder: "Enter Text Content", required: true },
      },
    ],
    heroimage: [
      {
        key: "heroimage",
        label: "heroimage",
        type: "url",
        props: { placeholder: "Enter Image Url", required: true },
      },
    ],
    rawhtml: [{ key: "rawhtml", label: "rawhtml", type: "rawhtml" }],
  },
  CampaignChannel: {
    EMAIL: 0,
    SMS: 1,
    MOBILE: 2,
    WEB: 3,
    SNAIL: 4,
    WEBHOOK: 5,
    AD: 6,
  },
  enableUserTour: true, // Enable / Disable User Tour
  maxCredit: 100,
  paypalClientId:
    "AT35ESL753xSVe_D_wsKEGEK718J3JpxfIioBp0bKPTOZPXFMLa8XCWGGLYVxQq1WvyVeqv0Q7a0mSuc",
  copyRightText: "Gravito Ltd © 2021 All Rights Reserved.", // Copy Right Text
  // light theme colors
  themeColors: {
    primary: "#f07e26",
    secondary: "#706f6f",
    info: "#C9C9C9",
    success: "#919191",
    warning: "#D5573B",
    danger: "#706f6f",
    default: "#657786",
    purple: "#6f42c1",
    yellow: "#D46B08",
    white: "#FFFFFF",
    dark: "#706f6f",
    greyLighten: "#DFE3E8",
    grey: "#9FA5AB",
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: "#424242",
  },
  identityClientMapping: [
    { value: 1, label: "Single Page Application" },
    { value: 2, label: "Login Web Component" },
    { value: 0, label: "Access Gravito" },
  ],
  appVersion: 42,
  webhookPayload: {
    GravitoId: "d8e25298-5538-44b2-940c-4b9a1479ffe6",
    Email: "testuser@gravito.net",
    EventType: "consentAdded",
    Domain: "",
    ConsentName: "Email",
    ConsentValue: true,
    UpdatedTs: "",
  },
  customDomainConstants: {
    ARecordIp: "13.69.228.1",
    TextRecordUid: "asuid.",
    TextRecordValue:
      "9078AD835C4DC1F8799E400B00C22F557D87DA569B7F322F989D58EE9418D209",
    CNameRecordNetwork: "_acme-challenge-MD5PLACEHOLDER.gravito.network",
    CNAMERecordText: "_acme-challenge.TEXTPLACEHOLDER",
  },
  countries: [
    {
      name: "Argentina",
      dial_code: "+54",
      code: "AR",
    },
    {
      name: "Armenia",
      dial_code: "+374",
      code: "AM",
    },
    {
      name: "Australia",
      dial_code: "+61",
      code: "AU",
    },
    {
      name: "Austria",
      dial_code: "+43",
      code: "AT",
    },
    {
      name: "Belgium",
      dial_code: "+32",
      code: "BE",
    },
    {
      name: "Brazil",
      dial_code: "+55",
      code: "BR",
    },
    {
      name: "Bulgaria",
      dial_code: "+359",
      code: "BG",
    },
    {
      name: "Burkina Faso",
      dial_code: "+226",
      code: "BF",
    },
    {
      name: "Canada",
      dial_code: "+1",
      code: "CA",
    },

    {
      name: "Chile",
      dial_code: "+56",
      code: "CL",
    },
    {
      name: "China",
      dial_code: "+86",
      code: "CN",
    },

    {
      name: "Colombia",
      dial_code: "+57",
      code: "CO",
    },
    {
      name: "Congo",
      dial_code: "+242",
      code: "CG",
    },
    {
      name: "Czech Republic",
      dial_code: "+420",
      code: "CZ",
    },
    {
      name: "Denmark",
      dial_code: "+45",
      code: "DK",
    },
    {
      name: "Djibouti",
      dial_code: "+253",
      code: "DJ",
    },
    {
      name: "Egypt",
      dial_code: "+20",
      code: "EG",
    },
    {
      name: "Estonia",
      dial_code: "+372",
      code: "EE",
    },
    {
      name: "Finland",
      dial_code: "+358",
      code: "fi",
    },
    {
      name: "France",
      dial_code: "+33",
      code: "FR",
    },
    {
      name: "Germany",
      dial_code: "+49",
      code: "DE",
    },
    {
      name: "Hungary",
      dial_code: "+36",
      code: "HU",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Indonesia",
      dial_code: "+62",
      code: "ID",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Ireland",
      dial_code: "+353",
      code: "IE",
    },
    {
      name: "Israel",
      dial_code: "+972",
      code: "IL",
    },
    {
      name: "Italy",
      dial_code: "+39",
      code: "IT",
    },
    {
      name: "Japan",
      dial_code: "+81",
      code: "JP",
    },
    {
      name: "Kenya",
      dial_code: "+254",
      code: "KN",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: "+850",
      code: "KP",
    },
    {
      name: "Korea, Republic of South Korea",
      dial_code: "+82",
      code: "KR",
    },
    {
      name: "Kuwait",
      dial_code: "+965",
      code: "KW",
    },
    {
      name: "Latvia",
      dial_code: "+371",
      code: "LV",
    },
    {
      name: "Luxembourg",
      dial_code: "+352",
      code: "LU",
    },
    {
      name: "Mexico",
      dial_code: "+52",
      code: "MX",
    },
    {
      name: "Netherlands",
      dial_code: "+31",
      code: "NL",
    },
    {
      name: "Norway",
      dial_code: "+47",
      code: "NO",
    },
    {
      name: "Oman",
      dial_code: "+968",
      code: "OM",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Paraguay",
      dial_code: "+595",
      code: "PY",
    },
    {
      name: "Peru",
      dial_code: "+51",
      code: "PE",
    },
    {
      name: "Poland",
      dial_code: "+48",
      code: "PL",
    },
    {
      name: "Portugal",
      dial_code: "+351",
      code: "PT",
    },
    {
      name: "Qatar",
      dial_code: "+974",
      code: "QA",
    },
    {
      name: "Romania",
      dial_code: "+40",
      code: "RO",
    },
    {
      name: "Russia",
      dial_code: "+7",
      code: "RU",
    },
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "Singapore",
      dial_code: "+65",
      code: "SG",
    },
    {
      name: "Spain",
      dial_code: "+34",
      code: "ES",
    },
    {
      name: "Sweden",
      dial_code: "+46",
      code: "SE",
    },
    {
      name: "Switzerland",
      dial_code: "+41",
      code: "CH",
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "United Kingdom",
      dial_code: "+44",
      code: "GB",
    },
    {
      name: "United States",
      dial_code: "+1",
      code: "US",
    },
  ],
  identityConfig: {
    client_id: "bec531dc-053d-4cfd-a0b7-5361dfee1397",
    grant_type: "code",
    scope: "API openid",
    redirect_uri: "http://localhost:3100/",
    authorize_endpoint: "http://localhost:44363/connect/authorize",
  },
};
// /*** dev config
const AppConfigDev = {
  appLogo: require("../assets/img/gravito_logo.png"), // App Logo
  appUrl: "https://dev-admin.gravito.net", // App Url
  appV2Url: "https://dev-adminv2.gravito.net", // admin v2 url for redirection ß
  loginUrl: "https://dev-admin.gravito.net/landing",
  accountUrl: "https://account.gravito.net",
  appLocalUrl: "/assets/data/",
  cookieDomain: "gravito.net", // 'https://*.gravito.net'
  cookieSecure: true,
  brandName: "Gravito", // Brand Name
  apiPath: "https://dev-api.gravito.net/api", // Gravito API Path
  identityApiPath: "https://dev-identity.gravito.net/api", // Gravito Identity API Path
  identityLoginPreview:
    "https://dev-identity.gravito.net/auth/loginpreview?clientId=",
  facebookAppId: "191618938156957",
  googleClientId:
    "35785192605-ur4910kfhiejtrahuibcvp6cn9ov7kvu.apps.googleusercontent.com",
  gravitoCDNUrl: "https://gravitodevcdn.blob.core.windows.net/",
  lightCMPConfigPreviewUrl:
    "https://gravitodevcdn.blob.core.windows.net/cmppreview/lightCMPiframe.html",
  tcfCMPConfigPreviewUrl:
    "https://gravitodevcdn.blob.core.windows.net/cmppreview/tcfCMPiframe.html",
  striptpaymentKey:
    "pk_test_51Ix5tODMqZTvKuMs7DdNBdK4h4wkXnyvLxtKGHODKSaONCpRxEPEMMz2ADG0fu6BbxLXvM0WYubM8FgAdPHAI0Ml00qJ9WsRxT",
  navCollapsed: false, // Sidebar collapse
  darkMode: false, // Dark Mode
  boxLayout: false, // Box Layout
  rtlLayout: false, // RTL Layout
  miniSidebar: false, // Mini Sidebar
  sidebarActiveFilter: "dark", // Select Sidebar Color You can Choose following color 'primary', 'blue', 'warning', 'info', 'danger','success','purple'
  enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
  sidebarImage: require("../assets/img/boxed-bg.png"), // Select sidebar image
  locale: {
    languageId: "english",
    locale: "en_US",
    name: "English",
    icon: "en",
  },
  controls: {
    subject: [
      {
        key: "subject",
        label: "subject",
        props: { required: true, placeholder: "Enter Subject" },
      },
    ],
    body: [
      {
        key: "body",
        label: "body",
        type: "richtext",
        props: { required: true, placeholder: "Enter Body Content" },
      },
    ],
    simpletext: [
      {
        key: "simpletext",
        label: "simpletext",
        props: { placeholder: "Enter Text Content", required: true },
      },
    ],
    heroimage: [
      {
        key: "heroimage",
        label: "heroimage",
        type: "url",
        props: { placeholder: "Enter Image Url", required: true },
      },
    ],
    rawhtml: [{ key: "rawhtml", label: "rawhtml", type: "rawhtml" }],
  },
  CampaignChannel: {
    EMAIL: 0,
    SMS: 1,
    MOBILE: 2,
    WEB: 3,
    SNAIL: 4,
    WEBHOOK: 5,
    AD: 6,
  },
  enableUserTour: true, // Enable / Disable User Tour
  maxCredit: 100,
  paypalClientId:
    "AT35ESL753xSVe_D_wsKEGEK718J3JpxfIioBp0bKPTOZPXFMLa8XCWGGLYVxQq1WvyVeqv0Q7a0mSuc",
  copyRightText: "Gravito Ltd © 2021 All Rights Reserved.", // Copy Right Text
  // light theme colors
  themeColors: {
    primary: "#f07e26",
    secondary: "#706f6f",
    info: "#C9C9C9",
    success: "#919191",
    warning: "#D5573B",
    danger: "#706f6f",
    default: "#657786",
    purple: "#6f42c1",
    yellow: "#D46B08",
    white: "#FFFFFF",
    dark: "#706f6f",
    greyLighten: "#DFE3E8",
    grey: "#9FA5AB",
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: "#424242",
  },
  identityClientMapping: [
    { value: 1, label: "Single Page Application" },
    { value: 2, label: "Login Web Component" },
    { value: 0, label: "Access Gravito" },
  ],
  appVersion: 42,
  webhookPayload: {
    GravitoId: "d8e25298-5538-44b2-940c-4b9a1479ffe6",
    Email: "testuser@gravito.net",
    EventType: "consentAdded",
    Domain: "",
    ConsentName: "Email",
    ConsentValue: true,
    UpdatedTs: "",
  },
  customDomainConstants: {
    ARecordIp: "13.69.228.1",
    TextRecordUid: "asuid.",
    TextRecordValue:
      "9078AD835C4DC1F8799E400B00C22F557D87DA569B7F322F989D58EE9418D209",
    CNameRecordNetwork: "_acme-challenge-MD5PLACEHOLDER.gravito.network",
    CNAMERecordText: "_acme-challenge.TEXTPLACEHOLDER",
  },
  countries: [
    {
      name: "Argentina",
      dial_code: "+54",
      code: "AR",
    },
    {
      name: "Armenia",
      dial_code: "+374",
      code: "AM",
    },
    {
      name: "Australia",
      dial_code: "+61",
      code: "AU",
    },
    {
      name: "Austria",
      dial_code: "+43",
      code: "AT",
    },
    {
      name: "Belgium",
      dial_code: "+32",
      code: "BE",
    },
    {
      name: "Brazil",
      dial_code: "+55",
      code: "BR",
    },
    {
      name: "Bulgaria",
      dial_code: "+359",
      code: "BG",
    },
    {
      name: "Burkina Faso",
      dial_code: "+226",
      code: "BF",
    },
    {
      name: "Canada",
      dial_code: "+1",
      code: "CA",
    },

    {
      name: "Chile",
      dial_code: "+56",
      code: "CL",
    },
    {
      name: "China",
      dial_code: "+86",
      code: "CN",
    },

    {
      name: "Colombia",
      dial_code: "+57",
      code: "CO",
    },
    {
      name: "Congo",
      dial_code: "+242",
      code: "CG",
    },
    {
      name: "Czech Republic",
      dial_code: "+420",
      code: "CZ",
    },
    {
      name: "Denmark",
      dial_code: "+45",
      code: "DK",
    },
    {
      name: "Djibouti",
      dial_code: "+253",
      code: "DJ",
    },
    {
      name: "Egypt",
      dial_code: "+20",
      code: "EG",
    },
    {
      name: "Estonia",
      dial_code: "+372",
      code: "EE",
    },
    {
      name: "Finland",
      dial_code: "+358",
      code: "fi",
    },
    {
      name: "France",
      dial_code: "+33",
      code: "FR",
    },
    {
      name: "Germany",
      dial_code: "+49",
      code: "DE",
    },
    {
      name: "Hungary",
      dial_code: "+36",
      code: "HU",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Indonesia",
      dial_code: "+62",
      code: "ID",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Ireland",
      dial_code: "+353",
      code: "IE",
    },
    {
      name: "Israel",
      dial_code: "+972",
      code: "IL",
    },
    {
      name: "Italy",
      dial_code: "+39",
      code: "IT",
    },
    {
      name: "Japan",
      dial_code: "+81",
      code: "JP",
    },
    {
      name: "Kenya",
      dial_code: "+254",
      code: "KN",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: "+850",
      code: "KP",
    },
    {
      name: "Korea, Republic of South Korea",
      dial_code: "+82",
      code: "KR",
    },
    {
      name: "Kuwait",
      dial_code: "+965",
      code: "KW",
    },
    {
      name: "Latvia",
      dial_code: "+371",
      code: "LV",
    },
    {
      name: "Luxembourg",
      dial_code: "+352",
      code: "LU",
    },
    {
      name: "Mexico",
      dial_code: "+52",
      code: "MX",
    },
    {
      name: "Netherlands",
      dial_code: "+31",
      code: "NL",
    },
    {
      name: "Norway",
      dial_code: "+47",
      code: "NO",
    },
    {
      name: "Oman",
      dial_code: "+968",
      code: "OM",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Paraguay",
      dial_code: "+595",
      code: "PY",
    },
    {
      name: "Peru",
      dial_code: "+51",
      code: "PE",
    },
    {
      name: "Poland",
      dial_code: "+48",
      code: "PL",
    },
    {
      name: "Portugal",
      dial_code: "+351",
      code: "PT",
    },
    {
      name: "Qatar",
      dial_code: "+974",
      code: "QA",
    },
    {
      name: "Romania",
      dial_code: "+40",
      code: "RO",
    },
    {
      name: "Russia",
      dial_code: "+7",
      code: "RU",
    },
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "Singapore",
      dial_code: "+65",
      code: "SG",
    },
    {
      name: "Spain",
      dial_code: "+34",
      code: "ES",
    },
    {
      name: "Sweden",
      dial_code: "+46",
      code: "SE",
    },
    {
      name: "Switzerland",
      dial_code: "+41",
      code: "CH",
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "United Kingdom",
      dial_code: "+44",
      code: "GB",
    },
    {
      name: "United States",
      dial_code: "+1",
      code: "US",
    },
  ],
  identityConfig: {
    client_id: "537d2a71-90a8-4e24-8d6c-d1cd649d25f3",
    grant_type: "code",
    scope: "API openid",
    redirect_uri: "https://dev-admin.gravito.net/",
    authorize_endpoint: "https://dev-identity.gravito.net/connect/authorize",
  },
};

// /*** production config
const AppConfigProd = {
  appLogo: require("../assets/img/gravito_logo.png"), // App Logo
  appUrl: "https://admin.gravito.net", // App Url
  appV2Url: "https://adminv2.gravito.net", // admin v2 url for redirection
  loginUrl: "https://admin.gravito.net/landing",
  accountUrl: "https://account.gravito.net",
  appLocalUrl: "/assets/data/",
  cookieDomain: "gravito.net", // 'https://*.gravito.net'
  cookieSecure: true,
  brandName: "Gravito", // Brand Name
  apiPath: "https://apiv2.gravito.net/api", // Gravito API Path
  identityApiPath: "https://identity.gravito.net/api", // Gravito Identity API Path
  identityLoginPreview:
    "https://identity.gravito.net/auth/loginpreview?clientId=",
  facebookAppId: "191618938156957",
  googleClientId:
    "35785192605-ur4910kfhiejtrahuibcvp6cn9ov7kvu.apps.googleusercontent.com",
  navCollapsed: false, // Sidebar collapse
  lightCMPConfigPreviewUrl:
    "https://cdn.gravito.fi/cmppreview/lightCMPiframe.html",
  gravitoCDNUrl: "https://cdn.gravito.net/",
  tcfCMPConfigPreviewUrl: "https://cdn.gravito.fi/cmppreview/tcfCMPiframe.html",
  striptpaymentKey:
    "pk_live_51Ix5tODMqZTvKuMsxfHHFEJopx4Z3yVON8u4pOp2XV4WcVaBM6W2r6r1Eb5NiD8xxaGM6DScSyyt1faC46m5AFz700nemxNlLV",
  darkMode: false, // Dark Mode
  boxLayout: false, // Box Layout
  rtlLayout: false, // RTL Layout
  miniSidebar: false, // Mini Sidebar
  sidebarActiveFilter: "dark", // Select Sidebar Color You can Choose following color 'primary', 'blue', 'warning', 'info', 'danger','success','purple'
  enableSidebarBackgroundImage: true, // Enable Sidebar Background Image
  sidebarImage: require("../assets/img/boxed-bg.png"), // Select sidebar image
  locale: {
    languageId: "english",
    locale: "en_US",
    name: "English",
    icon: "en",
  },
  controls: {
    subject: [
      {
        key: "subject",
        label: "subject",
        props: { required: true, placeholder: "Enter Subject" },
      },
    ],
    body: [
      {
        key: "body",
        label: "body",
        type: "richtext",
        props: { required: true, placeholder: "Enter Body Content" },
      },
    ],
    simpletext: [
      {
        key: "simpletext",
        label: "simpletext",
        props: { placeholder: "Enter Text Content", required: true },
      },
    ],
    heroimage: [
      {
        key: "heroimage",
        label: "heroimage",
        type: "url",
        props: { placeholder: "Enter Image Url", required: true },
      },
    ],
    rawhtml: [{ key: "rawhtml", label: "rawhtml", type: "rawhtml" }],
  },
  CampaignChannel: {
    EMAIL: 0,
    SMS: 1,
    MOBILE: 2,
    WEB: 3,
    SNAIL: 4,
    WEBHOOK: 5,
    AD: 6,
  },
  enableUserTour: true, // Enable / Disable User Tour
  maxCredit: 100,
  paypalClientId:
    "AT35ESL753xSVe_D_wsKEGEK718J3JpxfIioBp0bKPTOZPXFMLa8XCWGGLYVxQq1WvyVeqv0Q7a0mSuc",
  copyRightText: "Gravito Ltd © 2021 All Rights Reserved.", // Copy Right Text
  // light theme colors
  themeColors: {
    primary: "#f07e26",
    secondary: "#706f6f",
    info: "#C9C9C9",
    success: "#919191",
    warning: "#D5573B",
    danger: "#706f6f",
    default: "#657786",
    purple: "#6f42c1",
    yellow: "#D46B08",
    white: "#FFFFFF",
    dark: "#706f6f",
    greyLighten: "#DFE3E8",
    grey: "#9FA5AB",
  },
  // dark theme colors
  darkThemeColors: {
    darkBgColor: "#424242",
  },
  identityClientMapping: [
    { value: 1, label: "Single Page Application" },
    { value: 2, label: "Login Web Component" },
    { value: 0, label: "Access Gravito" },
  ],
  appVersion: 42,
  webhookPayload: {
    GravitoId: "d8e25298-5538-44b2-940c-4b9a1479ffe6",
    Email: "testuser@gravito.net",
    EventType: "consentAdded",
    Domain: "",
    ConsentName: "Email",
    ConsentValue: true,
    UpdatedTs: "",
  },
  customDomainConstants: {
    ARecordIp: "13.69.228.1",
    TextRecordUid: "asuid.",
    TextRecordValue:
      "9078AD835C4DC1F8799E400B00C22F557D87DA569B7F322F989D58EE9418D209",
    CNameRecordNetwork: "_acme-challenge-MD5PLACEHOLDER.gravito.network",
    CNAMERecordText: "_acme-challenge.TEXTPLACEHOLDER",
  },
  countries: [
    {
      name: "Argentina",
      dial_code: "+54",
      code: "AR",
    },
    {
      name: "Armenia",
      dial_code: "+374",
      code: "AM",
    },
    {
      name: "Australia",
      dial_code: "+61",
      code: "AU",
    },
    {
      name: "Austria",
      dial_code: "+43",
      code: "AT",
    },
    {
      name: "Belgium",
      dial_code: "+32",
      code: "BE",
    },
    {
      name: "Brazil",
      dial_code: "+55",
      code: "BR",
    },
    {
      name: "Bulgaria",
      dial_code: "+359",
      code: "BG",
    },
    {
      name: "Burkina Faso",
      dial_code: "+226",
      code: "BF",
    },
    {
      name: "Canada",
      dial_code: "+1",
      code: "CA",
    },

    {
      name: "Chile",
      dial_code: "+56",
      code: "CL",
    },
    {
      name: "China",
      dial_code: "+86",
      code: "CN",
    },

    {
      name: "Colombia",
      dial_code: "+57",
      code: "CO",
    },
    {
      name: "Congo",
      dial_code: "+242",
      code: "CG",
    },
    {
      name: "Czech Republic",
      dial_code: "+420",
      code: "CZ",
    },
    {
      name: "Denmark",
      dial_code: "+45",
      code: "DK",
    },
    {
      name: "Djibouti",
      dial_code: "+253",
      code: "DJ",
    },
    {
      name: "Egypt",
      dial_code: "+20",
      code: "EG",
    },
    {
      name: "Estonia",
      dial_code: "+372",
      code: "EE",
    },
    {
      name: "Finland",
      dial_code: "+358",
      code: "fi",
    },
    {
      name: "France",
      dial_code: "+33",
      code: "FR",
    },
    {
      name: "Germany",
      dial_code: "+49",
      code: "DE",
    },
    {
      name: "Hungary",
      dial_code: "+36",
      code: "HU",
    },
    {
      name: "India",
      dial_code: "+91",
      code: "IN",
    },
    {
      name: "Indonesia",
      dial_code: "+62",
      code: "ID",
    },
    {
      name: "Iran, Islamic Republic of Persian Gulf",
      dial_code: "+98",
      code: "IR",
    },
    {
      name: "Ireland",
      dial_code: "+353",
      code: "IE",
    },
    {
      name: "Israel",
      dial_code: "+972",
      code: "IL",
    },
    {
      name: "Italy",
      dial_code: "+39",
      code: "IT",
    },
    {
      name: "Japan",
      dial_code: "+81",
      code: "JP",
    },
    {
      name: "Kenya",
      dial_code: "+254",
      code: "KN",
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: "+850",
      code: "KP",
    },
    {
      name: "Korea, Republic of South Korea",
      dial_code: "+82",
      code: "KR",
    },
    {
      name: "Kuwait",
      dial_code: "+965",
      code: "KW",
    },
    {
      name: "Latvia",
      dial_code: "+371",
      code: "LV",
    },
    {
      name: "Luxembourg",
      dial_code: "+352",
      code: "LU",
    },
    {
      name: "Mexico",
      dial_code: "+52",
      code: "MX",
    },
    {
      name: "Netherlands",
      dial_code: "+31",
      code: "NL",
    },
    {
      name: "Norway",
      dial_code: "+47",
      code: "NO",
    },
    {
      name: "Oman",
      dial_code: "+968",
      code: "OM",
    },
    {
      name: "Pakistan",
      dial_code: "+92",
      code: "PK",
    },
    {
      name: "Paraguay",
      dial_code: "+595",
      code: "PY",
    },
    {
      name: "Peru",
      dial_code: "+51",
      code: "PE",
    },
    {
      name: "Poland",
      dial_code: "+48",
      code: "PL",
    },
    {
      name: "Portugal",
      dial_code: "+351",
      code: "PT",
    },
    {
      name: "Qatar",
      dial_code: "+974",
      code: "QA",
    },
    {
      name: "Romania",
      dial_code: "+40",
      code: "RO",
    },
    {
      name: "Russia",
      dial_code: "+7",
      code: "RU",
    },
    {
      name: "Saudi Arabia",
      dial_code: "+966",
      code: "SA",
    },
    {
      name: "Singapore",
      dial_code: "+65",
      code: "SG",
    },
    {
      name: "Spain",
      dial_code: "+34",
      code: "ES",
    },
    {
      name: "Sweden",
      dial_code: "+46",
      code: "SE",
    },
    {
      name: "Switzerland",
      dial_code: "+41",
      code: "CH",
    },
    {
      name: "United Arab Emirates",
      dial_code: "+971",
      code: "AE",
    },
    {
      name: "United Kingdom",
      dial_code: "+44",
      code: "GB",
    },
    {
      name: "United States",
      dial_code: "+1",
      code: "US",
    },
  ],
  identityConfig: {
    client_id: "059b3b53-fea2-4d17-938b-10d95606a1dc",
    grant_type: "code",
    scope: "API openid",
    redirect_uri: "https://admin.gravito.net/",
    authorize_endpoint: "https://identity.gravito.net/connect/authorize",
  },
};
// export default AppConfigLocal;
// export default AppConfigDev;
export default AppConfigProd;
