/**
 * Redux Actions for managing crud operations on Campaigns.
 */

 import {
    GET_CUSTOMER_EVENT_REPORT,
    GET_CUSTOMER_EVENT_REPORT_SUCCESS,
    GET_CUSTOMER_EVENT_REPORT_FAILURE,
    GET_CUSTOMER_LEDGER_REPORT,
    GET_CUSTOMER_LEDGER_REPORT_SUCCESS,
    GET_CUSTOMER_LEDGER_REPORT_FAILURE,
    GET_CUSTOMER_LIST,
    GET_CUSTOMER_LIST_SUCCESS,
    GET_CUSTOMER_LIST_FAILURE,
    SELECT_CUSTOMER,
    EXECUTE_PAYMENT,
    EXECUTE_PAYMENT_SUCCESS,
    EXECUTE_PAYMENT_FAILURE,
    REFUND_PAYMENT,
    REFUND_PAYMENT_SUCCESS,
    REFUND_PAYMENT_FAILURE,
    GET_CHANNEL_COUNT_REPORT,
    GET_CHANNEL_COUNT_REPORT_SUCCESS,
    GET_CHANNEL_COUNT_REPORT_FAILURE,
    SELECT_CUSTOMER_DOMAIN,
    SELECT_CUSTOMER_DOMAIN_SUCCESS,
    SELECT_CUSTOMER_DOMAIN_FAILURE,
    GET_COST_PER_ACTIVITY_SUCCESS,
    GET_COST_PER_ACTIVITY_FAILURE,
    GET_COST_PER_CONTACT_SUCCESS,
    GET_COST_PER_CONTACT_FAILURE,
    GET_CONSENT_CHECK_COUNT_SUCCESS,
    GET_CONSENT_CHECK_COUNT_FAILURE,
    GET_DOMAIN_EVENTS,
    GET_DOMAIN_EVENTS_SUCCESS,
    GET_DOMAIN_EVENTS_FAILURE,
    CHANGE_DOMAIN,
    GET_DOMAIN_CAMPAIGN_REPORT,
    GET_DOMAIN_CAMPAIGN_REPORT_FAILURE,
    GET_DOMAIN_LEDGER_REPORT,
    GET_DOMAIN_LEDGER_REPORT_SUCCESS,
    GET_DOMAIN_CAMPAIGN_REPORT_SUCCESS,
    GET_CUSTOMER_CONSUMER_REPORT,
    GET_CUSTOMER_CONSUMER_REPORT_SUCCESS,
    GET_CUSTOMER_CONSUMER_REPORT_FAILURE,
    GET_CUSTOMER_CONSENT_REPORT,
    GET_CUSTOMER_CONSENT_REPORT_SUCCESS,
    GET_CUSTOMER_CONSENT_REPORT_FAILURE,
    FILTER_CUSTOMER_CONSENT_REPORT
} from './types';

/**
 * Redux action to get Campaigns which are non draft status
 */
export const getCustomerEventReport = () => ({
    type: GET_CUSTOMER_EVENT_REPORT
})

/**
 * Redux action for successful fetching of Campaigns
 */

export const getCustomerEventReportSuccess = (response) => ({
    type: GET_CUSTOMER_EVENT_REPORT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaigns
 */
export const getCustomerEventReportFailure = (error) => ({
    type: GET_CUSTOMER_EVENT_REPORT_FAILURE,
    payload: error
})

/**
 * Redux action to get Campaigns stats
 */
export const getCustomerLedgerReport = () => ({
    type: GET_CUSTOMER_LEDGER_REPORT
})

/**
 * Redux action for successful fetching of Campaign Stats
 */

export const getCustomerLedgerReportSuccess = (response) => ({
    type: GET_CUSTOMER_LEDGER_REPORT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaign Stats
 */
export const getCustomerLedgerReportFailure = (error) => ({
    type: GET_CUSTOMER_LEDGER_REPORT_FAILURE,
    payload: error
})


/**
 * Redux action to get list of customers
 */
export const getCustomerList = () => ({
    type: GET_CUSTOMER_LIST
})

/**
 * Redux action for successful fetching of Campaigns
 */

export const getCustomerListSuccess = (response) => ({
    type: GET_CUSTOMER_LIST_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaigns
 */
export const getCustomerListFailure = (error) => ({
    type: GET_CUSTOMER_LIST_FAILURE,
    payload: error
})


export const selectReportingCustomer = (customer) => ({
    type: SELECT_CUSTOMER,
    payload: customer
})

/**
 * Redux action for executing payment
 */

export const executePayment = (payment) => ({
    type: EXECUTE_PAYMENT,
    payload: payment
})

/**
 * Redux action for successful payment
 */

export const executePaymentSuccess = (response) => ({
    type: EXECUTE_PAYMENT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in execute payment
 */
export const executePaymentFailure = (error) => ({
    type: EXECUTE_PAYMENT_FAILURE,
    payload: error
})


/**
 * Redux action for refunding payment
 */

export const refundPayment = (transactionid) => ({
    type: REFUND_PAYMENT,
    payload: transactionid
})

/**
 * Redux action for successful refund of payment
 */

export const refundPaymentSuccess = (response) => ({
    type: REFUND_PAYMENT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in refunding payment
 */
export const refundPaymentFailure = (error) => ({
    type: REFUND_PAYMENT_FAILURE,
    payload: error
})


/**
 * Redux action to get channel count report
 */
export const getChannelCountReport = () => ({
    type: GET_CHANNEL_COUNT_REPORT
})

/**
 * Redux action for successful fetching of channel count
 */

export const getChannelCountReportSuccess = (response) => ({
    type: GET_CHANNEL_COUNT_REPORT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching channel count
 */
export const getChannelCountReportFailure = (error) => ({
    type: GET_CHANNEL_COUNT_REPORT_FAILURE,
    payload: error
})


/**
 * Redux action to get channel count report
 */
export const getChannelCountReportForDomain = (domain) => ({
    type: SELECT_CUSTOMER_DOMAIN,
    payload: domain
})

/**
 * Redux action for successful fetching of channel count
 */

export const getChannelCountReportForDomainSuccess = (response) => ({
    type: SELECT_CUSTOMER_DOMAIN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching channel count
 */
export const getChannelCountReportForDomainFailure = (error) => ({
    type: SELECT_CUSTOMER_DOMAIN_FAILURE,
    payload: error
})



/**
 * Redux action for successful fetching cost per activity
 */

export const getCostPerActivitySuccess = (response) => ({
    type: GET_COST_PER_ACTIVITY_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching cost per contact
 */
export const getCostPerActivityFailure = (error) => ({
    type: GET_COST_PER_ACTIVITY_FAILURE,
    payload: error
})

/**
 * Redux action for successful fetching cost per contact
 */

export const getCostPerContactSuccess = (response) => ({
    type: GET_COST_PER_CONTACT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching cost per contact
 */
export const getCostPerContactFailure = (error) => ({
    type: GET_COST_PER_CONTACT_FAILURE,
    payload: error
})



/**
 * Redux action for successful fetching consent checks
 */

export const getConsentCheckCountSuccess = (response) => ({
    type: GET_CONSENT_CHECK_COUNT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching consent checks
 */
export const getConsentCheckCountFailure = (error) => ({
    type: GET_CONSENT_CHECK_COUNT_FAILURE,
    payload: error
})
export const getDomainEventReport = (domain) => (
    {
        type: GET_DOMAIN_EVENTS,
        payload:domain

    }
)
export const getDomainEventReportSuccess = (data) => (
    {
        type: GET_DOMAIN_EVENTS_SUCCESS,
        payload: data

    }
)
export const getDomainEventReportFailure = (error) => (
    {
        type: GET_DOMAIN_EVENTS_FAILURE,
        payload: error

    }
)
export const changedomain=(domain)=>{
    return(
        {
            type:CHANGE_DOMAIN,
            payload:domain
        }
    )
}

export const getDomainCampaignReport=()=>{
    return(
        {
            type:GET_DOMAIN_CAMPAIGN_REPORT,
            
        }
    )
}
export const getDomainCampaignReportSuccess=(data)=>{
    return(
        {
            type:GET_DOMAIN_CAMPAIGN_REPORT_SUCCESS,
            payload:data
        }
    )
}
export const getDomainCampaignReportFailure=(error)=>{
    return(
        {
            type:GET_DOMAIN_CAMPAIGN_REPORT_FAILURE,
            payload:error
        }
    )
}
export const getDomainLedgerReport=()=>{
    return(
        {
            type:GET_DOMAIN_LEDGER_REPORT,
        }
    )
}
export const getDomainLedgerReportSuccess=(data)=>{
    return(
        {
            type:GET_DOMAIN_LEDGER_REPORT_SUCCESS,
            payload:data
        }
    )
}
export const getDomainLedgerReportFailure=(error)=>{
    return(
        {
            type:GET_DOMAIN_LEDGER_REPORT_SUCCESS,
            payload:error
        }
    )
}


/**
 * Redux action to get customer consents stats
 */
 export const getCustomerConsentReport = (filter) => ({
    type: GET_CUSTOMER_CONSENT_REPORT,
    payload:filter
})

/**
 * Redux action for successful fetching of consent Stats
 */

export const getCustomerConsentReportSuccess = (response) => ({
    type: GET_CUSTOMER_CONSENT_REPORT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching consent Stats
 */
export const getCustomerConsentReportFailure = (error) => ({
    type: GET_CUSTOMER_CONSENT_REPORT_FAILURE,
    payload: error
})

export const filterCustomerConsentReport = (filter) => ({
    type: FILTER_CUSTOMER_CONSENT_REPORT,
    payload:filter
})

/**
 * Redux action to get customer consumer data stats
 */
 export const getCustomerConsumerReport = (type) => ({
    type: GET_CUSTOMER_CONSUMER_REPORT,
    payload: type
})

/**
 * Redux action for successful fetching of consumer Stats
 */

export const getCustomerConsumerReportSuccess = (response) => ({
    type: GET_CUSTOMER_CONSUMER_REPORT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching consumer Stats
 */
export const getCustomerConsumerReportFailure = (error) => ({
    type: GET_CUSTOMER_CONSUMER_REPORT_FAILURE,
    payload: error
})


