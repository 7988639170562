/* eslint-disable import/no-anonymous-default-export */
/**
 * Manage User Reducer
 */

import { NotificationManager } from "react-notifications";
// action types
import {
  GET_EVENTS_FOR_DOMAIN_PROFILE,
  GET_EVENTS_FOR_DOMAIN_PROFILE_FAILURE,
  GET_EVENTS_FOR_DOMAIN_PROFILE_SUCCESS,
  GET_USERS_FOR_DOMAIN,
  GET_USERS_FOR_DOMAIN_FAILURE,
  GET_USERS_FOR_DOMAIN_SUCCESS,
} from "../actions/types";

// initial state
const INIT_STATE = {
  domain: "",
  selectedProfile: null,
  users: [],
  events: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // get domain users
    case GET_USERS_FOR_DOMAIN:
      return { ...state, loading: true, domain: action.payload };
    // Success in getting domain users
    case GET_USERS_FOR_DOMAIN_SUCCESS:
      // Remove device from role list, as we are not loading device type users.
      if (action.payload) {
        var domainUsers = [];
        action.payload.forEach((element) => {
          if (element.userDetails) {
            var user = {
              id: element.id,
              user: element.userDetails,
            };
            domainUsers.push({ label: element.userDetails.email, value: user });
          } else {
            domainUsers.push({
              label: "Anonymous - " + element.id.split("-")[0],
              value: element.id,
            });
          }
        });
      }

      return { ...state, loading: false, users: domainUsers };
    // failure in fetching the domain users
    case GET_USERS_FOR_DOMAIN_FAILURE:
      NotificationManager.error("Could not load users");
      return { ...state, loading: false };
    case GET_EVENTS_FOR_DOMAIN_PROFILE:
      return { ...state, loading: true, selectedProfile: action.payload };
    case GET_EVENTS_FOR_DOMAIN_PROFILE_SUCCESS:
      return { ...state, loading: false, events: action.payload.data };
    case GET_EVENTS_FOR_DOMAIN_PROFILE_FAILURE:
      NotificationManager.error("We found errors while fetching the events.");
      return { ...state, loading: false };
    // return default state.
    default:
      return { ...state };
  }
};
