/* eslint-disable import/no-anonymous-default-export */
/**
 * App Settings Reducers
 */
import React from "react";
import { NotificationManager } from "react-notifications";
import {
  COLLAPSED_SIDEBAR,
  SEND_FEEDBACK,
  SEND_FEEDBACK_FAILURE,
  SEND_FEEDBACK_SUCCESS,
  SET_LANGUAGE,
  SET_SIDEBAR_IMAGE,
} from "../actions/types";
// app config
import AppConfig from "../constants/AppConfig";
import IntlMessages from "../util/IntlMessages";

/**
 * initial app settings
 */

const INIT_STATE = {
  navCollapsed: AppConfig.navCollapsed,
  darkMode: AppConfig.darkMode,
  boxLayout: AppConfig.boxLayout,
  rtlLayout: AppConfig.rtlLayout,
  miniSidebar: AppConfig.miniSidebar,
  searchFormOpen: false, // search form by default false
  startUserTour: false,
  themes: [
    {
      id: 1,
      name: "primary",
    },
    {
      id: 2,
      name: "secondary",
    },
    {
      id: 3,
      name: "warning",
    },
    {
      id: 4,
      name: "info",
    },
    {
      id: 5,
      name: "danger",
    },
    {
      id: 6,
      name: "success",
    },
  ],
  activeTheme: {
    id: 1,
    name: "primary",
  },
  // sidebar background image
  sidebarBackgroundImages: [
    require("../assets/img/sidebar-1.jpg"),
    require("../assets/img/sidebar-2.jpg"),
    require("../assets/img/sidebar-3.jpg"),
    require("../assets/img/sidebar-4.jpg"),
  ],
  sidebarActiveFilter: AppConfig.sidebarActiveFilter, // default sidebar color
  enableSidebarBackgroundImage: AppConfig.enableSidebarBackgroundImage, // default enable sidebar background
  selectedSidebarImage: AppConfig.sidebarImage, // default sidebar background image
  locale: AppConfig.locale,
  languages: [
    {
      languageId: "english",
      locale: "en",
      name: "English",
      icon: "en",
    },
    {
      languageId: "finnish",
      locale: "fi",
      name: "Finnish",
      icon: "fi",
    },
  ],
};

const Message = ({ id }) => <IntlMessages id={id} />;

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // collapse sidebar
    case COLLAPSED_SIDEBAR:
      return { ...state, navCollapsed: action.isCollapsed };

    // set sidebar image
    case SET_SIDEBAR_IMAGE:
      return { ...state, selectedSidebarImage: action.payload };

    // set language
    case SET_LANGUAGE:
      return { ...state, locale: action.payload };

    // send feedback
    case SEND_FEEDBACK:
      return { ...state, feedback: action.payload };

    // send feedback success
    case SEND_FEEDBACK_SUCCESS:
      NotificationManager.success(Message({ id: "feedback.success" }));
      return { ...state, feedback: null };

    // send feedback failure
    case SEND_FEEDBACK_FAILURE:
      NotificationManager.error(Message({ id: "feedback.failure" }));
      return { ...state, feedback: null };

    default:
      return { ...state };
  }
};
