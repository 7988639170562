/**
 * Redux Actions for managing crud operations on Campaigns.
 */

import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMER_BY_ID_FAILURE,
  GET_CUSTOMER_SCAN,
  GET_CUSTOMER_SCAN_SUCCESS,
  GET_CUSTOMER_SCAN_FAILURE,
  REFRESH_CUSTOMERS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  CANCEL_ONBOARDING,
  CANCEL_ONBOARDING_SUCCESS,
  CANCEL_ONBOARDING_FAILURE,
  ONBOARD_CUSTOMER,
  ONBOARD_CUSTOMER_SUCCESS,
  ONBOARD_CUSTOMER_FAILURE,
  HOLD_CUSTOMER,
  HOLD_CUSTOMER_SUCCESS,
  HOLD_CUSTOMER_FAILURE,
  SEARCH_CUSTOMERS,
  SEARCH_DOMAINS,
  REFRESH_CUSTOMER_DOMAIN_LIST,
  REFRESH_CUSTOMER_DOMAIN_LIST_SUCCESS,
  REFRESH_CUSTOMER_DOMAIN_LIST_FAILURE,
  SELECT_A_CUSTOMER,
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_TYPE,
  UPDATE_CUSTOMER_TYPE_SUCCESS,
  UPDATE_CUSTOMER_TYPE_FAILURE,
  DELETE_CUSTOMER_SCAN_DOMAIN,
  DELETE_CUSTOMER_SCAN_DOMAIN_SUCCESS,
  DELETE_CUSTOMER_SCAN_DOMAIN_FAILURE,
  ADD_CUSTOMER_SCAN_DOMAIN,
  ADD_CUSTOMER_SCAN_DOMAIN_SUCCESS,
  ADD_CUSTOMER_SCAN_DOMAIN_FAILURE,
} from "./types";

/**
 * Redux action to get Customer
 */
export const getCustomer = () => ({
  type: GET_CUSTOMERS,
});

/**
 * Redux action for successful fetching of Customer
 */

export const getCustomerSuccess = (response) => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching Customer
 */
export const getCustomerFailure = (error) => ({
  type: GET_CUSTOMERS_FAILURE,
  payload: error,
});

export const getCustomerById = (id) => ({
  type: GET_CUSTOMER_BY_ID,
  payload: id,
});

export const getCustomerByIdSuccess = (response) => ({
  type: GET_CUSTOMER_BY_ID_SUCCESS,
  payload: response,
});

export const getCustomerByIdFailure = (error) => ({
  type: GET_CUSTOMER_BY_ID_FAILURE,
  payload: error,
});

export const getCustomerScan = () => ({
  type: GET_CUSTOMER_SCAN,
});

export const getCustomerScanSuccess = (response) => ({
  type: GET_CUSTOMER_SCAN_SUCCESS,
  payload: response,
});

export const getCustomerScanFailure = (error) => ({
  type: GET_CUSTOMER_SCAN_FAILURE,
  payload: error,
});

export const deleteCustomerScanDomain = (id) => ({
  type: DELETE_CUSTOMER_SCAN_DOMAIN,
  payload: id,
});

export const deleteCustomerScanSuccess = (response) => ({
  type: DELETE_CUSTOMER_SCAN_DOMAIN_SUCCESS,
  payload: response,
});

export const deleteCustomerScanFailure = (error) => ({
  type: DELETE_CUSTOMER_SCAN_DOMAIN_FAILURE,
  payload: error,
});

export const addCustomerScanDomain = (domainIds) => ({
  type: ADD_CUSTOMER_SCAN_DOMAIN,
  payload: domainIds,
});

export const addCustomerScanSuccess = (response) => ({
  type: ADD_CUSTOMER_SCAN_DOMAIN_SUCCESS,
  payload: response,
});

export const addCustomerScanFailure = (error) => ({
  type: ADD_CUSTOMER_SCAN_DOMAIN_FAILURE,
  payload: error,
});

/**
 * Redux action to refresh CustomerDomainList
 */
export const refreshCustomerDomainList = () => ({
  type: REFRESH_CUSTOMER_DOMAIN_LIST,
});

/**
 * Redux action for successful fetching of Customer
 */

export const refreshCustomerDomainListSuccess = (response) => ({
  type: REFRESH_CUSTOMER_DOMAIN_LIST_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching Customer
 */
export const refreshCustomerDomainListFailure = (error) => ({
  type: REFRESH_CUSTOMER_DOMAIN_LIST_FAILURE,
  payload: error,
});

export const refreshCustomers = (response) => ({
  type: REFRESH_CUSTOMERS,
  payload: response,
});

export const searchDomain = (searchText) => ({
  type: SEARCH_DOMAINS,
  payload: searchText,
});

export const cancelCustomerOnboarding = (model) => ({
  type: CANCEL_ONBOARDING,
  payload: model,
});

export const cancelCustomerOnboardingSuccess = (response) => ({
  type: CANCEL_ONBOARDING_SUCCESS,
  payload: response,
});

export const cancelCustomerOnboardingFailure = (error) => ({
  type: CANCEL_ONBOARDING_FAILURE,
  payload: error,
});

export const updateCustomer = (model) => ({
  type: UPDATE_CUSTOMER,
  payload: model,
});

export const updateCustomerSuccess = (response) => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: response,
});

export const updateCustomerFailure = (error) => ({
  type: UPDATE_CUSTOMER_FAILURE,
  payload: error,
});

export const onboardCustomer = (model) => ({
  type: ONBOARD_CUSTOMER,
  payload: model,
});

export const onboardCustomerSuccess = (response) => ({
  type: ONBOARD_CUSTOMER_SUCCESS,
  payload: response,
});

export const onboardCustomerFailure = (error) => ({
  type: ONBOARD_CUSTOMER_FAILURE,
  payload: error,
});

export const customerOnHold = (model) => ({
  type: HOLD_CUSTOMER,
  payload: model,
});

export const customerOnHoldSuccess = (response) => ({
  type: HOLD_CUSTOMER_SUCCESS,
  payload: response,
});

export const customerOnHoldFailure = (error) => ({
  type: HOLD_CUSTOMER_FAILURE,
  payload: error,
});

/**
 * Redux Action On Search Customers
 */
export const searchCustomers = (searchText) => ({
  type: SEARCH_CUSTOMERS,
  payload: searchText,
});

/**
 * Redux action for selecting a customer
 */
export const selectCustomer = (id) => ({
  type: SELECT_A_CUSTOMER,
  payload: id,
});

export const addCustomer = (model) => ({
  type: ADD_CUSTOMER,
  payload: model,
});

export const addCustomerSuccess = (response) => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: response,
});

export const addCustomerFailure = (error) => ({
  type: ADD_CUSTOMER_FAILURE,
  payload: error,
});

export const updateCustomerType = (model) => ({
  type: UPDATE_CUSTOMER_TYPE,
  payload: model,
});

export const updateCustomerTypeSuccess = (response) => ({
  type: UPDATE_CUSTOMER_TYPE_SUCCESS,
  payload: response,
});

export const updateCustomerTypeFailure = (error) => ({
  type: UPDATE_CUSTOMER_TYPE_FAILURE,
  payload: error,
});
