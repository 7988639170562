/**
 * Roles Sagas
 */
 import { all, call, put, takeEvery, select } from 'redux-saga/effects';
 import {getAxiosRequest} from '../helpers/helpers'
 
 
 import {
    confirmTeamInviteSuccess,
    confirmTeamInviteFailure
 } from '../actions';
  
 
 
 const invitation = (state) => state.inviteConfirmationReducer;
 
 
 const confirmInvitation = async (model) =>{
     return await getAxiosRequest().post('/account/magiclinkinviteconfirmation', model);
 }
 
 function* inviteConfirmation(){
     
     let invite = yield select(invitation); 

     let model = {
         token: invite.confirmationToken
     }

     try {

         const response = yield call(confirmInvitation,model);
         yield put(confirmTeamInviteSuccess(response))
       
     } catch (error) {
         yield put(confirmTeamInviteFailure(error.response.data.error))
     }
 
 }
 
 
 
 export const inviteConfirmationSagas = [
     takeEvery('CONFIRM_TEAMMEMBER_INVITE', inviteConfirmation)
   
 ]
 
 
 /**
  * Confirmation Root Saga
  */
 export default function* rootSaga() {
     yield all([
         ...inviteConfirmationSagas,
     ])
 }
 
 