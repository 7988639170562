import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_FAILURE,
  ADD_CUSTOMER_SUCCESS,
  CANCEL_ONBOARDING,
  CANCEL_ONBOARDING_FAILURE,
  CANCEL_ONBOARDING_SUCCESS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAILURE,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_BY_ID_FAILURE,
  GET_CUSTOMER_BY_ID_SUCCESS,
  GET_CUSTOMER_SCAN,
  GET_CUSTOMER_SCAN_FAILURE,
  GET_CUSTOMER_SCAN_SUCCESS,
  DELETE_CUSTOMER_SCAN_DOMAIN,
  DELETE_CUSTOMER_SCAN_DOMAIN_SUCCESS,
  DELETE_CUSTOMER_SCAN_DOMAIN_FAILURE,
  ADD_CUSTOMER_SCAN_DOMAIN,
  ADD_CUSTOMER_SCAN_DOMAIN_SUCCESS,
  ADD_CUSTOMER_SCAN_DOMAIN_FAILURE,
  HOLD_CUSTOMER,
  HOLD_CUSTOMER_FAILURE,
  HOLD_CUSTOMER_SUCCESS,
  ONBOARD_CUSTOMER,
  ONBOARD_CUSTOMER_FAILURE,
  ONBOARD_CUSTOMER_SUCCESS,
  REFRESH_CUSTOMERS,
  REFRESH_CUSTOMER_DOMAIN_LIST,
  REFRESH_CUSTOMER_DOMAIN_LIST_FAILURE,
  REFRESH_CUSTOMER_DOMAIN_LIST_SUCCESS,
  SEARCH_DOMAINS,
  SEARCH_CUSTOMERS,
  SELECT_A_CUSTOMER,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_TYPE,
  UPDATE_CUSTOMER_TYPE_FAILURE,
  UPDATE_CUSTOMER_TYPE_SUCCESS,
} from "../actions/types";
import AppConfig from "../constants/AppConfig";

// initial state
const INIT_STATE = {
  customer: null,
  customers: [],
  filteredCustomers: [],
  domains: [],
  filteredDomains: [],
  loading: false,
  customerDetail: null,
  customerStatus: null,
  selected: null,
  selectedDomainID: null,
  selectedDomainIDs: [],
  search: "",
  redirect: false,
  customerTypeModel: null,
  customerScan: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get non draft campaigns from server.
    case GET_CUSTOMERS:
      return { ...state, loading: true, redirect: false };
    // On Success...
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customers: action.payload,
        filteredCustomers: action.payload,
      };

    // On Failure, show error message.
    case GET_CUSTOMERS_FAILURE:
      NotificationManager.error(
        "Opps, we faced problems while fetching customers."
      );
      return { ...state, loading: false, customers: [], error: action.payload };

    case GET_CUSTOMER_BY_ID:
      return { ...state, loading: true, redirect: false };

    case GET_CUSTOMER_BY_ID_SUCCESS:
      return { ...state, loading: false, customer: action.payload };

    case GET_CUSTOMER_BY_ID_FAILURE:
      NotificationManager.error("Failed to fetch the customer details.");
      return { ...state, loading: false, error: action.payload };

    case GET_CUSTOMER_SCAN:
      return { ...state, loading: true, redirect: false };

    case GET_CUSTOMER_SCAN_SUCCESS:
      return { ...state, loading: false, customerScan: action.payload };

    case GET_CUSTOMER_SCAN_FAILURE:
      NotificationManager.error("Failed to fetch the customer details.");
      return { ...state, loading: false, error: action.payload };

    case DELETE_CUSTOMER_SCAN_DOMAIN:
      return {
        ...state,
        loading: true,
        selectedDomainID: action.payload,
      };

    case DELETE_CUSTOMER_SCAN_DOMAIN_SUCCESS:
      NotificationManager.success("Domain removed successfully.");
      return { ...state, loading: false };

    case DELETE_CUSTOMER_SCAN_DOMAIN_FAILURE:
      NotificationManager.error("Failed to remove the domain.");
      return { ...state, loading: false, error: action.payload };

    case ADD_CUSTOMER_SCAN_DOMAIN:
      return { ...state, loading: true, selectedDomainIDs: action.payload };

    case ADD_CUSTOMER_SCAN_DOMAIN_SUCCESS:
      NotificationManager.success("Domain added successfully.");
      return { ...state, selectedDomainIDs: [], loading: false };

    case ADD_CUSTOMER_SCAN_DOMAIN_FAILURE:
      NotificationManager.error("Failed to add the domain.");
      return {
        ...state,
        loading: false,
        selectedDomainIDs: [],
        error: action.payload,
      };

    case CANCEL_ONBOARDING:
      var status = {
        id: action.payload.id,
        status: 0,
      };
      return { ...state, loading: true, customerStatus: status };

    case CANCEL_ONBOARDING_SUCCESS:
      //NotificationManager.success('Customer Approved successfully.');
      return { ...state, loading: false, customerStatus: null };

    case CANCEL_ONBOARDING_FAILURE:
      //NotificationManager.error('Customer Approval Failed.');
      return {
        ...state,
        loading: false,
        customerStatus: null,
        error: action.payload,
      };

    case ONBOARD_CUSTOMER:
      return { ...state, loading: true, customerDetail: action.payload };

    case ONBOARD_CUSTOMER_SUCCESS:
      NotificationManager.success("Customer onboarded successfully.");
      return { ...state, loading: false };

    case ONBOARD_CUSTOMER_FAILURE:
      NotificationManager.error("Customer onboarding Failed.");
      return { ...state, loading: false, error: action.payload };

    case UPDATE_CUSTOMER:
      let customerDetails = action.payload;
      if (action.payload.countryCode && action.payload.countryCode.value) {
        customerDetails.countryCode = action.payload.countryCode.value;
      } else if (
        action.payload.countryCode &&
        action.payload.countryCode.value == null
      ) {
        customerDetails.countryCode = action.payload.countryCode[0].value;
      } else {
        customerDetails.countryCode = "";
      }
      return { ...state, loading: true, customerDetail: customerDetails };

    case UPDATE_CUSTOMER_SUCCESS:
      NotificationManager.success("Customer updated successfully.");
      return { ...state, loading: false, redirect: true };

    case UPDATE_CUSTOMER_FAILURE:
      NotificationManager.error("Customer updation Failed.");
      return { ...state, loading: false, error: action.payload };

    case REFRESH_CUSTOMERS:
      return {
        ...state,
        loading: false,
        customers: action.payload,
        filteredCustomers: action.payload,
      };

    case HOLD_CUSTOMER:
      var holdStatus = {
        id: action.payload.id,
        status: 2,
      };
      return { ...state, loading: true, customerStatus: holdStatus };

    case HOLD_CUSTOMER_SUCCESS:
      //NotificationManager.success('Customer Approved successfully.');
      return { ...state, loading: false, customerStatus: null };

    case HOLD_CUSTOMER_FAILURE:
      //NotificationManager.error('Customer Approval Failed.');
      return {
        ...state,
        loading: false,
        customerStatus: null,
        error: action.payload,
      };

    case SEARCH_CUSTOMERS:
      if (action.payload === "") {
        return { ...state, filteredCustomers: state.customers };
      } else {
        const filtered = state.customers.filter(
          (item) =>
            item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredCustomers: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case REFRESH_CUSTOMER_DOMAIN_LIST:
      return { ...state, loading: true };
    case REFRESH_CUSTOMER_DOMAIN_LIST_SUCCESS:
      // NotificationManager.success("Customer domain list refreshed.");
      return {
        ...state,
        domains: action.payload,
        filteredDomains: action.payload,
        loading: false,
      };

    case REFRESH_CUSTOMER_DOMAIN_LIST_FAILURE:
      NotificationManager.error("Failed to fetch the customer domain list.");
      return { ...state, loading: false };

    case SEARCH_DOMAINS:
      if (action.payload === "") {
        return { ...state, filteredDomains: state.domains };
      } else {
        const filtered = state.domains.filter(
          (item) =>
            item.domainName
              .toLowerCase()
              .indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredDomains: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case SELECT_A_CUSTOMER:
      var selectedCustomer = _.filter(state.customers, {
        id: action.payload,
      })[0];

      if (selectedCustomer.countryCode) {
        let country = _.filter(AppConfig.countries, {
          code: selectedCustomer.countryCode.trim(),
        }).map(function (a) {
          return { value: a.code, label: a.name + " (" + a.dial_code + ")" };
        });
        if (country) {
          selectedCustomer.countryCode = country;
        }
      }

      return {
        ...state,
        loading: false,
        selected: selectedCustomer,
      };

    case ADD_CUSTOMER:
      var customer = {
        name: action.payload.name,
        requestedDomains: action.payload.requestedDomains,
        primaryEmail: action.payload.primaryEmail,
        address: action.payload.address,
        businessId: action.payload.businessId,
        phone: action.payload.phone,
        countryCode: action.payload.countryCode.value,
        iamPrimary: action.payload.usePrimaryEmail,
      };
      return { ...state, loading: true, customerDetail: customer };

    case ADD_CUSTOMER_SUCCESS:
      NotificationManager.success("Customer added successfully.");
      return { ...state, loading: false, redirect: true };

    case ADD_CUSTOMER_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, error: action.payload };

    case UPDATE_CUSTOMER_TYPE:
      return { ...state, loading: true, customerTypeModel: action.payload };

    case UPDATE_CUSTOMER_TYPE_SUCCESS:
      NotificationManager.success("Customer type changed successfully.");
      return { ...state, loading: false, customerTypeModel: null };

    case UPDATE_CUSTOMER_TYPE_FAILURE:
      NotificationManager.error(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
        customerTypeModel: null,
      };

    default:
      return { ...state };
  }
};
