/**
 * App Reducers
 */
import { combineReducers } from "redux";
import applicationsReducer from "./ApplicationsReducer";
import audienceReducer from "./AudiencesReducer";
import authUser from "./AuthUserReducer";
import campaignDashboardReducer from "./CampaignDashboardReducer";
import campaignReducer from "./CampaignReducer";
import configReducer from "./ConfigReducer";
import connectorsReducer from "./ConnectorsReducer";
import constellationReducer from "./ConstellationReducer";
import contentsReducer from "./ContentsReducer";
import scanCookiesReducer from "./CookieScanReducer";
import customDomainReducer from "./CustomDomainReducer";
import customerDashboardReducer from "./CustomerDashboardReducer";
import customerReducer from "./CustomerReducer";
import domainReducer from "./DomainReducer";
import domainSegmentsReducer from "./DomainSegmentsReducer";
import domainUserReducer from "./DomainUserReducer";
import helpReducer from "./helpReducer";
import lightConfigurationReducer from "./LightConfigurationReducer";
import domainPropertyReducer from "./ManageDomainPropertiesReducer";
import userReducer from "./ManagerUserReducer";
import registrationReducer from "./RegistrationReducer";
import reportReducer from "./ReportsReducer";
import rolesReducer from "./rolesReducer";
import segmentsReducer from "./SegmentsReducer";
import settings from "./settings";
import sidebarReducer from "./SidebarReducer";
import tcfConfigurationReducer from "./TCFConfigurationReducer";
import manageAccountReducer from "./ManageAccountReducer";
import inviteConfirmationReducer from "./InviteConfirmationReducer";
import manageRequestsReducer from "./ManageRequestsReducer";
import partnerReducer from "./ManagePartnerReducer";
import partnerCustomerReducer from "./ManagePartnerCustomerReducer";
import gvlUpdatesReducer from "./GVLUpdateReducer";
import AdminRouteReducer from "./AdminRouteReducer";
import FirstPartyAppsReducer from "./FirstPartyAppsReducer";
import CustomerDomainAppsReducer from "./CustomerDomainAppsReducer";
import compareTrendsReducer from "./CompareTrendsReducer";
const reducers = combineReducers({
  settings,
  sidebar: sidebarReducer,
  authUser: authUser,
  rolesReducer: rolesReducer,
  userReducer: userReducer,
  contentsReducer: contentsReducer,
  configReducer: configReducer,
  constellationReducer: constellationReducer,
  domainSegmentsReducer: domainSegmentsReducer,
  audiencesReducer: audienceReducer,
  campaignReducer: campaignReducer,
  campaignDashboardReducer,
  domainReducer,
  customerReducer,
  customerDashboardReducer,
  domainPropertyReducer,
  domainUserReducer,
  segmentsReducer,
  applicationsReducer,
  customDomainReducer,
  lightConfigurationReducer,
  tcfConfigurationReducer,
  helpReducer,
  reportReducer,
  connectorsReducer,
  registrationReducer,
  scanCookiesReducer,
  manageAccountReducer,
  inviteConfirmationReducer,
  manageRequestsReducer,
  partnerReducer,
  partnerCustomerReducer,
  gvlUpdatesReducer,
  AdminRouteReducer,
  FirstPartyAppsReducer,
  CustomerDomainAppsReducer,
  compareTrendsReducer,
});

export default reducers;
