/* eslint-disable react-hooks/exhaustive-deps */
/**
 * App.js Layout Start Here
 */
import { MuiThemeProvider } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { SpringSpinner } from "react-epic-spinners";
import { IntlProvider } from "react-intl";
import { NotificationContainer } from "react-notifications";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
// actions
import {
  authenticateUser,
  checkCustomerAdminStatus,
  getUserDomains,
  setLanguage,
} from "../actions";
// App locale
import { AppLocale } from "../lang";
import AuthContainer from "./AuthContainer";
import ConfirmationContainer from "./ConfirmationContainer";
import LandingContainer from "./LandingContainer";
import RegContainer from "./RegContainer";
// App locale
// themes
import primaryTheme from "./themes/primaryTheme";

const Auth = ({ component: Component, authUser, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const Landing = ({ component: Component, authUser, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

/**
 * Initial Path for invite confirmation
 */
const Confirmation = ({ component: Component, authUser, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

/**
 * Initial Path To Check Whether User Is Logged In Or Not
 */
const Registration = ({ component: Component, authUser, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

const AppContainer = (props) => {
  const { history, location } = props;

  useEffect(() => {
    // authenticate user if its not a invite confirmation link
    if (location.pathname !== "/magiclinkinviteconfirmation") {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const authorization_code = params.get("code");
      const authorization_session_state = params.get("session_state");
      if (authorization_code) {
        localStorage.setItem("authorization_code", authorization_code);
      }
      if (authorization_session_state) {
        localStorage.setItem(
          "authorization_session_state",
          authorization_session_state
        );
        window.location.href = window.location.href.split("?")[0];
      }

      history.push("/");

      props.authenticateUser();
    }
  }, []);

  const { loading } = props.authUser;

  if (loading) {
    return (
      <div className="rct-loader">
        <SpringSpinner />
      </div>
    );
  }

  // Get the current language from the navigator.
  let lang = "en_US";
  //Load app locale by passing the language first part.
  const currentAppLocale = AppLocale(lang);
  // theme changes
  let theme = primaryTheme;
  /*
    Check if the user has clicked on the invite confirmation link. 
      - If the user has already logged in but have clicked on the link then show error page.
      - Else redirect user to confirmation page.

    - If the user has not landed on the confirmation route then
      1. Check if the user has logged in, 
      2. If the user is normal user, that means he has not registered then redirect user to registration page.
      3. If user has a registered user then redirect user to dashboard.
      
    - If the user has not logged in then - take the user to landing page for the sign in flow.
  */
  return (
    <MuiThemeProvider theme={theme}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <React.Fragment>
          <NotificationContainer />

          {location.pathname === "/magiclinkinviteconfirmation" ? (
            <Confirmation
              path={`${props.match.url}`}
              component={ConfirmationContainer}
            />
          ) : props.authUser.user ? (
            !props.authUser.isNormalUser ? (
              <Auth path={`${props.match.url}`} component={AuthContainer} />
            ) : (
              <Registration
                path={`${props.match.url}`}
                component={RegContainer}
              />
            )
          ) : (
            <Landing path={`${props.match.url}`} component={LandingContainer} />
          )}
        </React.Fragment>
      </IntlProvider>
    </MuiThemeProvider>
  );
};

// map state to props
const mapStateToProps = ({ settings, authUser }) => {
  return { settings, authUser };
};

export default withRouter(
  connect(mapStateToProps, {
    setLanguage,
    getUserDomains,
    checkCustomerAdminStatus,
    authenticateUser,
  })(AppContainer)
);
