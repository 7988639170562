/**
 * Redux Actions for managing admin profile options.
 */

import {
  ADD_DOMAIN_LICENSE,
  ADD_DOMAIN_LICENSE_FAILURE,
  ADD_DOMAIN_LICENSE_SUCCESS,
  CHANGE_COST_CENTER_MONTH,
  CREATE_MANUAL_VOUCHER,
  CREATE_MANUAL_VOUCHER_FAILURE,
  CREATE_MANUAL_VOUCHER_SUCCESS,
  DELETE_TEAMMEMBER,
  DELETE_TEAMMEMBER_FAILURE,
  DELETE_TEAMMEMBER_SUCCESS,
  FILTER_VOUCHERS_BY_STATUS,
  GET_CREDITS_LIST,
  GET_CREDITS_LIST_FAILURE,
  GET_CREDITS_LIST_SUCCESS,
  GET_CURRENT_CUSTOMER,
  GET_CURRENT_CUSTOMER_FAILURE,
  GET_CURRENT_CUSTOMER_SUCCESS,
  GET_CURRENT_CUSTOMER_VOUCHERS,
  GET_CURRENT_CUSTOMER_VOUCHERS_FAILURE,
  GET_CURRENT_CUSTOMER_VOUCHERS_SUCCESS,
  GET_DOMAIN_LICENSES,
  GET_LEDGER_BALANCE,
  GET_LEDGER_BALANCE_FAILURE,
  GET_LEDGER_BALANCE_SUCCESS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_VOUCHERS,
  GET_VOUCHERS_FAILURE,
  GET_VOUCHERS_SUCCESS,
  GET_VOUCHER_BY_CODE,
  GET_VOUCHER_BY_CODE_FAILURE,
  GET_VOUCHER_BY_CODE_SUCCESS,
  INVITE_TEAMMEMBER,
  INVITE_TEAMMEMBER_FAILURE,
  INVITE_TEAMMEMBER_SUCCESS,
  PURCHASE_CREDITS,
  PURCHASE_CREDITS_FAILURE,
  PURCHASE_CREDITS_SUCCESS,
  REDEEM_VOUCHER,
  REDEEM_VOUCHER_FAILURE,
  REDEEM_VOUCHER_SUCCESS,
  REGISTER_NEW_DOMAINS,
  REGISTER_NEW_DOMAINS_FAILURE,
  REGISTER_NEW_DOMAINS_SUCCESS,
  SAVE_CUSTOMER_BASIC_INFO,
  SAVE_CUSTOMER_BASIC_INFO_FAILURE,
  SAVE_CUSTOMER_BASIC_INFO_SUCCESS,
  UPDATE_DOMAIN_LICENSE,
  UPDATE_DOMAIN_LICENSE_FAILURE,
  UPDATE_DOMAIN_LICENSE_SUCCESS,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_FAILURE,
  UPDATE_USER_PROFILE_SUCCESS,
} from "./types";

/**
 * Redux action to get current customer
 */
export const getCurrentCustomer = () => ({
  type: GET_CURRENT_CUSTOMER,
});

/**
 * Redux action for successful fetching of current customer
 */

export const getCurrentCustomerSuccess = (response) => ({
  type: GET_CURRENT_CUSTOMER_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching current customer
 */
export const getCurrentCustomerFailure = (error) => ({
  type: GET_CURRENT_CUSTOMER_FAILURE,
  payload: error,
});

/**
 * Redux action for updating customer basic info
 */
export const saveCustomerBasicInfo = (model) => ({
  type: SAVE_CUSTOMER_BASIC_INFO,
  payload: model,
});
/**
 * Redux action for successful updating of customer
 */
export const saveCustomerBasicInfoSuccess = (response) => ({
  type: SAVE_CUSTOMER_BASIC_INFO_SUCCESS,
  payload: response,
});

/**
 * Redux action for failing to update customer
 */
export const saveCustomerBasicInfoFailure = (error) => ({
  type: SAVE_CUSTOMER_BASIC_INFO_FAILURE,
  payload: error,
});

/**
 * Redux action for registering new domains for the customer
 */
export const registerNewDomains = (model) => ({
  type: REGISTER_NEW_DOMAINS,
  payload: model,
});
/**
 * Redux action for successful registration of new domains
 */
export const registerNewDomainsSuccess = (response) => ({
  type: REGISTER_NEW_DOMAINS_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure to register new domains
 */
export const registerNewDomainsFailure = (error) => ({
  type: REGISTER_NEW_DOMAINS_FAILURE,
  payload: error,
});

/**
 * Redux action to get current customer vouchers
 */
export const getCurrentCustomerVouchers = () => ({
  type: GET_CURRENT_CUSTOMER_VOUCHERS,
});

/**
 * Redux action for successful fetching of current customer vouchers
 */

export const getCurrentCustomerVouchersSuccess = (response) => ({
  type: GET_CURRENT_CUSTOMER_VOUCHERS_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching current customer vouchers
 */
export const getCurrentCustomerVouchersFailure = (error) => ({
  type: GET_CURRENT_CUSTOMER_VOUCHERS_FAILURE,
  payload: error,
});

/**
 * Redux action for creating new voucher manually
 */
export const createManualVouchers = (model) => ({
  type: CREATE_MANUAL_VOUCHER,
  payload: model,
});
/**
 * Redux action for successful creating new voucher
 */
export const createManualVouchersSuccess = (response) => ({
  type: CREATE_MANUAL_VOUCHER_SUCCESS,
  payload: response,
});

/**
 * Redux action for failing to create new voucher
 */
export const createManualVouchersFailure = (error) => ({
  type: CREATE_MANUAL_VOUCHER_FAILURE,
  payload: error,
});

/**
 * Redux action for redeeming voucher
 */
export const redeemVoucher = (model) => ({
  type: REDEEM_VOUCHER,
  payload: model,
});
/**
 * Redux action for successful redemption of voucher
 */
export const redeemVoucherSuccess = (response) => ({
  type: REDEEM_VOUCHER_SUCCESS,
  payload: response,
});

/**
 * Redux action for failing to redeem a voucher
 */
export const redeemVoucherFailure = (error) => ({
  type: REDEEM_VOUCHER_FAILURE,
  payload: error,
});

/**
 * Redux action to get vouchers
 */
export const getVouchers = () => ({
  type: GET_VOUCHERS,
});

/**
 * Redux action for successful fetching of current customer vouchers
 */

export const getVouchersSuccess = (response) => ({
  type: GET_VOUCHERS_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching current customer vouchers
 */
export const getVouchersFailure = (error) => ({
  type: GET_VOUCHERS_FAILURE,
  payload: error,
});

/**
 * Redux action to get voucher by code
 */
export const getVoucherByCode = (code) => ({
  type: GET_VOUCHER_BY_CODE,
  payload: code,
});

/**
 * Redux action for successful fetching of voucher
 */

export const getVoucherByCodeSuccess = (response) => ({
  type: GET_VOUCHER_BY_CODE_SUCCESS,
  payload: response,
});
/**
 * Redux action for failure in fetching voucher
 */
export const getVoucherByCodeFailure = (error) => ({
  type: GET_VOUCHER_BY_CODE_FAILURE,
  payload: error,
});

/**
 * Redux action to filter vouchers by status
 */
export const filterVouchersByStatus = (status) => ({
  type: FILTER_VOUCHERS_BY_STATUS,
  payload: status,
});

/**
 * Redux action for inviting team member
 */
export const inviteTeamMember = (model) => ({
  type: INVITE_TEAMMEMBER,
  payload: model,
});
/**
 * Redux action for successful invitation
 */
export const inviteTeamMemberSuccess = (response) => ({
  type: INVITE_TEAMMEMBER_SUCCESS,
  payload: response,
});

/**
 * Redux action for failing to invite a member
 */
export const inviteTeamMemberFailure = (error) => ({
  type: INVITE_TEAMMEMBER_FAILURE,
  payload: error,
});

// get current user email
export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
});

// Successful log off by user on server
export const getUserProfileSuccess = (response) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: response,
});

// update current user
export const updateUserProfile = (user) => ({
  type: UPDATE_USER_PROFILE,
  payload: user,
});

// Successful update to current user
export const updateUserProfileSuccess = (response) => ({
  type: UPDATE_USER_PROFILE_SUCCESS,
  payload: response,
});

// failure to update to current user
export const updateUserProfileFailure = (response) => ({
  type: UPDATE_USER_PROFILE_FAILURE,
  payload: response,
});

export const getLedgerBalance = () => {
  return {
    type: GET_LEDGER_BALANCE,
  };
};
export const getLedgerBalanceSuccess = (data) => {
  return {
    type: GET_LEDGER_BALANCE_SUCCESS,
    payload: data,
  };
};
export const getLedgerBalanceFailure = (error) => {
  return {
    type: GET_LEDGER_BALANCE_FAILURE,
    payload: error,
  };
};

/**
 * Redux action for deleting team member
 */
export const deleteTeamMember = (model) => ({
  type: DELETE_TEAMMEMBER,
  payload: model,
});
/**
 * Redux action for successful deletion of team member
 */
export const deleteTeamMemberSuccess = (response) => ({
  type: DELETE_TEAMMEMBER_SUCCESS,
  payload: response,
});

/**
 * Redux action for failing to delete a member
 */
export const deleteTeamMemberFailure = (error) => ({
  type: DELETE_TEAMMEMBER_FAILURE,
  payload: error,
});

/**
 * Redux action for adding domain license for a domain
 */
export const addDomainLicense = (model) => ({
  type: ADD_DOMAIN_LICENSE,
  payload: model,
});

/**
 * Redux action for successful addition
 */
export const addDomainLicenseSuccess = (response) => ({
  type: ADD_DOMAIN_LICENSE_SUCCESS,
  payload: response,
});

/**
 * Redux action for failing to add a domain license
 */
export const addDomainLicenseFailure = (error) => ({
  type: ADD_DOMAIN_LICENSE_FAILURE,
  payload: error,
});

/**
 * Redux action for updating domain license for a domain
 */
export const updateDomainLicense = (model) => ({
  type: UPDATE_DOMAIN_LICENSE,
  payload: model,
});
/**
 * Redux action for successful updation
 */
export const updateDomainLicenseSuccess = (response) => ({
  type: UPDATE_DOMAIN_LICENSE_SUCCESS,
  payload: response,
});

/**
 * Redux action for failing to update a domain license
 */
export const updateDomainLicenseFailure = (error) => ({
  type: UPDATE_DOMAIN_LICENSE_FAILURE,
  payload: error,
});

export const getDomainLicenses = (response) => ({
  type: GET_DOMAIN_LICENSES,
  payload: response,
});

export const changeCostCenterMonth = (month) => ({
  type: CHANGE_COST_CENTER_MONTH,
  payload: month,
});

export const purchaseCredits = (tokenModel) => ({
  type: PURCHASE_CREDITS,
  payload: tokenModel,
});

export const purchaseCreditsSuccess = (data) => ({
  type: PURCHASE_CREDITS_SUCCESS,
  payload: data,
});

export const purchaseCreditsFailure = (error) => ({
  type: PURCHASE_CREDITS_FAILURE,
  payload: error,
});

export const getCreditsList = () => ({
  type: GET_CREDITS_LIST,
});

export const getCreditsListSuccess = (credits) => ({
  type: GET_CREDITS_LIST_SUCCESS,
  payload: credits,
});

export const getCreditsListFailure = (error) => ({
  type: GET_CREDITS_LIST_FAILURE,
  payload: error,
});
