/**
 * Domain Segment Actions
 */
import {
    GET_DOMAIN_SEGMENTS,
    GET_DOMAIN_SEGMENTS_SUCCESS,
    GET_DOMAIN_SEGMENTS_FAILURE,
    SEARCH_DOMAIN_SEGMENTS,
    SORT_DOMAIN_SEGMENTS
} from '../actions/types';

/**
 * Redux Action To get Segments for the domains which the current user is domain admin
 */
export const getDomainSegments = () => ({
    type: GET_DOMAIN_SEGMENTS,
});

/**
 * Redux Action Domain Segments success
 */
export const getDomainSegmentsSuccess = (segments) => ({
    type: GET_DOMAIN_SEGMENTS_SUCCESS,
    payload: segments
});


/**
 * Redux Action Domain Segments Failure
 */
export const getDomainSegmentsFailure = (error) => ({
    type: GET_DOMAIN_SEGMENTS_FAILURE,
    payload: error
});

/**
 * Redux Action On Search domain segments
 */
export const searchDomainSegments = (searchText) => ({
    type: SEARCH_DOMAIN_SEGMENTS,
    payload: searchText
});

/**
 * Redux Action On Sort domain segments
 */
export const sortDomainSegments = () => ({
    type: SORT_DOMAIN_SEGMENTS,
});