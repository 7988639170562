/**
 * Redux Actions for managing crud operations on constellations.
 */

import {
    GET_CONSTELLATION,
    GET_CONSTELLATION_SUCCESS,
    GET_CONSTELLATION_FAILURE,
    ADD_CONSTELLATION,
    ADD_CONSTELLATION_SUCCESS ,
    ADD_CONSTELLATION_FAILURE ,
    UPDATE_CONSTELLATION,
    UPDATE_CONSTELLATION_SUCCESS ,
    UPDATE_CONSTELLATION_FAILURE ,
    DELETE_CONSTELLATION ,
    DELETE_CONSTELLATION_SUCCESS,
    DELETE_CONSTELLATION_FAILURE,
    FILLTER_CONSTELLATION,
    FILLTER_CONSTELLATION_BY_TYPE,
    REFRESH_CONSTELLATION, 
    GET_CHANNELS,
    GET_CHANNELS_SUCCESS,
    GET_CHANNELS_FAILURE,
    GET_CONSTELLATION_BY_ID,
    GET_CONSTELLATION_BY_ID_SUCCESS,
    GET_CONSTELLATION_BY_ID_FAILURE,
    SELECT_CONSTELLATION,
    CONSTELLATION_ERROR,
    SEND_UAT_EMAIL, 
    SEND_UAT_EMAIL_SUCCESS,
    SEND_UAT_EMAIL_FAILURE,
    PUBLISH_CONSTELLATION,
    PUBLISH_CONSTELLATION_SUCCESS,
    PUBLISH_CONSTELLATION_FAILURE,
    GET_CONSTELLATION_CONTENTS,
    GET_CONSTELLATION_CONTENTS_SUCCESS,
    GET_CONSTELLATION_CONTENTS_FAILURE,
    GET_CONSTELLATION_DOMAINS,
    GET_CONSTELLATION_DOMAINS_SUCCESS,
    GET_CONSTELLATION_DOMAINS_FAILURE
} from './types';

/**
 * Redux action to get contents on constellation screen.
 */
export const getConstellationContents =()=>({
    type: GET_CONSTELLATION_CONTENTS
})
/**
 * Redux action for successfully fetching contents on constellation screen.
 */
export const getConstellationContentsSuccess =(response)=>({
    type: GET_CONSTELLATION_CONTENTS_SUCCESS,
    payload: response
})

/**
 * Redux action for failure in fetching contents on constellation screen.
 */
export const getConstellationContentsFailure =(error)=>({
    type: GET_CONSTELLATION_CONTENTS_FAILURE,
    payload: error
})

/**
 * Redux action for fetching constellation channels 
 */
export const getChannels =()=>({
    type: GET_CHANNELS
})
/**
 * Redux action for successful fetching constellation channels 
 */
export const getChannelsSuccess =(response)=>({
    type: GET_CHANNELS_SUCCESS,
    payload:response
})
/**
 * Redux action for failure in fetching constellation channels 
 */
export const getChannelsFailure =(error)=>({
    type: GET_CHANNELS_FAILURE,
    payload:error
})
/**
 * Redux action for fetching constellations 
 */
export const getConstellation =()=>({
    type: GET_CONSTELLATION
})

/**
 * Redux action for successful fetching constellations
 */
export const getConstellationSuccess =(response)=>({
    type: GET_CONSTELLATION_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching constellations
 */
export const getConstellationFailure =(error)=>({
    type: GET_CONSTELLATION_FAILURE,
    payload: error
})


/**
 * Redux action for fetching constellations after any updates to constellations. 
 */
export const refreshConstellation = (response) => ({
    type: REFRESH_CONSTELLATION,
    payload: response
});

/**
 * Redux action for adding new constellation
 */
export const addConstellation = (model) => (
    {
    
    type: ADD_CONSTELLATION,
    payload:model
})

/**
 * Redux action for successful addition of constellation. 
 */
export const addConstellationSuccess =(response)=>({
    type: ADD_CONSTELLATION_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in adding constellation.
 */
export const addConstellationFailure =(error)=>({
    type: ADD_CONSTELLATION_FAILURE,
    payload: error
})

/**
 * Redux action for modifying constellation.
 */
export const updateConstellation =(model)=>({
    type: UPDATE_CONSTELLATION,
    payload:model
})

/**
 * Redux action for successful modification of constellation.
 */
export const updateConstellationSuccess =(response)=>({
    type: UPDATE_CONSTELLATION_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in modifying constellation.
 */
export const updateConstellationFailure =(error)=>({
    type: UPDATE_CONSTELLATION_FAILURE,
    payload: error
})

/**
 * Redux action for deleting constellation.
 */
export const deleteConstellation =(id)=>({
    type: DELETE_CONSTELLATION,
    payload:id
})
/**
 * Redux action for successful deletion of constellation
 */
export const deleteConstellationSuccess =(response)=>({
    type: DELETE_CONSTELLATION_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in deleting constellation.
 */
export const deleteConstellationFailure =(error)=>({
    type: DELETE_CONSTELLATION_FAILURE,
    payload: error
})

/**
 * Redux action for filtering list of constellations using search box
 */
export const fillterConstellation=(keyword)=>({
    type: FILLTER_CONSTELLATION,
    payload: keyword
})
/**
 * Redux action for filtering constellations by type.
 */
export const fillterConstellationByType=(keyword)=>({
    type: FILLTER_CONSTELLATION_BY_TYPE,
    payload: keyword
})

/**
 * Redux action for fetching constellation by id
 */
export const getConstellationById =()=>({
    type: GET_CONSTELLATION_BY_ID
})
/**
 * Redux action for successful fetching constellation by id
 */
export const getConstellationByIdSuccess =(response)=>({
    type: GET_CONSTELLATION_BY_ID_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching constellation by id
 */
export const getConstellationByIdFailure =(error)=>({
    type: GET_CONSTELLATION_BY_ID_FAILURE,
    payload: error
})

/**
 * Redux action for selecting constellation from list.
 */
export const selectConstellation=(id)=>({
    type: SELECT_CONSTELLATION,
    payload: id
})

/**
 * Redux action for handing any error on constelaltion screen. 
 */
export const handleConstellationError=()=>({
    type: CONSTELLATION_ERROR
})


/**
 * Redux action for send UAT email to current user
 */
export const sendUATEmail =()=>({
    type: SEND_UAT_EMAIL
})
/**
 * Redux action for UAT email sent successfully
 */
export const sendUATEmailSuccess =(response)=>({
    type: SEND_UAT_EMAIL_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in sending UAT email
 */
export const sendUATEmailFailure =(error)=>({
    type: SEND_UAT_EMAIL_FAILURE,
    payload: error
})


/**
 * Redux action for publishing the constellation
 */
export const publishConstellation =()=>({
    type: PUBLISH_CONSTELLATION,
})
/**
 * Redux action for successful constellation publish
 */
export const publishConstellationSuccess =(response)=>({
    type: PUBLISH_CONSTELLATION_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in publishing constellation
 */
export const publishConstellationFailure =(error)=>({
    type: PUBLISH_CONSTELLATION_FAILURE,
    payload: error
})

/**
 * Redux action for fetching constellations 
 */
export const getConstellationDomains =()=>({
    type: GET_CONSTELLATION_DOMAINS
})

/**
 * Redux action for successful fetching constellations
 */
export const getConstellationDomainsSuccess =(response)=>({
    type: GET_CONSTELLATION_DOMAINS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching constellations
 */
export const getConstellationDomainsFailure =(error)=>({
    type: GET_CONSTELLATION_DOMAINS_FAILURE,
    payload: error
})
