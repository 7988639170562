/**
 * App Language Provider
 * Add more locales here
 */

import * as _ from 'lodash';
import { addLocaleData } from 'react-intl';
import appLocaleDataen from 'react-intl/locale-data/en';
import data from './locales/en_US.json';

const AppLocal = {
    en: {
        messages: {
            ...data
        },
        locale: 'en',
        data: appLocaleDataen
    }
  
};

addLocaleData(AppLocal.en.data);

function createAppLocaleData(lang,language) {
    var newLang={ messages: {}, locale: lang }
    newLang.messages=require(`./locales/${language}`);
    addLocaleData(require(`react-intl/locale-data/${lang}`));
    AppLocal[lang] = newLang;
};

// export default AppLocale;
export const AppLocale=(language) =>  {
    var lang=language.split('_');
    if(_.has(AppLocal,lang[0])==false){
        createAppLocaleData(lang[0],language);
    }
    switch(lang[0]){
        case "en":
        return AppLocal.en
        default:
        return AppLocal[lang[0]]
    }
  };
  
