/**
 * Admin Profile Sagas
 */
 import { all, call, put, select, takeEvery } from "redux-saga/effects";
 import {
   getDomainRequestsSuccess,
   getDomainRequestsFailure,
   processDomainRequestSuccess,
   processDomainRequestFailure
 } from "../actions";
 
 import { getAxiosRequest } from "../helpers/helpers";
 
 export const managerequests = (state) => state.manageRequestsReducer;
 
 const getDomainRequestsFromServer = async () => {
   return await getAxiosRequest().get("/manage/domain/deletionrequest");
 };
 
 const processDomainDeletionRequest = async (domainId) => {
  
   return await getAxiosRequest().put(`/manage/domain/deletionrequest?id=${domainId}`);
 };
 
 /**
  * Get Domain Deletion requests
  */
 function* getDomainRequests() {
   try {
     // call api to get domain deletion requests.
     const response = yield call(getDomainRequestsFromServer);
     yield put(getDomainRequestsSuccess(response));
   } catch (error) {
     yield put(getDomainRequestsFailure(error.response.data.error));
   }
 }
 /**
  * Process domain deletion request
  */
 function* processDomainRequest() {
   try {
     // get admin profile
     let model = yield select(managerequests); // <-- get the model
 
     const response = yield call(
       processDomainDeletionRequest,
       model.selectedDomainRequest
     );
     yield put(processDomainRequestSuccess(response));
     // refresh the domain requests
     try {
       // call api to generate magic link
       const refreshResponse = yield call(getDomainRequestsFromServer);
       yield put(getDomainRequestsSuccess(refreshResponse));
     } catch (error) {
       yield put(getDomainRequestsFailure(error.response.data.error));
     }
   } catch (error) {
     yield put(processDomainRequestFailure(error.response.data.error));
   }
 }
 
 
 export const manageRequestsSagas = [
   takeEvery("GET_DOMAIN_REQUESTS", getDomainRequests),
   takeEvery("PROCESS_DOMAIN_REQUESTS", processDomainRequest),
 
 ];
 
 /**
  * Audiences Root Saga
  */
 export default function* rootSaga() {
   yield all([...manageRequestsSagas]);
 }
 