import {
    SAVE_SEGMENT,
    SAVE_SEGMENT_FAILURE,
    SAVE_SEGMENT_SUCCESS,
    GET_SEGMENTS,
    GET_SEGMENTS_FAILURE,
    GET_SEGMENTS_SUCCESS,
    SELECT_SEGMENT,
    UPDATE_SEGMENT,
    UPDATE_SEGMENT_FAILURE,
    UPDATE_SEGMENT_SUCCESS,
    DELETE_SEGMENT,
    DELETE_SEGMENT_FAILURE,
    DELETE_SEGMENT_SUCCESS,
    FILTER_SEGMENT,
    SORT_SEGMENT
} from "../actions/types"
import { NotificationManager } from 'react-notifications';
import appConfig from "../constants/AppConfig";
import * as _ from "lodash";
const INIT_STATE = {
    selectedSegment: null,
    loading: false,
    title: null,
    isDomainSpecific: false,
    isCustomerSpecific: false,
    isAdminOnly: false,
    segments: [],
    segmentToDelete:null,
    filterSegment:[]

};

const SegmentsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SAVE_SEGMENT:

            return ({
                ...state, loading: true,
                title: action.payload.name,
                isDomainSpecific: action.payload.isDomainSpecific,
                isCustomerSpecific: action.payload.isCustomerSpecific,
                isAdminOnly: action.payload.isAdminOnly
            })

        case SAVE_SEGMENT_SUCCESS:
            NotificationManager.success('segment created successfully')
            setTimeout(() => {
                window.location = `${appConfig.appUrl}/#/segments/list`
            }, 1000)
            return ({ ...state, loading: false, })
        case SAVE_SEGMENT_FAILURE:
            // NotificationManager.error(action.payload);
            return ({ ...state, loading: false })
        case GET_SEGMENTS:
            return { ...state, loading: true }
        case GET_SEGMENTS_SUCCESS:
            let currentSegments = []
            if(action.payload.counts){
                
                action.payload.segments.forEach(segment => {
                    //see if the segment exists in counts array
                    var countSegment = _.filter(action.payload.counts, {id: segment.id});
                    if(countSegment.length > 0){
                        segment["count"] = countSegment[0].count
                    }else{
                        segment["count"] = 0
                    } 
                    currentSegments.push(segment);
                });
            }else{
                currentSegments =  action.payload.segments
            }
            return ({ ...state, loading: false, segments: currentSegments,filterSegment:currentSegments })
        case GET_SEGMENTS_FAILURE:
            NotificationManager.error(action.payload);
            return ({ ...state, loading: false, })
        case SELECT_SEGMENT:
            return ({ ...state, selectedSegment: action.payload })
        case UPDATE_SEGMENT:
            
            return ({ ...state, selectedSegment: action.payload, loading: true })
        case UPDATE_SEGMENT_SUCCESS:
                
            NotificationManager.success('segment updated successfully');
            setTimeout(() => {
                window.location = `${appConfig.appUrl}/#/segments/list`
            }, 1000)
            return ({ ...state, loading: false })
        case UPDATE_SEGMENT_FAILURE:
            NotificationManager.error(action.payload);
            return ({ ...state, loading: false })
        case DELETE_SEGMENT:
           
            return({...state,loading:true,segmentToDelete:action.payload});
        case DELETE_SEGMENT_SUCCESS:
            NotificationManager.success('segment deleted successfully')
            return({...state,});
        case DELETE_SEGMENT_FAILURE:
            NotificationManager.error(action.payload);
            return({...state,loading:false})
        case FILTER_SEGMENT:
            if(action.payload!=null&&action.payload!==""){
                const filterSegments = state.segments.filter((item) =>
                item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
              
                return({...state,filterSegment:filterSegments})
            }else{
                return({...state,filterSegment:state.segments})
            }
        case SORT_SEGMENT:
                if(action.payload!=null&&action.payload!==""){
                    var sortedSegments = [];
                    if(action.payload === 'count'){
                        sortedSegments = _.orderBy(state.segments, ["count"], ["desc"]);
                    }else{
                        sortedSegments = _.orderBy(state.segments, [action.payload], 
                            ['asc']);
                    }
                   
                    return({...state,filterSegment:sortedSegments})
                }else{
                    return({...state,filterSegment:state.segments})
                }
    
           
           
        default:
            return ({ ...state })
    }

}
export default SegmentsReducer;