/**
 * Content Sagas
 */
import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";
import {
  addContentFailure,
  addContentSuccess,
  deleteContentFailure,
  deleteContentSuccess,
  getContentsFailure,
  getContentsSuccess,
  refreshContents,
  updateContentFailure,
  updateContentSuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const contents = (state) => state.contentsReducer;
export const authUser = (state) => state.authUser;

const getContentsFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };
  return await getAxiosRequest().get("/manage/content", config);
};

// Get consents from Server and then filter domains from consentdataset

const addContentToServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().post("/manage/content", model.content, config);
};

const updateContent = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().put("/manage/content", model.content, config);
};

const removeContent = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().delete(
    "/manage/content?id=" + model.id,
    config
  );
};

/**
 * Get Contents
 */
function* getContents() {
  try {
    // get current domain from authUser
    let model = yield select(authUser); // <-- get the model

    const response = yield call(getContentsFromServer, model.currentDomain.id);
    yield put(getContentsSuccess(response));
  } catch (error) {
    if (error.response.status === 403) {
      yield delay(2000);
      try {
        let model = yield select(authUser); // <-- get the model

        const response = yield call(
          getContentsFromServer,
          model.currentDomain.id
        );
        yield put(getContentsSuccess(response));
      } catch (error) {
        yield put(getContentsFailure(error.response.data.error));
      }
    }
    yield put(getContentsFailure(error.response.data.error));
  }
}
/**
 * Add Content
 */
function* addContent() {
  try {
    // get content from the reducer model
    let model = yield select(contents); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model
    model.content.domain = user.currentDomain.domain;
    var contentModel = {
      content: model.content,
      domain: user.currentDomain.id,
    };
    const response = yield call(addContentToServer, contentModel);
    yield put(addContentSuccess(response));
  } catch (error) {
    yield put(addContentFailure(error.response.data.error));
  }
}

/**
 * Edit Content
 */
function* editContent() {
  try {
    // get content to be updated from content Reducer model
    let model = yield select(contents); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    var contentModel = {
      content: model.content,
      domain: user.currentDomain.id,
    };
    const response = yield call(updateContent, contentModel);
    yield put(updateContentSuccess(response));
  } catch (error) {
    yield put(updateContentFailure(error.response.data.error));
  }
}

/**
 * Edit User
 */
function* deleteContent() {
  try {
    // get content to be removed from reducer model
    let model = yield select(contents); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    var contentModel = {
      id: model.content.id,
      domain: user.currentDomain.id,
    };
    const response = yield call(removeContent, contentModel);
    yield put(deleteContentSuccess(response));
    const refreshResponse = yield call(
      getContentsFromServer,
      user.currentDomain.id
    );
    yield put(refreshContents(refreshResponse));
  } catch (error) {
    yield put(deleteContentFailure(error.response.data.error));
  }
}

export const contentSagas = [
  takeEvery("GET_CONTENTS", getContents),
  takeEvery("ADD_CONTENT", addContent),
  takeEvery("UPDATE_CONTENT", editContent),
  takeEvery("DELETE_CONTENT", deleteContent),
  //takeEvery('GET_CONTENT_DOMAINS', getContentDomains),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...contentSagas]);
}
