/**
 * App Routes
 */
import React from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
// async component
import {
  AsyncRegistrationComponent,
  AsyncWelcomeComponent,
} from "../components/AsyncComponent/AsyncComponent";
// app default layout
import RegistrationLayout from "../components/RegistrationLayout";

const RegRoutes = (props) => {
  return (
    <RegistrationLayout>
      <Route path='/welcome' component={AsyncWelcomeComponent} />
      <Route path='/register' component={AsyncRegistrationComponent} />
    </RegistrationLayout>
  );
};

export default withRouter(connect(null)(RegRoutes));
