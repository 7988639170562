import {
  GET_ALL_FP_APPS,
  GET_ALL_FP_APPS_FAILURE,
  GET_ALL_FP_APPS_SUCCESS,
  SEARCH_FP_APPS,
  ADD_FP_APP,
  ADD_FP_APP_FAILURE,
  ADD_FP_APP_SUCCESS,
  UPDATE_FP_APP,
  UPDATE_FP_APP_FAILURE,
  UPDATE_FP_APP_SUCCESS,
} from "./types";

export const getALLFPApps = () => {
  return {
    type: GET_ALL_FP_APPS,
  };
};
export const getALLFPAppsSuccess = (data) => {
  return {
    type: GET_ALL_FP_APPS_SUCCESS,
    payload: data,
  };
};

export const getALLFPAppsFailure = (error) => {
  return {
    type: GET_ALL_FP_APPS_FAILURE,
    payload: error,
  };
};

export const searchFPApps = (name) => {
  return {
    type: SEARCH_FP_APPS,
    payload: name,
  };
};

export const addFPApps = (model) => ({ type: ADD_FP_APP, payload: model });
export const addFPAppsSuccess = (data) => ({
  type: ADD_FP_APP_SUCCESS,
  payload: data,
});
export const addFPAppsFailure = (error) => ({
  type: ADD_FP_APP_FAILURE,
  payload: error,
});

export const updateFPApps = (model) => ({
  type: UPDATE_FP_APP,
  payload: model,
});
export const updateFPAppsSuccess = (data) => ({
  type: UPDATE_FP_APP_SUCCESS,
  payload: data,
});
export const updateFPAppsFailure = (error) => ({
  type: UPDATE_FP_APP_FAILURE,
  payload: error,
});
