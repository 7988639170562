/**
 * constellation Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addConstellationFailure,
  addConstellationSuccess,
  deleteConstellationFailure,
  deleteConstellationSuccess,
  getChannelsFailure,
  getChannelsSuccess,
  getConstellationByIdFailure,
  getConstellationByIdSuccess,
  getConstellationContentsFailure,
  getConstellationContentsSuccess,
  getConstellationDomainsFailure,
  getConstellationDomainsSuccess,
  getConstellationFailure,
  getConstellationSuccess,
  publishConstellationFailure,
  publishConstellationSuccess,
  refreshConstellation,
  sendUATEmailFailure,
  sendUATEmailSuccess,
  updateConstellationFailure,
  updateConstellationSuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";
import channel from "../routes/pages/constellation/channel.json";

export const Constellation = (state) => state.constellationReducer;
export const authUser = (state) => state.authUser;

const getContentsFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };
  return await getAxiosRequest().get("/manage/content", config);
};

const getConstellationFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };
  return await getAxiosRequest().get("/manage/constellation", config);
};

// Get consents from Server and then filter domains from consentdataset
const UserConsents = async (model) => {
  return await getAxiosRequest().get("/consent");
};

const sendUATEmailFromServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().get(
    "/manage/constellation/" + model.constellation.id + "/uat",
    config
  );
};

const getConstellationByIdFromServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().get(
    "/manage/constellation/" + model.id + "/contents",
    config
  );
};

const addConstellationToServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().post(
    "/manage/constellation/",
    model.constellation,
    config
  );
};

const updateConstellation = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().put(
    "/manage/constellation/",
    model.constellation,
    config
  );
};

const publishConstellation = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().put(
    "/manage/constellation/" + model.constellation.id + "/publish",
    model,
    config
  );
};

const removeConstellation = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().delete(
    "/manage/constellation?id=" + model.id,
    config
  );
};
const getChannelsFromServer = async () => {
  const request = channel;
  return await request;
};

/**
 * Get Constellations
 */
function* getConstellation() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model
    // call api to generate magic link
    const response = yield call(
      getConstellationFromServer,
      user.currentDomain.id
    );
    yield put(getConstellationSuccess(response));
  } catch (error) {
    yield put(getConstellationFailure(error.response.data.error));
  }
}

function* getConstellationDomains() {
  try {
    // call api to get the domains for which the current user is admin.
    const response = yield call(UserConsents);
    yield put(getConstellationDomainsSuccess(response));
  } catch (error) {
    yield put(getConstellationDomainsFailure(error.response.data.error));
  }
}

/**
 * Get Constellation By Id
 */
function* getConstellationById() {
  try {
    // call api to delete constellation
    let model = yield select(Constellation); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    if (model.selectedConstellation === null) {
      yield put(getConstellationByIdFailure(""));
    }
    var constellationModel = {
      id: model.selectedConstellation.id,
      domain: user.currentDomain.id,
    };

    const [constellationContents, contents] = yield all([
      call(getConstellationByIdFromServer, constellationModel),
      call(getContentsFromServer, user.currentDomain.id),
    ]);
    var response = {
      constellation: constellationContents.data,
      contents: contents.data,
    };
    yield put(getConstellationByIdSuccess(response));
    //yield put(getConstellationContentsSuccess(contents))
  } catch (error) {
    if (error.response) {
      yield put(getConstellationByIdFailure(error.response.data.error));
    }
  }
}

/**
 * Add Content
 */
function* addConstellation() {
  try {
    // call api to generate magic link
    let model = yield select(Constellation); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model
    model.constellation.domain = user.currentDomain.domain;

    var constellationModel = {
      constellation: model.constellation,
      domain: user.currentDomain.id,
    };
    const response = yield call(addConstellationToServer, constellationModel);
    yield put(addConstellationSuccess(response));
  } catch (error) {
    yield put(addConstellationFailure(error.response.data.error));
  }
}
/**
 * Edit Constellation
 */
function* editConstellation() {
  try {
    // call api to generate magic link
    let model = yield select(Constellation); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    var constellationModel = {
      constellation: model.constellation,
      domain: user.currentDomain.id,
    };
    const response = yield call(updateConstellation, constellationModel);
    yield put(updateConstellationSuccess(response));
  } catch (error) {
    yield put(updateConstellationFailure(error.response.data.error));
  }
}
/**
 * Delete Constellation
 */
function* deleteConstellation() {
  try {
    // call api to delete constellation
    let model = yield select(Constellation); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    var constellationModel = {
      id: model.constellation.id,
      domain: user.currentDomain.id,
    };
    const response = yield call(removeConstellation, constellationModel);
    yield put(deleteConstellationSuccess(response));
    const refreshResponse = yield call(
      getConstellationFromServer,
      user.currentDomain.id
    );
    yield put(refreshConstellation(refreshResponse));
  } catch (error) {
    yield put(deleteConstellationFailure(error.response.data.error));
  }
}
function* getChannels() {
  try {
    const response = yield call(getChannelsFromServer);
    yield put(getChannelsSuccess(response));
  } catch (error) {
    yield put(getChannelsFailure(""));
  }
}

/**
 * Get Contents
 */
function* getContents() {
  try {
    // call api to generate magic link
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    const response = yield call(getContentsFromServer, user.currentDomain.id);
    yield put(getConstellationContentsSuccess(response));
  } catch (error) {
    yield put(getConstellationContentsFailure(error.response.data.error));
  }
}

/**
 * Add Content
 */
function* sendUAT() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(Constellation); // <-- get the model
    var constellationModel = {
      constellation: model.selectedConstellation,
      domain: user.currentDomain.id,
    };
    const response = yield call(sendUATEmailFromServer, constellationModel);
    yield put(sendUATEmailSuccess(response));
  } catch (error) {
    yield put(sendUATEmailFailure(error.response.data.error));
  }
}

/**
 * Publish Constellation
 */
function* publish() {
  try {
    // call api to generate magic link
    let model = yield select(Constellation); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    var constellationModel = {
      constellation: model.selectedConstellation,
      domain: user.currentDomain.id,
    };
    const response = yield call(publishConstellation, constellationModel);
    yield put(publishConstellationSuccess(response));
  } catch (error) {
    yield put(publishConstellationFailure(error.response.data.error));
  }
}

export const Constellationagas = [
  takeEvery("GET_CONSTELLATION_CONTENTS", getContents),
  takeEvery("GET_CHANNELS", getChannels),
  takeEvery("GET_CONSTELLATION", getConstellation),
  takeEvery("GET_CONSTELLATION_BY_ID", getConstellationById),
  takeEvery("ADD_CONSTELLATION", addConstellation),
  takeEvery("UPDATE_CONSTELLATION", editConstellation),
  takeEvery("DELETE_CONSTELLATION", deleteConstellation),
  takeEvery("SEND_UAT_EMAIL", sendUAT),
  takeEvery("PUBLISH_CONSTELLATION", publish),
  takeEvery("GET_CONSTELLATION_DOMAINS", getConstellationDomains),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...Constellationagas]);
}
