/**
 * Redux Actions for managing crud operations on Campaigns.
 */

import {
    GET_DASHBOARD_CAMPAIGNS,
    GET_DASHBOARD_CAMPAIGNS_SUCCESS,
    GET_DASHBOARD_CAMPAIGNS_FAILURE,
    GET_CAMPAIGNS_STATS,
    GET_CAMPAIGNS_STATS_SUCCESS,
    GET_CAMPAIGNS_STATS_FAILURE,
    EXECUTE_CAMPAIGN,
    EXECUTE_CAMPAIGN_SUCCESS,
    EXECUTE_CAMPAIGN_FAILURE,
    STOP_CAMPAIGN,
    STOP_CAMPAIGN_SUCCESS,
    STOP_CAMPAIGN_FAILURE,
    SEARCH_DASHBOARD_CAMPAIGN,
    SORT_DASHBOARD
} from './types';

/**
 * Redux action to get Campaigns which are non draft status
 */
export const getDashboardCampaigns =()=>({
    type: GET_DASHBOARD_CAMPAIGNS
})

/**
 * Redux action for successful fetching of Campaigns
 */

export const getDashboardCampaignsSuccess =(response)=>({
    type: GET_DASHBOARD_CAMPAIGNS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaigns
 */
export const getDashboardCampaignsFailure =(error)=>({
    type: GET_DASHBOARD_CAMPAIGNS_FAILURE,
    payload: error
})

/**
 * Redux action to get Campaigns stats
 */
export const getCampaignStats =(id)=>({
    type: GET_CAMPAIGNS_STATS,
    payload:id
})

/**
 * Redux action for successful fetching of Campaign Stats
 */

export const getCampaignStatsSuccess =(response)=>({
    type: GET_CAMPAIGNS_STATS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaign Stats
 */
export const getCampaignStatsFailure =(error)=>({
    type: GET_CAMPAIGNS_STATS_FAILURE,
    payload: error
})

/**
 * Redux action to execute campaign
 */
export const executeCampaign=(id)=>({
    type: EXECUTE_CAMPAIGN,
    payload: id
})


/**
 * Redux action for successful fetching of Campaign Stats
 */

export const executeCampaignSuccess =(response)=>({
    type: EXECUTE_CAMPAIGN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Campaign Stats
 */
export const executeCampaignFailure =(error)=>({
    type: EXECUTE_CAMPAIGN_FAILURE,
    payload: error
})

/**
 * Redux action to stop campaign
 */
export const stopCampaign=(id)=>({
    type: STOP_CAMPAIGN,
    payload: id
})


/**
 * Redux action for successful stopping campaign
 */

export const stopCampaignSuccess =(response)=>({
    type: STOP_CAMPAIGN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in stopping campaign
 */
export const stopCampaignFailure =(error)=>({
    type: STOP_CAMPAIGN_FAILURE,
    payload: error
})

/**
 * Redux Action On Search Customers
 */
export const searchDashboardCampaigns = (searchText) => ({
    type: SEARCH_DASHBOARD_CAMPAIGN,
    payload: searchText
});

/**
 * Redux Action On Sort Campaign
 */
export const sortDashboardCampaigns = () => ({
    type: SORT_DASHBOARD,
});










