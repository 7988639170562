import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  GET_GRAVITO_FP_DOMAIN_COUNT,
  GET_GRAVITO_DOMAIN_COUNT,
  GET_GRAVITO_FP_DOMAINS,
  CERTIFICATE_CHECK,
} from "../actions/types";
import {
  getGravitoDomainCountSucess,
  getGravitoDomainCountFailure,
  getGravitoFPDomainCountSucess,
  getGravitoFPDomainCountFailure,
  getGravitoFPDomainsSuccess,
  getGravitoFPDomainsFailure,
  certificateCheckSuccess,
  certificateCheckFailure,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const adminRoute = (state) => state.AdminRouteReducer;

const getGravitoDomainCall = async () => {
  return await getAxiosRequest().get(`/admin/gravitodomains`);
};
const getGravitoFPDomainCall = async () => {
  return await getAxiosRequest().get(`/admin/gravitofpdomains`);
};

const getGravitoValidatedDomains = async () => {
  return await getAxiosRequest().get(`/admin/validateddomains`);
};

const getGravitoRenewedDomains = async () => {
  return await getAxiosRequest().get(`/admin/reneweddomains`);
};

const checkCertificates = async (customerId) => {
  return await getAxiosRequest().get(
    `/admin/checkcertificates?customerId=${customerId}`
  );
};

function* getGravitoDomain() {
  try {
    let response = yield call(getGravitoDomainCall);
    if (response) {
      yield put(getGravitoDomainCountSucess(JSON.parse(response.data)));
    }
  } catch (error) {
    console.log("error", error);
    yield put(getGravitoDomainCountFailure(error));
  }
}
function* getGravitoFPDomain() {
  try {
    let response = yield call(getGravitoFPDomainCall);
    if (response) {
      yield put(getGravitoFPDomainCountSucess(JSON.parse(response.data)));
    }
  } catch (error) {
    console.log("error", error);
    yield put(getGravitoFPDomainCountFailure(error));
  }
}

function* getGravitoFPDomains() {
  try {
    const [validated, renewed] = yield all([
      call(getGravitoValidatedDomains),
      call(getGravitoRenewedDomains),
    ]);

    var response = {
      validated: validated.data,
      renewed: renewed.data,
    };

    yield put(getGravitoFPDomainsSuccess(response));
  } catch (error) {
    console.log("error", error);
    yield put(getGravitoFPDomainsFailure(error));
  }
}

function* certificateCheckForCustomer() {
  try {
    let model = yield select(adminRoute); // <-- get the current user
    // call api to get the segments of the domains for which the current user is admin.
    const response = yield call(checkCertificates, model.customerId);

    yield put(certificateCheckSuccess(response.data));
  } catch (error) {
    console.log("error", error);
    yield put(certificateCheckFailure(error));
  }
}
export const domainCountSagas = [
  takeEvery(GET_GRAVITO_DOMAIN_COUNT, getGravitoDomain),
  takeEvery(GET_GRAVITO_FP_DOMAIN_COUNT, getGravitoFPDomain),
  takeEvery(GET_GRAVITO_FP_DOMAINS, getGravitoFPDomains),
  takeEvery(CERTIFICATE_CHECK, certificateCheckForCustomer),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...domainCountSagas]);
}
