/**
 * Light configuration Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addLightConfigurationFailure,
  addLightConfigurationSuccess,
  deleteLightConfigurationFailure,
  deleteLightConfigurationSuccess,
  fetchLightCMPConfigByIdFailure,
  fetchLightCMPConfigByIdSuccess,
  fetchLightCMPtemplateFaliure,
  fetchLightCMPtemplateSuccess,
  getLightConfigurationsFailure,
  getLightConfigurationsSuccess,
  getLightTemplatesFailure,
  getLightTemplatesSuccess,
  getMetadataLightFailure,
  getMetadataLightSuccess,
  refreshLightConfigurations,
} from "../actions";
import {
  FETCH_LIGHTCMP_CONIFG_BY_ID,
  FETCH_LIGHTCMP_TEMPLATE,
  GET_LIGHTTEMPLATES,
  GET_METADATA_LIGHT,
} from "../actions/types";
import { getAxiosRequest } from "../helpers/helpers";

export const configurations = (state) => state.lightConfigurationReducer;
export const authUser = (state) => state.authUser;

const getLightConfigurationsFromContainer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain.id },
  };
  return await getAxiosRequest().get(
    `/cmpconfigurator/lightconfig/${currentDomain.customerId}`,
    config
  );
};

const getLightTemplateFromContainer = async (currentDomain, path) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain.id },
  };
  return await getAxiosRequest().get(
    "/cmpconfigurator/templates/light/" + path,
    config
  );
};

const getLightConfigurationsTemplates = async () => {
  var config = {};

  return await getAxiosRequest().get(
    `/cmpconfigurator/templates/light`,
    config
  );
};
const getLightConfigurationsSdk = async () => {
  var config = {};

  return await getAxiosRequest().get(`/cmpconfigurator/sdk`, config);
};
const getLightConfigurationsbundles = async () => {
  var config = {};

  return await getAxiosRequest().get(`/cmpconfigurator/lightcmpbundle`, config);
};

const addLightConfigurationToContainer = async (model) => {
  var config = {};

  config = {
    headers: {
      DOMAIN_KEY: model.domain,
      "content-type": "application/json",
    },
  };

  return await getAxiosRequest().post(
    `/cmpconfigurator/lightconfig`,
    model,
    config
  );
};

const fetchLightConfigurationFromContainer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain, "content-type": "application/json" },
  };

  return await getAxiosRequest().get(`/cmpconfigurator/${model.id}`, config);
};

const removeLightConfigurationFromContainer = async (model) => {
  let path = model.path.replace("light/", "");

  return await getAxiosRequest().delete(`/cmpconfigurator/lightconfig/${path}`);
};

/**
 * Get LightConfigurations
 */
function* getLightConfigurations() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    const response = yield call(
      getLightConfigurationsFromContainer,
      user.currentDomain
    );
    yield put(getLightConfigurationsSuccess(response));
  } catch (error) {
    console.log(error);
    if (error.response) {
      yield put(getLightConfigurationsFailure(error.response.data.error));
    }
  }
}
/**
 * Add Light Configuration
 */
function* addLightConfiguration() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(configurations); // <-- get the model
    var postModel = {
      customerId: parseInt(user.currentDomain.customerId),
      domain: user.currentDomain.id,
      title: model.configTitle,
      type: 0,
      template: model.templatePath ? model.templatePath : "NA",
      sdkVersion: model.sdkVersion,
      bundleVersion: model.bundleVersion,
      config: JSON.stringify(model.configuration),
      mapper: JSON.stringify(model.mapper),
    };

    const response = yield call(addLightConfigurationToContainer, postModel);
    yield put(addLightConfigurationSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(addLightConfigurationFailure(error.response.data.error));
  }
}

/**
 * delete Light Configuration
 */
function* deleteLightConfiguration() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(configurations); // <-- get the model
    var modelToDelete = {
      ...model.configFolderNameToDelete,
    };
    const response = yield call(
      removeLightConfigurationFromContainer,
      modelToDelete
    );
    yield put(deleteLightConfigurationSuccess(response));
    const refreshResponse = yield call(
      getLightConfigurationsFromContainer,
      user.currentDomain
    );
    yield put(refreshLightConfigurations(refreshResponse));
  } catch (error) {
    yield put(deleteLightConfigurationFailure(error.response.data.error));
  }
}

function* getLightTemplates() {
  try {
    let response = yield call(getLightConfigurationsTemplates);
    if (response) {
      yield put(getLightTemplatesSuccess(response.data));
    }
  } catch (error) {
    console.log("error", error);
    yield put(getLightTemplatesFailure(error));
  }
}

function* fetchLightCMPTemplatesSaga() {
  try {
    let lightCMPReducer = yield select(configurations);
    let user = yield select(authUser); //
    let path = lightCMPReducer.templatePath;
    let response = yield call(
      getLightTemplateFromContainer,
      user.currentDomain,
      path
    );
    if (response) {
      yield put(fetchLightCMPtemplateSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchLightCMPtemplateFaliure(error));
  }
}

function* getMetadataLightCMP() {
  try {
    const [templates, sdk, bundles] = yield all([
      call(getLightConfigurationsTemplates),
      call(getLightConfigurationsSdk),
      call(getLightConfigurationsbundles),
    ]);
    let obj = {
      templates: templates.data,
      sdkFiles: sdk.data,
      bundles: bundles.data,
    };
    yield put(getMetadataLightSuccess(obj));
  } catch (error) {
    console.log(error);
    yield put(getMetadataLightFailure());
  }
}

function* getLightConfigurationByID() {
  try {
    let lightCMPReducer = yield select(configurations);
    let user = yield select(authUser);

    let model = {
      customerId: user.currentDomain.customerId,
      id: lightCMPReducer.referenceConfigFolderName,
    };
    let response = yield call(fetchLightConfigurationFromContainer, model);
    if (response) {
      yield put(fetchLightCMPConfigByIdSuccess(response.data));
    }
  } catch (error) {
    console.log(error);
    yield put(fetchLightCMPConfigByIdFailure(error));
  }
}

export const lightConfigurationSagas = [
  takeEvery("GET_LIGHTCONFIGURATIONS", getLightConfigurations),
  takeEvery("ADD_LIGHTCONFIGURATION", addLightConfiguration),
  takeEvery("DELETE_LIGHTCONFIGURATION", deleteLightConfiguration),
  takeEvery(GET_LIGHTTEMPLATES, getLightTemplates),
  takeEvery(FETCH_LIGHTCMP_TEMPLATE, fetchLightCMPTemplatesSaga),
  takeEvery(GET_METADATA_LIGHT, getMetadataLightCMP),
  takeEvery(FETCH_LIGHTCMP_CONIFG_BY_ID, getLightConfigurationByID),
];

/**
 * Light Configuration Root Saga
 */
export default function* rootSaga() {
  yield all([...lightConfigurationSagas]);
}
