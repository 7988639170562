/**
 * Redux Actions for managing crud operations on Audiences.
 */

import {
    GET_CUSTOMDOMAINS,
    GET_CUSTOMDOMAINS_SUCCESS,
    GET_CUSTOMDOMAINS_FAILURE,
    ADD_CUSTOMDOMAIN,
    ADD_CUSTOMDOMAIN_SUCCESS ,
    ADD_CUSTOMDOMAIN_FAILURE ,
    DELETE_CUSTOMDOMAIN ,
    DELETE_CUSTOMDOMAIN_SUCCESS,
    DELETE_CUSTOMDOMAIN_FAILURE,
    FILLTER_CUSTOMDOMAINS,
    REFRESH_CUSTOMDOMAINS,
    FILLTER_CUSTOMDOMAINS_BY_TYPE,
    SELECT_CUSTOMDOMAIN,
    VALIDATE_CUSTOMDOMAIN,
    VALIDATE_CUSTOMDOMAIN_SUCCESS,
    VALIDATE_CUSTOMDOMAIN_FAILURE,
    RENEW_CUSTOMDOMAIN,
    RENEW_CUSTOMDOMAIN_SUCCESS,
    RENEW_CUSTOMDOMAIN_FAILURE,
    VALIDATE_CUSTOMDOMAIN_DNS,
    VALIDATE_CUSTOMDOMAIN_DNS_SUCCESS,
    VALIDATE_CUSTOMDOMAIN_DNS_FAILURE,
} from './types';

/**
 * Redux action to get Custom Domain
 */
export const getCustomDomains =()=>({
    type: GET_CUSTOMDOMAINS
})

/**
 * Redux action for successful fetching of Custom Domains
 */

export const getCustomDomainsSuccess =(response)=>({
    type: GET_CUSTOMDOMAINS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Custom Domains
 */
export const getCustomDomainsFailure =(error)=>({
    type: GET_CUSTOMDOMAINS_FAILURE,
    payload: error
})


/**
 * Redux action for fetching Custom Domains after add/edit/delete of Custom Domain
 */
export const refreshCustomDomains = (response) => ({
    type: REFRESH_CUSTOMDOMAINS,
    payload: response
});

/**
 * Redux action for adding new Custom Domain
 */
export const addCustomDomain = (model) => ({
    type: ADD_CUSTOMDOMAIN,
    payload:model
})
/**
 * Redux action for successful addition of Custom Domain
 */
export const addCustomDomainSuccess =(response)=>({
    type: ADD_CUSTOMDOMAIN_SUCCESS,
    payload: response
})

/**
 * Redux action for failing to add new custom domain
 */
export const addCustomDomainFailure =(error)=>({
    type: ADD_CUSTOMDOMAIN_FAILURE,
    payload: error
})


/**
 * Redux action for deleting a Custom Domain
 */
export const deleteCustomDomain =(id)=>({
    type: DELETE_CUSTOMDOMAIN,
    payload:id
})
/**
 * Redux action for successful deletion of Custom Domain
 */
export const deleteCustomDomainSuccess =(response)=>({
    type: DELETE_CUSTOMDOMAIN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in deletion of Custom Domain
 */
export const deleteCustomDomainFailure =(error)=>({
    type: DELETE_CUSTOMDOMAIN_FAILURE,
    payload: error
})

/**
 * Redux action for filtering custom domains from search box
 */
export const fillterCustomDomains=(keyword)=>({
    type: FILLTER_CUSTOMDOMAINS,
    payload: keyword
})
/**
 * Redux action for filtering Custom Domains by status.
 */
export const fillterCustomDomainsByType=(type)=>({
    type: FILLTER_CUSTOMDOMAINS_BY_TYPE,
    payload: type
})
/**
 * Redux action for selecting a custom domain
 */
export const selectCustomDomain=(id)=>({
    type: SELECT_CUSTOMDOMAIN,
    payload: id
})

/**
 * Redux action for validate a custom domain
 */
export const validateCustomDomain =(model)=>({
    type: VALIDATE_CUSTOMDOMAIN,
    payload:model
})
/**
 * Redux action for successful validation of a custom domain
 */
export const validateCustomDomainSuccess =(response)=>({
    type: VALIDATE_CUSTOMDOMAIN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to validate a custom domain
 */
export const validateCustomDomainFailure =(error)=>({
    type: VALIDATE_CUSTOMDOMAIN_FAILURE,
    payload: error
})

/**
 * Redux action for validate a custom domain dns
 */
 export const validateCustomDomainDns =(model)=>({
    type: VALIDATE_CUSTOMDOMAIN_DNS,
    payload:model
})
/**
 * Redux action for successful validation of a custom domain dns
 */
export const validateCustomDomainDnsSuccess =(response)=>({
    type: VALIDATE_CUSTOMDOMAIN_DNS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to validate a custom domain dns
 */
export const validateCustomDomainDnsFailure =(error)=>({
    type: VALIDATE_CUSTOMDOMAIN_DNS_FAILURE,
    payload: error
})

/**
 * Redux action for renew a custom domain
 */
export const renewCustomDomain =(model)=>({
    type: RENEW_CUSTOMDOMAIN,
    payload:model
})
/**
 * Redux action for successful renew of a custom domain
 */
export const renewCustomDomainSuccess =(response)=>({
    type: RENEW_CUSTOMDOMAIN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to renew a custom domain
 */
export const renewCustomDomainFailure =(error)=>({
    type: RENEW_CUSTOMDOMAIN_FAILURE,
    payload: error
})

