/**
 * Auth Actions for sign in with magic link and social logins
 */
import {
  AUTHENTICATE_USER,
  AUTHENTICATE_USER_FAILURE,
  AUTHENTICATE_USER_SUCCESS,
  CHECK_CUSTOMER_ADMIN_STATUS,
  CHECK_CUSTOMER_ADMIN_STATUS_FAILURE,
  CHECK_CUSTOMER_ADMIN_STATUS_SUCCESS,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_DOMAIN_USERS,
  GET_DOMAIN_USERS_FAILURE,
  GET_DOMAIN_USERS_SUCCESS,
  GET_FIRSTPARTY_DOMAINS,
  GET_FIRSTPARTY_DOMAINS_FAILURE,
  GET_FIRSTPARTY_DOMAINS_SUCCESS,
  GET_USER_DOMAINS,
  GET_USER_DOMAINS_FAILURE,
  GET_USER_DOMAINS_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_FAILURE,
  LOGOUT_USER_SUCCESS,
  SET_USER_DOMAIN,
  SET_USER_CUSTOMER
} from "../actions/types";

/**
 * Redux Action To Get domains for which the user is admin
 */
export const getUserDomains = () => ({
  type: GET_USER_DOMAINS,
});

/**
 * Redux Action get domains success
 */
export const getUserDomainsSuccess = (domains) => ({
  type: GET_USER_DOMAINS_SUCCESS,
  payload: domains,
});

/**
 * Redux Action get domains Failure
 */
export const getUserDomainsFailure = (error) => ({
  type: GET_USER_DOMAINS_FAILURE,
  payload: error,
});

/**
 * Redux Action set current domain
 */
export const setUserDomain = (domain) => ({
  type: SET_USER_DOMAIN,
  payload: domain,
});

/**
 * Redux Action set current domain
 */
 export const setUserCustomer = (customer) => ({
  type: SET_USER_CUSTOMER,
  payload: customer,
});

export const getDomainUsers = (domain) => ({
  type: GET_DOMAIN_USERS,
  payload: domain,
});

export const getDomainUsersSuccess = (users) => ({
  type: GET_DOMAIN_USERS_SUCCESS,
  payload: users,
});

export const getDomainUsersFailure = (error) => ({
  type: GET_DOMAIN_USERS_FAILURE,
  payload: error,
});

export const checkCustomerAdminStatus = () => ({
  type: CHECK_CUSTOMER_ADMIN_STATUS,
});

export const checkCustomerAdminStatusSuccess = (status) => ({
  type: CHECK_CUSTOMER_ADMIN_STATUS_SUCCESS,
  payload: status,
});

export const checkCustomerAdminStatusFailure = (error) => ({
  type: CHECK_CUSTOMER_ADMIN_STATUS_FAILURE,
  payload: error,
});

// Send Authentication Request to server
export const authenticateUser = () => ({
  type: AUTHENTICATE_USER,
});

// Successful authentication by user on server
export const authenticateUserSuccess = (response) => ({
  type: AUTHENTICATE_USER_SUCCESS,
  payload: response,
});

// Failure to authenticate user on server
export const authenticateUserFailure = (error) => ({
  type: AUTHENTICATE_USER_FAILURE,
  payload: error,
});

// Send log off Request to server
export const logOff = () => ({
  type: LOGOUT_USER,
});

// Successful log off by user on server
export const logOffSuccess = (response) => ({
  type: LOGOUT_USER_SUCCESS,
  payload: response,
});

// Failure to log off user from gravito
export const logOffFailure = (error) => ({
  type: LOGOUT_USER_FAILURE,
  payload: error,
});

// get current user email
export const getCurrentUser = () => ({
  type: GET_CURRENT_USER,
});

// Successful log off by user on server
export const getCurrentUserSuccess = (response) => ({
  type: GET_CURRENT_USER_SUCCESS,
  payload: response,
});

export const getFirstpartyDomains = () => ({
  type: GET_FIRSTPARTY_DOMAINS,
});

export const getFirstpartyDomainsSuccess = (domains) => ({
  type: GET_FIRSTPARTY_DOMAINS_SUCCESS,
  payload: domains,
});

export const getFirstpartyDomainsFailure = (error) => ({
  type: GET_FIRSTPARTY_DOMAINS_FAILURE,
  payload: error,
});
