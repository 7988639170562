/* eslint-disable no-redeclare */
/* eslint-disable import/no-anonymous-default-export */
/*
    Campaign Reducer
    Curd Operations on Campaign.
*/
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_CAMPAIGN,
  ADD_CAMPAIGN_FAILURE,
  ADD_CAMPAIGN_SUCCESS,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN_SUCCESS,
  FILLTER_CAMPAIGNS,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_FAILURE,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_AUDIENCES,
  GET_CAMPAIGN_AUDIENCES_FAILURE,
  GET_CAMPAIGN_AUDIENCES_SUCCESS,
  GET_CAMPAIGN_CONSTELLATIONS,
  GET_CAMPAIGN_CONSTELLATIONS_FAILURE,
  GET_CAMPAIGN_CONSTELLATIONS_SUCCESS,
  GET_CAMPAIGN_DOMAINS,
  GET_CAMPAIGN_DOMAINS_FAILURE,
  GET_CAMPAIGN_DOMAINS_SUCCESS,
  GET_CAMPAIGN_SEGMENTS,
  GET_CAMPAIGN_SEGMENTS_FAILURE,
  GET_CAMPAIGN_SEGMENTS_SUCCESS,
  PUBLISH_CAMPAIGN,
  PUBLISH_CAMPAIGN_FAILURE,
  PUBLISH_CAMPAIGN_SUCCESS,
  REFRESH_CAMPAIGNS,
  SELECT_CAMPAIGN,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_FAILURE,
  UPDATE_CAMPAIGN_SUCCESS,
} from "../actions/types";
import AppConfig from "../constants/AppConfig";
// initial state
const INIT_STATE = {
  campaigns: [],
  standardCampaigns: [],
  transactionalCampaigns: [],
  filteredCampaigns: null,
  campaign: null,
  constellations: null,
  emailConstellations: null,
  smsConstellations: null,
  mobileConstellations: null,
  webConstellations: null,
  leafletConstellations: null,
  adConstellations: null,
  audiences: null,
  domains: null,
  search: "",
  action: "",
  loading: false,
  redirect: false,
  selected: null,
  selectedAudience: "",
  channels: AppConfig.CampaignChannel,
  segments: [],
  loadingError: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get audiences from server.
    case GET_CAMPAIGNS:
      return { ...state, loading: true };
    // On Success...
    case GET_CAMPAIGNS_SUCCESS:
      var standardCampaigns = _.filter(action.payload.data, {
        isTransactional: false,
      });
      var transactionalCampaigns = _.filter(action.payload.data, {
        isTransactional: true,
      });

      return {
        ...state,
        loading: false,
        campaigns: action.payload.data,
        filteredCampaigns: action.payload.data,
        standardCampaigns: standardCampaigns,
        transactionalCampaigns: transactionalCampaigns,
        redirect: false,
        loadingError: false,
      };

    // On Failure, show error message.
    case GET_CAMPAIGNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loadingError: true,
      };

    //ADD new campaign
    case ADD_CAMPAIGN:
      if (action.payload === "") {
        return { ...state, error: "Please enter valid campaign details" };
      } else {
        var campaign = action.payload;
        var constellations = [];

        if (action.payload.emailConstellation !== "") {
          constellations.push({
            constellationIdFK: action.payload.emailConstellation,
            channel: AppConfig.CampaignChannel.EMAIL,
          });
        }

        if (action.payload.smsConstellation !== "") {
          constellations.push({
            constellationIdFK: action.payload.smsConstellation,
            channel: AppConfig.CampaignChannel.SMS,
          });
        }
        if (action.payload.webConstellation !== "") {
          constellations.push({
            constellationIdFK: action.payload.webConstellation,
            channel: AppConfig.CampaignChannel.WEB,
          });
        }

        if (action.payload.mobileConstellation !== "") {
          constellations.push({
            constellationIdFK: action.payload.mobileConstellation,
            channel: AppConfig.CampaignChannel.MOBILE,
          });
        }

        if (action.payload.leafletConstellation !== "") {
          constellations.push({
            constellationIdFK: action.payload.leafletConstellation,
            channel: AppConfig.CampaignChannel.SNAIL,
          });
        }

        if (action.payload.webHookConstellation !== "") {
          constellations.push({
            constellationIdFK: action.payload.webHookConstellation,
            channel: AppConfig.CampaignChannel.WEBHOOK,
          });
        }

        if (action.payload.adConstellation !== "") {
          constellations.push({
            constellationIdFK: action.payload.adConstellation,
            channel: AppConfig.CampaignChannel.AD,
          });
        }

        var audience = "";
        if (action.payload.audience !== "") {
          audience = action.payload.audience;
        }

        var campaignModel = {
          title: action.payload.title,
          domain: action.payload.domain,
          constellation: constellations,
          audienceIdFK: audience,
          isTransactional: campaign.isTransactional,
          status: 0,
          postExecutionSegmentChange: campaign.postExecutionSegmentChange,
          onOpenSegmentChange: campaign.onOpenSegmentChange,
          onClickSegmentChange: campaign.onClickSegmentChange,
        };

        return {
          ...state,
          loading: true,
          campaign: campaignModel,
          action: "ADD",
          redirect: false,
        };
      }

    // On Success, show message and redirect to listing page...
    case ADD_CAMPAIGN_SUCCESS:
      NotificationManager.success("Campaign added successfully.");
      return { ...state, loading: false, campaign: null, redirect: true };
    // On failure, show error message.
    case ADD_CAMPAIGN_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, campaign: null };
    // Select campaign from list
    case SELECT_CAMPAIGN:
      if (action.payload === "") {
        NotificationManager.error("Invalid Campaign");
      } else {
        var selectedCampaign = _.filter(state.campaigns, {
          id: action.payload,
        })[0];

        var campaign = {
          id: selectedCampaign.id,
          title: selectedCampaign.title,
          domain: selectedCampaign.domain,
          emailConstellation: "",
          smsConstellation: "",
          webConstellation: "",
          mobileConstellation: "",
          webHookConstellation: "",
          leafletConstellation: "",
          adConstellation: "",
          isTransactional: selectedCampaign.isTransactional,
          status: selectedCampaign.status,
          audience: "",
          segmentsToAdd: _.filter(selectedCampaign.postExecutionSegmentChange, {
            isRemove: false,
          }).map(function (a) {
            return { value: a.segmentId, label: a.segmentName };
          }),
          segmentsToRemove: _.filter(
            selectedCampaign.postExecutionSegmentChange,
            { isRemove: true }
          ).map(function (a) {
            return { value: a.segmentId, label: a.segmentName };
          }),
          segmentsToAddOnOpen: _.filter(selectedCampaign.onOpenSegmentChange, {
            isRemove: false,
          }).map(function (a) {
            return { value: a.segmentId, label: a.segmentName };
          }),
          segmentsToRemoveOnOpen: _.filter(
            selectedCampaign.onOpenSegmentChange,
            { isRemove: true }
          ).map(function (a) {
            return { value: a.segmentId, label: a.segmentName };
          }),
          segmentsToAddOnClick: _.filter(
            selectedCampaign.onClickSegmentChange,
            { isRemove: false }
          ).map(function (a) {
            return { value: a.segmentId, label: a.segmentName };
          }),
          segmentsToRemoveOnClick: _.filter(
            selectedCampaign.onClickSegmentChange,
            { isRemove: true }
          ).map(function (a) {
            return { value: a.segmentId, label: a.segmentName };
          }),
        };

        var emailConstellations = _.filter(state.constellations, {
          channel: AppConfig.CampaignChannel.EMAIL,
        }).map(function (a) {
          return { value: a.id, label: a.title };
        });
        var smsConstellations = _.filter(state.constellations, {
          channel: AppConfig.CampaignChannel.SMS,
        }).map(function (a) {
          return { value: a.id, label: a.title };
        });
        var webConstellations = _.filter(state.constellations, {
          channel: AppConfig.CampaignChannel.WEB,
        }).map(function (a) {
          return { value: a.id, label: a.title };
        });
        var mobileConstellations = _.filter(state.constellations, {
          channel: AppConfig.CampaignChannel.MOBILE,
        }).map(function (a) {
          return { value: a.id, label: a.title };
        });
        var leafletConstellations = _.filter(state.constellations, {
          channel: AppConfig.CampaignChannel.SNAIL,
        }).map(function (a) {
          return { value: a.id, label: a.title };
        });
        var webHookConstellations = _.filter(state.constellations, {
          channel: AppConfig.CampaignChannel.WEBHOOK,
        }).map(function (a) {
          return { value: a.id, label: a.title };
        });
        var adConstellations = _.filter(state.constellations, {
          channel: AppConfig.CampaignChannel.AD,
        }).map(function (a) {
          return { value: a.id, label: a.title };
        });

        var selectedEmailConstellation = _.filter(
          selectedCampaign.constellation,
          { channel: AppConfig.CampaignChannel.EMAIL }
        );
        var selectedSMSConstellation = _.filter(
          selectedCampaign.constellation,
          { channel: AppConfig.CampaignChannel.SMS }
        );
        var selectedWebConstellations = _.filter(
          selectedCampaign.constellation,
          { channel: AppConfig.CampaignChannel.WEB }
        );
        var selectedmobileConstellation = _.filter(
          selectedCampaign.constellation,
          { channel: AppConfig.CampaignChannel.MOBILE }
        );
        var selectedLeafletConstellation = _.filter(
          selectedCampaign.constellation,
          { channel: AppConfig.CampaignChannel.SNAIL }
        );
        var selectedWebHookConstellations = _.filter(
          selectedCampaign.constellation,
          { channel: AppConfig.CampaignChannel.WEBHOOK }
        );
        var selectedAdConstellations = _.filter(
          selectedCampaign.constellation,
          { channel: AppConfig.CampaignChannel.AD }
        );
        var selectedAudience = "";
        if (
          _.filter(state.audiences, { value: selectedCampaign.audienceIdFK })
            .length > 0
        ) {
          selectedAudience = _.filter(state.audiences, {
            value: selectedCampaign.audienceIdFK,
          })[0];
        }

        campaign.audience = selectedAudience;
        if (selectedEmailConstellation.length > 0) {
          var emailConstellation = _.filter(emailConstellations, {
            value: selectedEmailConstellation[0].constellationIdFK,
          });
          if (emailConstellation.length > 0) {
            campaign.emailConstellation = emailConstellation[0];
          }
        }
        if (selectedSMSConstellation.length > 0) {
          var smsConstellation = _.filter(smsConstellations, {
            value: selectedSMSConstellation[0].constellationIdFK,
          });
          if (smsConstellation.length > 0) {
            campaign.smsConstellation = smsConstellation[0];
          }
        }
        if (selectedWebConstellations.length > 0) {
          var webConstellations = _.filter(webConstellations, {
            value: selectedWebConstellations[0].constellationIdFK,
          });
          if (webConstellations.length > 0) {
            campaign.webConstellation = webConstellations[0];
          }
        }

        if (selectedmobileConstellation.length > 0) {
          var mobileConstellations = _.filter(mobileConstellations, {
            value: selectedmobileConstellation[0].constellationIdFK,
          });
          if (mobileConstellations.length > 0) {
            campaign.mobileConstellation = mobileConstellations[0];
          }
        }

        if (selectedLeafletConstellation.length > 0) {
          var leafletConstellation = _.filter(leafletConstellations, {
            value: selectedLeafletConstellation[0].constellationIdFK,
          });
          if (leafletConstellation.length > 0) {
            campaign.leafletConstellation = leafletConstellation[0];
          }
        }

        if (selectedWebHookConstellations.length > 0) {
          var webHookConstellations = _.filter(webHookConstellations, {
            value: selectedWebHookConstellations[0].constellationIdFK,
          });
          if (webHookConstellations.length > 0) {
            campaign.webHookConstellation = webHookConstellations[0];
          }
        }

        if (selectedAdConstellations.length > 0) {
          var adConstellations = _.filter(adConstellations, {
            value: selectedAdConstellations[0].constellationIdFK,
          });
          if (adConstellations.length > 0) {
            campaign.adConstellation = adConstellations[0];
          }
        }

        return {
          ...state,
          loading: false,
          selected: campaign,
          redirect: false,
        };
      }
      break;
    // Update campaign
    case UPDATE_CAMPAIGN:
      var campaign = action.payload;
      var constellations = [];

      if (action.payload.emailConstellation !== "") {
        var emailConstellation = _.filter(state.emailConstellations, {
          value: action.payload.emailConstellation.value,
        });
        constellations.push({
          constellationIdFK: emailConstellation[0].value,
          channel: AppConfig.CampaignChannel.EMAIL,
        });
      }

      if (action.payload.smsConstellation !== "") {
        var smsConstellation = _.filter(state.smsConstellations, {
          value: action.payload.smsConstellation.value,
        });
        constellations.push({
          constellationIdFK: smsConstellation[0].value,
          channel: AppConfig.CampaignChannel.SMS,
        });
      }

      if (action.payload.webConstellation !== "") {
        var webConstellations = _.filter(state.webConstellations, {
          value: action.payload.webConstellation.value,
        });
        constellations.push({
          constellationIdFK: webConstellations[0].value,
          channel: AppConfig.CampaignChannel.WEB,
        });
      }

      if (action.payload.mobileConstellation !== "") {
        var mobileConstellations = _.filter(state.mobileConstellations, {
          value: action.payload.mobileConstellation.value,
        });
        constellations.push({
          constellationIdFK: mobileConstellations[0].value,
          channel: AppConfig.CampaignChannel.MOBILE,
        });
      }

      if (action.payload.leafletConstellation !== "") {
        var leafletConstellation = _.filter(state.leafletConstellations, {
          value: action.payload.leafletConstellation.value,
        });
        constellations.push({
          constellationIdFK: leafletConstellation[0].value,
          channel: AppConfig.CampaignChannel.SNAIL,
        });
      }
      if (action.payload.webHookConstellation !== "") {
        var webHookConstellations = _.filter(state.webHookConstellations, {
          value: action.payload.webHookConstellation.value,
        });
        constellations.push({
          constellationIdFK: webHookConstellations[0].value,
          channel: AppConfig.CampaignChannel.WEBHOOK,
        });
      }

      if (action.payload.adConstellation !== "") {
        var adConstellations = _.filter(state.adConstellations, {
          value: action.payload.adConstellation.value,
        });
        constellations.push({
          constellationIdFK: adConstellations[0].value,
          channel: AppConfig.CampaignChannel.AD,
        });
      }

      var audience = "";
      if (action.payload.audience !== "") {
        audience = _.filter(state.audiences, {
          value: action.payload.audience.value,
        })[0];
      }

      var campaignModel = {
        title: action.payload.title,
        domain: action.payload.domain,
        constellation: constellations,
        AudienceIdFK: audience.value,
        id: action.payload.id,
        isTransactional: campaign.isTransactional,
        postExecutionSegmentChange: campaign.postExecutionSegmentChange,
        onOpenSegmentChange: campaign.onOpenSegmentChange,
        onClickSegmentChange: campaign.onClickSegmentChange,
        status: 0,
      };

      return {
        ...state,
        loading: true,
        campaign: campaignModel,
        action: "UPDATE",
        redirect: false,
      };
    // On Success, show message and redirect to listing page....
    case UPDATE_CAMPAIGN_SUCCESS:
      NotificationManager.success("Campaign updated successfully.");
      return { ...state, loading: false, campaign: null, redirect: false };

    // On Failure show error message.
    case UPDATE_CAMPAIGN_FAILURE:
      NotificationManager.error("Could not update campaign");
      return { ...state, loading: false, campaign: null, redirect: true };

    // Delete campaign
    case DELETE_CAMPAIGN:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid campaign details" };
      } else {
        return {
          ...state,
          loading: true,
          campaign: action.payload,
          action: "DELETE",
        };
      }

    // On Success, redirect to listing page..
    case DELETE_CAMPAIGN_SUCCESS:
      return { ...state, loading: false, campaign: null };

    // on
    case DELETE_CAMPAIGN_FAILURE:
      NotificationManager.error("Could not delete content");
      return { ...state, loading: false, campaign: null };

    case FILLTER_CAMPAIGNS:
      if (action.payload === "") {
        var standardCampaigns = _.filter(state.campaigns, {
          isTransactional: false,
        });
        var transactionalCampaigns = _.filter(state.campaigns, {
          isTransactional: true,
        });

        return {
          ...state,
          filteredCampaigns: state.campaigns,
          standardCampaigns: standardCampaigns,
          transactionalCampaigns: transactionalCampaigns,
          search: "",
        };
      } else {
        const filtered = state.campaigns.filter(
          (item) =>
            item.title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );

        var standardCampaigns = _.filter(filtered, { isTransactional: false });
        var transactionalCampaigns = _.filter(filtered, {
          isTransactional: true,
        });
        return {
          ...state,
          filteredCampaigns: filtered,
          standardCampaigns: standardCampaigns,
          transactionalCampaigns: transactionalCampaigns,
          search: action.payload.toLowerCase(),
        };
      }

    // Get campaign constellations  from server.
    case GET_CAMPAIGN_CONSTELLATIONS:
      return { ...state, loading: true };
    // On Success...
    case GET_CAMPAIGN_CONSTELLATIONS_SUCCESS:
      const email = _.filter(action.payload.data, {
        channel: AppConfig.CampaignChannel.EMAIL,
      }).map(function (a) {
        return { value: a.id, label: a.title };
      });

      const sms = _.filter(action.payload.data, {
        channel: AppConfig.CampaignChannel.SMS,
      }).map(function (a) {
        return { value: a.id, label: a.title };
      });
      const notification = _.filter(action.payload.data, {
        channel: AppConfig.CampaignChannel.MOBILE,
      }).map(function (a) {
        return { value: a.id, label: a.title };
      });
      const web = _.filter(action.payload.data, {
        channel: AppConfig.CampaignChannel.WEB,
      }).map(function (a) {
        return { value: a.id, label: a.title };
      });
      const leaflet = _.filter(action.payload.data, {
        channel: AppConfig.CampaignChannel.SNAIL,
      }).map(function (a) {
        return { value: a.id, label: a.title };
      });
      const webHook = _.filter(action.payload.data, {
        channel: AppConfig.CampaignChannel.WEBHOOK,
      }).map(function (a) {
        return { value: a.id, label: a.title };
      });

      const advertisement = _.filter(action.payload.data, {
        channel: AppConfig.CampaignChannel.AD,
      }).map(function (a) {
        return { value: a.id, label: a.title };
      });

      return {
        ...state,
        loading: false,
        constellations: action.payload.data,
        emailConstellations: email,
        smsConstellations: sms,
        webConstellations: web,
        mobileConstellations: notification,
        leafletConstellations: leaflet,
        webHookConstellations: webHook,
        adConstellations: advertisement,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_CAMPAIGN_CONSTELLATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get audience domains from server.
    case GET_CAMPAIGN_DOMAINS:
      return { ...state, loading: true };
    // On Success...
    case GET_CAMPAIGN_DOMAINS_SUCCESS:
      const domains = action.payload.data.filter(
        (item) => item.isAdmin === true
      );

      return { ...state, loading: false, domains: domains, redirect: false };

    // On Failure, show error message.
    case GET_CAMPAIGN_DOMAINS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get campaign audiences  from server.
    case GET_CAMPAIGN_AUDIENCES:
      return { ...state, loading: true };
    // On Success...
    case GET_CAMPAIGN_AUDIENCES_SUCCESS:
      var audienceList = [];
      if (action.payload.data !== null && action.payload.data.length > 0) {
        action.payload.data.forEach((element) => {
          var audienceItem = {
            value: element.id,
            label: element.title,
          };
          audienceList.push(audienceItem);
        });
      }

      return {
        ...state,
        loading: false,
        audiences: audienceList,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_CAMPAIGN_AUDIENCES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case REFRESH_CAMPAIGNS:
      if (state.action === "UPDATE") {
        NotificationManager.success("Campaign updated successfully.");
      } else if (state.action === "ADD") {
        NotificationManager.success("Campaign added successfully.");
      } else {
        NotificationManager.success("Campaign deleted successfully.");
      }

      if (state.search === "") {
        var standardCampaigns = _.filter(action.payload.data, {
          isTransactional: false,
        });
        var transactionalCampaigns = _.filter(action.payload.data, {
          isTransactional: true,
        });

        return {
          ...state,
          loading: false,
          campaigns: action.payload.data,
          filteredCampaigns: action.payload.data,
          standardCampaigns: standardCampaigns,
          transactionalCampaigns: transactionalCampaigns,
        };
      } else {
        const filtered = state.campaigns.filter(
          (item) =>
            item.title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        var standardCampaigns = _.filter(filtered, { isTransactional: false });
        var transactionalCampaigns = _.filter(filtered, {
          isTransactional: true,
        });
        return {
          ...state,
          filteredCampaigns: filtered,
          search: action.payload.toLowerCase(),
          standardCampaigns: standardCampaigns,
          transactionalCampaigns: transactionalCampaigns,
        };
      }
    case PUBLISH_CAMPAIGN:
      return { ...state, loading: true };

    case PUBLISH_CAMPAIGN_SUCCESS:
      NotificationManager.success("Campaign published successfully.");
      return { ...state, loading: false, redirect: true };

    // On Failure show error message.
    case PUBLISH_CAMPAIGN_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, redirect: true };

    // Get campaign segments  from server.
    case GET_CAMPAIGN_SEGMENTS:
      return { ...state, loading: true };
    // On Success...
    case GET_CAMPAIGN_SEGMENTS_SUCCESS:
      var segmentList = [];
      if (action.payload !== null && action.payload.length > 0) {
        action.payload.forEach((element) => {
          var segmentItem = {
            value: element.id,
            label: element.name,
          };
          segmentList.push(segmentItem);
        });
      }

      return {
        ...state,
        loading: false,
        segments: segmentList,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_CAMPAIGN_SEGMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
