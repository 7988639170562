import {
    SAVE_SEGMENT,
    SAVE_SEGMENT_FAILURE,
    SAVE_SEGMENT_SUCCESS,
    GET_SEGMENTS,
    GET_SEGMENTS_SUCCESS,
    SELECT_SEGMENT,
    UPDATE_SEGMENT,
    UPDATE_SEGMENT_FAILURE,
    UPDATE_SEGMENT_SUCCESS,
    DELETE_SEGMENT,
    DELETE_SEGMENT_SUCCESS,
    FILTER_SEGMENT,
    SORT_SEGMENT
} from "./types"

export const saveSegment=(segment)=>(
    {
        type:SAVE_SEGMENT,
        payload:segment
    }
)

export const saveSegmentSuccess=()=>(
    {
        type:SAVE_SEGMENT_SUCCESS,
        
    }
)
export const saveSegmentFailure=(error)=>(
    {
        type:SAVE_SEGMENT_FAILURE,
        payload:error
        
    }
)
export const getSegments=()=>(
    {
        type:GET_SEGMENTS,
        
    }
)

export const getSegmentsSuccess=(segments)=>(
    {
        type:GET_SEGMENTS_SUCCESS,
        payload:segments
    }
)
export const getSegmentsFailure=(error)=>(
    {
        type:SAVE_SEGMENT_FAILURE,
        payload:error
        
    }
)

export const selectSegment=(segment)=>(
    {
        type:SELECT_SEGMENT,
        payload:segment
    }
)

export const updateSegments=(segment)=>(
    {
        type:UPDATE_SEGMENT,
        payload:segment
        
    }
)

export const updateSegmentsSuccess=()=>(
    {
        type:UPDATE_SEGMENT_SUCCESS,
       
    }
)
export const updateSegmentsFailure=(error)=>(
    {
        type:UPDATE_SEGMENT_FAILURE,
        payload:error
        
    }
)

export const deleteSegement=(segment)=>(
    {
        type:DELETE_SEGMENT,
        payload:segment
    }
)

export const deleteSegmentSuccess=()=>(
    {
    type:DELETE_SEGMENT_SUCCESS,
    

})
export const deleteSegmentFailure=(error)=>(
    {
    type:DELETE_SEGMENT_SUCCESS,
    payload:error

})
export const filterSegment=(message)=>(
    {
        type:FILTER_SEGMENT,
        payload:message
    }
)

export const SortSegmentByType=(type)=>(
    {
        type:SORT_SEGMENT,
        payload:type
    }
)


