/* eslint-disable import/no-anonymous-default-export */
import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_COOKIES_DESCRIPTIONS,
  ADD_COOKIES_DESCRIPTIONS_FAILURE,
  ADD_COOKIES_DESCRIPTIONS_SUCCESS,
  DELETE_COOKIES_DESCRIPTIONS,
  DELETE_COOKIES_DESCRIPTIONS_FAILURE,
  DELETE_COOKIES_DESCRIPTIONS_SUCCESS,
  FILTER_COOKIES_DESCRIPTION,
  GET_COOKIES_DESCRIPTIONS,
  GET_COOKIES_DESCRIPTIONS_FAILURE,
  GET_COOKIES_DESCRIPTIONS_SUCCESS,
  GET_COOKIES_SCAN_HISTORY,
  GET_COOKIES_SCAN_HISTORY_FAILURE,
  GET_COOKIES_SCAN_HISTORY_SUCCESS,
  GET_COOKIE_SCAN_LANGAUGES,
  GET_COOKIE_SCAN_LANGAUGES_FAILURE,
  GET_COOKIE_SCAN_LANGAUGES_SUCCESS,
  GET_SCAN_COOKIES_REPORT,
  GET_SCAN_COOKIES_REPORT_FAILURE,
  GET_SCAN_COOKIES_REPORT_SUCCESS,
  NEW_SCAN_COOKIES_REPORT,
  SCAN_NON_GRAVITO_DOMAIN,
  SCAN_NON_GRAVITO_DOMAIN_FAILURE,
  SCAN_NON_GRAVITO_DOMAIN_SUCCESS,
  SEARCH_COOKIES_DESCRIPTION,
  UPDATE_COOKIES_DESCRIPTIONS,
  UPDATE_COOKIES_DESCRIPTIONS_FAILURE,
  UPDATE_COOKIES_DESCRIPTIONS_SUCCESS,
} from "../actions/types";
const initState = {
  scanLoading: false,
  reportData: null,
  reportRecived: false,
  scanHistory: [],
  scanRequestObj: null,
  cookieDescriptions: [],
  currentDescription: null,
  filteredDescriptions: [],
  nonGravitoDomain: null,
  nonGravitoDomainReport: [],
  languages: [],
  redirect: false,
};

export default (state = initState, action) => {
  switch (action.type) {
    case GET_SCAN_COOKIES_REPORT:
      return {
        ...state,
        scanLoading: true,
        reportData: null,
        reportRecived: false,
        scanRequestObj: action.payload,
      };
    case GET_SCAN_COOKIES_REPORT_SUCCESS:
      let reportData = action.payload;
      if (reportData) {
        reportData.beforeAccept = reportData.beforeAcceptCookies;

        reportData.afterAccept = reportData.afterAcceptCookies;
      }
      return {
        ...state,
        scanLoading: false,
        reportData: reportData,
        reportRecived: true,
      };
    case GET_SCAN_COOKIES_REPORT_FAILURE:
      console.error(action.payload);
      return { ...state, scanLoading: false, reportRecived: false };
    case NEW_SCAN_COOKIES_REPORT:
      return { ...state, reportRecived: false, reportData: null };
    case GET_COOKIES_SCAN_HISTORY:
      return { ...state, scanLoading: true };
    case GET_COOKIES_SCAN_HISTORY_SUCCESS:
      return { ...state, scanLoading: false, scanHistory: action.payload };
    case GET_COOKIES_SCAN_HISTORY_FAILURE:
      NotificationManager.error("Error occured");
      return { ...state, scanLoading: false, scanHistory: [] };
    case GET_COOKIES_DESCRIPTIONS:
      return { ...state, loading: true, redirect: false };
    case GET_COOKIES_DESCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        cookieDescriptions: action.payload,
        filteredDescriptions: action.payload,
      };
    case GET_COOKIES_DESCRIPTIONS_FAILURE:
      NotificationManager.error("Error occured");
      return { ...state, loading: false };
    case ADD_COOKIES_DESCRIPTIONS:
      return { ...state, loading: true, currentDescription: action.payload };
    case ADD_COOKIES_DESCRIPTIONS_SUCCESS:
      NotificationManager.success("Succesfully added description");
      return {
        ...state,
        loading: false,
        currentDescription: null,
        redirect: true,
      };
    case ADD_COOKIES_DESCRIPTIONS_FAILURE:
      NotificationManager.error("Error occured while add cookie to repo");
      return { ...state, loading: false, currentDescription: null };
    case DELETE_COOKIES_DESCRIPTIONS:
      return { ...state, loading: true, currentDescription: action.payload };
    case DELETE_COOKIES_DESCRIPTIONS_SUCCESS:
      NotificationManager.success("Succesfully deleted description");
      return {
        ...state,
        loading: false,
        currentDescription: null,
        cookieDescriptions: action.payload,
        filteredDescriptions: action.payload,
      };
    case DELETE_COOKIES_DESCRIPTIONS_FAILURE:
      NotificationManager.error("Error occured");
      return { ...state, loading: false, currentDescription: null };
    case UPDATE_COOKIES_DESCRIPTIONS:
      return { ...state, loading: true, currentDescription: action.payload };
    case UPDATE_COOKIES_DESCRIPTIONS_SUCCESS:
      NotificationManager.success("Succesfully Updated description");
      return {
        ...state,
        loading: false,
        currentDescription: null,
        redirect: true,
      };
    case UPDATE_COOKIES_DESCRIPTIONS_FAILURE:
      NotificationManager.error("Error occured");
      return { ...state, loading: false, currentDescription: null };
    case SEARCH_COOKIES_DESCRIPTION:
      let searchValue = action.payload;
      if (searchValue === "") {
        return { ...state, filteredDescriptions: state.cookieDescriptions };
      } else {
        let fd = _.filter(state.cookieDescriptions, (d, i) => {
          return _.includes(d.name, searchValue);
        });
        return { ...state, filteredDescriptions: fd };
      }
    case FILTER_COOKIES_DESCRIPTION: {
      let filterType = action.payload;

      if (filterType !== -1) {
        let fd = _.filter(state.cookieDescriptions, (d, i) => {
          return d.type === filterType;
        });
        return { ...state, filteredDescriptions: fd };
      } else {
        return { ...state, filteredDescriptions: state.cookieDescriptions };
      }
    }
    case SCAN_NON_GRAVITO_DOMAIN: {
      return { ...state, nonGravitoDomain: action.payload, scanLoading: true };
    }
    case SCAN_NON_GRAVITO_DOMAIN_SUCCESS: {
      return {
        ...state,
        nonGravitoDomainReport: action.payload,
        nonGravitoDomain: null,
        reportRecived: true,
        scanLoading: false,
      };
    }
    case SCAN_NON_GRAVITO_DOMAIN_FAILURE: {
      return {
        ...state,
        nonGravitoDomainReport: [],
        nonGravitoDomain: null,
        scanLoading: false,
      };
    }
    case GET_COOKIE_SCAN_LANGAUGES: {
      return {
        ...state,
      };
    }
    case GET_COOKIE_SCAN_LANGAUGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        languages: action.payload,
      };
    }
    case GET_COOKIE_SCAN_LANGAUGES_FAILURE: {
      NotificationManager.error("Error occured while fetching languages");
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return { ...state };
  }
};
