/**
 * Applications Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  addApplicationFailure,
  addApplicationSuccess,
  deleteApplicationFailure,
  deleteApplicationSuccess,
  getApplicationsFailure,
  getApplicationsSuccess,
  refreshApplications,
  updateApplicationFailure,
  updateApplicationSuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const applications = (state) => state.applicationsReducer;
export const authUser = (state) => state.authUser;

const getApplicationsFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain.id },
  };
  return await getAxiosRequest().get(
    "/manage/client/customer/" + currentDomain.customerId,
    config
  );
};

const addApplicationToServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().post(
    "/manage/client",
    model.application,
    config
  );
};

const updateApplication = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().put(
    "/manage/client",
    model.application,
    config
  );
};

const removeApplication = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().delete("/manage/client/" + model.id, config);
};

/**
 * Get Applications
 */
function* getApplications() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    const response = yield call(getApplicationsFromServer, user.currentDomain);
    yield put(getApplicationsSuccess(response));
  } catch (error) {
    yield put(getApplicationsFailure(error.response.data.error));
  }
}
/**
 * Add Application
 */
function* addApplication() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(applications); // <-- get the model
    // set the customerId from the current selected domain
    model.application.customerId = user.currentDomain.customerId;

    var app = { application: model.application, domain: user.currentDomain.id };

    const response = yield call(addApplicationToServer, app);
    yield put(addApplicationSuccess(response));
  } catch (error) {
    yield put(addApplicationFailure(error.response.data.error));
  }
}

/**
 * Edit Application
 */
function* editApplication() {
  try {
    // call api to generate magic link
    let model = yield select(applications); // <-- get the model
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model
    // set the customerId from the current selected domain
    model.application.customerId = user.currentDomain.customerId;
    var app = { application: model.application, domain: user.currentDomain.id };
    const response = yield call(updateApplication, app);
    yield put(updateApplicationSuccess(response));
  } catch (error) {
    yield put(updateApplicationFailure(error.response.data.error));
  }
}

/**
 * delete Application
 */
function* deleteApplication() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model

    // call api to generate magic link
    let model = yield select(applications); // <-- get the model
    var applicationModel = {
      id: model.application.clientId,
      domain: user.currentDomain.id,
    };
    const response = yield call(removeApplication, applicationModel);
    yield put(deleteApplicationSuccess(response));
    const refreshResponse = yield call(
      getApplicationsFromServer,
      user.currentDomain
    );
    yield put(refreshApplications(refreshResponse));
  } catch (error) {
    yield put(deleteApplicationFailure(error.response.data.error));
  }
}

export const applicationSagas = [
  takeEvery("GET_APPLICATIONS", getApplications),
  takeEvery("ADD_APPLICATION", addApplication),
  takeEvery("UPDATE_APPLICATION", editApplication),
  takeEvery("DELETE_APPLICATION", deleteApplication),
];

/**
 * Audiences Root Saga
 */
export default function* rootSaga() {
  yield all([...applicationSagas]);
}
