/**
 * App Routes
 */
 import React from "react";
 import { connect } from "react-redux";
 import { Route, withRouter } from "react-router-dom";
 // async component
 import {
    
  
   AsyncLandingComponent,
   AsyncInviteComponent
 } from "../components/AsyncComponent/AsyncComponent";
 // app default layout
 
 const InviteRoutes = (props) => {
    return (
       <> 
     
     <Route exact
         path="/magiclinkinviteconfirmation"
         component={AsyncInviteComponent}
       />
          <Route
        path="/customerdashboard"
        component={AsyncLandingComponent}
      />
      <Route path="/reports" component={AsyncLandingComponent} />
      <Route
        path="/profiles"
        component={AsyncLandingComponent}
      />
      <Route path="/devices" component={AsyncLandingComponent} />
      <Route path="/upload" component={AsyncLandingComponent} />
      <Route
        path="/contents"
        component={AsyncLandingComponent}
      />
      <Route
        path="/constellations"
        component={AsyncLandingComponent}
      />
      <Route
        path="/campaigns"
        component={AsyncLandingComponent}
      />
      <Route
        path="/audiences"
        component={AsyncLandingComponent}
      />
      <Route
        path="/roles"
        component={AsyncLandingComponent}
      />
      <Route
        path="/users"
        component={AsyncLandingComponent}
      />
      <Route
        path="/domains"
        component={AsyncLandingComponent}
      />
      <Route
        path="/customers"
        component={AsyncLandingComponent}
      />
      <Route
        path="/domain-segments"
        component={AsyncLandingComponent}
      />
      <Route
        path="/campaigndashboard"
        component={AsyncLandingComponent}
      />
      <Route
        path="/domainproperties"
        component={AsyncLandingComponent}
      />
      <Route
        path="/domainprofiles"
        component={AsyncLandingComponent}
      />
      <Route
        path="/customerdomains"
        component={AsyncLandingComponent}
      />
      <Route
        path="/segments"
        component={AsyncLandingComponent}
      />
      <Route
        path="/applications"
        component={AsyncLandingComponent}
      />
      <Route
        path="/managecustomdomains"
        component={AsyncLandingComponent}
      />
      <Route
        path="/tcfconfig"
        component={AsyncLandingComponent}
      />
      <Route
        path="/lightconfig"
        component={AsyncLandingComponent}
      />
      <Route
        path="/connectors"
        component={AsyncLandingComponent}
      />
       </>
   );
 };
 
 export default withRouter(connect(null)(InviteRoutes));
 