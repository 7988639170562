/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/**
 * Customer Dashboard Sagas
 */
import { all, call, delay, put, select, takeEvery } from "redux-saga/effects";
import {
  getTrendsDataSuccess,
  getTrendsDataFailure,
  getCustomersForTrendsSuccess,
  getCustomersForTrendsFailure,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const trends = (state) => state.compareTrendsReducer;
export const authUser = (state) => state.authUser;



// Return the base64-urlencoded sha256 hash for the PKCE challenge

const getTrendsCountFromServer = async (model) => {
  var payload = {
    noOfDays: model.days,
    domainList: model.filterDomains ? model.filterDomains : [],
  };

  return await getAxiosRequest().post(
    `/customer/${model.customerId}/domaincounts`,
    payload
  );
};

const getTrendsEventsFromServer = async (model) => {
  var payload = {
    noOfDays: model.days,
    domainList: model.filterDomains ? model.filterDomains : [],
  };

  return await getAxiosRequest().post(
    `/customer/${model.customerId}/domaineventcounts`,
    payload
  );
};

const getCustomerListFromServer = async () => {
  return await getAxiosRequest().get("/customer");
};

/**
 * Get Trends Data
 */
function* getTrendsData() {
  try {
    // get current domain from authUser
    let user = yield select(authUser); // <-- get the model
    let trendsDetails = yield select(trends);

   
    var model = {};
    if (trendsDetails.selectedCustomer !== null) {
      // create group one model and group two model separately
      var groupOneModel = {
        customerId: trendsDetails.selectedCustomer.id,
        days: trendsDetails.filterDays,
        filterDomains: trendsDetails.trendPayload.groupOne,
      };

      var groupTwoModel = {
        customerId: trendsDetails.selectedCustomer.id,
        days: trendsDetails.filterDays,
        filterDomains: trendsDetails.trendPayload.groupTwo,
      };
    
    } else {
      var groupOneModel = {
        customerId: user.currentDomain.customerId,
        days: trendsDetails.filterDays,
        filterDomains: trendsDetails.trendPayload.groupOne,
      };

      var groupTwoModel = {
        customerId: user.currentDomain.customerId,
        days: trendsDetails.filterDays,
        filterDomains: trendsDetails.trendPayload.groupTwo,
      };
      
    }

    const [groupOneCdpData, groupTwoCdpData , groupOneCmpData, groupTwoCmpData] = yield all([
      call(getTrendsCountFromServer, groupOneModel),
      call(getTrendsCountFromServer, groupTwoModel),
      call(getTrendsEventsFromServer, groupOneModel),
      call(getTrendsEventsFromServer, groupTwoModel),
    ]);

    var response = {
      groupOneCdp: groupOneCdpData.data,
      groupTwoCdp: groupTwoCdpData.data,
      groupOneCmp: groupOneCmpData.data,
      groupTwoCmp: groupTwoCmpData.data,
    };

    // set response back 
    yield put(getTrendsDataSuccess(response));
  } catch (error) {
    if (error.response.status === 403) {
      yield delay(2000);
      try {
        let user = yield select(authUser); // <-- get the model
        let trendsDetails = yield select(trends);
        var model = {};
        if (trendsDetails.selectedCustomer === null) {
          model = {
            customerId: user.currentDomain.customerId,
            days: trendsDetails.filterDays,
            filterDomains: trendsDetails.filterDomains,
          };
        } else {
          model = {
            customerId: trendsDetails.selectedCustomer.id,
            days: trendsDetails.filterDays,
            filterDomains: trendsDetails.filterDomains,
          };
        }

        const [cdpdata, cmpdata] = yield all([
          call(getTrendsCountFromServer, model),
          call(getTrendsEventsFromServer, model),
        ]);

        var response = {
          profiles: cdpdata.data,
          events: cmpdata.data,
        };

        // const identityResponse = yield call(getIndentityToken);
        // console.log(identityResponse);
        yield put(getTrendsDataSuccess(response));
      } catch (error) {
        yield put(getTrendsDataFailure(error.response.data.error));
      }
    }
    yield put(getTrendsDataFailure(error.response.data.error));
  }
}

/**
 * Get Customer List
 */
function* getCustomers() {
  try {
    const response = yield call(getCustomerListFromServer);
    yield put(getCustomersForTrendsSuccess(response));
  } catch (error) {
    yield put(getCustomersForTrendsFailure(error.response.data.error));
  }
}

export const compareTrendsSagas = [
  takeEvery("GET_TRENDS_DATA", getTrendsData),
  takeEvery("GET_CUSTOMER_LIST_FOR_TRENDS", getCustomers),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([... compareTrendsSagas]);
}
