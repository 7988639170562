/* eslint-disable import/no-anonymous-default-export */
/*
    Contents Reducer
    Curd Operations on Contents.
*/
import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  ADD_CONTENT,
  ADD_CONTENT_FAILURE,
  ADD_CONTENT_SUCCESS,
  DELETE_CONTENT,
  DELETE_CONTENT_FAILURE,
  DELETE_CONTENT_SUCCESS,
  FILLTER_CONTENT,
  FILLTER_CONTENT_BY_TYPE,
  GET_CONTENTS,
  GET_CONTENTS_FAILURE,
  GET_CONTENTS_SUCCESS,
  GET_CONTENT_DOMAINS,
  GET_CONTENT_DOMAINS_FAILURE,
  GET_CONTENT_DOMAINS_SUCCESS,
  REFRESH_CONTENTS,
  SELECT_CONTENT,
  UPDATE_CONTENT,
  UPDATE_CONTENT_FAILURE,
  UPDATE_CONTENT_SUCCESS,
} from "../actions/types";

// initial state
const INIT_STATE = {
  contents: [],
  domains: null,
  filteredContents: [],
  content: null,
  search: "",
  action: "",
  loading: false,
  contentList: null,
  redirect: false,
  selected: null,
  filterType: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get Contents from server.
    case GET_CONTENTS:
      return { ...state, filteredContents: [], loading: true };
    // On Success...
    case GET_CONTENTS_SUCCESS:
      var jsonObj = [];
      // Filter contents by contenttype...
      Object.keys(_.groupBy(action.payload.data, "contentType")).forEach(
        (element) => {
          switch (element) {
            case "body":
              jsonObj.push({
                body: _.filter(action.payload.data, { contentType: element }),
              });

              break;
            case "subject":
              jsonObj.push({
                subject: _.filter(action.payload.data, {
                  contentType: element,
                }),
              });

              break;
            case "simpletext":
              jsonObj.push({
                subject: _.filter(action.payload.data, {
                  contentType: element,
                }),
              });

              break;
            case "heroimage":
              jsonObj.push({
                heroimage: _.filter(action.payload.data, {
                  contentType: element,
                }),
              });

              break;
            default:
              break;
          }
        }
      );

      return {
        ...state,
        loading: false,
        contents: action.payload.data,
        filteredContents: action.payload.data,
        contentList: jsonObj,
        redirect: false,
      };

    // On Failure, show error message.
    case GET_CONTENTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Refres
    case REFRESH_CONTENTS:
      if (state.action === "UPDATE") {
        NotificationManager.success("Content updated successfully.");
      } else if (state.action === "ADD") {
        NotificationManager.success("Content added successfully.");
      } else {
        NotificationManager.success("Content deleted successfully.");
      }
      if (state.filterType === "") {
        return {
          ...state,
          loading: false,
          contents: action.payload.data,
          filteredContents: action.payload.data,
        };
      } else {
        const filtered = action.payload.data.filter(
          (item) =>
            item.contentType
              .toLowerCase()
              .indexOf(state.filterType.toLowerCase()) > -1
        );
        return {
          ...state,
          loading: false,
          filteredContents: filtered,
          contents: action.payload.data,
        };
      }

    //ADD new content
    case ADD_CONTENT:
      if (action.payload === "") {
        return { ...state, error: "Please enter valid content details" };
      } else {
        return {
          ...state,
          loading: true,
          content: action.payload,
          action: "ADD",
          redirect: false,
        };
      }

    // On Success, show message and redirect to listing page...
    case ADD_CONTENT_SUCCESS:
      NotificationManager.success("Content added successfully.");
      return { ...state, loading: false, content: null, redirect: true };
    // On failure, show error message.
    case ADD_CONTENT_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, content: null };
    // Select content from list
    case SELECT_CONTENT:
      if (action.payload === "") {
        NotificationManager.error("Invalid content");
      } else {
        var selectedContent = _.filter(state.contents, { id: action.payload });
        return {
          ...state,
          loading: false,
          selected: selectedContent[0],
          redirect: false,
        };
      }
      break;
    // Update content
    case UPDATE_CONTENT:
      return {
        ...state,
        loading: true,
        content: action.payload,
        action: "UPDATE",
        redirect: false,
      };

    // On Success, show message and redirect to listing page....
    case UPDATE_CONTENT_SUCCESS:
      NotificationManager.success("Content updated successfully.");
      return {
        ...state,
        loading: false,
        content: null,
        selected: null,
        redirect: false,
      };

    // On Failure show error message.
    case UPDATE_CONTENT_FAILURE:
      NotificationManager.error("Could not update content");
      return { ...state, loading: false, content: null, redirect: true };

    // Delete content
    case DELETE_CONTENT:
      if (action.payload === "") {
        return { ...state, error: "Please enter a valid content details" };
      } else {
        return {
          ...state,
          loading: true,
          content: action.payload,
          action: "DELETE",
        };
      }

    // On Success, redirect to listing page..
    case DELETE_CONTENT_SUCCESS:
      return { ...state, loading: true, content: null };

    // on
    case DELETE_CONTENT_FAILURE:
      NotificationManager.error("Could not delete content");
      return { ...state, loading: false, content: null };

    case FILLTER_CONTENT:
      if (action.payload === "") {
        return { ...state, filteredContents: state.contents };
      } else {
        const filtered = state.contents.filter(
          (item) =>
            item.title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredContents: filtered,
          search: action.payload.toLowerCase(),
        };
      }

    case FILLTER_CONTENT_BY_TYPE:
      if (action.payload === "") {
        return { ...state, filteredContents: state.contents };
      } else {
        const filtered = state.contents.filter(
          (item) =>
            item.contentType
              .toLowerCase()
              .indexOf(action.payload.toLowerCase()) > -1
        );
        return {
          ...state,
          filteredContents: filtered,
          filterType: action.payload.toLowerCase(),
        };
      }

    case GET_CONTENT_DOMAINS:
      return { ...state, loading: true };
    // On Success...
    case GET_CONTENT_DOMAINS_SUCCESS:
      const domains = action.payload.data.filter(
        (item) => item.isAdmin === true
      );

      return { ...state, loading: false, domains: domains, redirect: false };

    // On Failure, show error message.
    case GET_CONTENT_DOMAINS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
