/**
 * Audience Sagas
 */
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  executeCampaignFailure,
  executeCampaignSuccess,
  getCampaignStatsFailure,
  getCampaignStatsSuccess,
  getDashboardCampaignsFailure,
  getDashboardCampaignsSuccess,
  stopCampaignFailure,
  stopCampaignSuccess,
} from "../actions";
import { getAxiosRequest } from "../helpers/helpers";

export const campaigns = (state) => state.campaignDashboardReducer;

export const authUser = (state) => state.authUser;

const getCampaignsFromServer = async (currentDomain) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: currentDomain },
  };
  return await getAxiosRequest().get("/manage/campaign/nondraft", config);
};

const getCampaignStatsFromServer = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().get(
    "/manage/campaign/" + model.id + "/response",
    config
  );
};

const executeCampaign = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().post(
    "/manage/campaign/" + model.id + "/execute",
    model,
    config
  );
};

const stopCampaign = async (model) => {
  var config = {};
  config = {
    headers: { DOMAIN_KEY: model.domain },
  };
  return await getAxiosRequest().post(
    "/manage/campaign/" + model.id + "/stop",
    model.id,
    config
  );
};

/**
 * Get Contents
 */
function* getCampaigns() {
  try {
    let user = yield select(authUser);
    // call api to generate magic link
    const response = yield call(getCampaignsFromServer, user.currentDomain.id);
    yield put(getDashboardCampaignsSuccess(response));
    //yield all(response.data.map(x => call(getCampaignStatsAtLoad, x.id)));
  } catch (error) {
    yield put(getDashboardCampaignsFailure(error.response.data.error));
  }
}

function* getCampaignStats() {
  try {
    // call api to get the segments of the domains for which the current user is admin.
    let model = yield select(campaigns); // <-- get the model
    let user = yield select(authUser);
    var campaignModel = {
      id: model.refreshCampaign,
      domain: user.currentDomain.id,
    };

    const response = yield call(getCampaignStatsFromServer, campaignModel);
    yield put(getCampaignStatsSuccess(response));
  } catch (error) {
    yield put(getCampaignStatsFailure(error.response.data.error));
  }
}

/**
 * Execute Campaign
 */
function* execute() {
  try {
    let user = yield select(authUser);
    let model = yield select(campaigns); // <-- get the model
    var campaignModel = {
      id: model.campaignId,
      domain: user.currentDomain.id,
    };
    const response = yield call(executeCampaign, campaignModel);
    yield put(executeCampaignSuccess(response));
  } catch (error) {
    yield put(executeCampaignFailure(error.response.data.error));
  }
}

/**
 * Stop Campaign
 */
function* stop() {
  try {
    let user = yield select(authUser);
    let model = yield select(campaigns); // <-- get the model
    var campaignModel = {
      id: model.campaignId,
      domain: user.currentDomain.id,
    };
    const response = yield call(stopCampaign, campaignModel);
    yield put(stopCampaignSuccess(response));
  } catch (error) {
    yield put(stopCampaignFailure(error.response.data.error));
  }
}

export const campaignDashboardSagas = [
  takeEvery("GET_DASHBOARD_CAMPAIGNS", getCampaigns),
  takeEvery("GET_CAMPAIGNS_STATS", getCampaignStats),
  takeEvery("EXECUTE_CAMPAIGN", execute),
  takeEvery("STOP_CAMPAIGN", stop),
];

/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
  yield all([...campaignDashboardSagas]);
}
