/* eslint-disable no-redeclare */
/* eslint-disable no-fallthrough */
/* eslint-disable import/no-anonymous-default-export */
/*
    applications Reducer
    Curd Operations on applications.
*/
import * as _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
 GET_DOMAIN_REQUESTS,
 GET_DOMAIN_REQUESTS_SUCCESS,
 GET_DOMAIN_REQUESTS_FAILURE,
 PROCESS_DOMAIN_REQUESTS,
 PROCESS_DOMAIN_REQUESTS_SUCCESS,
 PROCESS_DOMAIN_REQUESTS_FAILURE
} from "../actions/types";

// initial state
const INIT_STATE = {
  domainRequests: [],
  selectedDomainRequest: null,
  loading:false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Get domain requests from server.
    case GET_DOMAIN_REQUESTS:
      return { ...state, loading: true };
    // On Success...
    case GET_DOMAIN_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        domainRequests: action.payload.data,
        };
    // On Failure, show error message.
    case GET_DOMAIN_REQUESTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    
    //PROCESS domain request
    case PROCESS_DOMAIN_REQUESTS:
      if (action.payload === "") {
        return { ...state, error: "Please select a domain request that you would like to process." };
      } else {
        
        return {
          ...state,
          loading: true,
          selectedDomainRequest: action.payload
        };
      }

    // On Success, show message 
    case PROCESS_DOMAIN_REQUESTS_SUCCESS:
      NotificationManager.success("Your request has been processed successfully.");
      return { ...state, loading: false, selectedDomainRequest: null };
    // On failure, show error message.
    case PROCESS_DOMAIN_REQUESTS_FAILURE:
      NotificationManager.error(action.payload);
      return { ...state, loading: false, selectedDomainRequest: null };
    
    default:
      return { ...state };
  }
};
